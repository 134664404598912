import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import 'moment/locale/ja'

import React from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import App from './App'
import { ErrorFallback } from './components/cards/ErrorFallback'

import 'antd/dist/antd.css'

import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)
