import { TScoreReportFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel. just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace comma character with `.` if needed.
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 *
 * Remove the `50.0` rows.
 */
export const theTScore2024Male: TScoreReportFromExcel = [
  [48.69, 52.53, 50.21, 49.19, 51.55, 47.54, 48.67, 49.19, 50.03, 49.27, 51.09],
  [48.73, 51.70, 51.09, 50.10, 51.06, 47.85, 48.92, 48.78, 50.32, 49.52, 52.10],
  [49.43, 53.61, 52.81, 50.69, 51.04, 49.17, 49.93, 49.40, 51.39, 49.75, 52.31],
  [49.61, 52.73, 53.04, 50.75, 50.08, 49.32, 50.23, 48.86, 51.20, 50.49, 52.56],
  [49.79, 51.22, 53.42, 50.22, 49.69, 49.51, 49.41, 48.68, 50.11, 50.09, 52.88],
  [49.96, 50.67, 53.48, 49.37, 48.77, 48.89, 49.03, 47.96, 49.62, 50.01, 52.48],
  [50.19, 51.16, 51.78, 47.98, 48.24, 49.66, 49.13, 48.95, 50.23, 49.98, 51.87],
  [49.74, 51.35, 52.23, 49.07, 49.21, 50.34, 48.56, 49.63, 50.79, 48.99, 51.33],
  [50.14, 50.33, 51.68, 48.68, 48.66, 50.60, 48.72, 49.46, 50.52, 49.51, 51.93],
  [49.60, 50.14, 50.78, 51.00, 50.50, 49.51, 49.00, 49.13, 49.63, 49.78, 52.70],
  [48.92, 49.53, 50.35, 50.29, 49.65, 48.80, 49.04, 49.15, 48.98, 49.70, 52.41],
  [49.10, 49.48, 49.91, 50.64, 50.53, 49.05, 48.93, 49.40, 49.39, 50.02, 52.18],
]

export const theTScore2024Female: TScoreReportFromExcel = [
  [49.01, 52.13, 50.33, 48.79, 52.06, 48.20, 49.64, 50.50, 50.72, 49.06, 51.00],
  [49.23, 51.88, 51.04, 50.23, 52.65, 48.84, 49.98, 50.25, 51.16, 49.79, 51.45],
  [49.24, 53.59, 53.20, 50.44, 51.36, 49.61, 49.79, 51.14, 51.92, 49.96, 51.80],
  [49.06, 52.83, 53.05, 51.13, 51.75, 49.32, 49.64, 50.22, 51.71, 49.17, 51.04],
  [49.32, 51.70, 52.95, 50.27, 50.66, 49.70, 49.56, 50.37, 50.85, 49.47, 51.33],
  [50.39, 52.10, 52.96, 50.15, 50.71, 49.73, 49.48, 50.19, 51.30, 49.65, 51.30],
  [49.48, 50.87, 52.84, 47.73, 49.59, 50.05, 47.80, 49.65, 49.98, 49.37, 51.37],
  [49.94, 50.86, 52.97, 48.31, 49.63, 50.57, 48.17, 49.52, 50.38, 48.46, 50.74],
  [50.05, 50.61, 52.88, 47.82, 49.63, 50.74, 48.39, 49.71, 50.39, 48.93, 51.00],
  [49.79, 50.48, 51.61, 51.23, 50.56, 49.97, 49.50, 49.19, 50.06, 49.39, 51.88],
  [49.31, 49.63, 51.63, 50.63, 50.44, 48.70, 49.50, 49.08, 49.67, 49.62, 51.80],
  [49.44, 49.67, 52.27, 51.11, 50.28, 48.79, 49.61, 49.54, 49.94, 49.66, 52.45]
]
