/**
 * To migrate data from Excel: just copy and paste the data from the Excel file.
 */
export const count2023 = {
  elementarySchoolReport: {
    schoolCount: 456,
    reports: [
      {
        grade: 1,
        male: {
          totalCount: 10953,
          samplingCount: 10614,
        },
        female: {
          totalCount: 10393,
          samplingCount: 10122,
        },
      },
      {
        grade: 2,
        male: {
          totalCount: 11307,
          samplingCount: 11009,
        },
        female: {
          totalCount: 10871,
          samplingCount: 10606,
        },
      },
      {
        grade: 3,
        male: {
          totalCount: 11519,
          samplingCount: 11183,
        },
        female: {
          totalCount: 10905,
          samplingCount: 10644,
        },
      },
      {
        grade: 4,
        male: {
          totalCount: 11576,
          samplingCount: 11166,
        },
        female: {
          totalCount: 10963,
          samplingCount: 10611,
        },
      },
      {
        grade: 5,
        male: {
          totalCount: 11727,
          samplingCount: 11232,
        },
        female: {
          totalCount: 11456,
          samplingCount: 11063,
        },
      },
      {
        grade: 6,
        male: {
          totalCount: 12020,
          samplingCount: 11429,
        },
        female: {
          totalCount: 11339,
          samplingCount: 10855,
        },
      },
    ],
  },
  juniorHighSchoolReport: {
    schoolCount: 229,
    reports: [
      {
        grade: 1,
        male: {
          totalCount: 11765,
          samplingCount: 10894,
        },
        female: {
          totalCount: 11017,
          samplingCount: 10194,
        },
      },
      {
        grade: 2,
        male: {
          totalCount: 11969,
          samplingCount: 10893,
        },
        female: {
          totalCount: 11153,
          samplingCount: 9975,
        },
      },
      {
        grade: 3,
        male: {
          totalCount: 12068,
          samplingCount: 10999,
        },
        female: {
          totalCount: 11285,
          samplingCount: 10100,
        },
      },
    ],
  },
  allDayHighSchoolReport: {
    schoolCount: 91,
    reports: [
      {
        grade: 1,
        male: {
          totalCount: 8297,
          samplingCount: 8033,
        },
        female: {
          totalCount: 7762,
          samplingCount: 7526,
        },
      },
      {
        grade: 2,
        male: {
          totalCount: 8322,
          samplingCount: 8072,
        },
        female: {
          totalCount: 7647,
          samplingCount: 7394,
        },
      },
      {
        grade: 3,
        male: {
          totalCount: 8063,
          samplingCount: 7879,
        },
        female: {
          totalCount: 7517,
          samplingCount: 7300,
        },
      },
    ],
  },
  fixTimeHighSchoolReport: {
    schoolCount: 13,
    reports: [
      {
        grade: 1,
        male: {
          totalCount: 312,
          samplingCount: 296,
        },
        female: {
          totalCount: 165,
          samplingCount: 143,
        },
      },
      {
        grade: 2,
        male: {
          totalCount: 323,
          samplingCount: 307,
        },
        female: {
          totalCount: 174,
          samplingCount: 163,
        },
      },
      {
        grade: 3,
        male: {
          totalCount: 300,
          samplingCount: 286,
        },
        female: {
          totalCount: 132,
          samplingCount: 120,
        },
      },
      {
        grade: 4,
        male: {
          totalCount: 63,
          samplingCount: 58,
        },
        female: {
          totalCount: 30,
          samplingCount: 30,
        },
      },
    ],
  },
}
