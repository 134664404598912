import { TestRankReportFromExcel, TestRankReportTotalsFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel, just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace comma character with `.` if needed.
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 */
export const testRank2023Male: TestRankReportFromExcel = [
  [10953, 1699, 2959, 3264, 1794, 898, 16.01, 27.88, 30.75, 16.9, 8.46],
  [11307, 1666, 2685, 3684, 2172, 802, 15.13, 24.39, 33.46, 19.73, 7.28],
  [11519, 2136, 3065, 3165, 1958, 859, 19.1, 27.41, 28.3, 17.51, 7.68],
  [11576, 2108, 2714, 2988, 2052, 1304, 18.88, 24.31, 26.76, 18.38, 11.68],
  [11727, 2146, 2606, 3191, 2167, 1122, 19.11, 23.2, 28.41, 19.29, 9.99],
  [12020, 2243, 3041, 3007, 2067, 1071, 19.63, 26.61, 26.31, 18.09, 9.37],
  [11765, 1010, 2524, 3362, 2900, 1098, 9.27, 23.17, 30.86, 26.62, 10.08],
  [11969, 1843, 3131, 3224, 1899, 796, 16.92, 28.74, 29.6, 17.43, 7.31],
  [12068, 3006, 3115, 2760, 1479, 639, 27.33, 28.32, 25.09, 13.45, 5.81],
  [8297, 1274, 2269, 2862, 1282, 346, 15.86, 28.25, 35.63, 15.96, 4.31],
  [8322, 1803, 2388, 2425, 1166, 290, 22.34, 29.58, 30.04, 14.44, 3.59],
  [8063, 2117, 2499, 2013, 1035, 215, 26.87, 31.72, 25.55, 13.14, 2.73],
  [312, 6, 32, 80, 101, 77, 2.03, 10.81, 27.03, 34.12, 26.01],
  [323, 7, 44, 92, 106, 58, 2.28, 14.33, 29.97, 34.53, 18.89],
  [300, 8, 51, 111, 80, 36, 2.8, 17.83, 38.81, 27.97, 12.59],
  [63, 0, 3, 17, 27, 11, 0.0, 5.17, 29.31, 46.55, 18.97],
]

export const testRank2023Female: TestRankReportFromExcel = [
  [10393, 1721, 2935, 3172, 1559, 735, 17.0, 29.0, 31.34, 15.4, 7.26],
  [10871, 1834, 2733, 3519, 1930, 590, 17.29, 25.77, 33.18, 18.2, 5.56],
  [10905, 2341, 3154, 2993, 1580, 576, 21.99, 29.63, 28.12, 14.84, 5.41],
  [10963, 2457, 3007, 2768, 1653, 726, 23.16, 28.34, 26.09, 15.58, 6.84],
  [11456, 2854, 2929, 3140, 1572, 568, 25.8, 26.48, 28.38, 14.21, 5.13],
  [11339, 2443, 3230, 2926, 1721, 535, 22.51, 29.76, 26.96, 15.85, 4.93],
  [11017, 3272, 3266, 2373, 1123, 160, 32.1, 32.04, 23.28, 11.02, 1.57],
  [11153, 3450, 2928, 2263, 1056, 278, 34.59, 29.35, 22.69, 10.59, 2.79],
  [11285, 3638, 2594, 2282, 1184, 402, 36.02, 25.68, 22.59, 11.72, 3.98],
  [7762, 1422, 2083, 2561, 1164, 296, 18.89, 27.68, 34.03, 15.47, 3.93],
  [7647, 1466, 2044, 2349, 1255, 280, 19.83, 27.64, 31.77, 16.97, 3.79],
  [7517, 1376, 2210, 2228, 1257, 229, 18.85, 30.27, 30.52, 17.22, 3.14],
  [165, 5, 11, 38, 48, 41, 3.5, 7.69, 26.57, 33.57, 28.67],
  [174, 3, 12, 40, 69, 39, 1.84, 7.36, 24.54, 42.33, 23.93],
  [132, 0, 13, 29, 51, 27, 0.0, 10.83, 24.17, 42.5, 22.5],
  [30, 2, 2, 7, 12, 7, 6.67, 6.67, 23.33, 40.0, 23.33],
]

export const testRank2023Totals: TestRankReportTotalsFromExcel = [
  [135029, 25648, 35058, 37817, 22225, 9786, 19.65, 26.86, 28.97, 17.03, 7.5],
  [69257, 16219, 17558, 16264, 9641, 3373, 25.72, 27.85, 25.79, 15.29, 5.35],
  [47608, 9458, 13493, 14438, 7159, 1656, 20.47, 29.2, 31.25, 15.49, 3.58],
  [1499, 31, 168, 414, 494, 296, 2.21, 11.97, 29.51, 35.21, 21.1],
]
