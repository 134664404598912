// prettier-ignore

import { CrossAnalysis } from "./type";

/**
 * 平均値
 * To migrate data from Excel:
 *   - On Excel, replace `,` character with `.` if needed.
 *   - Just select all data's cell (include rows and columns).
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with `,` character
 *   - Wrap each line by the `[]` character (an array) by many ways.
 *   - Use editor/IDE to format this file
 */
export const crossAnalysisAverage2023: CrossAnalysis = {
  /**
   * category: 運動部やスポーツクラブへの所属状況
   */
  q2: {
    male: [
  [3406,117.14,21.98,9.19,13.45,27.08,28.15,23.06,11.37,118.02,8.68,32.85]
,[7439,116.72,21.9,8.96,12.19,27,26.79,19.43,11.77,114.13,7.76,30.63]
,[4380,123.32,24.96,10.78,16.47,28.99,32.32,33.6,10.5,129.01,12.01,40.11]
,[6770,122.74,24.89,10.39,14.83,28.88,30.34,27.16,10.89,123.75,10.32,36.96]
,[4962,129,28.43,12.77,18.6,32.07,36.06,40.98,9.95,139.88,15.26,46.32]
,[6275,128.37,28.4,12.16,16.83,31.17,33.94,33.47,10.32,134.18,13.19,42.76]
,[5540,134.4,32.18,14.72,20.13,34.59,39.97,47.9,9.5,148.04,18.67,51.76]
,[5730,134.16,32.61,13.97,17.67,33.41,36.77,37.32,9.98,140.69,15.62,46.9]
,[5697,139.97,35.96,17.12,21.76,37.21,43.91,56,9.13,158.35,22.21,57.57]
,[5682,139.52,36.6,16.27,19.15,35.69,40.08,42.91,9.6,149.67,18.3,51.94]
,[5578,146.61,40.47,20.64,23.59,40.22,46.79,64.23,8.69,169.73,25.94,63.46]
,[6087,146.23,41.34,19.38,20.66,38.23,42.85,49.08,9.22,159.52,20.97,57.23]
,[8461,154.32,46.27,24.76,24.6,43.62,49.04,67.95,8.41,183.23,18.2,36.28]
,[2642,154.1,46.75,23.64,22.56,41.91,45.67,56.77,8.75,173.55,15.91,32.13]
,[8752,160.98,50.93,30.53,27.76,47.88,52.97,84.04,7.73,202.47,21.74,45.76]
,[2420,160.89,51.49,28.78,24.6,45.32,48.59,67.04,8.21,190.02,18.53,38.94]
,[8572,165.83,55.52,35.32,29.73,51.04,55.64,91.54,7.33,215.81,24.56,52.33]
,[2568,165.79,55.46,33.07,26.25,48.03,50.44,72.87,7.8,202.84,20.8,44.46]
,[4591,168.73,60.24,37.22,29.58,50.12,58.18,89.57,7.3,220.63,24.58,53.1]
,[3582,167.62,59.28,35.2,26.11,46.32,53.68,69.46,7.7,208.56,20.72,44.63]
,[4290,170.07,61.3,39.63,31.37,52.32,59.82,99,7.08,228.87,26.54,57.86]
,[3924,169.07,60.67,37.08,26.76,46.97,54.38,70.33,7.62,213.2,21.93,46.65]
,[3915,170.94,63.44,41.45,32.82,54.47,61.09,101.44,6.97,232.64,27.8,60.71]
,[4026,169.89,62.22,38.75,27.87,49.23,55.68,73.87,7.48,217.62,23.25,49.72]
    ],

    female: [
[2568,116.3,21.56,8.67,12.76,29.39,26.93,18.62,11.67,110.09,6.11,32.9],
[7704,115.87,21.45,8.49,11.92,29.3,26.32,16.88,11.96,107.91,5.7,31.37],
[3323,122.32,24.31,10.16,15.57,32.26,31.29,26.07,10.87,121.55,8.16,40.77],
[7389,121.86,24.22,9.9,14.36,31.6,29.72,22.77,11.1,117.14,7.42,38.22],
[3892,128.29,27.63,12.06,18.04,35.31,34.66,32.26,10.29,131.77,10.3,47.42],
[6785,127.75,27.51,11.64,16.42,34.32,33.23,27.43,10.53,126.87,9.38,44.48],
[3866,134.57,31.28,14.13,19.58,38.62,38.52,39.26,9.81,141.3,12.75,53.71],
[6836,134.49,31.68,13.66,17.6,37.16,36.35,32.59,10.07,135.96,11.34,50.03],
[4072,141.4,35.69,16.98,21.25,42.09,42.51,48.33,9.3,152.48,15.5,60.62],
[7050,141.5,36.23,16.49,19.04,40.29,39.38,38.21,9.68,144.06,13.31,55.7],
[3619,148.07,40.57,20.22,22.68,45.94,44.9,54.67,8.94,161.8,17.66,65.9],
[7407,147.85,41.35,19.44,19.96,43.48,41.59,41.39,9.34,151.21,14.77,60.24],
[6390,152.49,44.59,21.92,21.95,46.98,45.23,52.14,8.84,165.48,12.23,47.18],
[4010,152.37,45.23,21.12,19.67,45.89,42.33,40.69,9.33,154.14,10.39,40.88],
[6451,154.8,47.46,24.37,24.19,49.98,47.67,59.63,8.52,173.36,14.11,53.69],
[3893,155.15,47.81,22.98,20.76,48.22,43.56,43.4,9.13,157.66,11.21,44.45],
[5355,156.13,49.74,25.95,25.33,52.65,48.51,60.56,8.41,176.45,15.19,56.82],
[4021,156,49.77,24.12,21.48,50.36,44.19,43.05,9.03,162.15,12.06,46.89],
[2408,157.49,51.89,25.98,24.85,51.69,50.82,56.84,8.58,177.78,14.95,56.2],
[5259,156.4,51.33,24.62,21.57,48.86,46.98,42.73,9.07,164.71,12.26,47.4],
[2187,157.92,52.76,26.99,26.54,53.65,52.23,61.94,8.44,182.74,15.99,59.66],
[5335,156.93,52.49,25.22,21.72,49.62,46.91,41.62,9.14,165.23,12.76,48.03],
[2120,158.16,53.02,27.55,27.17,55.41,52.67,63.44,8.39,183.96,16.51,61.22],
[5287,157.3,52.63,25.64,22.55,51.32,47.6,42.04,9.15,165.93,13.07,49.35]
    ]
  },
  /**
   * 運動・スポーツの実施状況
   */
  q3: {
    male: [
[2533,117.13,21.96,9.43,13.8,27.71,28.33,24.15,11.22,120.38,9.29,33.98],
[5712,116.85,21.93,9.02,12.63,26.89,27.29,20.57,11.6,115.53,8,31.33],
[1521,116.55,21.82,8.74,11.42,26.6,26.31,17.48,12.07,111.08,7.06,29.07],
[1056,116.59,21.99,8.58,11.1,26.67,25.53,16.5,12.29,108.6,6.8,28.2],
[3498,123.33,24.9,10.96,16.96,29.45,32.65,35.78,10.36,131.63,12.75,41.36],
[5373,122.92,24.86,10.49,15.24,28.69,30.89,28.47,10.78,125.06,10.55,37.65],
[1314,122.59,25,10.13,13.92,28.49,29.46,23.59,11.16,119.8,9.39,34.94],
[928,122.44,25.21,9.89,13.46,28.9,29.09,22.68,11.34,117.14,9.19,34.26],
[4668,128.95,28.38,12.9,19.22,32.44,36.86,43.29,9.83,141.91,15.92,47.57],
[4263,128.6,28.31,12.26,17.01,31.32,34.16,34.38,10.23,135.47,13.43,43.25],
[1258,128.17,28.47,11.84,15.81,30.46,33.06,29.81,10.53,130.24,12.03,40.71],
[990,128.07,28.81,11.7,14.95,29.96,31.09,25.51,10.9,126.09,11.08,38.33],
[5667,134.53,32,14.91,20.64,34.98,40.62,50.1,9.4,149.99,19.23,52.89],
[3687,134.19,32.79,14.03,17.87,33.43,37.03,37.37,9.92,141.38,15.83,47.17],
[1063,133.75,32.44,13.29,16.06,32.23,34.97,31.79,10.3,136.23,13.88,43.84],
[775,133.61,33.31,13.12,14.83,32.05,32.87,27.01,10.7,128.15,12.4,40.61],
[5982,139.89,35.63,17.34,22.21,37.69,44.35,58.13,9.03,160.26,22.7,58.59],
[3629,139.69,36.93,16.18,19.13,35.59,40.37,42.36,9.59,149.59,18.3,51.84],
[962,139.45,37.07,15.57,17.43,34.31,38,35.31,9.98,142.8,16.13,48.1],
[670,139.21,37.41,15.41,16.53,33.56,36.11,32.29,10.26,139.08,15.18,45.98],
[6026,146.46,40.03,20.78,24.01,40.66,47.42,66.43,8.61,171.5,26.57,64.46],
[3711,146.25,41.57,19.36,20.69,38.16,42.99,48.31,9.19,159.5,20.92,57.18],
[1108,146.71,42.72,18.79,18.9,36.79,40.17,40.3,9.54,152.73,18.05,53.1],
[635,146.32,42.19,18.21,17.36,35.7,38.02,37.14,9.98,147.76,16.68,50.39],
[8083,154.29,46.08,25.04,25.13,44.06,49.73,70.7,8.32,185.49,18.75,37.43],
[1640,154.3,46.78,23.25,22.1,41.45,45.28,53.64,8.78,171.58,15.3,30.61],
[674,154.23,48.1,22.74,20.3,40.54,42.68,46.49,9.17,164.44,13.95,27.56],
[464,153.75,47.37,22.03,19.3,39.02,41.54,41.52,9.36,159.55,12.74,25.9],
[7766,160.98,50.83,30.74,28.16,48.32,53.47,85.91,7.68,204.22,22.17,46.62],
[1682,161.06,52.21,28.82,24.49,45.03,48.25,65.54,8.17,188.23,18.16,38.14],
[747,160.93,51.66,27.45,22.16,42.31,45.95,55.86,8.49,180.23,16.2,33.63],
[658,160.87,51.7,26.98,21.28,41.86,44.67,53.56,8.73,178.24,15.15,32.33],
[7766,165.79,55.57,35.63,30.25,51.61,56.35,94.07,7.28,217.6,25.18,53.61],
[1682,165.91,55.13,33.7,26.97,48.12,51.14,76.86,7.64,205.32,21.21,45.51],
[747,165.7,56.1,32.17,25.16,47.33,49.42,66.3,7.94,199.06,19.61,41.4],
[658,165.99,55.04,31.12,23.15,45.21,47.74,60.72,8.17,192.96,17.67,38.31],
[4655,168.73,60.23,37.4,29.68,50.19,58.28,89.83,7.29,220.99,24.71,53.33],
[1387,167.83,59.91,35.75,26.94,47.35,54.89,73.09,7.6,212.02,21.68,46.63],
[941,167.49,59.99,34.71,25.42,45.66,52.91,67.46,7.77,206.6,20.06,43.2],
[1155,167.37,57.84,34.12,25.05,45.1,52.23,64.58,7.84,203.83,19.36,41.91],
[4432,170.14,61.26,39.65,31.43,52.28,59.82,98.95,7.08,228.85,26.52,57.86],
[1314,168.99,61.65,37.59,27.59,48.09,55.19,73.43,7.54,215.45,22.76,48.37],
[980,168.94,61.08,37.03,26.53,46.93,54.01,68.5,7.65,212.4,21.8,46.07],
[1429,168.91,59.52,36.26,25.39,45.44,53.2,65.31,7.75,209.66,20.65,44.02],
[3822,170.97,63.53,41.59,32.95,54.43,61.12,101.59,6.96,232.48,27.87,60.86],
[1451,170.16,62.84,39.93,29.44,51.04,57.53,80.9,7.32,223,25.01,53.46],
[1048,169.92,63.12,38.73,27.41,49.19,55.51,71.79,7.54,216.91,22.87,48.85],
[1568,169.6,60.86,37.48,26.57,47.86,54.18,69.34,7.6,213.89,21.89,46.92
    ],],
    female: [
[1799,116.28,21.46,8.86,13.24,29.91,27.34,19.57,11.56,112.77,6.27,34.08],
[5404,116.02,21.51,8.56,12.2,29.28,26.57,17.58,11.86,108.65,5.85,31.93],
[1757,115.73,21.42,8.35,11.49,29.09,25.92,15.76,12.11,105.8,5.51,30.35],
[1299,115.75,21.44,8.23,11.22,28.95,25.67,15.26,12.2,105.32,5.4,29.73],
[2295,122.35,24.14,10.39,16.2,32.62,31.57,27.92,10.71,123.21,8.46,42.09],
[5582,122.06,24.29,9.99,14.72,31.8,30.27,23.74,11.01,118.8,7.65,39.07],
[1556,121.7,24.36,9.72,13.71,31.38,29.14,20.82,11.28,115.17,7.07,36.79],
[1224,121.47,24.15,9.49,13.31,30.85,28.79,20.2,11.39,112.75,6.94,35.86],
[3393,128.14,27.42,12.17,18.52,35.86,35.34,34.38,10.17,133.77,10.56,48.68],
[4890,127.98,27.58,11.72,16.68,34.49,33.52,28.01,10.48,127.81,9.53,44.98],
[1351,127.61,27.59,11.49,15.93,33.55,32.37,25.38,10.7,124.53,9,42.96],
[1004,127.62,27.84,11.33,15,33.18,31.55,22.71,10.83,121.65,8.72,41.28],
[4139,134.62,31.02,14.26,20.06,39,39.31,41.47,9.67,143.72,13.05,55],
[4461,134.46,31.7,13.7,17.72,37.36,36.43,32.48,10.06,135.95,11.42,50.19],
[1275,134.53,32.2,13.3,16.38,36.14,34.84,28.24,10.34,131.67,10.54,47.19],
[766,134.24,32.16,13.13,15.58,35.2,33.51,26.01,10.53,128.09,10.12,45.42],
[4335,141.42,35.3,17.17,21.83,42.41,43.02,50.65,9.22,154.38,15.69,61.65],
[4746,141.41,36.39,16.51,19.13,40.4,39.73,37.96,9.63,144.67,13.47,56.02],
[1262,141.68,36.63,16.06,17.85,39.7,37.67,33.36,9.96,138.87,12.36,52.92],
[677,141.73,37,15.66,16.22,38.07,35.8,30.46,10.1,134.19,11.9,50.18],
[3791,147.88,40.34,20.4,23.29,46.17,45.54,56.87,8.86,163.31,18.04,66.9],
[4509,147.86,41.27,19.54,20.25,44.09,42.03,42.2,9.27,152.96,15.04,61.03],
[1744,148.16,41.94,19.02,18.69,42.41,40.16,37.04,9.52,146.84,13.72,57.69],
[847,147.93,41.84,18.79,17.75,40.86,38.7,33.75,9.76,141.93,13.21,55.4],
[6079,152.42,44.56,22.11,22.47,47.59,45.74,53.6,8.79,166.9,12.4,48.24],
[2259,152.54,45.1,21.12,19.79,45.75,42.69,41.86,9.22,156.02,10.7,41.48],
[1176,152.4,45.3,20.78,18.57,45.04,41.18,37.26,9.46,151.28,9.94,38.54],
[675,152.44,45.29,20.42,17.49,43.07,39.93,33.6,9.73,144.09,9.34,35.65],
[6306,154.79,47.48,24.54,24.51,50.48,48.07,60.88,8.48,174.42,14.23,54.51],
[1808,155.03,47.61,23.14,21.33,48.59,44.02,44.9,9.01,160.92,11.73,45.84],
[1083,155.43,48.15,22.52,19.84,47.17,42.63,40.29,9.23,154.17,10.74,42.01],
[847,155.02,47.8,22.13,18.55,45.18,41.13,36.15,9.52,148.39,10.05,38.96],
[5647,156.08,49.76,26.17,25.86,53.3,49.13,62.52,8.35,177.95,15.52,57.98],
[2073,156.02,49.83,24.6,22.6,50.78,45.12,46.91,8.84,166.54,12.75,49.51],
[1328,156.27,49.91,23.9,21.02,49.63,43.55,42.03,9.08,160.46,12.06,45.95],
[1082,155.92,49.04,23.57,19.93,48.65,43.12,39.01,9.19,157.81,11.25,43.8],
[2512,157.45,51.97,26.01,24.8,51.72,50.7,56.33,8.59,177.41,14.85,55.93],
[1625,156.64,51.86,24.95,22.78,50.13,48.17,45.8,8.93,168.49,12.9,49.98],
[1521,156.32,51.42,24.76,21.66,48.84,47.1,43.19,9.07,164.35,12.34,47.58],
[1986,156.26,50.72,24.12,20.38,47.6,45.83,39.63,9.2,161.56,11.64,44.9],
[2253,157.83,52.76,26.92,26.31,53.63,51.9,61.32,8.46,181.85,15.81,59.16],
[1449,157.25,53.06,25.79,23.22,51.7,48.4,45.02,8.99,169.63,13.57,51.21],
[1457,157.02,53.07,25.31,21.93,49.91,47.28,41.74,9.1,165.7,12.94,48.61],
[2328,156.7,51.75,24.8,20.69,48.04,45.85,39.38,9.27,162.43,12.2,45.75],
[2051,158.17,53.14,27.57,27.3,55.58,52.61,63.1,8.41,183.2,16.34,60.98],
[1344,157.48,53.45,26.21,23.8,52.93,48.91,46.03,8.96,170.07,13.86,52.43],
[1460,157.49,53.18,25.69,22.82,51.8,47.7,42.54,9.1,166.51,13.38,50.01],
[2522,157.13,51.8,25.34,21.74,50.16,47.01,40.48,9.23,164.36,12.67,47.83
    ],]
  },
  /**
   * 1日の運動・スポーツの実施時間
   */
  q4: {
    male: [
[2439,116.37,21.73,8.71,11.44,26.78,26.15,17.56,12.07,111,7.11,29.15],
[4095,116.87,21.96,8.96,12.37,26.92,26.94,19.89,11.69,114.25,7.77,30.8],
[3379,117.06,21.95,9.24,13.24,27.04,27.81,22.31,11.42,118,8.46,32.53],
[906,117.28,22.19,9.5,14.25,28.08,29.22,25.33,11.12,122.33,10.3,35.11],
[2258,122.51,24.91,10.03,13.83,28.51,29.43,23.75,11.16,119.64,9.39,34.98],
[3546,122.78,24.82,10.4,15.04,28.6,30.58,27.46,10.85,123.83,10.27,37.04],
[3497,123.22,24.96,10.72,15.98,29.15,31.62,31.6,10.59,128.22,11.35,39.28],
[1811,123.43,25.03,11.15,17.43,29.62,33.37,37.88,10.27,132.91,13.68,42.48],
[2485,127.98,28.44,11.69,15.59,30.39,32.07,28.26,10.66,129.19,11.75,39.88],
[2929,128.63,28.32,12.25,17.02,31.29,34.24,34.05,10.24,134.64,13.04,43.04],
[2932,128.81,28.39,12.59,18.03,31.78,35.62,38.57,10.06,138.97,14.17,45.27],
[2835,129.1,28.47,13.1,19.57,32.68,37.28,45.31,9.73,143.2,17.19,48.61],
[2116,133.65,32.67,13.26,15.87,32.38,34.4,30.67,10.37,133.75,13.35,42.96],
[2696,134.06,32.38,13.96,17.75,33.3,36.86,37.56,9.92,141.74,15.45,47.02],
[2843,134.32,32.29,14.43,19.28,34.51,39.14,43.72,9.65,146.71,17.09,50.28],
[3540,134.79,32.31,15.22,21.25,35.11,41.26,52.57,9.3,150.71,20.7,54.03],
[1862,139.36,37.2,15.51,17.25,34.3,37.52,35.01,9.98,142.13,15.71,47.78],
[2538,139.69,36.64,16.17,19.01,35.86,40.03,41.78,9.61,149.05,17.82,51.57],
[2904,139.62,36.13,16.66,20.67,36.5,42.32,49.92,9.31,155.61,19.88,55.09],
[3935,140.05,35.72,17.62,22.78,37.89,45.24,61.17,8.95,161.78,24.3,59.88],
[2045,146.2,42.24,18.49,18.56,36.68,40.05,40.34,9.65,151.59,17.91,52.74],
[2472,146.17,41.26,19.31,20.7,38.05,42.94,48.4,9.19,159.7,20.86,57.13],
[3106,146.44,40.63,20.1,22.58,39.58,45.42,57.95,8.86,166.49,23.08,61.13],
[3858,146.66,40.2,21.12,24.42,40.98,47.92,68.94,8.54,172.85,28.12,65.5],
[1257,154.1,47.29,22.43,20.15,39.78,43.08,46.36,9.14,164.02,13.81,27.57],
[1526,154.27,46.66,23.47,22.1,41.78,45.32,54.73,8.84,173.2,15.42,31.01],
[3778,154.41,46.35,24.29,23.78,42.71,48.03,64.21,8.49,180.67,17.43,34.52],
[4304,154.18,46.02,25.65,26.3,45.22,51.04,75.75,8.17,189.01,19.82,39.76],
[1274,160.74,51.29,27.53,22.55,43.1,46.27,58.8,8.45,183.38,16.46,34.84],
[1323,160.96,51.3,28.77,24.96,45.12,49.12,68.91,8.13,190.57,18.59,39.1],
[3909,161.11,51.08,30.03,27.03,47.24,52.08,81.21,7.8,200.12,20.8,44.12],
[4387,160.93,50.93,31.5,29.16,49.32,54.69,89.86,7.57,207.55,23.43,48.89],
[1543,165.86,55.52,31.99,24.69,46.29,49.07,66.59,7.98,197.33,19.18,41.08],
[1725,166.01,55.55,33.68,27.14,48.61,51.69,78.2,7.62,206.41,21.74,46.3],
[3774,165.85,55.19,34.7,29.26,50.64,55.16,89.57,7.38,214.27,23.83,51.16],
[3813,165.67,55.8,36.6,31.23,52.61,57.46,98.09,7.17,220.94,26.39,55.86],
[2164,167.47,58.58,34.49,25.57,45.71,53.14,67.53,7.77,206.69,19.97,43.31],
[1306,167.71,60.08,35.8,26.69,46.97,54.59,72.92,7.63,211.1,21.48,46.25],
[1621,168.36,59.95,36.52,28.34,48.64,56.49,82.62,7.43,216.69,23.12,50.07],
[3038,168.96,60.48,37.8,30.29,50.95,58.97,92.68,7.23,222.67,25.48,54.75],
[2506,168.96,59.9,36.51,26.12,46.42,53.84,68.1,7.69,211.25,21.19,45.31],
[1236,169.19,62.12,38.13,27.7,48.51,55.2,73.97,7.52,215.99,22.96,48.8],
[1445,169.59,61.27,38.4,29.65,50.36,57.91,88.51,7.26,223.31,24.86,53.63],
[2968,170.3,61.34,40.14,32.12,52.83,60.57,102.91,7.01,231.18,27.27,59.54],
[2768,169.69,61.5,38.2,27.46,48.86,55.29,72.87,7.53,216.75,22.76,48.85],
[1354,170.52,63.38,40.06,29.47,50.99,57.45,80.44,7.32,222.69,24.59,53.2],
[1243,170.21,62.82,39.96,30.61,51.81,58.89,90.1,7.17,225.1,25.71,55.79],
[2525,171.23,63.96,42.24,33.73,55.45,61.87,105.8,6.88,235.15,28.87,62.76
    ],],
    female: [
[2881,115.78,21.47,8.38,11.4,29.06,25.87,15.87,12.1,106.06,5.51,30.36],
[4239,115.94,21.4,8.47,12.07,29.08,26.41,17.28,11.89,108.38,5.76,31.56],
[2663,116.18,21.56,8.73,12.85,29.86,27.06,18.42,11.72,110.43,6.06,33.12],
[474,116.42,21.67,8.96,13.24,29.8,27.52,20.44,11.58,112.8,6.62,34.42],
[2856,121.64,24.19,9.62,13.82,31.01,29,21.02,11.27,114.41,7.07,36.7],
[4027,121.99,24.17,9.96,14.47,31.75,30.12,23.45,11.02,118.36,7.58,38.75],
[2964,122.28,24.38,10.2,15.44,32.31,31,25.44,10.91,121.12,7.95,40.48],
[810,122.34,24.41,10.55,16.65,33.06,32.09,29.47,10.66,124.34,9,43.19],
[3141,127.67,27.5,11.42,15.6,33.56,32.32,25.15,10.72,124.41,8.97,42.73],
[3583,127.96,27.56,11.79,16.87,34.59,33.52,28.1,10.44,128.09,9.56,45.19],
[2522,128.14,27.61,12,17.74,35.55,34.7,31.45,10.3,131.65,10.01,47.19],
[1392,128.2,27.57,12.3,19.26,35.88,36,37.11,10.1,134.7,11.27,49.9],
[2990,134.38,31.91,13.34,16.32,36.17,34.9,28.77,10.3,130.97,10.6,47.39],
[3525,134.42,31.57,13.72,17.96,37.39,36.67,32.89,10,137.51,11.47,50.64],
[2428,134.75,31.32,14.07,19.27,39.02,38.41,38.29,9.82,141.65,12.33,53.52],
[1694,134.61,31.07,14.57,21.24,39.16,40.34,45.82,9.57,145.61,14.18,56.82],
[2820,141.53,36.3,16.13,17.85,39.46,38.03,34.09,9.86,139.83,12.45,53.29],
[3528,141.45,36.23,16.48,19.16,40.48,39.6,38.23,9.63,145.45,13.49,56.11],
[2836,141.36,35.8,16.84,20.76,41.79,41.72,44.32,9.39,150.08,14.51,59.25],
[2103,141.53,35.65,17.47,22.47,42.66,43.84,55.21,9.15,155.91,16.82,63.1],
[3097,147.77,41.43,18.99,18.73,42.58,40.32,37.23,9.54,146.64,13.81,57.8],
[3125,147.89,41.38,19.51,20.19,43.49,41.81,41.68,9.28,152.18,14.83,60.7],
[2570,148.03,40.89,20.03,21.88,45.73,43.76,48.08,9.07,158.64,16.16,64.09],
[2100,148.06,40.43,20.62,23.79,46.28,46.22,61.89,8.76,165.72,19.38,68.15],
[2200,152.32,45.26,20.73,18.42,44.32,41.35,37.32,9.5,150.16,9.86,38.36],
[2008,152.35,45.11,21.14,19.99,45.86,42.52,42.04,9.2,155.81,10.51,41.51],
[3332,152.54,44.59,21.67,21.39,46.72,44.56,49.07,8.94,163.3,11.74,45.59],
[2653,152.5,44.48,22.68,23.78,48.86,47.22,59.26,8.61,171.81,13.46,51.62],
[2383,154.96,47.95,22.66,19.91,46.67,42.84,40.99,9.25,154.89,10.86,42.5],
[1745,155.06,47.69,23.2,21.54,48.56,44.51,46.33,8.99,161.21,11.89,46.41],
[3078,154.81,47.37,24.19,23.71,49.99,47.12,56.7,8.58,171.66,13.51,52.46],
[2838,154.92,47.5,24.93,25.5,51.35,48.99,65.79,8.35,177.93,15.12,56.98],
[2654,156.01,49.56,24.02,20.96,49.71,43.97,42.35,9.07,160.95,11.8,46.06],
[2097,156,49.8,24.51,22.59,50.66,44.95,46.88,8.86,166.01,12.89,49.39],
[2983,156.05,49.79,25.82,25.18,52.7,48.39,58.93,8.43,175.26,14.81,56.2],
[2396,156.25,49.74,26.67,26.81,54.02,50.17,67.63,8.22,181.97,16.58,60.62],
[3735,156.45,50.88,24.46,21.3,48.64,46.68,41.99,9.11,163.95,12.06,46.73],
[1507,156.24,52.11,24.9,22.38,49.59,47.77,45.2,8.98,166.57,12.76,49.17],
[1072,157.11,51.75,25.66,24,50.75,50.15,52.28,8.69,174.73,14.06,53.88],
[1330,157.86,52.45,26.35,25.38,52.18,51.31,59.66,8.47,180.24,15.65,57.85],
[3946,156.87,52.23,25.06,21.46,49.18,46.55,40.77,9.18,164.45,12.53,47.32],
[1306,157.03,52.97,25.74,22.77,51.03,48.13,44.76,8.99,168.75,13.42,50.62],
[905,157.62,52.76,26.36,24.99,53.11,50.59,53.45,8.71,176.69,14.88,55.77],
[1326,158.11,52.98,27.27,27.12,53.78,52.94,66.38,8.33,184.91,16.64,61.34],
[4154,157.25,52.23,25.55,22.37,51.08,47.49,41.9,9.16,165.88,13,49.11],
[1300,157.48,53.57,26.15,23.64,52.86,48.69,45.6,8.99,169.04,13.75,51.94],
[788,158.12,52.6,26.88,25.71,54.53,51.48,55.42,8.61,178.89,15.33,57.7],
[1137,158.37,53.72,28.09,28.37,55.9,53.47,69.17,8.24,187,17.34,63.63
    ],],
  },
  /**
   * 朝食の有無
   */
  q5: {
    male: [
[9949,116.9,21.9,9.05,12.61,27.02,27.29,20.78,11.62,115.54,8.06,31.41],
[800,116.33,22.21,8.92,12.34,27.08,26.58,18.32,11.81,113.68,7.99,30.55],
[71,116.35,22.12,8.2,12.3,27.14,26.43,17.84,12.49,109.93,7.43,29.87],
[9978,123.07,24.91,10.57,15.54,28.91,31.26,30.17,10.71,126.1,11.03,38.38],
[1005,121.98,24.91,10.4,14.86,28.92,30.05,25.75,10.92,123.45,10.7,36.83],
[126,122.75,25.57,10.06,15.94,29.54,29.19,25.94,10.91,124.86,10.4,37.32],
[9548,128.71,28.34,12.44,17.73,31.62,35.06,37.36,10.13,137.14,14.16,44.56],
[1431,128.28,28.8,12.42,17.03,31.4,34.07,33.88,10.28,134.75,13.77,43.27],
[203,128.4,28.63,12.13,16.73,30.53,32.82,32.23,10.32,132.61,13.93,41.93],
[9494,134.38,32.32,14.39,19.07,34.09,38.61,43.48,9.71,145.1,17.3,49.7],
[1470,133.74,32.87,14.12,17.92,33.59,37.08,37.87,9.92,140.39,16.25,47.36],
[228,133.51,32.45,14.08,17.83,33.06,36.91,36.67,9.98,139.27,16.21,46.83],
[9331,139.86,36.14,16.77,20.72,36.59,42.43,50.98,9.31,155.12,20.49,55.33],
[1640,139.19,36.91,16.41,19.47,36.01,40.35,43.36,9.59,149.65,19.36,52.5],
[273,139.05,37.3,15.96,18.21,35.69,39.07,40.67,9.78,146.24,18.88,50.95],
[9441,146.48,40.7,19.99,22.29,39.31,45.18,57.73,8.92,165.38,23.58,60.72],
[1686,146.1,41.92,19.97,21.16,38.51,42.96,51.11,9.12,160.45,22.59,58.26],
[351,146.03,41.65,19.87,21.02,39.82,42.94,49.25,9.37,161.58,22.13,58.15],
[9022,154.27,46.16,24.48,24.31,43.4,48.72,66.84,8.45,182,17.74,35.74],
[1545,154.39,47.21,24.53,23.35,42.58,46.37,59.39,8.6,176.56,17.43,33.58],
[293,153.21,48.46,24.94,22.83,41.79,45.19,54.64,8.77,175.9,17.47,32.83],
[8777,161,50.74,30.19,27.41,47.65,52.63,82.56,7.78,201.39,21.22,45.01],
[1682,160.89,52.43,30.19,26.06,46.63,50.33,73.42,7.98,195.2,20.73,42.33],
[431,160.93,52.34,30.04,25.36,44.16,48.53,70.16,8.12,191.61,20.28,40.15],
[8675,165.85,55.37,34.88,29.23,50.74,55.09,89.29,7.4,213.92,23.89,51.26],
[1712,165.54,55.73,34.7,28.24,49.13,52.87,82.05,7.51,210.58,23.36,48.77],
[462,166.06,57.28,34.5,26.84,48.81,51.05,75.89,7.69,204.96,22.77,46.67],
[6335,168.35,59.73,36.37,28.35,48.84,56.72,82.7,7.44,216.75,22.97,50.11],
[1226,167.99,60.22,36.27,27.06,47.4,54.77,75.15,7.58,211.1,22.76,47.16],
[564,167.55,59.94,36.16,27.01,46.51,53.64,71.18,7.62,208.49,22.31,46.1],
[6028,169.8,60.95,38.61,29.58,50.48,58.04,88.43,7.28,223.33,24.47,53.61],
[1349,169.25,61.44,38.04,28.16,48.48,55.45,78.2,7.47,217.26,23.96,49.94],
[775,168.56,60.62,37.58,27.75,46.52,53.97,73.46,7.59,213.3,23.83,48.13],
[5717,170.57,62.76,40.28,30.82,52.56,59.13,90.95,7.17,226.59,25.7,56.35],
[1339,169.88,63.02,39.8,29.3,50.04,56.78,80.82,7.34,221.48,25.07,52.79],
[821,170.17,62.94,39.29,28.4,49.48,55.34,74.1,7.5,219.42,24.72,50.65
    ],],
    female: [
[9640,116.02,21.45,8.54,12.17,29.29,26.55,17.45,11.87,108.69,5.81,31.85],
[733,115.46,21.76,8.44,11.68,29.77,25.78,15.96,12.08,106.05,5.73,30.81],
[67,115.59,21.77,8.25,12.16,28.24,24.55,14.79,12.45,102.72,5.84,29.55],
[9683,122.03,24.23,9.98,14.79,31.8,30.31,24.01,11.01,118.7,7.67,39.13],
[883,121.72,24.55,10.01,14.28,31.86,29.19,22.04,11.17,117.2,7.52,38.13],
[90,121.64,24.15,9.18,13.49,31.42,29.51,19.91,11.52,113.53,7.42,36],
[9352,127.97,27.47,11.8,17.09,34.68,33.93,29.54,10.42,129.1,9.75,45.76],
[1144,127.86,28.13,11.82,16.62,34.68,32.81,27.14,10.58,126.36,9.55,44.4],
[141,127.29,28.63,11.19,15.3,35.19,31.04,24.02,10.83,122.39,9.13,42.08],
[9223,134.44,31.35,13.82,18.41,37.78,37.35,35.54,9.95,138.3,11.88,51.62],
[1267,135.01,32.73,13.93,17.78,37.18,36.1,32.02,10.11,135.79,11.71,50.04],
[150,135.09,32.75,13.93,17.68,37.17,34.59,30.15,10.13,132.91,11.49,49.01],
[9199,141.4,35.75,16.6,19.99,40.97,40.8,42.79,9.51,147.88,14.18,57.84],
[1608,141.73,37.28,17.06,19.35,41.03,39.49,38.57,9.65,144.15,13.9,56.26],
[212,142.05,38.36,16.86,18.7,40.75,37.9,33.53,9.8,140.73,13.37,54.55],
[8727,147.86,40.7,19.63,21.1,44.36,43.11,47.07,9.17,155.88,15.82,62.56],
[1874,148.26,42.71,20.05,20.17,44.06,41.24,41.41,9.33,150.65,15.54,60.69],
[287,147.54,42.51,19.55,18.68,44.09,40.04,37.7,9.49,147.34,14.7,58.63],
[8038,152.46,44.55,21.61,21.31,46.53,44.54,49.07,8.98,162.39,11.58,45.32],
[1894,152.37,45.58,21.64,20.37,47,42.97,43.85,9.19,157.43,11.48,43.33],
[260,152.45,47.26,22.02,19.88,45.74,41.34,39,9.22,152.78,11.07,41.21],
[7751,154.95,47.28,23.92,23.21,49.54,46.61,55.42,8.69,169.09,13.14,51.04],
[1953,154.87,48.57,23.7,22.19,48.81,45.01,49.06,8.87,163.5,12.84,48.51],
[339,154.58,49.49,23.67,21.28,48.31,43.59,44.14,9.06,159.92,12.8,46.48],
[7718,156.08,49.58,25.41,24.27,52.19,47.44,55.93,8.58,172.42,14.15,54.03],
[2042,156.11,50.28,25.01,22.93,50.81,45.57,49.1,8.8,167.85,13.74,50.9],
[369,155.94,49.5,24.16,22.45,49.39,44.44,46.69,8.83,165.22,13.89,49.53],
[5948,156.91,51.4,25.11,22.86,50.05,48.49,48.32,8.88,169.94,13.23,50.83],
[1369,156.19,51.78,24.8,21.71,48.76,47.09,43.34,9.05,164.89,12.59,47.7],
[322,156.01,52.59,24.91,21.68,48.1,47.14,41.33,9.08,164.69,12.92,47.74],
[5510,157.31,52.41,25.82,23.54,51.27,49,49.22,8.87,171.69,13.82,52.36],
[1490,157.07,52.95,25.54,22.21,49.97,47.33,43.88,9.1,167.67,13.5,49.44],
[483,156.61,53.22,25.3,20.98,47.8,45.36,39.27,9.27,162.27,12.93,46.18],
[5517,157.69,52.69,26.34,24.29,53.01,49.59,49.87,8.85,172.67,14.17,53.74],
[1432,157.14,53,25.84,22.76,51.48,47.7,43.93,9.12,167.26,13.75,50.34],
[421,157.16,52.62,25.41,22.12,49.25,46.62,40.64,9.31,162.56,13.49,47.75
    ],]
  },
  /**
   * 1日の睡眠時間
   */
  q6: {
    male: [
[88,116.33,21.87,8.84,12.73,25.61,27.52,18.2,12.04,113.26,7.99,30.74],
[2183,116.83,22.23,8.99,12.56,27.35,26.63,18.93,11.75,114.03,7.85,30.77],
[8534,116.86,21.85,9.05,12.6,26.95,27.37,21.04,11.61,115.72,8.1,31.49],
[280,123.15,24.93,10.35,15.77,28.19,30.05,26.84,10.75,123.55,10.55,37.25],
[2763,122.93,25.35,10.5,15.37,29.05,30.64,27.87,10.84,124.86,10.94,37.65],
[8055,122.97,24.77,10.57,15.51,28.9,31.34,30.45,10.7,126.27,11.02,38.46],
[911,128.4,28.72,12.4,17.39,31.36,33.74,34.56,10.23,134.62,13.87,43.32],
[3544,128.69,28.59,12.42,17.43,31.39,34.53,35.28,10.19,135.59,13.77,43.75],
[6690,128.67,28.27,12.45,17.76,31.7,35.24,37.95,10.13,137.62,14.32,44.81],
[887,133.85,32.85,14.15,17.78,33.31,36.18,37.49,9.98,139.4,16.02,46.56],
[4150,134.3,32.63,14.28,18.53,33.75,37.99,40.92,9.77,143.86,16.79,48.72],
[6133,134.32,32.16,14.42,19.31,34.29,38.95,44.5,9.69,145.44,17.54,50.16],
[772,139.5,37.34,16.56,19.52,35.17,39.82,43.09,9.71,148.03,18.56,51.74],
[4701,139.89,36.7,16.65,20.33,36.19,41.72,47.81,9.41,152.98,19.93,54.23],
[5736,139.66,35.78,16.76,20.74,36.91,42.63,52.04,9.27,155.88,20.82,55.73],
[834,147.06,43.06,20.41,21.18,38.57,42.09,47.88,9.18,158.64,22.21,57.52],
[5349,146.69,41.5,20.1,21.84,38.9,44.45,55.32,8.98,164.09,23.06,59.85],
[5249,146.02,39.96,19.8,22.48,39.62,45.55,59.09,8.9,165.89,23.93,61.17],
[1117,154.34,48.72,25.35,23.27,42.17,45.52,57.36,8.63,176.3,17.12,33.27],
[6616,154.17,46.63,24.54,24.02,43.05,48.35,65.53,8.48,181.41,17.62,35.23],
[3115,154.41,44.99,24.12,24.66,44.03,49.17,68.2,8.44,182.03,18.06,36.36],
[1404,160.65,52.69,30.75,25.95,46.43,50.11,73.76,7.9,196.23,20.37,42.41],
[7255,161.05,51.09,30.21,27.3,47.31,52.35,81.39,7.81,200.75,21.09,44.61],
[2226,160.97,49.95,29.77,27.29,48.08,52.65,82.83,7.8,200.28,21.63,45.11],
[1746,165.99,56.3,34.9,28,49.6,52.76,81,7.58,208.66,22.96,48.39],
[7767,165.79,55.41,34.8,29.16,50.47,54.94,89.05,7.4,213.84,23.79,51.07],
[1333,165.75,55.01,34.93,29.19,51.11,54.82,87.99,7.44,213.99,24.59,51.45],
[1588,168.31,60.14,36.64,27.86,48.33,55.98,78.81,7.5,214.71,22.73,48.97],
[5898,168.22,59.69,36.26,28.15,48.51,56.43,81.66,7.46,215.85,22.93,49.63],
[644,168.27,60.05,36.34,27.74,48.35,54.89,77.83,7.55,212.22,23.04,48.39],
[2007,169.61,60.79,38.32,28.77,49.26,56.64,81.76,7.42,220.29,23.99,51.35],
[5700,169.59,61.08,38.47,29.36,50.03,57.54,86.66,7.31,221.84,24.44,53],
[443,169.56,61.01,38.06,28.49,48.76,55.82,83.96,7.41,220.24,24.26,51.19],
[2090,170.2,62.79,39.92,30.23,51.55,58,85.34,7.29,224.16,25.27,54.53],
[5441,170.48,62.8,40.13,30.39,51.97,58.59,88.6,7.21,225.43,25.62,55.51],
[351,170.59,63.37,40.46,29.47,50.59,56.45,82.36,7.3,222.66,24.92,53.27
    ],],
    female: [
[62,115.69,21.67,8.53,12.69,27.81,25.95,17.57,12.23,107.06,5.16,30.92],
[2124,116.1,21.84,8.53,12.09,29.19,25.96,16.63,11.97,107.08,5.73,31.24],
[8062,115.95,21.37,8.54,12.15,29.35,26.62,17.51,11.87,108.83,5.83,31.9],
[261,122,24.12,9.83,15.05,31.23,29.07,21.86,11.17,116.61,7.27,37.73],
[2661,121.95,24.53,9.98,14.63,31.93,29.75,22.89,11.08,117.79,7.62,38.65],
[7723,122.02,24.16,9.98,14.76,31.78,30.42,24.19,11,118.83,7.68,39.2],
[704,127.57,27.54,11.45,16.25,33.64,32.27,27.12,10.57,123.94,9.42,43.53],
[3282,127.98,27.81,11.78,16.98,34.48,33.12,28.24,10.49,127.85,9.69,45.01],
[6633,127.97,27.42,11.85,17.11,34.89,34.25,29.92,10.4,129.61,9.76,46.05],
[607,134.53,31.75,13.5,17.55,36.58,35.26,31.47,10.17,133.59,11.37,48.78],
[3959,134.53,31.78,13.79,18.18,37.37,36.63,33.61,10,137.31,11.76,50.81],
[6060,134.5,31.35,13.89,18.51,38.04,37.71,36.37,9.94,138.78,11.97,52.06],
[621,142.39,38.15,16.92,18.38,40.17,38.77,36.44,9.79,143.17,13.74,55.06],
[4670,141.71,36.59,16.85,19.76,40.95,40.29,40.88,9.56,146.18,14.08,57.24],
[5719,141.16,35.33,16.5,20.13,41.07,40.99,43.52,9.49,148.51,14.2,58.08],
[771,148.43,43.21,20.16,20.08,44.33,41.15,41.35,9.37,150.64,15.62,60.41],
[5728,148.05,41.41,19.81,20.77,44.16,42.49,45.35,9.21,154.15,15.61,61.92],
[4367,147.66,40.3,19.48,21.15,44.49,43.28,47.36,9.16,156.31,15.93,62.74],
[1252,152.66,46.41,21.75,20.67,45.84,43.07,43.98,9.15,157.28,11.38,43.55],
[6646,152.47,44.86,21.65,21.07,46.63,44.08,47.92,9.02,161.24,11.49,44.75],
[2287,152.26,43.79,21.52,21.41,46.91,45,49.77,8.97,163.36,11.8,45.86],
[1735,154.84,48.61,23.69,22.33,49.09,45.36,49.96,8.9,164.47,12.73,48.91],
[6833,154.95,47.49,23.94,23.17,49.47,46.48,54.78,8.69,168.68,13.15,50.86],
[1471,154.91,46.9,23.78,22.66,49.16,45.9,53.99,8.75,167.13,13.11,50.15],
[2248,155.95,50.14,25.03,23.73,51.97,46.54,51.83,8.72,169.19,13.95,52.47],
[6996,156.05,49.53,25.35,24.09,51.85,47.22,55.27,8.6,172.25,14.11,53.68],
[886,156.57,50.1,25.39,23.23,51.23,46.01,52.39,8.7,168.71,13.97,52.03],
[2043,156.71,51.71,25.03,22.78,50.05,48.37,47.33,8.89,169.5,13.32,50.6],
[5121,156.74,51.34,25.05,22.62,49.73,48.25,47.42,8.91,168.77,13.05,50.18],
[480,157.02,52.52,25.07,21.61,48.5,46.67,43.31,9.05,166.21,12.79,47.9],
[2554,157.26,52.75,25.86,23.19,51.17,48.42,47.4,8.97,169.9,13.79,51.52],
[4630,157.15,52.42,25.66,23.13,50.7,48.54,47.86,8.91,170.7,13.67,51.49],
[300,157.7,53.38,25.58,22.29,49.08,47.24,43.76,9.13,167.33,13.39,49.03],
[2734,157.58,52.94,26.16,24.04,52.88,49.27,48.76,8.94,171.7,14.09,53.08],
[4390,157.53,52.57,26.23,23.88,52.39,49.04,48.24,8.91,170.94,14.06,52.78],
[247,157.89,53.84,25.76,21.93,50.63,46.93,41.34,9.14,166.56,13.49,48.94
    ],],
  },

  /**
   * 1日のテレビ（テレビゲーム含む）の視聴時間
   */
  q7: {
    male: [
[1346,116.83,21.7,9.19,12.95,26.75,27.37,21.68,11.5,116.58,8.41,32.06],
[4777,116.86,21.86,9.09,12.71,27.08,27.45,21.19,11.59,115.92,8.23,31.69],
[3317,116.87,22.01,8.99,12.49,27.11,26.99,20,11.69,114.73,7.88,31.02],
[1381,116.79,22.17,8.79,12.08,26.88,26.87,18.85,11.85,113.82,7.51,30.2],
[1505,123,24.59,10.72,15.8,28.92,31.17,31.22,10.62,127.51,11.24,38.94],
[4459,122.96,24.75,10.57,15.62,28.93,31.38,30.72,10.69,126.85,11.13,38.62],
[3310,123.03,25.06,10.51,15.39,28.96,31.03,28.81,10.78,124.89,10.83,37.89],
[1840,122.86,25.32,10.41,15.05,28.85,30.67,27.7,10.88,123.77,10.73,37.29],
[2290,128.58,28.01,12.47,18.01,31.67,35.29,39.05,10.1,137.59,14.54,45.04],
[3761,128.53,28.08,12.45,17.86,31.65,35.17,38.23,10.08,137.99,14.24,44.9],
[2589,128.69,28.48,12.38,17.32,31.39,34.82,35.62,10.17,136.18,13.87,43.9],
[2542,128.86,29.16,12.43,17.22,31.56,34.21,33.96,10.3,134.72,13.75,43.36],
[2130,134.02,31.58,14.42,19.41,33.7,38.53,44.84,9.65,145.1,17.81,49.97],
[3559,134.24,32.05,14.44,19.48,34.31,39.12,44.84,9.63,146.44,17.52,50.46],
[2430,134.23,32.34,14.28,18.78,34.07,38.43,42.82,9.75,144.24,17.01,49.27],
[3075,134.54,33.4,14.24,17.95,33.82,37.37,38.32,9.93,141.51,16.34,47.63],
[1578,134.02,31.58,14.42,19.41,33.7,38.53,44.84,9.65,145.1,17.81,49.97],
[3454,139.65,35.67,16.82,20.95,36.51,42.66,52.32,9.24,156.04,20.71,55.81],
[2620,139.74,36.28,16.66,20.52,36.64,42.31,49.93,9.34,154.28,20.35,55.02],
[3590,139.96,37.43,16.51,19.61,36.31,40.95,44.6,9.55,150.99,19.43,53.06],
[1330,146.26,39.82,20.23,22.99,39.59,45.44,60.06,8.89,166.37,24.52,61.41],
[3337,146.03,39.85,19.87,22.73,39.48,45.58,59.98,8.87,166.45,23.85,61.38],
[2838,146.41,40.81,19.9,22.18,39.42,45.14,57.08,8.92,165.16,23.32,60.58],
[3976,146.78,42.24,20.06,21.16,38.69,43.62,51.94,9.1,161.88,22.68,58.74],
[1214,154.71,45.85,24.7,24.82,43.44,48.87,68.39,8.4,181.53,18.28,36.55],
[2939,154.27,45.26,24.39,24.69,43.92,49.18,69.33,8.39,183.75,18.04,36.53],
[2777,154.28,46.22,24.65,24.42,43.58,48.96,67.39,8.42,182.93,17.82,35.98],
[3919,154.11,47.48,24.44,23.31,42.44,47.01,60.36,8.62,177.67,17.18,33.68],
[1042,161.09,50.12,30.26,27.56,47.14,52.34,81.86,7.86,201.2,21.31,44.92],
[2539,161.18,50.47,30.1,27.91,48.26,53.02,84.3,7.77,201.71,21.58,45.68],
[2923,160.86,50.8,30.19,27.37,47.55,52.64,82.9,7.78,201.29,21.41,45.07],
[4325,160.91,51.85,30.23,26.38,46.69,51.17,76.8,7.87,197.98,20.57,43.12],
[1232,165.61,55.08,35.73,29.85,50.79,54.91,89.77,7.37,215.4,24.75,52.13],
[2541,165.96,54.92,34.61,29.6,50.85,55.27,90.95,7.39,214.59,24.08,51.71],
[2889,165.89,55.37,34.83,29.18,50.52,55.09,89.35,7.39,214.04,23.85,51.2],
[4192,165.72,56.08,34.7,28.2,49.95,53.69,83.74,7.5,210.67,23.21,49.29],
[1950,168.49,59.27,37,29.56,49.84,57.58,87.44,7.36,220.51,24.29,52.37],
[2295,168.52,59.66,36.52,28.61,49.52,57.13,84.17,7.4,218.12,23.32,50.84],
[1727,168.05,60.1,36.1,27.81,48.31,55.89,79.91,7.51,213.36,22.41,48.71],
[2164,167.87,60.22,35.75,26.33,46.18,54.27,71.96,7.64,209.32,21.59,45.74],
[2047,169.92,61.05,39.48,30.44,51.66,58.62,91.22,7.25,225.83,25.59,55.46],
[2145,169.63,60.53,38.48,30.06,50.73,58.18,90.7,7.23,224.22,24.73,54.34],
[1615,169.71,61.35,38.01,29.12,49.68,57.24,84.12,7.35,220.72,24.12,52.13],
[2349,169.19,61.17,37.7,27.29,47.3,55.11,76.06,7.51,215.32,22.98,48.48],
[2376,170.95,62.55,41.06,31.76,53.77,59.99,94.33,7.09,231.2,26.8,58.54],
[2191,170.43,62.63,40.25,31.08,52.94,59.22,91.5,7.16,227,25.89,56.71],
[1400,170.39,62.97,39.74,29.75,50.66,57.89,85.09,7.29,222.2,24.97,53.79],
[1921,169.74,63.27,38.97,28.05,48.91,55.62,76.07,7.44,216.99,23.82,50.2
    ],],
    female: [
[1711,115.89,21.21,8.59,12.43,29.04,26.57,18.03,11.84,108.66,5.76,32],
[4716,115.95,21.45,8.57,12.2,29.32,26.57,17.6,11.86,108.79,5.83,31.95],
[2786,116.05,21.59,8.49,12.06,29.44,26.42,16.96,11.9,108.5,5.84,31.66],
[1046,116.08,21.71,8.42,11.62,29.42,26.06,15.93,12.08,106.59,5.67,30.81],
[1988,121.78,23.85,10.08,15.07,31.8,30.5,25.07,10.94,119.93,7.8,39.75],
[4618,122,24.14,10,14.8,31.9,30.33,24.41,10.98,119.29,7.68,39.33],
[2812,122.11,24.49,9.97,14.57,31.61,30.06,22.86,11.09,117.11,7.58,38.5],
[1237,122.11,24.79,9.76,14.32,31.9,29.7,21.7,11.22,116.66,7.5,37.9],
[3129,127.69,27.18,11.85,17.47,34.87,33.91,30.51,10.37,129.23,9.79,46.1],
[3910,127.93,27.4,11.81,17.12,34.79,34.15,29.68,10.41,129.62,9.82,45.99],
[2091,128.29,28.03,11.78,16.56,34.66,33.31,27.91,10.51,127.87,9.61,44.91],
[1508,128.05,28.05,11.67,16.44,34.06,33.13,27.12,10.59,126.5,9.43,44.25],
[2735,133.82,30.66,13.75,18.69,37.51,37.56,36.99,9.91,138.79,11.99,51.93],
[3748,134.35,31.18,13.8,18.44,37.9,37.27,35.58,9.92,138.59,11.9,51.73],
[2099,134.86,32.01,13.79,18.25,37.73,37.22,34.41,10.03,137.73,11.83,51.23],
[2057,135.4,32.86,14.04,17.71,37.58,36.36,32.14,10.09,135.75,11.63,50.23],
[2017,140.65,34.69,16.45,20.41,40.89,40.9,44.64,9.45,149.17,14.29,58.33],
[3714,141.06,35.34,16.44,20.06,40.97,40.87,43.05,9.49,148.14,14.1,57.93],
[2504,141.71,36.48,16.76,19.8,40.92,40.62,41.65,9.53,146.71,14.27,57.5],
[2783,142.38,37.51,17.04,19.29,41.08,39.84,38.97,9.66,144.99,13.9,56.51],
[1469,147.54,39.76,19.63,21.75,44.46,43.42,49.94,9.08,157.47,16.06,63.41],
[3319,147.51,40.2,19.57,21.29,44.59,43.19,47.93,9.15,155.9,15.91,62.81],
[2727,147.87,41,19.57,20.87,44.46,42.8,45.61,9.22,154.56,15.63,62.05],
[3377,148.54,42.62,19.96,20.08,43.82,41.84,42.26,9.3,152.63,15.53,61],
[1310,152.6,43.71,21.63,21.78,46.97,44.73,51.65,8.93,163.46,11.75,46.47],
[2799,152.48,44.01,21.65,21.43,47.05,44.72,49.83,8.98,162.99,11.51,45.61],
[2512,152.34,44.87,21.58,21.17,46.47,44.05,48.51,8.98,161.71,11.55,44.91],
[3566,152.43,45.81,21.66,20.55,46.19,43.59,44.43,9.13,158.69,11.5,43.61],
[1081,155.22,47.16,23.56,23.04,49.15,46.43,54.07,8.82,167.86,12.77,50.27],
[2636,154.93,47.01,23.94,23.44,49.59,46.77,56.89,8.65,169.63,13.15,51.48],
[2633,154.85,47.3,23.86,23.3,49.59,46.64,55.35,8.67,169.14,13.31,51.19],
[3682,154.89,48.38,23.92,22.33,49.07,45.41,50.5,8.83,165.3,12.93,49.14],
[1321,156.13,49.04,25.24,24.7,52.29,47.47,55.65,8.58,172.55,14.19,54.22],
[2780,156.06,49.22,25.45,24.44,52.45,47.46,56.45,8.56,172.55,14.14,54.29],
[2732,156.01,49.99,25.31,24.08,51.88,47.16,55.09,8.6,172.46,14.09,53.59],
[3299,156.12,50.19,25.14,23.08,51.04,46.18,51.09,8.74,168.64,13.92,51.72],
[2458,157.02,50.93,25.09,23.32,50.81,48.93,49.64,8.86,171.22,13.42,51.62],
[2402,156.83,51.51,25.15,22.79,50,48.5,48.37,8.88,169.51,13.15,50.71],
[1408,156.32,51.44,24.79,22.1,49.23,47.73,45.61,8.97,167.04,12.84,49.06],
[1374,156.57,52.62,25.01,21.47,47.87,46.76,42.13,9.03,165.15,12.74,47.64],
[2693,157.54,52.18,25.94,23.78,51.45,48.87,50.46,8.85,173.01,14.07,52.95],
[2148,157.28,52.65,25.54,23.37,51.13,48.83,48.44,8.89,171.19,13.77,51.9],
[1204,156.92,52.81,25.62,22.75,50.41,48.2,45.41,9.02,168.27,13.32,50.31],
[1443,156.75,52.97,25.71,21.78,49.36,47.25,42.38,9.11,165.46,13.21,48.62],
[2869,157.78,52.14,26.08,24.59,53.61,49.86,50.7,8.82,174.29,14.41,54.43],
[2123,157.49,52.67,26.3,24.26,52.86,49.52,50.07,8.88,172.51,14.17,53.61],
[1105,157.48,53.27,26.26,23.16,51.2,48.22,45.25,9.01,167.75,13.72,51.12],
[1280,157.23,53.77,26.19,22.26,50.57,47.18,42.04,9.18,164.26,13.35,49.02
    ],],
  },
}
