/**
 * To migrate data from Excel:
 *   - On Excel, replace comma character with `.` if needed.
 *   - Just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 */
export const body2023Male = [
  [10887, 116.86, 21.93, 5.07, 3.98],
  [11244, 122.97, 24.91, 5.38, 4.94],
  [11397, 128.66, 28.43, 5.64, 6.43],
  [11435, 134.26, 32.39, 5.99, 7.65],
  [11600, 139.74, 36.3, 6.49, 8.76],
  [11861, 146.42, 40.96, 7.55, 10.28],
  [11368, 154.28, 46.41, 8.19, 11.28],
  [11475, 161.01, 51.13, 7.51, 11.09],
  [11495, 165.82, 55.52, 6.62, 10.91],
  [8263, 168.24, 59.8, 5.9, 11.94],
  [8276, 169.6, 61, 5.79, 11.53],
  [8016, 170.42, 62.82, 5.89, 11.66],
  [309, 166.86, 60.4, 6.36, 15.62],
  [323, 168.21, 62.82, 6.06, 16.09],
  [299, 169.86, 64.26, 5.54, 16.82],
  [62, 168.82, 61.83, 5.56, 15.06],
]

export const body2023Female = [
  [10335, 115.98, 21.48, 4.98, 3.69],
  [10807, 122.01, 24.26, 5.4, 4.62],
  [10781, 127.95, 27.56, 5.87, 5.65],
  [10852, 134.53, 31.56, 6.6, 6.94],
  [11353, 141.47, 36.05, 7.09, 8.02],
  [11214, 147.91, 41.08, 6.5, 8.6],
  [10694, 152.43, 44.87, 5.99, 8.46],
  [10688, 154.92, 47.65, 5.64, 8.28],
  [10686, 156.13, 49.82, 5.46, 8.34],
  [7734, 156.76, 51.5, 5.32, 8.29],
  [7612, 157.21, 52.55, 5.27, 8.46],
  [7490, 157.55, 52.75, 5.38, 8.5],
  [165, 155.53, 52.51, 5.71, 11.8],
  [173, 156.33, 54.89, 6.12, 13.64],
  [131, 157.33, 55.72, 5.51, 14.67],
  [30, 155.98, 55.51, 7.45, 11.75],
]
