import { QuestionnaireRateFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel, replace comma character with `.` if needed.
 *   - Just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 *
 * Male: ID from 0 -> 11
 * Female: ID from 12 -> 23
 */
export const reportQuestionnaireRate2023: QuestionnaireRateFromExcel = {
  q2: [
    [31.4, 68.6],
    [39.3, 60.7],
    [44.2, 55.8],
    [49.2, 50.8],
    [50.1, 49.9],
    [47.8, 52.2],
    [76.2, 23.8],
    [78.3, 21.7],
    [76.9, 23.1],
    [54.9, 45.1],
    [51.4, 48.6],
    [48.4, 51.6],
    [25, 75],
    [31, 69],
    [36.5, 63.5],
    [36.1, 63.9],
    [36.6, 63.4],
    [32.8, 67.2],
    [61.4, 38.6],
    [62.4, 37.6],
    [61.7, 38.3],
    [31, 69],
    [28.7, 71.3],
    [28.4, 71.6],
  ],

  q3: [
    [23.4, 52.8, 14.1, 9.8],
    [31.5, 48.3, 11.8, 8.4],
    [41.8, 38.1, 11.3, 8.9],
    [50.6, 32.9, 9.5, 6.9],
    [53.2, 32.3, 8.6, 6],
    [52.5, 32.3, 9.7, 5.5],
    [74.4, 15.1, 6.2, 4.3],
    [78.9, 11, 5.3, 4.7],
    [71.6, 15.5, 6.9, 6.1],
    [56, 17.5, 11.9, 14.6],
    [53.3, 16.3, 12.3, 18.1],
    [47.7, 18.5, 13.8, 20.1],
    [17.5, 52.7, 17.1, 12.7],
    [21.5, 52.4, 14.6, 11.5],
    [31.9, 46, 12.7, 9.4],
    [38.9, 41.9, 12, 7.2],
    [39.3, 43.1, 11.5, 6.1],
    [34.8, 41.4, 16, 7.8],
    [59.7, 22.2, 11.5, 6.6],
    [62.8, 18, 10.8, 8.4],
    [55.7, 20.5, 13.1, 10.7],
    [32.5, 21.4, 20, 26.1],
    [29.7, 19.6, 19.6, 31.2],
    [27.6, 18.3, 20, 34.1],
  ],
  q4: [
    [22.5, 37.9, 31.2, 8.4],
    [20.3, 31.9, 31.5, 16.3],
    [22.2, 26.2, 26.2, 25.4],
    [18.9, 24.1, 25.4, 31.6],
    [16.6, 22.6, 25.8, 35],
    [17.8, 21.5, 27.1, 33.6],
    [11.6, 14, 34.8, 39.6],
    [11.7, 12.1, 35.9, 40.3],
    [14.2, 15.9, 34.8, 35.1],
    [27.4, 16.4, 19.8, 36.4],
    [31.2, 15.6, 17.7, 35.5],
    [35.7, 17.4, 15.7, 31.2],
    [28.1, 41.3, 26, 4.6],
    [26.8, 37.8, 27.8, 7.6],
    [29.5, 33.7, 23.7, 13.1],
    [28.1, 33.1, 22.8, 15.9],
    [25.6, 29.6, 25.7, 19.1],
    [28.4, 28.7, 23.6, 19.3],
    [21.6, 19.7, 32.7, 26],
    [23.7, 17.4, 30.6, 28.3],
    [26.2, 20.7, 29.4, 23.7],
    [48.9, 20, 13.9, 17.1],
    [52.8, 17.8, 12, 17.4],
    [56.3, 17.8, 10.6, 15.2],
  ],
  q5: [
    [92, 7.4, 0.7],
    [89.8, 9, 1.1],
    [85.4, 12.8, 1.8],
    [84.8, 13.1, 2],
    [83, 14.6, 2.4],
    [82.3, 14.7, 3.1],
    [83.1, 14.2, 2.7],
    [80.6, 15.4, 4],
    [80, 15.8, 4.3],
    [76.9, 15.9, 7.2],
    [72.9, 17.1, 10],
    [71.8, 17.4, 10.8],
    [92.2, 7.1, 0.7],
    [90.9, 8.3, 0.8],
    [87.9, 10.8, 1.3],
    [86.7, 11.9, 1.4],
    [83.5, 14.6, 1.9],
    [80.2, 17.2, 2.6],
    [78.9, 18.6, 2.6],
    [77.2, 19.4, 3.4],
    [76.2, 20.2, 3.6],
    [77.2, 18.2, 4.6],
    [72.8, 20.4, 6.8],
    [74.2, 19.7, 6],
  ],
  q6: [
    [0.8, 20.2, 79],
    [2.5, 24.9, 72.6],
    [8.2, 31.8, 60],
    [7.9, 37.2, 54.9],
    [6.9, 41.9, 51.2],
    [7.3, 46.8, 45.9],
    [10.3, 61, 28.7],
    [12.9, 66.7, 20.5],
    [16.1, 71.6, 12.3],
    [19.6, 72.3, 8.1],
    [24.9, 69.4, 5.7],
    [26.4, 68.8, 4.8],
    [0.6, 20.7, 78.7],
    [2.5, 25, 72.6],
    [6.6, 30.9, 62.5],
    [5.7, 37.3, 57],
    [5.6, 42.4, 51.9],
    [7.1, 52.7, 40.2],
    [12.3, 65.3, 22.5],
    [17.3, 68.1, 14.7],
    [22.2, 69.1, 8.7],
    [26.6, 66.9, 6.5],
    [34, 61.9, 4.1],
    [37.1, 59.4, 3.4],
  ],
  q7: [
    [12.4, 44.1, 30.7, 12.8],
    [13.5, 40.1, 29.8, 16.6],
    [20.5, 33.6, 23.2, 22.7],
    [19, 31.8, 21.7, 27.5],
    [14, 30.7, 23.3, 31.9],
    [11.6, 29.1, 24.7, 34.6],
    [11.2, 27.1, 25.6, 36.1],
    [9.6, 23.8, 26.9, 39.7],
    [11.4, 23.4, 26.6, 38.6],
    [24, 28.3, 21.7, 26.1],
    [25.4, 26.2, 20.1, 28.3],
    [30.3, 27.9, 18, 23.8],
    [16.7, 46, 27.2, 10.2],
    [18.7, 43.3, 26.4, 11.6],
    [29.4, 36.8, 19.7, 14.2],
    [25.7, 35.2, 19.7, 19.3],
    [18.3, 33.7, 22.7, 25.3],
    [13.5, 30.5, 25, 31],
    [12.9, 27.5, 24.7, 35],
    [10.8, 26.3, 26.2, 36.7],
    [13, 27.4, 27, 32.6],
    [32, 31.3, 18.4, 18.3],
    [35.9, 28.5, 16.1, 19.5],
    [38.8, 28.6, 14.9, 17.8],
  ],
}
