export const averageScoreMale = {
  reportAverageScoreGraphSummary: {
    data: {
      elementarySchoolReport: {
        reports: [
          {
            year: 2012,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.540550736,
                  sitUps: 12.63749528,
                  bending: 26.53753301,
                  sideJump: 29.0328178,
                  shuttleRun: 22.02187854,
                  sprintRun: 11.57280272,
                  standingJump: 116.4530366,
                  handballThrow: 8.788758959,
                  totalScore: 32.32365145,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.23187311,
                  sitUps: 15.36593656,
                  bending: 28.36480363,
                  sideJump: 33.49660121,
                  shuttleRun: 32.44561934,
                  sprintRun: 10.68401057,
                  standingJump: 127.0706193,
                  handballThrow: 11.94712991,
                  totalScore: 39.4939577,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 13.04231626,
                  sitUps: 17.562732,
                  bending: 30.46696362,
                  sideJump: 37.65701559,
                  shuttleRun: 42.296585,
                  sprintRun: 10.04383816,
                  standingJump: 138.3619154,
                  handballThrow: 15.95360059,
                  totalScore: 46.13697105,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.94292068,
                  sitUps: 19.42809489,
                  bending: 32.25945145,
                  sideJump: 41.2920682,
                  shuttleRun: 49.71942179,
                  sprintRun: 9.650222387,
                  standingJump: 145.7383247,
                  handballThrow: 19.62935508,
                  totalScore: 51.31986657,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.33259094,
                  sitUps: 21.22271715,
                  bending: 34.99777283,
                  sideJump: 44.79213066,
                  shuttleRun: 57.14142539,
                  sprintRun: 9.242861915,
                  standingJump: 155.1592428,
                  handballThrow: 23.81959911,
                  totalScore: 57.0159614,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.48217567,
                  sitUps: 23.20066152,
                  bending: 37.48952591,
                  sideJump: 47.23520764,
                  shuttleRun: 64.10069827,
                  sprintRun: 8.825483278,
                  standingJump: 166.3829474,
                  handballThrow: 28.16868798,
                  totalScore: 62.73098126,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2013,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.492914592,
                  sitUps: 12.846419,
                  bending: 26.61930295,
                  sideJump: 29.1765607,
                  shuttleRun: 22.61279203,
                  sprintRun: 11.50428954,
                  standingJump: 116.9031023,
                  handballThrow: 8.80926848,
                  totalScore: 32.62811183,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.20645646,
                  sitUps: 15.47447447,
                  bending: 28.41216216,
                  sideJump: 33.43055556,
                  shuttleRun: 33.23160661,
                  sprintRun: 10.66805556,
                  standingJump: 127.661036,
                  handballThrow: 12.13626126,
                  totalScore: 39.70570571,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 13.00950932,
                  sitUps: 17.68276911,
                  bending: 30.64929631,
                  sideJump: 37.24496006,
                  shuttleRun: 41.81780145,
                  sprintRun: 10.10924306,
                  standingJump: 137.7995436,
                  handballThrow: 15.73373906,
                  totalScore: 45.85812096,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.85905541,
                  sitUps: 19.60766084,
                  bending: 32.51320193,
                  sideJump: 41.53142432,
                  shuttleRun: 50.51989587,
                  sprintRun: 9.600632205,
                  standingJump: 146.0948308,
                  handballThrow: 19.37486054,
                  totalScore: 51.54890294,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.26696329,
                  sitUps: 21.24026696,
                  bending: 35.22914349,
                  sideJump: 44.54134223,
                  shuttleRun: 56.57879125,
                  sprintRun: 9.227919911,
                  standingJump: 155.3704116,
                  handballThrow: 23.29885057,
                  totalScore: 56.97256211,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.39609902,
                  sitUps: 23.20517629,
                  bending: 37.73255814,
                  sideJump: 47.25956489,
                  shuttleRun: 63.93923481,
                  sprintRun: 8.852963241,
                  standingJump: 166.3128282,
                  handballThrow: 27.52775694,
                  totalScore: 62.54951238,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2014,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.548151002,
                  sitUps: 12.60862866,
                  bending: 26.9375963,
                  sideJump: 28.903698,
                  shuttleRun: 22.05431433,
                  sprintRun: 11.61232666,
                  standingJump: 116.2873652,
                  handballThrow: 8.520030817,
                  totalScore: 32.24191063,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.21359598,
                  sitUps: 15.79567401,
                  bending: 29.22904596,
                  sideJump: 33.65971418,
                  shuttleRun: 33.73966783,
                  sprintRun: 10.6167246,
                  standingJump: 128.9501738,
                  handballThrow: 12.34376207,
                  totalScore: 40.34105832,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 13.00303951,
                  sitUps: 17.69262918,
                  bending: 31.32978723,
                  sideJump: 37.85828267,
                  shuttleRun: 42.32598784,
                  sprintRun: 10.11241261,
                  standingJump: 137.9449088,
                  handballThrow: 15.78951368,
                  totalScore: 46.23442249,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.91913747,
                  sitUps: 19.67385445,
                  bending: 32.66576819,
                  sideJump: 41.66846361,
                  shuttleRun: 49.26415094,
                  sprintRun: 9.685752792,
                  standingJump: 146.283789,
                  handballThrow: 19.45629573,
                  totalScore: 51.49595687,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.27203647,
                  sitUps: 21.46048632,
                  bending: 35.40235562,
                  sideJump: 44.73670213,
                  shuttleRun: 57.73214286,
                  sprintRun: 9.212803951,
                  standingJump: 155.9642857,
                  handballThrow: 23.13981763,
                  totalScore: 57.24962006,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.45637329,
                  sitUps: 23.13808801,
                  bending: 38.14453718,
                  sideJump: 47.29400607,
                  shuttleRun: 64.35735964,
                  sprintRun: 8.847534143,
                  standingJump: 166.9704097,
                  handballThrow: 27.03110774,
                  totalScore: 62.72040971,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2015,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.537088457,
                  sitUps: 13.08528362,
                  bending: 27.56644189,
                  sideJump: 28.80880603,
                  shuttleRun: 22.82229274,
                  sprintRun: 11.48691789,
                  standingJump: 117.2859976,
                  handballThrow: 8.719555732,
                  totalScore: 32.91907973,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.20641126,
                  sitUps: 15.47654418,
                  bending: 28.96442533,
                  sideJump: 33.10828772,
                  shuttleRun: 32.76661454,
                  sprintRun: 10.6791165,
                  standingJump: 126.7990618,
                  handballThrow: 11.71735731,
                  totalScore: 39.54652072,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.97683549,
                  sitUps: 18.02120141,
                  bending: 30.96584217,
                  sideJump: 37.75775422,
                  shuttleRun: 42.68983117,
                  sprintRun: 10.04672163,
                  standingJump: 138.4923439,
                  handballThrow: 15.68904594,
                  totalScore: 46.3576757,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.90356313,
                  sitUps: 19.61463981,
                  bending: 33.33501162,
                  sideJump: 41.46165763,
                  shuttleRun: 50.14097599,
                  sprintRun: 9.629337723,
                  standingJump: 145.6502711,
                  handballThrow: 19.21920991,
                  totalScore: 51.65762974,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.95599214,
                  sitUps: 21.42907662,
                  bending: 35.14774067,
                  sideJump: 44.27229862,
                  shuttleRun: 56.25618861,
                  sprintRun: 9.267740668,
                  standingJump: 154.5850688,
                  handballThrow: 22.74420432,
                  totalScore: 56.51630648,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.31515855,
                  sitUps: 23.19141531,
                  bending: 38.28499613,
                  sideJump: 47.37896365,
                  shuttleRun: 64.37741686,
                  sprintRun: 8.822308585,
                  standingJump: 166.3534416,
                  handballThrow: 26.45707657,
                  totalScore: 62.66821346,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2016,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.624133714,
                  sitUps: 13.17733388,
                  bending: 27.09172442,
                  sideJump: 29.08560946,
                  shuttleRun: 22.66816143,
                  sprintRun: 11.431064,
                  standingJump: 117.3236853,
                  handballThrow: 8.75743987,
                  totalScore: 32.99592336,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.20098644,
                  sitUps: 15.93629264,
                  bending: 29.4730785,
                  sideJump: 33.43978627,
                  shuttleRun: 33.13440197,
                  sprintRun: 10.61126182,
                  standingJump: 128.4340321,
                  handballThrow: 12.0785039,
                  totalScore: 40.25770654,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.95919192,
                  sitUps: 18.03313131,
                  bending: 30.97656566,
                  sideJump: 37.24969697,
                  shuttleRun: 41.14343434,
                  sprintRun: 10.07866667,
                  standingJump: 136.9454545,
                  handballThrow: 15.3979798,
                  totalScore: 45.88969697,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.86218079,
                  sitUps: 20.15443859,
                  bending: 33.35832996,
                  sideJump: 41.88609647,
                  shuttleRun: 49.94081881,
                  sprintRun: 9.579691934,
                  standingJump: 147.3599514,
                  handballThrow: 19.29347385,
                  totalScore: 52.16051885,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.07395498,
                  sitUps: 21.39509646,
                  bending: 35.69533762,
                  sideJump: 44.49638264,
                  shuttleRun: 56.90554662,
                  sprintRun: 9.259815113,
                  standingJump: 154.5618971,
                  handballThrow: 22.54622186,
                  totalScore: 56.81551447,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.41423948,
                  sitUps: 23.30906149,
                  bending: 38.24595469,
                  sideJump: 47.16100324,
                  shuttleRun: 63.38834951,
                  sprintRun: 8.849555016,
                  standingJump: 166.1622168,
                  handballThrow: 26.58778317,
                  totalScore: 62.47572816,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2017,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.590123457,
                  sitUps: 13.45802469,
                  bending: 27.76995885,
                  sideJump: 29.30082305,
                  shuttleRun: 23.34938272,
                  sprintRun: 11.47357202,
                  standingJump: 117.6292181,
                  handballThrow: 8.764609053,
                  totalScore: 33.35802469,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.262423,
                  sitUps: 16.11786448,
                  bending: 29.82505133,
                  sideJump: 33.71129363,
                  shuttleRun: 33.66858316,
                  sprintRun: 10.56088706,
                  standingJump: 128.7942505,
                  handballThrow: 12.09034908,
                  totalScore: 40.69568789,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.97938144,
                  sitUps: 18.4342268,
                  bending: 32.01484536,
                  sideJump: 37.8742268,
                  shuttleRun: 41.86474227,
                  sprintRun: 10.07430928,
                  standingJump: 138.6305155,
                  handballThrow: 15.71051546,
                  totalScore: 46.70391753,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.92915309,
                  sitUps: 20.06066775,
                  bending: 33.37459283,
                  sideJump: 41.49104235,
                  shuttleRun: 49.65065147,
                  sprintRun: 9.606840391,
                  standingJump: 146.8684853,
                  handballThrow: 19.12907166,
                  totalScore: 51.91978827,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.24204082,
                  sitUps: 21.97020408,
                  bending: 36.26489796,
                  sideJump: 45.00530612,
                  shuttleRun: 57.51836735,
                  sprintRun: 9.206657143,
                  standingJump: 157.2926531,
                  handballThrow: 22.77265306,
                  totalScore: 57.70204082,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.38601455,
                  sitUps: 23.4130962,
                  bending: 38.91713824,
                  sideJump: 47.37510105,
                  shuttleRun: 63.94260307,
                  sprintRun: 8.813108327,
                  standingJump: 166.8237672,
                  handballThrow: 26.1738076,
                  totalScore: 62.87712207,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2018,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.526182432,
                  sitUps: 13.2495777,
                  bending: 27.66258446,
                  sideJump: 29.42989865,
                  shuttleRun: 23.16722973,
                  sprintRun: 11.45961149,
                  standingJump: 118.1427365,
                  handballThrow: 8.508445946,
                  totalScore: 33.22804054,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.2486396,
                  sitUps: 16.34365843,
                  bending: 29.65466722,
                  sideJump: 33.67475931,
                  shuttleRun: 34.21975722,
                  sprintRun: 10.56586019,
                  standingJump: 129.1033905,
                  handballThrow: 11.76726664,
                  totalScore: 40.68689828,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 13.06791667,
                  sitUps: 18.54583333,
                  bending: 31.52625,
                  sideJump: 37.84416667,
                  shuttleRun: 42.42166667,
                  sprintRun: 10.019675,
                  standingJump: 139.4679167,
                  handballThrow: 15.41708333,
                  totalScore: 46.7825,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 15.00420698,
                  sitUps: 20.43794699,
                  bending: 34.16912074,
                  sideJump: 41.64493058,
                  shuttleRun: 49.82414809,
                  sprintRun: 9.582141355,
                  standingJump: 147.8948254,
                  handballThrow: 18.83971393,
                  totalScore: 52.32646193,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.23442014,
                  sitUps: 21.93644243,
                  bending: 36.54312835,
                  sideJump: 44.68757738,
                  shuttleRun: 56.49773009,
                  sprintRun: 9.224457284,
                  standingJump: 156.3867107,
                  handballThrow: 22.32851837,
                  totalScore: 57.3941395,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.43191312,
                  sitUps: 23.76524645,
                  bending: 39.38345865,
                  sideJump: 47.43274854,
                  shuttleRun: 63.77360067,
                  sprintRun: 8.807819549,
                  standingJump: 167.3976608,
                  handballThrow: 26.16875522,
                  totalScore: 62.98579783,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2019,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.401444043,
                  sitUps: 13.23610108,
                  bending: 27.21732852,
                  sideJump: 28.58303249,
                  shuttleRun: 22.17220217,
                  sprintRun: 11.46909747,
                  standingJump: 116.934296,
                  handballThrow: 8.445487365,
                  totalScore: 32.56606498,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 11.15484336,
                  sitUps: 15.824991,
                  bending: 29.68455167,
                  sideJump: 33.14476053,
                  shuttleRun: 32.62657544,
                  sprintRun: 10.61739287,
                  standingJump: 128.5365502,
                  handballThrow: 11.50198055,
                  totalScore: 39.96002881,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.87228744,
                  sitUps: 18.25222341,
                  bending: 31.79437922,
                  sideJump: 37.30914265,
                  shuttleRun: 41.15368196,
                  sprintRun: 10.04795446,
                  standingJump: 138.6261117,
                  handballThrow: 15.04838136,
                  totalScore: 46.24581999,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.70284698,
                  sitUps: 20.31530249,
                  bending: 33.5886121,
                  sideJump: 41.4227758,
                  shuttleRun: 49.37081851,
                  sprintRun: 9.601067616,
                  standingJump: 146.6594306,
                  handballThrow: 18.64590747,
                  totalScore: 51.813879,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.02481122,
                  sitUps: 21.7605178,
                  bending: 36.62099964,
                  sideJump: 44.34555915,
                  shuttleRun: 54.49730313,
                  sprintRun: 9.262855088,
                  standingJump: 155.4088457,
                  handballThrow: 21.77957569,
                  totalScore: 56.70945703,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.03050727,
                  sitUps: 23.46541327,
                  bending: 38.91344448,
                  sideJump: 46.86732884,
                  shuttleRun: 61.3863072,
                  sprintRun: 8.853174885,
                  standingJump: 165.8758425,
                  handballThrow: 25.27775807,
                  totalScore: 62.03228095,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2021,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.154807869093583,
                  sitUps: 12.765398051112337,
                  bending: 26.541413862842433,
                  sideJump: 27.199301342158485,
                  shuttleRun: 21.39988968560397,
                  sprintRun: 11.540381503952933,
                  standingJump: 116.23965802537231,
                  handballThrow: 8.059698473984188,
                  totalScore: 31.411013053870196,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.595498583310484,
                  sitUps: 14.993327849373914,
                  bending: 28.507220546567954,
                  sideJump: 31.037016726076228,
                  shuttleRun: 29.631887396033267,
                  sprintRun: 10.699224019742255,
                  standingJump: 126.22337994698839,
                  handballThrow: 10.84510556621881,
                  totalScore: 37.83420162690796,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.420066201467167,
                  sitUps: 17.15038468420111,
                  bending: 30.512882447665056,
                  sideJump: 34.638217928073,
                  shuttleRun: 36.93397745571659,
                  sprintRun: 10.159632313472892,
                  standingJump: 136.0639649311147,
                  handballThrow: 13.847110395419573,
                  totalScore: 43.66120951869744,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.330343668122271,
                  sitUps: 18.800349344978166,
                  bending: 32.80488209606987,
                  sideJump: 38.34349344978166,
                  shuttleRun: 43.78296943231441,
                  sprintRun: 9.703063755458516,
                  standingJump: 144.96017467248907,
                  handballThrow: 17.143406113537118,
                  totalScore: 49.0903056768559,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.605369626527647,
                  sitUps: 20.43227074609008,
                  bending: 35.762584394496194,
                  sideJump: 42.04503888556533,
                  shuttleRun: 51.02179300914452,
                  sprintRun: 9.34367746346466,
                  standingJump: 153.86189214597044,
                  handballThrow: 20.25344842321169,
                  totalScore: 54.542090419622255,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.87763660521508,
                  sitUps: 22.138432154127223,
                  bending: 38.30310579637934,
                  sideJump: 44.98480318883907,
                  shuttleRun: 57.80078060122903,
                  sprintRun: 8.89872114266733,
                  standingJump: 165.00274040857,
                  handballThrow: 23.970104633781762,
                  totalScore: 60.28682943032719,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2022,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.042434767120847,
                  sitUps: 12.477854023705552,
                  bending: 26.972175153839295,
                  sideJump: 27.08430829686523,
                  shuttleRun: 20.327964205816556,
                  sprintRun: 11.52,
                  standingJump: 115.33776192599197,
                  handballThrow: 7.935995721162418,
                  totalScore: 30.874545937804555,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.558145648935238,
                  sitUps: 15.254274441034633,
                  bending: 28.64894736842105,
                  sideJump: 30.93019366197183,
                  shuttleRun: 28.211707746478872,
                  sprintRun: 10.67,
                  standingJump: 125.66406181403109,
                  handballThrow: 10.62770904624024,
                  totalScore: 37.170907511940946,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.38898616704605,
                  sitUps: 16.968536371603857,
                  bending: 31.60180559207643,
                  sideJump: 34.3384007029877,
                  shuttleRun: 34.59616905368597,
                  sprintRun: 10.14,
                  standingJump: 135.16400631468164,
                  handballThrow: 13.655190538764783,
                  totalScore: 42.8136974425661,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.29431582584658,
                  sitUps: 18.72047380252464,
                  bending: 33.26306212633221,
                  sideJump: 38.22864822682737,
                  shuttleRun: 41.61236540465439,
                  sprintRun: 9.67,
                  standingJump: 143.81475381414702,
                  handballThrow: 16.690156696389923,
                  totalScore: 47.85575364667747,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.57413836904056,
                  sitUps: 20.040352661919293,
                  bending: 35.924507472826086,
                  sideJump: 41.23790459965929,
                  shuttleRun: 47.45054477357848,
                  sprintRun: 9.29,
                  standingJump: 153.17790994052677,
                  handballThrow: 19.715848384634953,
                  totalScore: 52.6141535383846,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.74928917879244,
                  sitUps: 21.886243608014084,
                  bending: 38.888282234355316,
                  sideJump: 44.58777983504461,
                  shuttleRun: 55.352330293819655,
                  sprintRun: 8.82,
                  standingJump: 163.58563489390366,
                  handballThrow: 23.069661786976276,
                  totalScore: 58.19324346539536,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
        ],
        __typename: 'ReportAverageScoreGraphByCategory',
      },
      juniorHighSchoolReport: {
        reports: [
          {
            year: 2012,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.97185576,
                  sitUps: 25.15743184,
                  bending: 41.48504837,
                  sideJump: 49.93491645,
                  shuttleRun: 71.97009675,
                  sprintRun: 8.443975374,
                  standingJump: 183.1165347,
                  handballThrow: 18.64951627,
                  totalScore: 36.28496042,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.96066434,
                  sitUps: 28.87587413,
                  bending: 46.39554196,
                  sideJump: 53.48645105,
                  shuttleRun: 91.44318182,
                  sprintRun: 7.790996503,
                  standingJump: 202.5734266,
                  handballThrow: 22.34134615,
                  totalScore: 46.27054196,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 36.09365427,
                  sitUps: 30.78161926,
                  bending: 49.82319475,
                  sideJump: 55.9750547,
                  shuttleRun: 98.34617068,
                  sprintRun: 7.420262582,
                  standingJump: 215.628884,
                  handballThrow: 24.99124726,
                  totalScore: 52.80919037,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2013,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.90920882,
                  sitUps: 25.38521401,
                  bending: 41.55771725,
                  sideJump: 49.77561608,
                  shuttleRun: 73.07176827,
                  sprintRun: 8.441591007,
                  standingJump: 183.0332901,
                  handballThrow: 18.6947687,
                  totalScore: 36.43579767,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.69775627,
                  sitUps: 28.99076111,
                  bending: 46.49934008,
                  sideJump: 53.5675319,
                  shuttleRun: 90.26704795,
                  sprintRun: 7.802551694,
                  standingJump: 201.8587769,
                  handballThrow: 22.01803784,
                  totalScore: 46.00659921,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 35.90713028,
                  sitUps: 31.16549296,
                  bending: 50.08934859,
                  sideJump: 56.02112676,
                  shuttleRun: 97.85475352,
                  sprintRun: 7.419586268,
                  standingJump: 215.5506162,
                  handballThrow: 25.11487676,
                  totalScore: 53.05457746,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2014,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.80595668,
                  sitUps: 25.24323105,
                  bending: 41.55731047,
                  sideJump: 49.78249097,
                  shuttleRun: 72.63628159,
                  sprintRun: 8.490072202,
                  standingJump: 182.1028881,
                  handballThrow: 18.43998195,
                  totalScore: 36.22337545,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.63351017,
                  sitUps: 28.90185676,
                  bending: 46.36914235,
                  sideJump: 53.38284704,
                  shuttleRun: 91.01768347,
                  sprintRun: 7.805702918,
                  standingJump: 200.035809,
                  handballThrow: 21.78779841,
                  totalScore: 45.77099912,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 35.88604755,
                  sitUps: 30.91296546,
                  bending: 49.71332436,
                  sideJump: 55.69493046,
                  shuttleRun: 96.95558546,
                  sprintRun: 7.441991925,
                  standingJump: 214.1399731,
                  handballThrow: 24.5397039,
                  totalScore: 52.42440556,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2015,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.86371923,
                  sitUps: 25.26891522,
                  bending: 42.3887876,
                  sideJump: 50.02461258,
                  shuttleRun: 73.47493163,
                  sprintRun: 8.375615314,
                  standingJump: 183.103464,
                  handballThrow: 18.45031905,
                  totalScore: 36.77256153,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.47255261,
                  sitUps: 28.78087832,
                  bending: 46.46065874,
                  sideJump: 53.54162855,
                  shuttleRun: 92.22186642,
                  sprintRun: 7.771870997,
                  standingJump: 201.8796889,
                  handballThrow: 21.79826167,
                  totalScore: 46.12397072,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 35.71978271,
                  sitUps: 31.18424627,
                  bending: 50.22589407,
                  sideJump: 56.18786781,
                  shuttleRun: 98.56994115,
                  sprintRun: 7.401584427,
                  standingJump: 215.3626075,
                  handballThrow: 24.5663196,
                  totalScore: 53.04391127,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2016,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.70302475,
                  sitUps: 25.31393217,
                  bending: 42.26718607,
                  sideJump: 49.91750687,
                  shuttleRun: 72.49816682,
                  sprintRun: 8.43395967,
                  standingJump: 182.7144821,
                  handballThrow: 18.22181485,
                  totalScore: 36.47525206,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.33893685,
                  sitUps: 28.9323035,
                  bending: 45.53112222,
                  sideJump: 53.95820082,
                  shuttleRun: 91.49795547,
                  sprintRun: 7.794320763,
                  standingJump: 199.6047251,
                  handballThrow: 21.49159473,
                  totalScore: 45.7782826,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 35.61195402,
                  sitUps: 31.0091954,
                  bending: 49.69609195,
                  sideJump: 56.42758621,
                  shuttleRun: 99.1908046,
                  sprintRun: 7.359172414,
                  standingJump: 215.5006897,
                  handballThrow: 24.58344828,
                  totalScore: 53.05793103,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2017,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.62505716,
                  sitUps: 25.53360768,
                  bending: 42.11476909,
                  sideJump: 50.1920439,
                  shuttleRun: 73.05166895,
                  sprintRun: 8.387517147,
                  standingJump: 182.7471422,
                  handballThrow: 18.04206676,
                  totalScore: 36.58847737,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.19443177,
                  sitUps: 28.88635326,
                  bending: 46.67594706,
                  sideJump: 54.03560018,
                  shuttleRun: 90.88909174,
                  sprintRun: 7.783614788,
                  standingJump: 201.433592,
                  handballThrow: 21.66955728,
                  totalScore: 46.10086718,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 35.28558436,
                  sitUps: 30.92451114,
                  bending: 50.06184629,
                  sideJump: 56.55616189,
                  shuttleRun: 98.65075034,
                  sprintRun: 7.395429741,
                  standingJump: 214.9004093,
                  handballThrow: 24.25648022,
                  totalScore: 52.79536153,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2018,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.40894421,
                  sitUps: 25.46104195,
                  bending: 42.35776856,
                  sideJump: 50.15260489,
                  shuttleRun: 72.32503458,
                  sprintRun: 8.430935915,
                  standingJump: 182.7169202,
                  handballThrow: 17.99492854,
                  totalScore: 36.47717842,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.26215841,
                  sitUps: 28.89022696,
                  bending: 46.25382121,
                  sideJump: 53.80500232,
                  shuttleRun: 90.42288096,
                  sprintRun: 7.767577582,
                  standingJump: 201.5558129,
                  handballThrow: 21.38258453,
                  totalScore: 45.87169986,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 34.95458758,
                  sitUps: 30.86515292,
                  bending: 50.36978684,
                  sideJump: 56.55421687,
                  shuttleRun: 97.81510658,
                  sprintRun: 7.399026877,
                  standingJump: 214.3118628,
                  handballThrow: 24.16311399,
                  totalScore: 52.60101946,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2019,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.10552764,
                  sitUps: 25.31703974,
                  bending: 42.53951576,
                  sideJump: 49.61215167,
                  shuttleRun: 70.38921882,
                  sprintRun: 8.486934673,
                  standingJump: 182.0753769,
                  handballThrow: 17.59524897,
                  totalScore: 35.80264961,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 30.01655934,
                  sitUps: 28.13983441,
                  bending: 46.90432383,
                  sideJump: 53.5873965,
                  shuttleRun: 88.78012879,
                  sprintRun: 7.808417663,
                  standingJump: 199.4728611,
                  handballThrow: 21.26402944,
                  totalScore: 45.24333027,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 34.8973769,
                  sitUps: 30.28025771,
                  bending: 50.19696272,
                  sideJump: 55.68338702,
                  shuttleRun: 94.7257248,
                  sprintRun: 7.444960884,
                  standingJump: 213.5987115,
                  handballThrow: 23.7740451,
                  totalScore: 51.54440865,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2021,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.247590741241105,
                  sitUps: 24.222822660542196,
                  bending: 42.022876699990995,
                  sideJump: 47.995316581104206,
                  shuttleRun: 66.5339998198685,
                  sprintRun: 8.485905611096099,
                  standingJump: 182.10555705665135,
                  handballThrow: 17.40709718094209,
                  totalScore: 34.92749707286319,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 29.84982022679082,
                  sitUps: 27.10058080575274,
                  bending: 46.50917304323776,
                  sideJump: 51.945053931962754,
                  shuttleRun: 82.85793306905136,
                  sprintRun: 7.831691711994099,
                  standingJump: 200.4245413478381,
                  handballThrow: 20.808334101594912,
                  totalScore: 43.824744168894625,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 34.53706369197553,
                  sitUps: 29.474001439366678,
                  bending: 49.991993522849945,
                  sideJump: 54.91507736595898,
                  shuttleRun: 91.47265203310543,
                  sprintRun: 7.419217344368478,
                  standingJump: 214.2957898524649,
                  handballThrow: 23.626934148974453,
                  totalScore: 50.90014393666787,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2022,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 24.370280568063734,
                  sitUps: 23.902086399445935,
                  bending: 42.46568201563858,
                  sideJump: 47.924324324324324,
                  shuttleRun: 63.18209580315854,
                  sprintRun: 8.23,
                  standingJump: 181.10717086346605,
                  handballThrow: 17.491091703056767,
                  totalScore: 33.139061190276614,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 29.953593736254067,
                  sitUps: 26.769061260467165,
                  bending: 46.40725342768686,
                  sideJump: 51.71910612535613,
                  shuttleRun: 80.37910714285714,
                  sprintRun: 7.41,
                  standingJump: 200.3568697198755,
                  handballThrow: 20.69305518409557,
                  totalScore: 40.994131455399064,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 34.45583318669717,
                  sitUps: 28.85758350224729,
                  bending: 49.9431246136183,
                  sideJump: 54.28805266435371,
                  shuttleRun: 87.49095446038677,
                  sprintRun: 7.05,
                  standingJump: 213.2745341614907,
                  handballThrow: 23.31369936034115,
                  totalScore: 47.27459704499664,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
        ],
        __typename: 'ReportAverageScoreGraphByCategory',
      },
      highSchoolReport: {
        reports: [
          {
            year: 2012,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 38.41292442,
                  sitUps: 29.51150055,
                  bending: 47.29956188,
                  sideJump: 54.95728368,
                  shuttleRun: 82.92990142,
                  sprintRun: 7.557831325,
                  standingJump: 214.898138,
                  handballThrow: 24.3822563,
                  totalScore: 50.00109529,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 40.97356828,
                  sitUps: 30.37665198,
                  bending: 49.06938326,
                  sideJump: 56.06828194,
                  shuttleRun: 87.59251101,
                  sprintRun: 7.457301762,
                  standingJump: 220.2488987,
                  handballThrow: 25.68612335,
                  totalScore: 53.1503304,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 42.57502738,
                  sitUps: 31.3932092,
                  bending: 51.07064622,
                  sideJump: 57.54271632,
                  shuttleRun: 89.30230011,
                  sprintRun: 7.402250821,
                  standingJump: 225.152793,
                  handballThrow: 26.77382256,
                  totalScore: 55.58652793,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2013,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 38.10846707,
                  sitUps: 29.64858882,
                  bending: 48.26508024,
                  sideJump: 54.76757056,
                  shuttleRun: 83.93027117,
                  sprintRun: 7.595008301,
                  standingJump: 213.2689541,
                  handballThrow: 24.6292197,
                  totalScore: 50.02822357,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 40.26353591,
                  sitUps: 30.9198895,
                  bending: 48.9839779,
                  sideJump: 56.26243094,
                  shuttleRun: 88.62928177,
                  sprintRun: 7.480381215,
                  standingJump: 219.0033149,
                  handballThrow: 25.5679558,
                  totalScore: 52.99005525,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 42.01772853,
                  sitUps: 31.76288089,
                  bending: 50.45263158,
                  sideJump: 57.14293629,
                  shuttleRun: 88.83822715,
                  sprintRun: 7.397722992,
                  standingJump: 222.4831025,
                  handballThrow: 26.46149584,
                  totalScore: 54.88531856,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2014,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 37.80860452,
                  sitUps: 29.66078323,
                  bending: 47.51792609,
                  sideJump: 55.34583563,
                  shuttleRun: 84.45725317,
                  sprintRun: 7.546966354,
                  standingJump: 213.0816327,
                  handballThrow: 24.03033646,
                  totalScore: 50.03088803,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 40.44456641,
                  sitUps: 30.63611416,
                  bending: 49.39077936,
                  sideJump: 56.37650933,
                  shuttleRun: 89.47145993,
                  sprintRun: 7.394923161,
                  standingJump: 220.1207464,
                  handballThrow: 25.78155873,
                  totalScore: 53.467618,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 41.50597177,
                  sitUps: 31.15200869,
                  bending: 49.99837134,
                  sideJump: 57.27252986,
                  shuttleRun: 89.62595005,
                  sprintRun: 7.375504886,
                  standingJump: 223.2166124,
                  handballThrow: 26.50325733,
                  totalScore: 54.80944625,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2015,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 37.45076586,
                  sitUps: 29.48851204,
                  bending: 46.61761488,
                  sideJump: 55.15317287,
                  shuttleRun: 84.05743982,
                  sprintRun: 7.611044858,
                  standingJump: 211.5497812,
                  handballThrow: 23.81236324,
                  totalScore: 49.32549234,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 40.20840243,
                  sitUps: 31.03427308,
                  bending: 49.68490879,
                  sideJump: 57.0923162,
                  shuttleRun: 92.15644002,
                  sprintRun: 7.365616363,
                  standingJump: 220.0154782,
                  handballThrow: 25.73079049,
                  totalScore: 54.06025428,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 41.85927152,
                  sitUps: 31.56456954,
                  bending: 50.51600442,
                  sideJump: 57.76655629,
                  shuttleRun: 90.22682119,
                  sprintRun: 7.324519868,
                  standingJump: 224.1258278,
                  handballThrow: 26.30739514,
                  totalScore: 55.36810155,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2016,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 37.2693133,
                  sitUps: 29.53272532,
                  bending: 47.24839056,
                  sideJump: 55.34603004,
                  shuttleRun: 84.02682403,
                  sprintRun: 7.526727468,
                  standingJump: 212.4227468,
                  handballThrow: 23.74409871,
                  totalScore: 49.70922747,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 39.97014115,
                  sitUps: 30.6465798,
                  bending: 48.77795874,
                  sideJump: 56.88327904,
                  shuttleRun: 90.66232356,
                  sprintRun: 7.390998914,
                  standingJump: 218.7931596,
                  handballThrow: 25.40988056,
                  totalScore: 53.40282302,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 41.12663755,
                  sitUps: 31.60644105,
                  bending: 50.78657205,
                  sideJump: 58.2489083,
                  shuttleRun: 92.76310044,
                  sprintRun: 7.300758734,
                  standingJump: 223.856441,
                  handballThrow: 26.65229258,
                  totalScore: 55.90720524,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2017,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 37.2937025,
                  sitUps: 29.43919653,
                  bending: 47.58957655,
                  sideJump: 55.48425624,
                  shuttleRun: 84.9771987,
                  sprintRun: 7.534945711,
                  standingJump: 212.5950054,
                  handballThrow: 23.78067318,
                  totalScore: 49.79315961,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 39.57142857,
                  sitUps: 30.61904762,
                  bending: 48.97153804,
                  sideJump: 56.83032293,
                  shuttleRun: 89.22714833,
                  sprintRun: 7.388199234,
                  standingJump: 220.2714833,
                  handballThrow: 24.91516147,
                  totalScore: 53.09085933,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 41.27512356,
                  sitUps: 32.00604064,
                  bending: 51.14607359,
                  sideJump: 57.86710599,
                  shuttleRun: 90.90719385,
                  sprintRun: 7.348841296,
                  standingJump: 222.4662273,
                  handballThrow: 26.28610653,
                  totalScore: 55.55024712,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2018,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 36.87554825,
                  sitUps: 28.83059211,
                  bending: 47.19846491,
                  sideJump: 55.96820175,
                  shuttleRun: 84.17269737,
                  sprintRun: 7.598667763,
                  standingJump: 212.4857456,
                  handballThrow: 23.42160088,
                  totalScore: 49.30975877,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 39.160239,
                  sitUps: 30.05920695,
                  bending: 49.02227051,
                  sideJump: 57.09016839,
                  shuttleRun: 88.58609451,
                  sprintRun: 7.443030961,
                  standingJump: 218.6979902,
                  handballThrow: 25.04780011,
                  totalScore: 52.64910375,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 41.39618529,
                  sitUps: 31.68228883,
                  bending: 51.42561308,
                  sideJump: 58.39564033,
                  shuttleRun: 88.95749319,
                  sprintRun: 7.335079019,
                  standingJump: 223.1504087,
                  handballThrow: 26.26485014,
                  totalScore: 55.5373297,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2019,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 36.982748,
                  sitUps: 29.40973506,
                  bending: 47.87430684,
                  sideJump: 56.30930376,
                  shuttleRun: 84.7159581,
                  sprintRun: 7.527652495,
                  standingJump: 213.2051756,
                  handballThrow: 23.51201479,
                  totalScore: 50.01109057,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 39.29490291,
                  sitUps: 30.30521845,
                  bending: 49.22451456,
                  sideJump: 56.91565534,
                  shuttleRun: 87.23118932,
                  sprintRun: 7.422851942,
                  standingJump: 218.878034,
                  handballThrow: 24.72633495,
                  totalScore: 52.65533981,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 40.99349497,
                  sitUps: 31.32347723,
                  bending: 51.13660556,
                  sideJump: 58.07332939,
                  shuttleRun: 89.61383797,
                  sprintRun: 7.33150207,
                  standingJump: 223.1868717,
                  handballThrow: 26.04198699,
                  totalScore: 55.29982259,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2021,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 36.628144654088054,
                  sitUps: 28.228713110788583,
                  bending: 47.85462022254475,
                  sideJump: 56.034591194968556,
                  shuttleRun: 82.83321238509917,
                  sprintRun: 7.47340106434446,
                  standingJump: 214.77564102564102,
                  handballThrow: 23.21734397677794,
                  totalScore: 49.466981132075475,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 38.9473526593196,
                  sitUps: 29.396262577862963,
                  bending: 49.560613320555824,
                  sideJump: 57.29540009583134,
                  shuttleRun: 87.80917585050311,
                  sprintRun: 7.309886200287494,
                  standingJump: 221.27000479156683,
                  handballThrow: 24.509822712026832,
                  totalScore: 52.92680881648299,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 40.64053571428571,
                  sitUps: 30.829285714285714,
                  bending: 51.69392857142857,
                  sideJump: 58.30309523809524,
                  shuttleRun: 88.90857142857143,
                  sprintRun: 7.23535,
                  standingJump: 226.36166666666668,
                  handballThrow: 25.879285714285714,
                  totalScore: 55.680119047619044,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2022,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 36.433174980205855,
                  sitUps: 27.874616695059625,
                  bending: 47.46282022217184,
                  sideJump: 55.833333333333336,
                  shuttleRun: 80.55750688705234,
                  sprintRun: 7.36,
                  standingJump: 214.7896410722399,
                  handballThrow: 22.879225071225076,
                  totalScore: 48.473297956418655,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 38.70128479657387,
                  sitUps: 29.149725602481507,
                  bending: 49.34377605717689,
                  sideJump: 57.17771661081857,
                  shuttleRun: 85.79161662262791,
                  sprintRun: 7.2,
                  standingJump: 221.30799569738258,
                  handballThrow: 24.25449101796407,
                  totalScore: 52.01734378712283,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 40.33627280120832,
                  sitUps: 30.190420696888474,
                  bending: 51.051252184041935,
                  sideJump: 58.16890550169373,
                  shuttleRun: 85.44538308107474,
                  sprintRun: 7.17,
                  standingJump: 224.1977829638273,
                  handballThrow: 25.19255368814192,
                  totalScore: 54.08429962894249,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
        ],
        __typename: 'ReportAverageScoreGraphByCategory',
      },
      __typename: 'ReportAverageScoreGraphSummary',
    },
    __typename: 'ReportAverageScoreGraphSummaryResponse',
  },
}

export const averageScoreFemale = {
  reportAverageScoreGraphSummary: {
    data: {
      elementarySchoolReport: {
        reports: [
          {
            year: 2012,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.108170311,
                  sitUps: 12.30724971,
                  bending: 29.18296893,
                  sideJump: 28.28922133,
                  shuttleRun: 18.50786344,
                  sprintRun: 11.74994246,
                  standingJump: 110.2907557,
                  handballThrow: 6.264288454,
                  totalScore: 33.24280783,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.6570997,
                  sitUps: 15.01661631,
                  bending: 31.14539275,
                  sideJump: 32.37046828,
                  shuttleRun: 25.94939577,
                  sprintRun: 10.87305136,
                  standingJump: 121.7050604,
                  handballThrow: 8.252643505,
                  totalScore: 40.7994713,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.39622642,
                  sitUps: 16.88226415,
                  bending: 33.85471698,
                  sideJump: 36.12188679,
                  shuttleRun: 33.03056604,
                  sprintRun: 10.33679245,
                  standingJump: 130.8271698,
                  handballThrow: 10.63660377,
                  totalScore: 47.2490566,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.44166043,
                  sitUps: 18.53515333,
                  bending: 36.29581152,
                  sideJump: 39.69035153,
                  shuttleRun: 39.84106208,
                  sprintRun: 9.880067315,
                  standingJump: 140.474196,
                  handballThrow: 12.96335079,
                  totalScore: 53.26028422,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.02952167,
                  sitUps: 20.28475336,
                  bending: 39.28213752,
                  sideJump: 42.88303438,
                  shuttleRun: 47.38901345,
                  sprintRun: 9.452780269,
                  standingJump: 150.1143498,
                  handballThrow: 15.41367713,
                  totalScore: 59.27989537,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.12402235,
                  sitUps: 21.57392924,
                  bending: 42.09385475,
                  sideJump: 44.66666667,
                  shuttleRun: 53.24096834,
                  sprintRun: 9.075385475,
                  standingJump: 159.2837989,
                  handballThrow: 18.00670391,
                  totalScore: 64.39478585,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2013,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.102622577,
                  sitUps: 12.29836564,
                  bending: 29.07525656,
                  sideJump: 28.27746104,
                  shuttleRun: 18.99353858,
                  sprintRun: 11.6977195,
                  standingJump: 110.7267199,
                  handballThrow: 6.278601292,
                  totalScore: 33.39642721,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.64425663,
                  sitUps: 14.9270073,
                  bending: 31.49903957,
                  sideJump: 32.33768728,
                  shuttleRun: 26.90971955,
                  sprintRun: 10.8944295,
                  standingJump: 121.8248175,
                  handballThrow: 8.170956589,
                  totalScore: 40.96465617,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.32700342,
                  sitUps: 17.0151918,
                  bending: 34.0224079,
                  sideJump: 36.02506646,
                  shuttleRun: 33.44587923,
                  sprintRun: 10.3235093,
                  standingJump: 131.2601595,
                  handballThrow: 10.45233574,
                  totalScore: 47.32016711,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.21128909,
                  sitUps: 18.76735317,
                  bending: 36.22196796,
                  sideJump: 39.75019069,
                  shuttleRun: 40.42906178,
                  sprintRun: 9.878680397,
                  standingJump: 138.9183829,
                  handballThrow: 12.74446987,
                  totalScore: 53.10831426,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 17.00149198,
                  sitUps: 20.42484148,
                  bending: 39.47817978,
                  sideJump: 42.8075345,
                  shuttleRun: 47.51883626,
                  sprintRun: 9.447071988,
                  standingJump: 150.4065647,
                  handballThrow: 15.3386796,
                  totalScore: 59.45766505,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.03155522,
                  sitUps: 21.50563486,
                  bending: 42.40946657,
                  sideJump: 44.67993989,
                  shuttleRun: 53.17543201,
                  sprintRun: 9.068332081,
                  standingJump: 158.930879,
                  handballThrow: 17.55785124,
                  totalScore: 64.33546206,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2014,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 9.009378664,
                  sitUps: 12.34935522,
                  bending: 29.56193826,
                  sideJump: 28.10511919,
                  shuttleRun: 18.88315748,
                  sprintRun: 11.81527941,
                  standingJump: 110.6908949,
                  handballThrow: 6.155529504,
                  totalScore: 33.24423603,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.66060372,
                  sitUps: 15.01934985,
                  bending: 32.20394737,
                  sideJump: 32.36416409,
                  shuttleRun: 26.86532508,
                  sprintRun: 10.86664087,
                  standingJump: 122.2163313,
                  handballThrow: 8.236842105,
                  totalScore: 41.25773994,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.31823506,
                  sitUps: 17.22569309,
                  bending: 34.46075752,
                  sideJump: 36.4283483,
                  shuttleRun: 34.35376806,
                  sprintRun: 10.33836783,
                  standingJump: 132.2561499,
                  handballThrow: 10.41351035,
                  totalScore: 47.76962124,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.27775629,
                  sitUps: 19.1787234,
                  bending: 36.46769826,
                  sideJump: 40.14738878,
                  shuttleRun: 41.1172147,
                  sprintRun: 9.876750484,
                  standingJump: 141.1911025,
                  handballThrow: 12.73346228,
                  totalScore: 53.67427466,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.91427463,
                  sitUps: 20.65089216,
                  bending: 39.67222653,
                  sideJump: 43.16330489,
                  shuttleRun: 48.13110939,
                  sprintRun: 9.459930178,
                  standingJump: 150.2071373,
                  handballThrow: 15.21683476,
                  totalScore: 59.51280062,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.25717566,
                  sitUps: 21.58668197,
                  bending: 42.76884807,
                  sideJump: 44.93226177,
                  shuttleRun: 53.13011864,
                  sprintRun: 9.080864906,
                  standingJump: 159.3046307,
                  handballThrow: 17.66858018,
                  totalScore: 64.58094145,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2015,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 8.944178628,
                  sitUps: 12.31419458,
                  bending: 29.89593301,
                  sideJump: 28.05063796,
                  shuttleRun: 19.66068581,
                  sprintRun: 11.71981659,
                  standingJump: 110.180622,
                  handballThrow: 6.186204147,
                  totalScore: 33.46172249,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.62019802,
                  sitUps: 15.26930693,
                  bending: 32.11920792,
                  sideJump: 32.42930693,
                  shuttleRun: 27.17108911,
                  sprintRun: 10.83284356,
                  standingJump: 120.8891089,
                  handballThrow: 8.178613861,
                  totalScore: 41.26019802,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.42896606,
                  sitUps: 17.4629045,
                  bending: 34.26519337,
                  sideJump: 36.21389108,
                  shuttleRun: 34.34490923,
                  sprintRun: 10.26140489,
                  standingJump: 131.863457,
                  handballThrow: 10.48224152,
                  totalScore: 47.95185478,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.31572677,
                  sitUps: 19.2192216,
                  bending: 37.19102462,
                  sideJump: 40.19459889,
                  shuttleRun: 41.77998411,
                  sprintRun: 9.811576648,
                  standingJump: 141.0587768,
                  handballThrow: 12.7954726,
                  totalScore: 54.10921366,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.77712032,
                  sitUps: 20.78698225,
                  bending: 39.77830375,
                  sideJump: 42.94556213,
                  shuttleRun: 48.27376726,
                  sprintRun: 9.416449704,
                  standingJump: 150.4,
                  handballThrow: 15.01262327,
                  totalScore: 59.60907298,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.94043393,
                  sitUps: 21.78382643,
                  bending: 43.00670611,
                  sideJump: 44.65522682,
                  shuttleRun: 52.63589744,
                  sprintRun: 9.083195266,
                  standingJump: 158.1573964,
                  handballThrow: 17.35029586,
                  totalScore: 64.28954635,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2016,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 8.957595718,
                  sitUps: 12.64841499,
                  bending: 29.42074928,
                  sideJump: 28.2643063,
                  shuttleRun: 19.10127625,
                  sprintRun: 11.72618361,
                  standingJump: 111.0288184,
                  handballThrow: 6.245368464,
                  totalScore: 33.58871964,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.63874132,
                  sitUps: 15.36697998,
                  bending: 32.23906825,
                  sideJump: 32.70044953,
                  shuttleRun: 27.53167143,
                  sprintRun: 10.8181038,
                  standingJump: 122.1610135,
                  handballThrow: 8.197793216,
                  totalScore: 41.6411933,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.28800329,
                  sitUps: 17.61503698,
                  bending: 34.43467543,
                  sideJump: 36.23952342,
                  shuttleRun: 34.04354971,
                  sprintRun: 10.2609696,
                  standingJump: 131.5402629,
                  handballThrow: 10.38167625,
                  totalScore: 47.91125719,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.3107943,
                  sitUps: 19.47169043,
                  bending: 37.23747454,
                  sideJump: 40.44480652,
                  shuttleRun: 41.61873727,
                  sprintRun: 9.793197556,
                  standingJump: 142.0407332,
                  handballThrow: 12.85784114,
                  totalScore: 54.3885947,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.9499795,
                  sitUps: 21.08241082,
                  bending: 40.00041,
                  sideJump: 43.3103731,
                  shuttleRun: 49.08774088,
                  sprintRun: 9.394292743,
                  standingJump: 150.9823698,
                  handballThrow: 15.30668307,
                  totalScore: 60.18778188,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.93966572,
                  sitUps: 22.06278027,
                  bending: 42.78393804,
                  sideJump: 45.26212801,
                  shuttleRun: 53.89604566,
                  sprintRun: 9.040644109,
                  standingJump: 159.0354668,
                  handballThrow: 17.21198532,
                  totalScore: 64.72604974,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2017,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 8.989987484,
                  sitUps: 12.80475594,
                  bending: 29.88694201,
                  sideJump: 28.38130997,
                  shuttleRun: 19.95828118,
                  sprintRun: 11.72478932,
                  standingJump: 111.2699207,
                  handballThrow: 6.359198999,
                  totalScore: 34.06758448,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.57160545,
                  sitUps: 15.45728436,
                  bending: 32.32191498,
                  sideJump: 32.53239785,
                  shuttleRun: 27.5233182,
                  sprintRun: 10.8406397,
                  standingJump: 122.4300454,
                  handballThrow: 8.324803962,
                  totalScore: 41.69665704,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.31930693,
                  sitUps: 17.76031353,
                  bending: 34.9269802,
                  sideJump: 36.87953795,
                  shuttleRun: 34.51567657,
                  sprintRun: 10.2759901,
                  standingJump: 132.245462,
                  handballThrow: 10.47235974,
                  totalScore: 48.37541254,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.23451693,
                  sitUps: 19.39925681,
                  bending: 37.02807597,
                  sideJump: 40.18001652,
                  shuttleRun: 41.68620974,
                  sprintRun: 9.813406276,
                  standingJump: 141.7939719,
                  handballThrow: 12.9611891,
                  totalScore: 54.20602808,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.96275072,
                  sitUps: 21.14326648,
                  bending: 40.6479738,
                  sideJump: 43.25747032,
                  shuttleRun: 48.93778142,
                  sprintRun: 9.378252149,
                  standingJump: 152.2005731,
                  handballThrow: 15.3733115,
                  totalScore: 60.46827671,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 20.22025525,
                  sitUps: 22.55743104,
                  bending: 43.96335941,
                  sideJump: 45.63400576,
                  shuttleRun: 54.71263895,
                  sprintRun: 9.00621655,
                  standingJump: 160.8995471,
                  handballThrow: 17.67435159,
                  totalScore: 65.64512145,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2018,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 8.969633066,
                  sitUps: 12.82201603,
                  bending: 30.05609447,
                  sideJump: 28.40742303,
                  shuttleRun: 19.96119781,
                  sprintRun: 11.69026993,
                  standingJump: 111.7342893,
                  handballThrow: 6.328975116,
                  totalScore: 34.08097849,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.70269134,
                  sitUps: 15.68755257,
                  bending: 32.16652649,
                  sideJump: 32.61059714,
                  shuttleRun: 27.81707317,
                  sprintRun: 10.85418839,
                  standingJump: 122.5664424,
                  handballThrow: 8.250210261,
                  totalScore: 41.81749369,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.39205021,
                  sitUps: 17.91297071,
                  bending: 35.01213389,
                  sideJump: 36.58995816,
                  shuttleRun: 34.76820084,
                  sprintRun: 10.31476569,
                  standingJump: 132.6769874,
                  handballThrow: 10.36359833,
                  totalScore: 48.36192469,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.40591174,
                  sitUps: 19.78975853,
                  bending: 37.92339717,
                  sideJump: 40.52414654,
                  shuttleRun: 42.68942548,
                  sprintRun: 9.782805995,
                  standingJump: 142.7514571,
                  handballThrow: 12.85720233,
                  totalScore: 54.86844296,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.89284219,
                  sitUps: 21.22352449,
                  bending: 41.00795312,
                  sideJump: 43.51820846,
                  shuttleRun: 49.056509,
                  sprintRun: 9.371532022,
                  standingJump: 151.9012139,
                  handballThrow: 15.07869401,
                  totalScore: 60.45793219,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.92266112,
                  sitUps: 22.51891892,
                  bending: 44.35259875,
                  sideJump: 45.57297297,
                  shuttleRun: 54.1022869,
                  sprintRun: 8.991305613,
                  standingJump: 161.0033264,
                  handballThrow: 17.48482328,
                  totalScore: 65.54469854,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2019,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 8.992399566,
                  sitUps: 12.76872964,
                  bending: 29.62794064,
                  sideJump: 27.83423815,
                  shuttleRun: 19.08034745,
                  sprintRun: 11.68765834,
                  standingJump: 111.3662685,
                  handballThrow: 6.1834962,
                  totalScore: 33.59934853,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.54456522,
                  sitUps: 15.52427536,
                  bending: 32.525,
                  sideJump: 32.02065217,
                  shuttleRun: 26.75362319,
                  sprintRun: 10.88173913,
                  standingJump: 122.2246377,
                  handballThrow: 8.067391304,
                  totalScore: 41.28369565,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 12.3019551,
                  sitUps: 17.55068791,
                  bending: 34.80919623,
                  sideJump: 36.14518465,
                  shuttleRun: 33.39500362,
                  sprintRun: 10.30477915,
                  standingJump: 132.1100652,
                  handballThrow: 10.22773353,
                  totalScore: 47.75488776,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 14.07640129,
                  sitUps: 19.87647269,
                  bending: 37.49018208,
                  sideJump: 40.14744734,
                  shuttleRun: 40.90182078,
                  sprintRun: 9.863298822,
                  standingJump: 141.2652624,
                  handballThrow: 12.67190289,
                  totalScore: 54.07390218,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.76234789,
                  sitUps: 21.3904796,
                  bending: 41.21188261,
                  sideJump: 43.15783822,
                  shuttleRun: 47.90408017,
                  sprintRun: 9.433392985,
                  standingJump: 151.5085898,
                  handballThrow: 14.87473157,
                  totalScore: 60.0547602,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.9249732,
                  sitUps: 22.52733119,
                  bending: 44.21864952,
                  sideJump: 45.41979278,
                  shuttleRun: 53.24508753,
                  sprintRun: 9.041693462,
                  standingJump: 160.6505895,
                  handballThrow: 17.11932833,
                  totalScore: 65.13040372,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2021,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 8.7207861361216,
                  sitUps: 12.423797076193242,
                  bending: 28.75849549811211,
                  sideJump: 26.536934843644108,
                  shuttleRun: 18.38658146964856,
                  sprintRun: 11.817158485816632,
                  standingJump: 109.48155678187626,
                  handballThrow: 5.880007745183464,
                  totalScore: 32.135347081033984,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.114540597271253,
                  sitUps: 14.506535635912604,
                  bending: 31.217059440893046,
                  sideJump: 30.23728651846198,
                  shuttleRun: 24.562446331456922,
                  sprintRun: 10.949806316191204,
                  standingJump: 119.52504532010305,
                  handballThrow: 7.674859269153706,
                  totalScore: 39.09579238622269,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 11.940602189781021,
                  sitUps: 16.74160583941606,
                  bending: 33.638047445255474,
                  sideJump: 33.91934306569343,
                  shuttleRun: 30.528649635036498,
                  sprintRun: 10.36611405109489,
                  standingJump: 129.92107664233578,
                  handballThrow: 9.73485401459854,
                  totalScore: 45.613594890510946,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 13.909797822706066,
                  sitUps: 18.33592534992224,
                  bending: 36.578766809989936,
                  sideJump: 37.17537279297411,
                  shuttleRun: 36.96660872747233,
                  sprintRun: 9.905126703869728,
                  standingJump: 139.33299789589242,
                  handballThrow: 11.971930290000914,
                  totalScore: 51.532705150489434,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.585343744390595,
                  sitUps: 19.778047029258662,
                  bending: 39.89222760725184,
                  sideJump: 40.71396517680847,
                  shuttleRun: 44.07494166217914,
                  sprintRun: 9.491012385568121,
                  standingJump: 148.32462753545144,
                  handballThrow: 14.189723568479627,
                  totalScore: 57.57987793932867,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.5546045123343,
                  sitUps: 20.819155473619524,
                  bending: 43.12360635589501,
                  sideJump: 42.91396716706171,
                  shuttleRun: 48.1669739267843,
                  sprintRun: 9.149784917917653,
                  standingJump: 156.4833640593451,
                  handballThrow: 16.225616714950398,
                  totalScore: 62.372662628390835,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2022,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 8.57404834676299,
                  sitUps: 12.064363771068717,
                  bending: 29.272112623877003,
                  sideJump: 26.45031084717454,
                  shuttleRun: 17.65615992561599,
                  sprintRun: 11.76,
                  standingJump: 108.59911036975257,
                  handballThrow: 5.8348011495318435,
                  totalScore: 31.550239234449762,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 10.048366254384346,
                  sitUps: 14.673900997414112,
                  bending: 31.304074655825556,
                  sideJump: 30.155749096636708,
                  shuttleRun: 23.17671791072421,
                  sprintRun: 10.96,
                  standingJump: 118.37817845584836,
                  handballThrow: 7.575101738808731,
                  totalScore: 38.38737498853106,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 11.811141804788214,
                  sitUps: 16.353044119001567,
                  bending: 34.553058216654385,
                  sideJump: 33.52395016151361,
                  shuttleRun: 28.38176112239247,
                  sprintRun: 10.39,
                  standingJump: 129.25905780400112,
                  handballThrow: 9.597785977859779,
                  totalScore: 44.50538321167883,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 4,
                score: {
                  grip: 13.879486277269528,
                  sitUps: 18.20835899692037,
                  bending: 36.95544205706235,
                  sideJump: 37.29807861801516,
                  shuttleRun: 35.350454505339336,
                  sprintRun: 9.86,
                  standingJump: 138.01984827099506,
                  handballThrow: 11.736466231705167,
                  totalScore: 50.544076655052265,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 5,
                score: {
                  grip: 16.508480628459203,
                  sitUps: 19.434596140100073,
                  bending: 40.63598101549208,
                  sideJump: 39.99335965541996,
                  shuttleRun: 41.00502828409805,
                  sprintRun: 9.44,
                  standingJump: 147.93353067814854,
                  handballThrow: 13.81505376344086,
                  totalScore: 55.98622151563328,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 6,
                score: {
                  grip: 19.446577997005196,
                  sitUps: 20.456936110130602,
                  bending: 43.48555270831493,
                  sideJump: 42.41203047484054,
                  shuttleRun: 45.55659121171771,
                  sprintRun: 9.05,
                  standingJump: 154.44060589954825,
                  handballThrow: 15.75885269121813,
                  totalScore: 60.247421786983274,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
        ],
        __typename: 'ReportAverageScoreGraphByCategory',
      },
      juniorHighSchoolReport: {
        reports: [
          {
            year: 2012,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.60392157,
                  sitUps: 22.19520697,
                  bending: 45.80217865,
                  sideJump: 45.98605664,
                  shuttleRun: 55.73594771,
                  sprintRun: 8.894204793,
                  standingJump: 168.3485839,
                  handballThrow: 12.46405229,
                  totalScore: 47.80827887,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 25.11037819,
                  sitUps: 24.68689534,
                  bending: 48.36939314,
                  sideJump: 47.60773967,
                  shuttleRun: 63.96262093,
                  sprintRun: 8.58175022,
                  standingJump: 175.0246262,
                  handballThrow: 14.10510114,
                  totalScore: 53.91996482,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.20418848,
                  sitUps: 25.27792321,
                  bending: 50.2465096,
                  sideJump: 47.89616056,
                  shuttleRun: 63.03446771,
                  sprintRun: 8.561518325,
                  standingJump: 176.8407504,
                  handballThrow: 14.94677138,
                  totalScore: 55.62914485,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2013,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.38743455,
                  sitUps: 22.43455497,
                  bending: 45.16099476,
                  sideJump: 45.68455497,
                  shuttleRun: 55.93237347,
                  sprintRun: 8.92319808,
                  standingJump: 165.7809773,
                  handballThrow: 12.43106457,
                  totalScore: 47.38219895,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.8754932,
                  sitUps: 24.60894345,
                  bending: 48.375274,
                  sideJump: 47.35554581,
                  shuttleRun: 64.06926786,
                  sprintRun: 8.581354669,
                  standingJump: 174.6317405,
                  handballThrow: 13.94476107,
                  totalScore: 53.59886015,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.18105356,
                  sitUps: 25.60646304,
                  bending: 50.20672864,
                  sideJump: 48.00309872,
                  shuttleRun: 62.89464365,
                  sprintRun: 8.5891722,
                  standingJump: 176.0283311,
                  handballThrow: 14.92208942,
                  totalScore: 55.52324037,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2014,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.39607493,
                  sitUps: 22.42908118,
                  bending: 45.29616414,
                  sideJump: 45.97903657,
                  shuttleRun: 56.3220339,
                  sprintRun: 8.914674398,
                  standingJump: 167.9094558,
                  handballThrow: 12.49464764,
                  totalScore: 47.83496878,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.72950089,
                  sitUps: 25.08912656,
                  bending: 48.44340463,
                  sideJump: 47.52406417,
                  shuttleRun: 64.76069519,
                  sprintRun: 8.582531194,
                  standingJump: 174.385918,
                  handballThrow: 13.79723708,
                  totalScore: 53.64349376,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.2183755,
                  sitUps: 25.75499334,
                  bending: 50.79627164,
                  sideJump: 48.05281846,
                  shuttleRun: 63.07101642,
                  sprintRun: 8.549711496,
                  standingJump: 175.7012872,
                  handballThrow: 14.8229028,
                  totalScore: 55.81402574,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2015,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.50775547,
                  sitUps: 22.5830292,
                  bending: 45.79562044,
                  sideJump: 45.99452555,
                  shuttleRun: 56.41377737,
                  sprintRun: 8.848065693,
                  standingJump: 167.5479015,
                  handballThrow: 12.31569343,
                  totalScore: 48.05702555,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.79620425,
                  sitUps: 24.65024853,
                  bending: 48.58382286,
                  sideJump: 47.41572526,
                  shuttleRun: 63.81653864,
                  sprintRun: 8.552327158,
                  standingJump: 173.6290104,
                  handballThrow: 13.81337551,
                  totalScore: 53.58065974,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.33848954,
                  sitUps: 26.11919927,
                  bending: 50.80937216,
                  sideJump: 48.44131028,
                  shuttleRun: 64.03139217,
                  sprintRun: 8.475295723,
                  standingJump: 177.1824386,
                  handballThrow: 14.91628753,
                  totalScore: 56.46405823,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2016,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.2168344,
                  sitUps: 22.64135407,
                  bending: 45.87328454,
                  sideJump: 46.15462031,
                  shuttleRun: 55.92497713,
                  sprintRun: 8.871134492,
                  standingJump: 167.6505032,
                  handballThrow: 12.13906679,
                  totalScore: 47.85041171,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.75789474,
                  sitUps: 25.00457666,
                  bending: 48.05812357,
                  sideJump: 48.02562929,
                  shuttleRun: 64.49839817,
                  sprintRun: 8.541372998,
                  standingJump: 174.3322654,
                  handballThrow: 13.83524027,
                  totalScore: 53.92448513,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.16486486,
                  sitUps: 25.93558559,
                  bending: 51.1018018,
                  sideJump: 48.72207207,
                  shuttleRun: 64.03783784,
                  sprintRun: 8.487702703,
                  standingJump: 176.8612613,
                  handballThrow: 14.81396396,
                  totalScore: 56.46576577,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2017,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.29147571,
                  sitUps: 22.97525206,
                  bending: 46.22502291,
                  sideJump: 46.48441797,
                  shuttleRun: 57.0375802,
                  sprintRun: 8.856370302,
                  standingJump: 167.7451879,
                  handballThrow: 12.23143905,
                  totalScore: 48.31255729,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.88171065,
                  sitUps: 25.59144677,
                  bending: 49.85805278,
                  sideJump: 48.64968153,
                  shuttleRun: 65.71792539,
                  sprintRun: 8.52132848,
                  standingJump: 174.9599636,
                  handballThrow: 13.89854413,
                  totalScore: 54.91810737,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.16238532,
                  sitUps: 25.94724771,
                  bending: 50.81376147,
                  sideJump: 48.87155963,
                  shuttleRun: 63.26513761,
                  sprintRun: 8.463422018,
                  standingJump: 176.312844,
                  handballThrow: 14.78761468,
                  totalScore: 56.37018349,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2018,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.3640148,
                  sitUps: 23.08094357,
                  bending: 46.69750231,
                  sideJump: 47.06521739,
                  shuttleRun: 57.48982424,
                  sprintRun: 8.821877891,
                  standingJump: 168.9181314,
                  handballThrow: 12.30203515,
                  totalScore: 49.01572618,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.7549884,
                  sitUps: 25.40464037,
                  bending: 49.28816705,
                  sideJump: 48.34385151,
                  shuttleRun: 64.80742459,
                  sprintRun: 8.538979118,
                  standingJump: 175.6974478,
                  handballThrow: 13.74709977,
                  totalScore: 54.49559165,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.14200092,
                  sitUps: 26.30843707,
                  bending: 51.61779622,
                  sideJump: 49.52973721,
                  shuttleRun: 64.37344398,
                  sprintRun: 8.431581374,
                  standingJump: 177.9087137,
                  handballThrow: 14.8879668,
                  totalScore: 57.19917012,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2019,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 22.06918819,
                  sitUps: 22.75968635,
                  bending: 46.23293358,
                  sideJump: 46.70064576,
                  shuttleRun: 55.70571956,
                  sprintRun: 8.915636531,
                  standingJump: 168.3071956,
                  handballThrow: 11.96678967,
                  totalScore: 47.86346863,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.61979648,
                  sitUps: 25.04070305,
                  bending: 49.86725254,
                  sideJump: 48.39824237,
                  shuttleRun: 63.54764107,
                  sprintRun: 8.566651249,
                  standingJump: 174.7012026,
                  handballThrow: 13.72155412,
                  totalScore: 54.13691027,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.1123906,
                  sitUps: 25.96453247,
                  bending: 52.24643022,
                  sideJump: 49.17134961,
                  shuttleRun: 62.86135421,
                  sprintRun: 8.500138185,
                  standingJump: 177.2243206,
                  handballThrow: 14.74435744,
                  totalScore: 56.64256103,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2021,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 21.625962178086098,
                  sitUps: 21.28318920459945,
                  bending: 45.575406252969685,
                  sideJump: 44.51971871139409,
                  shuttleRun: 50.96759479235959,
                  sprintRun: 8.989058253349805,
                  standingJump: 164.19899268269504,
                  handballThrow: 11.610472298774114,
                  totalScore: 45.31730495105958,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 24.18899011436325,
                  sitUps: 23.71118433805001,
                  bending: 49.32893971699942,
                  sideJump: 47.078891257995735,
                  shuttleRun: 58.586644698585,
                  sprintRun: 8.621715448730374,
                  standingJump: 172.1356852103121,
                  handballThrow: 13.412773793370809,
                  totalScore: 51.9640434192673,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 25.781122400531764,
                  sitUps: 25.07226284303485,
                  bending: 52.02763270344697,
                  sideJump: 48.12961732029247,
                  shuttleRun: 59.07739056120027,
                  sprintRun: 8.52500902098566,
                  standingJump: 175.4242711993163,
                  handballThrow: 14.44668122685405,
                  totalScore: 55.019751210711235,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2022,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 21.45956935588208,
                  sitUps: 21.02254666420255,
                  bending: 45.87273567467653,
                  sideJump: 44.20931746326948,
                  shuttleRun: 47.519589552238806,
                  sprintRun: 8.72,
                  standingJump: 162.01609901358646,
                  handballThrow: 11.393363161819538,
                  totalScore: 42.58910979759985,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 23.71124763705104,
                  sitUps: 22.852732266313097,
                  bending: 49.08129619101762,
                  sideJump: 46.28305133352452,
                  shuttleRun: 54.705430081691496,
                  sprintRun: 8.15,
                  standingJump: 169.7717909593744,
                  handballThrow: 12.936901570279586,
                  totalScore: 47.047763864042935,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 25.336288967705805,
                  sitUps: 24.17399027673897,
                  bending: 51.73497472383449,
                  sideJump: 47.742938119981105,
                  shuttleRun: 56.01646051379638,
                  sprintRun: 8.05,
                  standingJump: 173.0679932101094,
                  handballThrow: 14.149353712614397,
                  totalScore: 50.46912597310686,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
        ],
        __typename: 'ReportAverageScoreGraphByCategory',
      },
      highSchoolReport: {
        reports: [
          {
            year: 2012,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.57765668,
                  sitUps: 22.54986376,
                  bending: 46.21089918,
                  sideJump: 45.93569482,
                  shuttleRun: 48.89536785,
                  sprintRun: 9.076081744,
                  standingJump: 167.4866485,
                  handballThrow: 13.88501362,
                  totalScore: 48.90245232,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.37751222,
                  sitUps: 22.78978816,
                  bending: 47.18685497,
                  sideJump: 46.42911461,
                  shuttleRun: 49.59043998,
                  sprintRun: 9.165453558,
                  standingJump: 167.1613254,
                  handballThrow: 14.32916893,
                  totalScore: 49.72677892,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.85508791,
                  sitUps: 23.17208311,
                  bending: 48.35322323,
                  sideJump: 46.20245072,
                  shuttleRun: 48.13692062,
                  sprintRun: 9.221081513,
                  standingJump: 166.8673415,
                  handballThrow: 14.58337773,
                  totalScore: 50.10282365,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2013,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.33133841,
                  sitUps: 22.80522307,
                  bending: 47.51360174,
                  sideJump: 46.49129489,
                  shuttleRun: 48.90097933,
                  sprintRun: 9.095380849,
                  standingJump: 166.0228509,
                  handballThrow: 13.74265506,
                  totalScore: 49.00979325,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.09799675,
                  sitUps: 23.14726584,
                  bending: 47.64753655,
                  sideJump: 46.07688143,
                  shuttleRun: 49.22252301,
                  sprintRun: 9.185760693,
                  standingJump: 166.1672983,
                  handballThrow: 14.05089334,
                  totalScore: 49.38061722,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.75477881,
                  sitUps: 23.19989077,
                  bending: 48.40032769,
                  sideJump: 46.48498088,
                  shuttleRun: 48.80557073,
                  sprintRun: 9.261092299,
                  standingJump: 167.8514473,
                  handballThrow: 14.61769525,
                  totalScore: 50.22173676,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2014,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.5233298,
                  sitUps: 23.34146341,
                  bending: 46.90615058,
                  sideJump: 46.99787911,
                  shuttleRun: 49.68822906,
                  sprintRun: 9.014278897,
                  standingJump: 166.985684,
                  handballThrow: 13.74496288,
                  totalScore: 49.71845175,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.19045064,
                  sitUps: 23.20922747,
                  bending: 47.80096567,
                  sideJump: 46.54935622,
                  shuttleRun: 49.55311159,
                  sprintRun: 9.101099785,
                  standingJump: 167.3980687,
                  handballThrow: 14.24356223,
                  totalScore: 49.96405579,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.83181575,
                  sitUps: 23.61435458,
                  bending: 48.87252276,
                  sideJump: 47.0374933,
                  shuttleRun: 50.44563471,
                  sprintRun: 9.128462775,
                  standingJump: 168.780932,
                  handballThrow: 14.65773969,
                  totalScore: 51.1638993,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2015,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.6024296,
                  sitUps: 23.5527333,
                  bending: 47.74986195,
                  sideJump: 47.39536168,
                  shuttleRun: 50.83434567,
                  sprintRun: 8.934826063,
                  standingJump: 167.5146328,
                  handballThrow: 13.69077858,
                  totalScore: 50.36002209,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.42234332,
                  sitUps: 23.70408719,
                  bending: 48.03378747,
                  sideJump: 47.34659401,
                  shuttleRun: 50.7346049,
                  sprintRun: 9.094343324,
                  standingJump: 168.7542234,
                  handballThrow: 14.18583106,
                  totalScore: 50.79455041,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.81813187,
                  sitUps: 23.97967033,
                  bending: 49.50384615,
                  sideJump: 47.61703297,
                  shuttleRun: 50.03516484,
                  sprintRun: 9.07928022,
                  standingJump: 168.6120879,
                  handballThrow: 14.48736264,
                  totalScore: 51.66208791,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2016,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.68446089,
                  sitUps: 23.79862579,
                  bending: 47.27008457,
                  sideJump: 47.38266385,
                  shuttleRun: 51.30073996,
                  sprintRun: 8.876553911,
                  standingJump: 167.3366808,
                  handballThrow: 13.89323467,
                  totalScore: 50.69450317,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.12815013,
                  sitUps: 23.45630027,
                  bending: 48.58713137,
                  sideJump: 47.19892761,
                  shuttleRun: 50.34048257,
                  sprintRun: 9.084375335,
                  standingJump: 166.8895442,
                  handballThrow: 13.95817694,
                  totalScore: 50.37747989,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.86217949,
                  sitUps: 24.19604701,
                  bending: 49.46314103,
                  sideJump: 47.92574786,
                  shuttleRun: 50.5892094,
                  sprintRun: 9.122136752,
                  standingJump: 169.4508547,
                  handballThrow: 14.62820513,
                  totalScore: 51.86004274,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2017,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.50485437,
                  sitUps: 23.52049622,
                  bending: 47.01456311,
                  sideJump: 47.20334412,
                  shuttleRun: 49.99568501,
                  sprintRun: 9.001030205,
                  standingJump: 165.819849,
                  handballThrow: 13.45954693,
                  totalScore: 49.66720604,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.67694001,
                  sitUps: 24.30104568,
                  bending: 48.10842047,
                  sideJump: 47.66483214,
                  shuttleRun: 51.56851954,
                  sprintRun: 9.012212438,
                  standingJump: 167.5916346,
                  handballThrow: 14.04017611,
                  totalScore: 51.23280132,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.83774834,
                  sitUps: 24.30408389,
                  bending: 49.70860927,
                  sideJump: 48.06788079,
                  shuttleRun: 50.4089404,
                  sprintRun: 9.096743929,
                  standingJump: 168.2433775,
                  handballThrow: 14.38355408,
                  totalScore: 51.69812362,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2018,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.6959607,
                  sitUps: 23.45469432,
                  bending: 47.23526201,
                  sideJump: 48.11954148,
                  shuttleRun: 50.12663755,
                  sprintRun: 9.025529476,
                  standingJump: 167.5616812,
                  handballThrow: 13.66648472,
                  totalScore: 50.28820961,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.24932396,
                  sitUps: 23.70903191,
                  bending: 48.398053,
                  sideJump: 47.98972418,
                  shuttleRun: 51.30070308,
                  sprintRun: 9.060492158,
                  standingJump: 168.0870741,
                  handballThrow: 14.11627907,
                  totalScore: 51.07517577,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.98759439,
                  sitUps: 24.36461704,
                  bending: 49.29935275,
                  sideJump: 48.08306365,
                  shuttleRun: 49.94822006,
                  sprintRun: 9.059433657,
                  standingJump: 167.98274,
                  handballThrow: 14.40830636,
                  totalScore: 51.73354908,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2019,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.56896552,
                  sitUps: 24.03078818,
                  bending: 48.23214286,
                  sideJump: 48.15948276,
                  shuttleRun: 50.77832512,
                  sprintRun: 8.914587438,
                  standingJump: 168.2697044,
                  handballThrow: 13.68226601,
                  totalScore: 51.0135468,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.12184615,
                  sitUps: 23.848,
                  bending: 48.48061538,
                  sideJump: 48.04,
                  shuttleRun: 50.00615385,
                  sprintRun: 9.034929231,
                  standingJump: 168.6701538,
                  handballThrow: 13.94461538,
                  totalScore: 50.97723077,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.60789002,
                  sitUps: 24.35624626,
                  bending: 50.22056186,
                  sideJump: 48.18111178,
                  shuttleRun: 50.21817095,
                  sprintRun: 9.101572026,
                  standingJump: 168.6754334,
                  handballThrow: 14.2420801,
                  totalScore: 51.85236103,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2021,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.335414521112256,
                  sitUps: 23.22541194644696,
                  bending: 49.848609680741504,
                  sideJump: 48.4947219361483,
                  shuttleRun: 50.234423274974255,
                  sprintRun: 8.833560762100928,
                  standingJump: 170.39160659114316,
                  handballThrow: 13.412590113285273,
                  totalScore: 51.326338825952625,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 26.229007633587788,
                  sitUps: 23.892629207858842,
                  bending: 50.5274684019522,
                  sideJump: 48.78888749843574,
                  shuttleRun: 51.40357902640471,
                  sprintRun: 8.834517582280066,
                  standingJump: 172.05456138155424,
                  handballThrow: 14.011638092854461,
                  totalScore: 52.75347265673883,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.755255802649167,
                  sitUps: 24.49106817353263,
                  bending: 52.53505893790254,
                  sideJump: 49.27949933163203,
                  shuttleRun: 50.700206586462514,
                  sprintRun: 8.885727305869485,
                  standingJump: 173.01701300279498,
                  handballThrow: 14.508202697776158,
                  totalScore: 53.88929396038401,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
          {
            year: 2022,
            grades: [
              {
                grade: 1,
                score: {
                  grip: 25.174888668975754,
                  sitUps: 22.70048309178744,
                  bending: 49.22876949740035,
                  sideJump: 48.34580484773151,
                  shuttleRun: 48.019834295756965,
                  sprintRun: 8.75,
                  standingJump: 170.04821672673046,
                  handballThrow: 13.262335239990051,
                  totalScore: 50.16392835083385,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 2,
                score: {
                  grip: 25.90022321428571,
                  sitUps: 23.241357152037015,
                  bending: 51.010736196319016,
                  sideJump: 48.674095066340335,
                  shuttleRun: 48.84510339445962,
                  sprintRun: 8.64,
                  standingJump: 170.75721801616837,
                  handballThrow: 13.637024176954734,
                  totalScore: 51.27639791109413,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
              {
                grade: 3,
                score: {
                  grip: 26.480632218844985,
                  sitUps: 23.92787524366472,
                  bending: 51.91754834002189,
                  sideJump: 49.041850220264315,
                  shuttleRun: 48.73325934147244,
                  sprintRun: 8.71,
                  standingJump: 172.0861858190709,
                  handballThrow: 14.215928553951553,
                  totalScore: 52.41160087188181,
                  __typename: 'ReportAverageScoreGraph',
                },
                __typename: 'ReportAverageScoreGraphByGrade',
              },
            ],
            __typename: 'ReportAverageScoreGraphByYear',
          },
        ],
        __typename: 'ReportAverageScoreGraphByCategory',
      },
      __typename: 'ReportAverageScoreGraphSummary',
    },
    __typename: 'ReportAverageScoreGraphSummaryResponse',
  },
}
