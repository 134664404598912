import { UserGender } from '@alpha/core'
import {
  SchoolLevelNoFixTime,
  gradeNumberWithoutFixTimeHighSchool,
  tScoreSubjectNames,
} from '../../../../utils/constant'
import { getGenderName } from '../../../../utils/gender'
import { SchoolLevelNameType4 } from '../../../../utils/type'
import { crossAnalysisShoolLevel2023 } from './answered-school2023'
import { CrossAnalysis, questionnaireCategories } from './type'
import { crossAnalysisAverage2023 } from './average2023'
import { crossAnalysisDeviation2023 } from './deviation2023'
import { crossAnalysisAverage2024 } from './average2024'
import { crossAnalysisDeviation2024 } from './deviation2024'
import { crossAnalysisShoolLevel2024 } from './answered-school2024'

type CrossAnalysisAnswerScore = {
  score: number
  scoreType: typeof tScoreSubjectNames[number]
}

type CrossAnalysisAnswer = {
  answer: number
  totalCount: number
  averages: CrossAnalysisAnswerScore[]
  deviations: CrossAnalysisAnswerScore[]
}

type CrossAnalysisGrade = {
  schoolCategory: SchoolLevelNameType4
  grade: number
  answers: CrossAnalysisAnswer[]
}

export type CrossAnalysisRes = {
  dataKey: typeof questionnaireCategories[number]
  grades: CrossAnalysisGrade[]
}[]

/**
 * @example
 * [1, 2] answers:
 * 1. 所属している
 * 2. 所属していない
 *
 * [1, 2, 3, 4]:
 * 30分未満
 * 30分以上1時間未満
 * 1時間以上2時間未満
 * 2時間以上
 */
const questionnaireAnswers = [
  // q2
  [1, 2],
  // q3
  [1, 2, 3, 4],
  // q4
  [1, 2, 3, 4],
  // q5
  [1, 2, 3],
  // q6
  [1, 2, 3],
  // q7
  [1, 2, 3, 4],
] as const

const metrics = [
  'height',
  'weight',
  'grip',
  'sitUps',
  'bending',
  'sideJump',
  'shuttleRun',
  'sprintRun',
  'standingJump',
  'handballThrow',
  'totalScore',
] as const

export const transformFromExcelDataEachYearToCrossAnalysisReport = (
  year: number,
  gender: UserGender.Male | UserGender.Female,
): CrossAnalysisRes => {
  const crossAnalysisAverage: CrossAnalysis = year === 2023 ? crossAnalysisAverage2023 : crossAnalysisAverage2024
  const crossAnalysisDeviation: CrossAnalysis = year === 2023 ? crossAnalysisDeviation2023 : crossAnalysisDeviation2024
  const crossAnalysisShoolLevel = year === 2023 ? crossAnalysisShoolLevel2023 : crossAnalysisShoolLevel2024

  const genderName = getGenderName(gender)
  const result: CrossAnalysisRes = []

  for (let questionId = 2; questionId <= 7; questionId++) {
    const grades: CrossAnalysisGrade[] = []
    const questionKey =
      `q${questionId}` as typeof questionnaireCategories[number]

    const currAverageQuestion = crossAnalysisAverage[questionKey][genderName]
    const currDeviationQuestion =
      crossAnalysisDeviation[questionKey][genderName]

    const currShoolLevels = crossAnalysisShoolLevel[questionKey]
    // q2 -> q7 so (2 ID) - 2 = (0 ID)
    const answersIds = questionnaireAnswers[questionId - 2]
    const answerNumber = answersIds.length

    // Grades loop
    for (let i = 0; i < gradeNumberWithoutFixTimeHighSchool; i++) {
      /**
       * start form 1
       */
      let grade = 1
      let schoolCategory: SchoolLevelNoFixTime

      if (0 <= i && i <= 5) {
        schoolCategory = SchoolLevelNoFixTime.element
        grade = i + 1
      } else if (6 <= i && i <= 8) {
        schoolCategory = SchoolLevelNoFixTime.junior
        grade = i - 5
      } else {
        schoolCategory = SchoolLevelNoFixTime.high
        grade = i - 8
      }

      if (!currShoolLevels.includes(schoolCategory)) {
        // return 0 at score
        const answers: CrossAnalysisAnswer[] = []
        // answers number loop
        for (let answerId = 0; answerId < answerNumber; answerId++) {
          const averages = [] as CrossAnalysisAnswerScore[]
          const deviations = [] as CrossAnalysisAnswerScore[]

          for (let metricId = 0; metricId < metrics.length; metricId++) {
            // `metricId + 1`, because item 0 is totalCount
            averages.push({
              score: 0,
              scoreType: metrics[metricId],
            })
            deviations.push({
              score: 0,
              scoreType: metrics[metricId],
            })
          }

          answers.push({
            answer: answerId + 1,
            totalCount: 0,
            averages,
            deviations,
          })
        }

        grades.push({
          grade,
          schoolCategory,
          answers,
        })

        continue
      }

      const answers: CrossAnalysisAnswer[] = []

      let gradeStartId = i * answerNumber
      if (
        !currShoolLevels.includes(SchoolLevelNoFixTime.element) &&
        !currShoolLevels.includes(SchoolLevelNoFixTime.junior)
      ) {
        gradeStartId = (i - 9) * answerNumber
      } else if (!currShoolLevels.includes(SchoolLevelNoFixTime.element)) {
        gradeStartId = (i - 6) * answerNumber
      }

      // answers number loop
      for (let answerId = 0; answerId < answerNumber; answerId++) {
        const rowId = gradeStartId + answerId
        const rowAverage = currAverageQuestion[rowId]
        const rowDeviation = currDeviationQuestion[rowId]

        const averages = [] as CrossAnalysisAnswerScore[]
        const deviations = [] as CrossAnalysisAnswerScore[]

        for (let metricId = 0; metricId < metrics.length; metricId++) {
          // `metricId + 1`, because rowAverage item 0 is totalCount
          averages.push({
            score: rowAverage[metricId + 1],
            scoreType: metrics[metricId],
          })
          deviations.push({
            score: rowDeviation[metricId + 1],
            scoreType: metrics[metricId],
          })
        }

        answers.push({
          answer: answerId + 1,
          totalCount: rowAverage[0],
          averages,
          deviations,
        })
      }

      grades.push({
        grade,
        schoolCategory,
        answers,
      })
    }

    result.push({
      dataKey: questionKey,
      grades,
    })
  }

  return result
}
