import { Card } from '@alpha/components'
import { Anchor } from '@sergeimeza/uikit-react'
import { saveAs } from 'file-saver'

import { Dashboard } from '../layout/Dashboard'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import * as ExcelJS from 'exceljs'
import { utils, writeFile } from 'xlsx'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { titles } from '../../utils/titles'
import { defaultSchoolYear } from '../../utils/constant'

const metadata_sample = {
  year: 2021,
  city: '宇都宮市',
  schoolName: '宇都宮市立中央小学校',
  totalStudentsCount: 120,
  studentCount: 2,
  participation_percentage: '25.83',
  ab_de: 1,
  s_rank_count: 1,
  organizationName: 'Appcrunch LTD',
  uploaderName: 'foo',
  uploaderContactEmail: 'sergei@lpp.pw',
  uploaderContactPhone: '+818090999882',
  fileId: 'a2b29cb2-7d2e-48af-a1f2-118b48845d16',
  other: '',
}

const SchoolUploadPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const [metadata, setMetadata] = useState<any[]>()
  const [schoolCategory, setSchoolCategory] = useState('')
  const [loading, setLoading] = useState(false)

  function transformMetaData(data: any[]) {
    return data.map((item) => {
      return Object.keys(metadata_sample).reduce(
        (acc, curr) => ({ ...acc, [titles[curr]]: item[curr] }),
        {},
      )
    })
  }

  useEffect(() => {
    axios
      .create({ baseURL: 'https://api-v2.alpha-pestalozzi.com' })
      .get(`/ibaraki/upload-metadata/?year=${defaultSchoolYear}`)
      .then((res) => {
        // results
        setMetadata(transformMetaData(res.data))
      })
      .catch(() => {})
  }, [])

  function handleDownloadAllMetadata() {
    if (!metadata) return

    const workbook = utils.book_new()
    const worksheet = utils.json_to_sheet(metadata, {})
    utils.book_append_sheet(workbook, worksheet, 'metadata')
    writeFile(workbook, 'アップロード情報（すべての学校単位）.xlsx')
  }

  async function handleDownloadAllResults(schoolCategoryCode) {
    // .get(`/tochigi/uploaded-results/?year=${defaultSchoolYear}`)
    setLoading(true)
    setSchoolCategory(schoolCategoryCode)
    let results: any[] = []
    try {
      const resp = await axios.get(
        `https://api-v2.alpha-pestalozzi.com/ibaraki/upload-results/?year=${defaultSchoolYear}&category=${schoolCategoryCode}`,
      )
      results = resp.data
    } catch (err: unknown) {
      console.error('/ibaraki/upload-results/ error:', err)
      setLoading(false)
      return
    }

    fetch(
      schoolCategoryCode === 'B1'
        ? '/templates/template_elementary_school.xlsx'
        : schoolCategoryCode === 'C1'
        ? '/templates/template_junior_high_school.xlsx'
        : '/templates/template_high_school.xlsx',
    )
      .then((response) => response.arrayBuffer())
      .then(async (buffer) => {
        const workbook = new ExcelJS.Workbook()
        await workbook.xlsx.load(buffer)
        const worksheet = workbook.worksheets[0]
        const compareString = (a: any, b: any) => {
          if (a === undefined) return -1
          if (b === undefined) return 1
          return a.localeCompare(b)
        }
        results.sort((a, b) => {
          if (a.city !== b.city) return compareString(a.city, b.city)
          if (a.schoolName !== b.schoolName)
            return compareString(a.schoolName, b.schoolName)
          if (a.grade !== b.grade) return Number(a.grade) - Number(b.grade)
          if (a.class !== b.class) return Number(a.class) - Number(b.class)
          if (a.attendance_number !== b.attendance_number)
            return Number(a.attendance_number) - Number(b.attendance_number)
          return Number(a.gender) - Number(b.gender)
        })
        for (const [index, result] of results.entries()) {
          const lastRow = worksheet.getRow(index + 5)
          let id = 1
          lastRow.getCell(id++).value = index + 1
          lastRow.getCell(id++).value = result.schoolName
          lastRow.getCell(id++).value = result.grade ?? 0
          lastRow.getCell(id++).value = result.gender
          lastRow.getCell(id++).value = result.age
          lastRow.getCell(id++).value = result.family_name
          lastRow.getCell(id++).value = result.given_name
          lastRow.getCell(id++).value = result.family_name_hiragana
          lastRow.getCell(id++).value = result.given_name_hiragana
          lastRow.getCell(id++).value = result.q1
          lastRow.getCell(id++).value = result.q2
          lastRow.getCell(id++).value = result.q3
          lastRow.getCell(id++).value = result.q4
          lastRow.getCell(id++).value = result.q5
          lastRow.getCell(id++).value = result.q6
          lastRow.getCell(id++).value = result.q7
          lastRow.getCell(id++).value = result.height
          lastRow.getCell(id++).value = result.weight
          lastRow.getCell(id++).value = result.grip
          lastRow.getCell(id++).value = result.sit_ups
          lastRow.getCell(id++).value = result.bending
          lastRow.getCell(id++).value = result.side_jump
          lastRow.getCell(id++).value = result.shuttle_run
          lastRow.getCell(id++).value = result.sprint_run
          lastRow.getCell(id++).value = result.standing_jump
          lastRow.getCell(id++).value = result.ball_throw
        }
        setLoading(false)
        workbook.xlsx
          .writeBuffer()
          .then((buffer) =>
            saveAs(
              new Blob([buffer]),
              `アップロード情報（${
                schoolCategoryCode === 'B1'
                  ? '小学校'
                  : schoolCategoryCode === 'C1'
                  ? '中学校'
                  : '高校'
              }の個人単位）.xlsx`,
            ),
          )
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }
  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={<div className="theme-nav-title">アップロード情報</div>}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card>
        <div className="grid grid-cols-3 gap-8">
          <Anchor
            className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
            to="/reports/file-uploads/metadata"
          >
            アップロード情報（学校単位）
          </Anchor>
          <Anchor
            className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
            to="/reports/file-uploads/results"
          >
            アップロード情報（個人単位）
          </Anchor>
          <Anchor
            className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
            onClick={handleDownloadAllMetadata}
            to="#"
          >
            アップロード情報（すべての学校単位）
          </Anchor>
          <Anchor
            className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
            onClick={() => !loading && handleDownloadAllResults('B1')}
            to="#"
          >
            {loading && schoolCategory === 'B1'
              ? 'Downloading...'
              : 'アップロード情報（小学校の個人単位）'}
          </Anchor>
          <Anchor
            className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
            onClick={() => !loading && handleDownloadAllResults('C1')}
            to="#"
          >
            {loading && schoolCategory === 'C1'
              ? 'Downloading...'
              : 'アップロード情報（中学校の個人単位）'}
          </Anchor>
          <Anchor
            className="text-white text-center bg-primary-500 hover:bg-primary-100 hover:text-primary-700 px-4 py-2.5 rounded-lg text-lg"
            onClick={() => !loading && handleDownloadAllResults('D1')}
            to="#"
          >
            {loading && schoolCategory === 'D1'
              ? 'Downloading...'
              : 'アップロード情報（高校の個人単位）'}
          </Anchor>
        </div>
      </Card>
    </Dashboard>
  )
}

export default SchoolUploadPage
