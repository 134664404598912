export const schoolCategories = [
  {
    code: 'B1',
    name: '小学校・義務教育学校(前期)',
    meta: 'ELEMENTARY_SCHOOL',
  },
  {
    code: 'C1',
    name: '中学校・義務教育学校(後期)・中等教育学校(前期)',
    meta: 'MIDDLE_SCHOOL',
  },
  {
    code: 'D1',
    name: '高校(全日制・定時制)・中等教育学校（後期）',
    meta: 'HIGH_SCHOOL',
  },
]
