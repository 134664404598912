import { TScoreEachSchool, TScoreEachStudent, TScoreRes } from './type'
import { tScoreSubjectNames } from '../../../../utils/constant'
import { theTScore2023Female, theTScore2023Male } from './year2023'
import { theTScore2024Female, theTScore2024Male } from './year2024'

export const transformFromExcelDataEachYearTScoreReport = (
  year: number,
  isMale: boolean,
): TScoreRes => {
  const rawData = year === 2023
    ? isMale ? theTScore2023Male : theTScore2023Female
    : isMale ? theTScore2024Male : theTScore2024Female

  const elementarySchoolReport = {
    reports: [],
  } as TScoreEachSchool
  const juniorHighSchoolReport = {
    reports: [],
  } as TScoreEachSchool

  const highSchoolReport = { reports: [] } as TScoreEachSchool

  for (let i = 0; i < rawData.length; i++) {
    const schoolScore = rawData[i]

    let graphDataForSchool: TScoreEachSchool

    /**
     * start form 1
     */
    let grade = 1
    if (0 <= i && i <= 5) {
      graphDataForSchool = elementarySchoolReport
      grade = i + 1
    } else if (6 <= i && i <= 8) {
      graphDataForSchool = juniorHighSchoolReport
      grade = i - 5
    } else {
      graphDataForSchool = highSchoolReport
      grade = i - 8
    }

    const prefecture = {} as TScoreEachStudent

    const eachGradeData: TScoreEachSchool['reports'][0] = {
      grade,
      prefecture,
    }

    for (let j = 0; j < schoolScore.length; j++) {
      const subjectScore = schoolScore[j]
      const subjectName = tScoreSubjectNames[j]
      prefecture[subjectName] = subjectScore
    }

    graphDataForSchool.reports.push(eachGradeData)
  }

  return { elementarySchoolReport, juniorHighSchoolReport, highSchoolReport }
}
