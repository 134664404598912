import { BodyReport, BodyReportForSchool } from './type'

/**
 * Key Order is importance!
 */
const bodySubjectNames = [
  'sampleCount',
  'heightAvg',
  'weightAvg',
  'heightDev',
  'weightDev',
] as const

export const handleEachYearBody = (
  rawData: number[][],
): BodyReportForSchool => {
  const elementarySchoolReport = { reports: [] } as BodyReport
  const juniorHighSchoolReport = { reports: [] } as BodyReport
  const allDayHighSchoolReport = { reports: [] } as BodyReport
  const fixTimeHighSchoolReport = { reports: [] } as BodyReport

  for (let i = 0; i < rawData.length; i++) {
    const gradeScore = rawData[i]

    let graphDataForSchool: BodyReport

    /**
     * start form 1
     */
    let grade = 1
    if (0 <= i && i <= 5) {
      graphDataForSchool = elementarySchoolReport
      grade = i + 1
    } else if (6 <= i && i <= 8) {
      graphDataForSchool = juniorHighSchoolReport
      grade = i - 5
    } else if (9 <= i && i <= 11) {
      graphDataForSchool = allDayHighSchoolReport
      grade = i - 8
    } else {
      graphDataForSchool = fixTimeHighSchoolReport
      grade = i - 11
    }

    const report = { grade } as BodyReport['reports'][0]
    const reports = graphDataForSchool.reports

    for (let j = 0; j < gradeScore.length; j++) {
      const subjectScore = gradeScore[j]
      const subjectName = bodySubjectNames[j]
      report[subjectName] = subjectScore
    }

    reports.push(report)
  }

  return {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  }
}
