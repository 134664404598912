import { useHistory } from 'react-router-dom'
import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
// import { Button } from 'antd'
import { Button } from '@sergeimeza/uikit-react'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'

const reports = {
  count: '調査人数',
  test_comparison: '県平均値と全国平均値との比較',
  body: '体格の平均値及び標準偏差',
  test: '体力テスト測定項目の平均値と標準偏差',
  test_rank: '総合評価段階別人数及びその割合',
  score_chart: 'Tスコアによる全国平均値による比較',
  average_graph: '測定項目平均値の年次推移',
  test_rank_target: '総合評価段階別人数の割合の推移',
  test_rank_target_graph: '総合評価段階別人数の割合の推移(グラフ)',
  questionnaire: '児童生徒の生活状況',
  cross_analysis: 'アンケート回答別クロス集計',
  cross_analysis_graph: 'アンケート回答別クロス集計(グラフ)',
  school_performance: '体力つくり関係表彰校一覧',
  // school_performance: '体力つくり関係表彰校一覧（体力つくり優秀賞用）',
  // school_progress: '体力つくり関係表彰校一覧（体力つくり奨励賞用）',
  'file-uploads': 'アップロード情報',
}

const ReportPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const history = useHistory()

  let title = <div className="theme-nav-title">ホーム</div>

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card>
        <div className="grid grid-cols-3 gap-4">
          {Object.keys(reports).map((k) => (
            <Button
              key={k}
              type="button"
              onClick={() => history.push(`/reports/${k}`)}
            >
              {reports[k]}
            </Button>
          ))}
        </div>
      </Card>
    </Dashboard>
  )
}

export default ReportPage
