import { SchoolLevelNoFixTime } from '../../../../utils/constant'

export const crossAnalysisShoolLevel2023 = {
  q2: [
    SchoolLevelNoFixTime.element,
    SchoolLevelNoFixTime.junior,
    SchoolLevelNoFixTime.high,
  ],
  q3: [
    SchoolLevelNoFixTime.element,
    SchoolLevelNoFixTime.junior,
    SchoolLevelNoFixTime.high,
  ],
  q4: [
    SchoolLevelNoFixTime.element,
    SchoolLevelNoFixTime.junior,
    SchoolLevelNoFixTime.high,
  ],
  q5: [
    SchoolLevelNoFixTime.element,
    SchoolLevelNoFixTime.junior,
    SchoolLevelNoFixTime.high,
  ],
  q6: [
    SchoolLevelNoFixTime.element,
    SchoolLevelNoFixTime.junior,
    SchoolLevelNoFixTime.high,
  ],
  q7: [
    SchoolLevelNoFixTime.element,
    SchoolLevelNoFixTime.junior,
    SchoolLevelNoFixTime.high,
  ],
}
