import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import {
  logout,
  ReportTestRankByGrade,
  ReportTestRankSummary,
  useReportTestRankSummaryLazyQuery,
} from '@alpha/core'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import testRank2020Male from './data/test-rank/testRank2022Male.json'
import testRank2020Female from './data/test-rank/testRank2022Female.json'
import { customYears, defaultSchoolYear, prefectureCode, schoolsReportType } from '../../utils/constant'
import {
  transformFromExcelDataEachYearTestRankReport,
  transformFromExcelDataEachYearTestRankTotalsReport,
} from './data/test-rank'

const { Option } = Select

type ReportTableDataProps = {
  data: ReportTestRankByGrade
  className: string
}

const ranks = ['A', 'B', 'C', 'D', 'E'] as const

const ReportTableData = ({ data, className }: ReportTableDataProps) => {
  return (
    <tr className={className}>
      <td className={className}>{data.grade === 0 ? '合計' : data.grade}</td>
      <td className={className}>{data.totalCount}</td>
      {ranks.map((rank) => (
        <td className={className} key={`${data.grade}_count_${rank}`}>
          {data.countByRank?.[`rank${rank}`] ?? 0}
        </td>
      ))}
      {ranks.map((rank) => (
        <td className={className} key={`${data.grade}_rate_${rank}`}>
          {((data.rateByRank?.[`rank${rank}`] ?? 0) * 100).toFixed(2)}%
        </td>
      ))}
    </tr>
  )
}

const styleForReport = {
  fontSize: 12,
  width: 64,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className={className}>
        <th
          className={className}
          style={{ fontSize: 12, width: 128 }}
          rowSpan={2}
        >
          校種
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          学年
        </th>
        <th className={className} style={styleForReport} rowSpan={2}>
          全種目実施人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別人数
        </th>
        <th className={className} style={styleForReport} colSpan={5}>
          総合評価段階別割合
        </th>
      </tr>
      <tr className={className}>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
        <th className={className} style={styleForReport}>
          A
        </th>
        <th className={className} style={styleForReport}>
          B
        </th>
        <th className={className} style={styleForReport}>
          C
        </th>
        <th className={className} style={styleForReport}>
          D
        </th>
        <th className={className} style={styleForReport}>
          E
        </th>
      </tr>
    </thead>
  )
}

type TableBodyProps = {
  className: string
  data:
    | ReportTestRankSummary
    | typeof testRank2020Male.data.reportTestRankSummary.data
}

const TableBody = ({ className, data }: TableBodyProps) => {
  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  } = data
  if (
    !elementarySchoolReport?.reports ||
    !juniorHighSchoolReport?.reports ||
    !allDayHighSchoolReport?.reports ||
    !fixTimeHighSchoolReport?.reports
  ) {
    return null
  }
  return (
    <tbody>
      <tr>
        <th
          rowSpan={elementarySchoolReport.reports.length + 1}
          className={className}
        >
          小学校
        </th>
      </tr>
      {elementarySchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={juniorHighSchoolReport.reports.length + 1}
          className={className}
        >
          中学校
        </th>
      </tr>
      {juniorHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={allDayHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校
        </th>
      </tr>
      {allDayHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
      <tr>
        <th
          rowSpan={fixTimeHighSchoolReport.reports.length + 1}
          className={className}
        >
          高校(定時制)
        </th>
      </tr>
      {fixTimeHighSchoolReport.reports.map((data) => (
        <ReportTableData className={className} data={data} key={data.grade} />
      ))}
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
  year: number
}

const makeReportTestRankByGrade = (): ReportTestRankByGrade => {
  return {
    grade: 0,
    totalCount: 0,
    countByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
    rateByRank: {
      rankA: 0,
      rankB: 0,
      rankC: 0,
      rankD: 0,
      rankE: 0,
    },
  }
}

const ReportTestTable = ({ prefectureCode, year }: TableProps) => {
  const [getReportForMale, { data: dataForMale, loading: loadingForMale }] =
    useReportTestRankSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  const [
    getReportForFemale,
    { data: dataForFemale, loading: loadingForFemale, error },
  ] = useReportTestRankSummaryLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (error) {
      const errorMessage = JSON.stringify(error?.networkError)
      if (errorMessage.includes('"code":"UNAUTHENTICATED"')) {
        logout()
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    }
  }, [error])

  const [dataForTotal, setDataForTotal] = useState<
    ReportTestRankSummary | undefined
  >()

  useEffect(() => {
    if (prefectureCode === 0 || year >= 2023) return

    getReportForMale({
      variables: {
        input: {
          prefectureCode,
          year,
          gender: 1,
        },
      },
    })

    getReportForFemale({
      variables: {
        input: {
          prefectureCode,
          year,
          gender: 2,
        },
      },
    })
  }, [prefectureCode, year, getReportForMale, getReportForFemale])

  useEffect(() => {
    if (year >= 2023) {
      setDataForTotal(transformFromExcelDataEachYearTestRankTotalsReport(year))
      return
    }

    if (!dataForMale || !dataForFemale) return

    const totalData: ReportTestRankSummary = {
      elementarySchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      juniorHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      allDayHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
      fixTimeHighSchoolReport: {
        reports: [makeReportTestRankByGrade()],
      },
    }

    const reports = [
      year === 2022 ? testRank2020Male.data : dataForMale,
      year === 2022 ? testRank2020Female.data : dataForFemale,
    ]

    const totalFixedRates = [
      { A: 17.34, B: 26.98, C: 29.99, D: 17.78, E: 7.92 },
      { A: 24.66, B: 27.7, C: 26.58, D: 15.58, E: 5.49 },
      { A: 20.89, B: 29.85, C: 31.11, D: 14.73, E: 3.42 },
      { A: 1.32, B: 7.58, C: 27.34, D: 37.83, E: 25.93 },
    ] as const

    reports.forEach((d) => {
      schoolsReportType.forEach((s, index) => {
        if (!d.reportTestRankSummary?.data) return
        const targetData = d.reportTestRankSummary?.data[s].reports
        const destData = totalData[s].reports[0]
        targetData.forEach((t) => {
          destData.totalCount += t.totalCount
          ranks.forEach((rank) => {
            destData.countByRank[`rank${rank}`] +=
              t?.countByRank?.[`rank${rank}`] ?? 0
          })
        })
        if (year === 2022)
          ranks.forEach((rank) => {
            destData.rateByRank[`rank${rank}`] =
              totalFixedRates[index][rank] / 100
          })
      })
    })

    setDataForTotal(totalData)
  }, [dataForMale, dataForFemale, setDataForTotal, year])

  if (loadingForMale || loadingForFemale) {
    return <Loading />
  }

  if (
    (!dataForMale?.reportTestRankSummary?.data ||
      !dataForFemale?.reportTestRankSummary?.data) &&
    !customYears.includes(year)
  ) {
    return <NoData />
  }

  if (!dataForTotal) {
    return null
  }

  let maleData: ReportTestRankSummary, femaleData: ReportTestRankSummary

  if (!customYears.includes(year)) {
    maleData = (year === 2022 ? testRank2020Male.data : dataForMale)
      .reportTestRankSummary.data as ReportTestRankSummary
    femaleData = (year === 2022 ? testRank2020Female.data : dataForFemale)
      .reportTestRankSummary.data as ReportTestRankSummary
  } else {
    maleData = transformFromExcelDataEachYearTestRankReport(year, true)
    femaleData = transformFromExcelDataEachYearTestRankReport(year, false)
  }

  return (
    <>
      <div>男子</div>
      <table className="table border-collapse">
        <TableHeader className="table-border-male" />
        <TableBody className="table-border-male" data={maleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        女子
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-female" />
        <TableBody className="table-border-female" data={femaleData} />
      </table>
      <div className="pt-4" style={{ pageBreakBefore: 'always' }}>
        合計
      </div>
      <table className="table border-collapse">
        <TableHeader className="table-border-total" />
        <TableBody className="table-border-total" data={dataForTotal} />
      </table>
    </>
  )
}

const ReportTestRankPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const [year, setYear] = useState(defaultSchoolYear)

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数及びその割合</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="print:hidden">
        <Card>
          <div className="space-y-4 space-x-4 pb-8">
            <Select value={year} onChange={(v) => setYear(v)}>
              <Option value={2024}>2024 ({makeYearName(2024)})</Option>
              <Option value={2023}>2023 ({makeYearName(2023)})</Option>
              <Option value={2022}>2022 ({makeYearName(2022)})</Option>
              <Option value={2021}>2021 ({makeYearName(2021)})</Option>
            </Select>
          </div>

          <ReportTestTable prefectureCode={prefectureCode} year={year} />
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県児童生徒の体力・運動能力調査結果
        </div>
        <div>総合評価段階別人数及びその割合</div>
        <ReportTestTable prefectureCode={prefectureCode} year={year} />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankPage
