import { useCallback, useEffect, useMemo, useState } from 'react'

import { Select, Table } from 'antd'
import { utils, writeFile } from 'xlsx'
import { Button, SelectMenu } from '@sergeimeza/uikit-react'

import axios from 'axios'

import cities from '../../utils/cities.json'
import { titles } from '../../utils/titles'
import { schoolCategories } from '../../utils/schoolCategories'
import { makeYearName } from '../../utils'
import { defaultSchoolYear } from '../../utils/constant'
const { Option } = Select

const metadata_sample = {
  year: 2021,
  city: '宇都宮市',
  schoolName: '那珂市立横堀小学校',
  totalStudentsCount: 120,
  studentCount: 240,
  participation_percentage: '25.83',
  organizationName: 'Appcrunch LTD',
  uploaderName: 'sergei',
  uploaderContactEmail: 'sergei@lpp.pw',
  uploaderContactPhone: '+818090999882',
  fileId: '81e1cf1f-efe9-4a5d-a6f6-36b344d3d544',
  other: '',
}

const UploadMetadataPage = () => {
  const [category, setCategory] = useState<any>()
  const [city, setCity] = useState<any>()
  const [results, setResults] = useState<any[]>()
  const [year, setYear] = useState(defaultSchoolYear)
  const deleteFileId = useCallback(async (fileId: string, year: number) => {
    const result = await axios
      .create({ baseURL: 'https://api-v2.alpha-pestalozzi.com' })
      .delete(`/ibaraki/file-uploads/${fileId}?year=${year}`)
    return result.data?.data
  }, [])

  const ibarakiCities = useMemo(
    () =>
      cities.map((city) => ({
        name: city.name,
        value: city.name,
      })),
    [],
  )

  function transformData(data: any[]) {
    return data.map((item) => {
      return Object.keys(metadata_sample).reduce(
        (acc, curr) => ({ ...acc, [titles[curr]]: item[curr] }),
        {},
      )
    })
  }

  async function onFileIdDelete(fileId: string) {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure to remove all records of file ${fileId}`)) {
      await deleteFileId(fileId, year)
      window.location.reload()
    }
  }

  const columnsForData = (data: any[]) => {
    if (!data?.length) return []
    let sample = data[0]
    return Object.keys(sample).map((key) => {
      if (key === 'アップロードID') {
        return {
          title: key,
          dataIndex: key,
          key,
          render: (item) => (
            <div>
              <a
                className="text-blue-700 font-medium underline"
                href={`https://storage.googleapis.com/alpha_school/ibaraki/file-upload/${item}.xlsx`}
              >
                {item}
              </a>
            </div>
          ),
        }
      }

      if (key === 'その他') {
        return {
          title: key,
          dataIndex: key,
          key,
          render: (item) => (
            <div>
              <Button
                onClick={() => onFileIdDelete(item)}
                buttonType={Button.buttonType.outline}
              >
                <div className="text-red-500">削除</div>
              </Button>
            </div>
          ),
        }
      }

      return {
        title: key,
        dataIndex: key,
        key,
      }
    })
  }

  useEffect(() => {
    if (!city) return
    if (!category) return
    axios
      .create({ baseURL: 'https://api-v2.alpha-pestalozzi.com' })
      .get(
        `/ibaraki/upload-metadata/?city=${city}&category=${category}&year=${year}`,
      )
      .then((res) => {
        // results
        let tResults = transformData(
          res.data.map((data) => ({ ...data, other: data.fileId })),
        )
        setResults(tResults)
      })
      .catch(() => {})
  }, [city, category, year])

  function handleDownload() {
    if (!results) return

    let workbook = utils.book_new()
    let worksheet = utils.json_to_sheet(results, {})
    utils.book_append_sheet(workbook, worksheet, 'metadata')
    writeFile(workbook, 'metadata.xlsx')
  }

  return (
    <div className="px-10 py-10 max-w-7xl mx-auto flex flex-col items-center">
      <p className="font-medium text-gray-900">新体力テストAlpha</p>
      <h1 className="font-bold text-2xl mb-8 text-gray-900">茨城県</h1>
      <div className="space-y-4 pb-8 w-full">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <div className="w-full grid grid-cols-2 gap-x-4">
        <div>
          <SelectMenu
            name="schoolCategory"
            label="学校種別選択"
            options={schoolCategories}
            type={SelectMenu.type.custom}
            onItemSelected={(item) => {
              setCategory(item.code)
            }}
          />
        </div>
        <div>
          <SelectMenu
            name="city"
            label="市町選択「所在地」"
            options={ibarakiCities}
            type={SelectMenu.type.custom}
            onItemSelected={(item) => {
              setCity(item.value)
            }}
          />
        </div>
      </div>

      <div className="w-full">
        <div className="flex justify-end my-4">
          {!!results?.length && (
            <Button title="ダウンロード" onClick={handleDownload} />
          )}
        </div>
        <div className="overflow-x-auto bg-white px-4">
          <Table
            className="min-w-[3000px]"
            style={{
              minWidth: 3000,
            }}
            dataSource={results}
            columns={columnsForData(results)}
            pagination={{
              pageSize: 100,
              position: ['topLeft', 'bottomLeft'],
            }}
            scroll={{ x: true }}
            size="small"
          />
        </div>
      </div>
    </div>
  )
}

export default UploadMetadataPage
