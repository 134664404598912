import { FixedLengthArray } from '../../../../utils/type'

export enum CalculatorType {
  average = 'average',
  deviation = 'deviation',
}

export type TestReportFromExcel = FixedLengthArray<
  FixedLengthArray<number, 9>,
  16
>
