import { ComparisonYesNoArr } from './type'

/**
 * Copy from Excel.
 *
 * Prefecture.
 * Male.
 *
 * From 2024, data format had been changed.
 * So to migrate data from Excel:
 *   - On Excel, replace comma character with `.` if needed.
 *   - Just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 */
export const comparisonPrefMale2024: ComparisonYesNoArr = [
  [false, true, false, true, false, false, false, true, false],
  [false, false, false, true, false, false, false, true, false],
  [false, true, false, true, true, false, false, true, true],
  [false, true, false, true, true, false, true, true, true],
  [false, true, false, true, true, false, false, true, true],
  [false, true, false, true, true, true, true, true, true],
  [false, true, true, true, false, true, true, true, true],
  [false, true, true, true, true, true, false, true, true],
  [true, true, true, true, false, true, true, true, true],
  [true, true, false, true, false, false, true, true, true],
  [true, true, true, true, true, false, true, true, true],
  [false, false, false, false, false, false, false, true, false],
]

// female
export const comparisonPrefFemale2024: ComparisonYesNoArr = [
  [false, true, false, false, false, false, false, true, false],
  [false, false, false, false, false, false, false, true, false],
  [false, true, true, false, false, false, false, true, false],
  [false, true, false, true, true, false, false, true, true],
  [false, false, false, true, false, false, false, false, false],
  [false, true, false, true, false, false, false, true, false],
  [true, true, true, true, false, true, true, false, true],
  [true, true, true, false, false, true, true, false, false],
  [false, true, false, true, false, false, false, false, false],
  [false, false, false, false, false, false, true, false, false],
  [true, false, false, true, true, false, true, false, false],
  [true, false, false, false, false, false, true, true, false],
]

export const comparisonNationMale2024: ComparisonYesNoArr = [
  [false, true, true, false, true, false, false, false, true],
  [false, true, true, true, true, false, false, false, true],
  [false, true, true, true, true, false, false, false, true],
  [false, true, true, true, true, false, true, false, true],
  [false, true, true, true, false, false, false, false, true],
  [false, true, true, false, false, false, false, false, false],
  [true, true, true, false, false, false, false, false, true],
  [false, true, true, false, false, true, false, false, true],
  [true, true, true, false, false, true, false, false, true],
  [false, true, true, true, true, false, false, false, false],
  [false, false, true, true, false, false, false, false, false],
  [false, false, false, true, true, false, false, false, false],
]

export const comparisonNationFemale2024: ComparisonYesNoArr = [
  [false, true, true, false, true, false, false, true, true],
  [false, true, true, true, true, false, false, true, true],
  [false, true, true, true, true, false, false, true, true],
  [false, true, true, true, true, false, false, true, true],
  [false, true, true, true, true, false, false, true, true],
  [true, true, true, true, true, false, false, true, true],
  [false, true, true, false, false, false, false, false, false],
  [false, true, true, false, false, true, false, false, true],
  [true, true, true, false, false, true, false, false, true],
  [false, true, true, true, true, false, false, false, true],
  [false, false, true, true, true, false, false, false, false],
  [false, false, true, true, true, false, false, false, false],
]
