import { Fragment, useEffect, useState } from 'react'

import { Dashboard } from '../layout/Dashboard'
import { Card } from '@alpha/components'
import { Questionnaire, questionnaires } from './ReportQuestionnairePage'
import { Radio, Select } from 'antd'
import {
  logout,
  ReportCrossAnalysisByQuestionnaire,
  SchoolCategory,
  useReportCrossAnalysisSummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { customYears, defaultSchoolYear, prefectureCode } from '../../utils/constant'
import { transformFromExcelDataEachYearToCrossAnalysisReport } from './data/cross-analysis'

const { Option } = Select

const scoreTypes = {
  height: '身長',
  weight: '体重',
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
  totalScore: '得点合計',
}

const schoolCategories = [
  SchoolCategory.ElementarySchool,
  SchoolCategory.JuniorHighSchool,
  'ALL_DAY_HIGH_SCHOOL',
]

const styleForReport = {
  fontSize: 12,
  width: 80,
}
const styleForScoreType = {
  fontSize: 10,
  width: 80,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr>
        <th className={className} style={styleForReport}>
          校種
        </th>
        <th className={className} style={styleForReport}>
          学年
        </th>
        <th className={className} style={{ fontSize: 12, width: 128 }}>
          設問
        </th>
        <th className={className} style={styleForReport}>
          標本数
        </th>
        <>
          {Object.keys(scoreTypes).map((k) => (
            <th className={className} style={styleForScoreType} key={k}>
              {scoreTypes[k]}
            </th>
          ))}
        </>
      </tr>
    </thead>
  )
}

type TableBodyProps = {
  className: string
  question: Questionnaire
  data: ReportCrossAnalysisByQuestionnaire
  valueType: string
  schoolCategory: SchoolCategory
  forPrinting: boolean
}

const TableBody = ({
  className,
  question,
  data,
  valueType,
  schoolCategory,
  forPrinting,
}: TableBodyProps) => {
  if (!data || !question) {
    return null
  }
  const getCategoryName = (s: SchoolCategory): string => {
    switch (s) {
      case SchoolCategory.ElementarySchool:
        return '小学校'
      case SchoolCategory.JuniorHighSchool:
        return '中学校'
    }
    return '高校'
  }
  const answerCount = question.answers.length
  const gradeCount = schoolCategory === SchoolCategory.ElementarySchool ? 6 : 3
  const rowSpan = (answerCount + 1) * gradeCount + 1
  let scores = data.grades!.filter((d) => d.schoolCategory === schoolCategory)
  scores = scores.slice().sort((a, b) => a.grade! - b.grade!)
  return (
    <tbody>
      <tr>
        <th className={className} rowSpan={rowSpan}>
          {getCategoryName(schoolCategory)}
        </th>
      </tr>
      <>
        {scores.map((g) => (
          <Fragment key={`${g.schoolCategory}_${g.grade}`}>
            <tr>
              <th className={className} rowSpan={answerCount + 1}>
                {g.grade}
              </th>
            </tr>
            <>
              {g.answers!.map((a, idx) => {
                const target =
                  valueType === 'average' ? a.averages : a.deviations

                const scores = Object.keys(scoreTypes).map(
                  (type) => target!.find((t) => t.scoreType === type)!.score!,
                )
                let answer: any = a.answer!
                if (forPrinting) {
                  answer = `${idx + 1}`
                } else {
                  answer = questionnaires
                    .find((i) => i.dataKey === question.dataKey)!
                    .answers![answer - 1].replace(/\(.*\)/i, '')
                }

                return (
                  <tr key={idx}>
                    <td
                      className={className}
                      style={{ fontSize: forPrinting ? 10 : 10 }}
                    >
                      {answer}
                    </td>
                    <td className={className}>{a.totalCount}</td>
                    <>
                      {scores.map((score, idx) => (
                        <td className={className} key={idx}>
                          {score!.toFixed(2)}
                        </td>
                      ))}
                    </>
                  </tr>
                )
              })}
            </>
          </Fragment>
        ))}
      </>
    </tbody>
  )
}

type TableProps = {
  prefectureCode: number
  year: number
  gender: UserGender
  valueType: string
  question: Questionnaire | undefined
  forPrinting: boolean
}

const ReportCrossAnalysisTable = ({
  prefectureCode,
  year,
  gender,
  valueType,
  question,
  forPrinting,
}: TableProps) => {
  const [getReport, { data, loading, called, error }] =
    useReportCrossAnalysisSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (error) {
      const errorMessage = JSON.stringify(error?.networkError)
      if (errorMessage.includes('"code":"UNAUTHENTICATED"')) {
        logout()
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    }
  }, [error])

  useEffect(() => {
    if (prefectureCode === 0 || year >= 2023) {
      return
    }

    getReport({
      variables: {
        input: {
          prefectureCode,
          gender: gender === UserGender.Male ? 1 : 2,
          year,
        },
      },
    })
  }, [gender, prefectureCode, year, getReport])

  if (loading) {
    return <Loading />
  }

  if (!data?.reportCrossAnalysisSummary?.data?.reports && !customYears.includes(year)) {
    if (called) {
      return <NoData />
    } else {
      return null
    }
  }

  if (!question) {
    return null
  }

  let reportData: ReportCrossAnalysisByQuestionnaire

  if (!customYears.includes(year)) {
    reportData = data.reportCrossAnalysisSummary.data.reports.find(
      (d) => d.dataKey === question.dataKey,
    )
  } else {
    const reports = transformFromExcelDataEachYearToCrossAnalysisReport(
      year,
      gender as UserGender.Male | UserGender.Female,
    )

    reportData = reports.find((d) => d.dataKey === question.dataKey)
  }

  if (!reportData && !customYears.includes(year)) {
    return <NoData />
  }

  return (
    <>
      {schoolCategories.map((s) => (
        <div key={s} style={{ marginBottom: 10, pageBreakAfter: 'always' }}>
          <table className="table border-collapse">
            <TableHeader
              className={
                gender === UserGender.Male
                  ? 'table-border-male'
                  : 'table-border-female'
              }
            />
            <TableBody
              className={
                gender === UserGender.Male
                  ? 'table-border-male'
                  : 'table-border-female'
              }
              question={question}
              data={reportData!}
              schoolCategory={s as any}
              valueType={valueType}
              forPrinting={forPrinting}
            />
          </table>
        </div>
      ))}
    </>
  )
}

const ReportCrossAnalysisPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const [year, setYear] = useState(defaultSchoolYear)
  const [gender, setGender] = useState(UserGender.Male)
  const [valueType, setValueType] = useState('average')
  const [selectedQuestion, setSelectedQuestion] = useState('q2')
  const [question, setQuestion] = useState<Questionnaire>()

  const handleChangeValueType = (e) => setValueType(e.target.value)
  const handleChangeQuestion = (v) => setSelectedQuestion(v)

  useEffect(() => {
    const q = questionnaires.find((q) => q.dataKey === selectedQuestion)
    if (q) {
      setQuestion(q)
    }
  }, [selectedQuestion])

  return (
    <Dashboard
      navbar={<div className="theme-nav-title">アンケート回答別クロス集計</div>}
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div style={{ minWidth: 1200 }}>
            <div className="pb-8 space-y-4">
              <div className="space-x-4">
                <Select value={year} onChange={(v) => setYear(v)}>
                  <Option value={2012}>2012 ({makeYearName(2012)})</Option>
                  <Option value={2013}>2013 ({makeYearName(2013)})</Option>
                  <Option value={2014}>2014 ({makeYearName(2014)})</Option>
                  <Option value={2015}>2015 ({makeYearName(2015)})</Option>
                  <Option value={2016}>2016 ({makeYearName(2016)})</Option>
                  <Option value={2017}>2017 ({makeYearName(2017)})</Option>
                  <Option value={2018}>2018 ({makeYearName(2018)})</Option>
                  <Option value={2019}>2019 ({makeYearName(2019)})</Option>
                  <Option value={2021}>2021 ({makeYearName(2021)})</Option>
                  <Option value={2022}>2022 ({makeYearName(2022)})</Option>
                  <Option value={2023}>2023 ({makeYearName(2023)})</Option>
                  <Option value={2024}>2024 ({makeYearName(2024)})</Option>
                </Select>

                <Select value={gender} onChange={(v) => setGender(v)}>
                  <Option value={UserGender.Male}>男子</Option>
                  <Option value={UserGender.Female}>女子</Option>
                </Select>
              </div>

              <div>
                <Radio.Group onChange={handleChangeValueType} value={valueType}>
                  <Radio value="average" className="dark:text-white">
                    平均値
                  </Radio>
                  <Radio value="deviation" className="dark:text-white">
                    標準偏差
                  </Radio>
                </Radio.Group>
              </div>

              <div>
                <Select
                  value={selectedQuestion}
                  onChange={handleChangeQuestion}
                  style={{ width: 320 }}
                >
                  {questionnaires.map((q) => (
                    <Option value={q.dataKey} key={q.dataKey}>
                      {q.question}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            {selectedQuestion === 'q2' && (
              <div className="pt-4">
                （ほとんど毎日…週３日以上 ときどき…週に１～２日程度
                ときたま…月１～３日程度）
              </div>
            )}

            <ReportCrossAnalysisTable
              prefectureCode={prefectureCode}
              year={year}
              gender={gender}
              valueType={valueType}
              question={question}
              forPrinting={false}
            />
          </div>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県児童生徒の体力・運動能力調査結果
        </div>
        <div>アンケート回答別クロス集計</div>
        <div>
          {questionnaires.find((q) => q.dataKey === selectedQuestion)!.question}
        </div>
        <div>
          {valueType === 'average' ? '平均値' : '標準偏差'} /{' '}
          {gender === UserGender.Male ? '男子' : '女子'}
        </div>

        <div className="pt-2" style={{ pageBreakAfter: 'always' }}>
          {questionnaires
            .find((q) => q.dataKey === selectedQuestion)!
            .answers.map((a, idx) => {
              const s = `${idx + 1}: ${a}`
              return <div>{s}</div>
            })}
        </div>

        <ReportCrossAnalysisTable
          prefectureCode={prefectureCode}
          year={year}
          gender={gender}
          valueType={valueType}
          question={question}
          forPrinting
        />
      </div>
    </Dashboard>
  )
}

export default ReportCrossAnalysisPage
