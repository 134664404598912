import { ReportTestRankTargetByYear, SchoolCategory } from '@alpha/core'
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { LayoutType, StackOffsetType } from 'recharts/types/util/types'
import { makeYearName } from '../../utils'

type Props = {
  data: ReportTestRankTargetByYear[]
  targetType: null | SchoolCategory | 'national'
  forPrinting: boolean
}

const dataKeys = ['A', 'B', 'C', 'D', 'E']

const domain = [0, 1]
const ticks = [0, 0.2, 0.4, 0.6, 0.8, 1]
const tickFormatter = (val, index) => {
  return val * 100 + '%'
}

const barColors = ['#2f4b7c', '#a05195', '#f95d6a', '#ffa600']

// const thisYear = new Date().getFullYear()

const TestRankBarChart = ({ data, targetType, forPrinting }: Props) => {
  const chartData: Object[] = []
  data.forEach((d) => {
    // if (d.year === thisYear) return
    let rates = d.totalRates!.map((r) => r.toFixed(2))
    if (targetType) {
      switch (targetType) {
        case SchoolCategory.ElementarySchool:
          rates = d.elementarySchoolRates!.map((r) => r.toFixed(2))
          break
        case SchoolCategory.JuniorHighSchool:
          rates = d.juniorHighSchoolRates!.map((r) => r.toFixed(2))
          break
        case SchoolCategory.HighSchool:
          rates = d.highSchoolRates!.map((r) => r.toFixed(2))
          break
      }
    }
    const value = {
      name: makeYearName(d.year!),
    }
    rates.forEach((score, idx) => {
      value[dataKeys[idx]] = score
    })
    chartData.push(value)
  })

  const legendWrapperStyle = forPrinting ? { top: -12 } : {}
  const barChartProps = {
    data: chartData,
    layout: 'vertical' as LayoutType,
    stackOffset: 'expand' as StackOffsetType,
    width: forPrinting ? 600 : 0,
    height: forPrinting ? 512 : 0,
  }

  const barChart = (
    <BarChart {...barChartProps}>
      <Legend verticalAlign="top" wrapperStyle={legendWrapperStyle} />
      <Tooltip />
      <XAxis
        type="number"
        domain={domain}
        tickCount={10}
        ticks={ticks}
        tickFormatter={tickFormatter}
      />
      <YAxis type="category" dataKey="name" />
      {dataKeys.map((key, idx) => (
        <Bar
          dataKey={key}
          stackId="A"
          fill={barColors[idx]}
          key={key}
          isAnimationActive={!forPrinting}
        />
      ))}
    </BarChart>
  )
  if (forPrinting) {
    return <div className="flex justify-center">{barChart}</div>
  }

  return (
    <ResponsiveContainer height={512} width="100%">
      {barChart}
    </ResponsiveContainer>
  )
}

export default TestRankBarChart
