var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Drawer } from 'antd';
import { ChevronLeftIcon, ChevronRightIcon } from '../icons';
import { SidebarItem } from './sidebar';
export var DashboardLayout = function (_a) {
    var navbar = _a.navbar, sidebar = _a.sidebar, _b = _a.loading, loading = _b === void 0 ? false : _b, _c = _a.open, open = _c === void 0 ? true : _c, _d = _a.setIsOpen, setIsOpen = _d === void 0 ? function (open) { } : _d, onSettingsClick = _a.onSettingsClick, children = _a.children, account = _a.account;
    var flipSidebar = function () {
        setIsOpen(!open);
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex bg-gray-50 h-screen print:hidden" }, { children: [_jsx("nav", __assign({ className: "hidden md:block print:hidden" }, { children: _jsx("div", __assign({ className: "inline-block overflow-y-auto h-full" }, { children: _jsxs("div", __assign({ className: open
                                    ? 'flex flex-col justify-between flex-none w-64 bg-gray-50'
                                    : 'flex flex-col justify-between flex-none w-20 bg-gray-50' }, { children: [_jsxs("div", __assign({ className: "flex flex-col py-10" }, { children: [_jsx("div", __assign({ className: "mb-8" }, { children: _jsx("h1", __assign({ className: open
                                                        ? 'font-semibold text-xl pl-8 dark:text-white'
                                                        : 'font-semibold text-xl pl-4 dark:text-white' }, { children: open ? '新体力テストAlpha' : 'Alpha' }), void 0) }), void 0), sidebar] }), void 0), _jsx("div", __assign({ className: "flex flex-col py-10 space-y-4" }, { children: open ? (_jsx(SidebarItem, { icon: ChevronLeftIcon, title: "", onClick: flipSidebar }, void 0)) : (_jsx(SidebarItem, { icon: ChevronRightIcon, title: "", onClick: flipSidebar }, void 0)) }), void 0)] }), void 0) }), void 0) }), void 0), _jsx("nav", __assign({ className: "block md:hidden print:hidden" }, { children: _jsx(Drawer, __assign({ className: "block md:hidden print:hidden", placement: "left", closable: true, onClose: function () {
                                setIsOpen(false);
                            }, visible: open }, { children: _jsxs("div", __assign({ className: "flex flex-col py-10" }, { children: [_jsx("div", __assign({ className: "mb-8" }, { children: _jsx("h1", __assign({ className: "font-semibold text-xl pl-4" }, { children: "\u65B0\u4F53\u529B\u30C6\u30B9\u30C8Alpha" }), void 0) }), void 0), sidebar] }), void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "absolute left-8 bottom-4 z-10 block md:hidden print:hidden" }, { children: _jsx(Button, __assign({ type: "primary", shape: "round", size: "large", onClick: function (e) {
                                e.preventDefault();
                                setIsOpen(!open);
                            } }, { children: "\u30E1\u30CB\u30E5\u30FC" }), void 0) }), void 0), _jsxs("div", __assign({ className: "flex-auto flex flex-col bg-gray-100 dark:bg-gray-700 md:rounded-l-xl md:shadow-lg md:border-l md:border-gray-200 space-y-4 overflow-x-scroll" }, { children: [_jsxs("div", __assign({ className: "flex-none flex flex-col-reverse md:flex-row justify-between align-top md:space-x-16 mt-4 md:mt-8 ml-4 md:ml-6 md:ml-12 mr-4 md:mr-8" }, { children: [_jsx("div", __assign({ className: "flex-1" }, { children: navbar }), void 0), _jsx("div", __assign({ className: "flex-none flex space-x-4" }, { children: account }), void 0)] }), void 0), _jsx("div", __assign({ className: "flex-1 md:pl-12 md:pr-8 overflow-y-scroll" }, { children: children }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "hidden print:block" }, { children: children }), void 0)] }, void 0));
};
