import { TestReportFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel, replace comma character with `.` if needed.
 *   - Just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 */
export const testAverage2023Male: TestReportFromExcel = [
  [9.04, 12.58, 27.02, 27.22, 20.59, 11.64, 115.38, 8.05, 31.34],
  [10.54, 15.46, 28.92, 31.12, 29.68, 10.74, 125.86, 10.98, 38.22],
  [12.43, 17.6, 31.56, 34.87, 36.75, 10.16, 136.68, 14.07, 44.33],
  [14.35, 18.9, 34.02, 38.36, 42.61, 9.74, 144.31, 17.15, 49.35],
  [16.69, 20.4, 36.42, 41.97, 49.5, 9.37, 153.96, 20.23, 54.77],
  [20, 22.03, 39.19, 44.74, 56.41, 8.97, 164.41, 23.36, 60.25],
  [24.51, 24, 43.25, 48.25, 65.31, 8.49, 180.88, 17.67, 35.35],
  [30.2, 26.89, 47.33, 52.01, 80.47, 7.83, 199.69, 21.08, 44.34],
  [34.85, 28.8, 50.39, 54.51, 87.57, 7.43, 212.92, 23.79, 50.7],
  [36.33, 28.07, 48.49, 56.22, 80.81, 7.48, 215.34, 22.91, 49.42],
  [38.4, 29.16, 49.78, 57.21, 85.34, 7.34, 221.34, 24.34, 52.5],
  [40.09, 30.32, 51.82, 58.37, 87.54, 7.23, 225.09, 25.52, 55.2],
  [34.99, 22.48, 44.74, 47.18, 54.96, 8.11, 197.47, 19.43, 38.28],
  [37.67, 22.56, 43.13, 48.5, 58.66, 8.03, 202.54, 20.94, 40.46],
  [38.57, 24.61, 44.61, 50.7, 63.04, 7.85, 208.64, 22.03, 43.93],
  [37.21, 23.03, 43.03, 43.9, 48.26, 8.4, 201.23, 21.08, 37.34],
]

export const testAverage2023Female: TestReportFromExcel = [
  [8.53, 12.12, 29.3, 26.45, 17.3, 11.9, 108.47, 5.8, 31.73],
  [9.98, 14.72, 31.79, 30.21, 23.78, 11.03, 118.5, 7.64, 39.01],
  [11.79, 17, 34.66, 33.77, 29.2, 10.44, 128.64, 9.71, 45.56],
  [13.83, 18.3, 37.75, 37.14, 34.94, 9.98, 137.72, 11.85, 51.37],
  [16.65, 19.8, 40.94, 40.5, 41.9, 9.54, 147.1, 14.11, 57.5],
  [19.69, 20.79, 44.24, 42.63, 45.76, 9.21, 154.58, 15.72, 62.09],
  [21.61, 20.97, 46.51, 44.08, 47.68, 9.03, 160.84, 11.53, 44.77],
  [23.85, 22.67, 49.29, 46.1, 53.68, 8.75, 167.49, 13.05, 50.34],
  [25.26, 23.66, 51.76, 46.89, 54.13, 8.64, 171.02, 14.05, 53.17],
  [25.05, 22.61, 49.77, 48.19, 47.22, 8.91, 168.84, 13.11, 50.18],
  [25.74, 23.12, 50.82, 48.46, 47.53, 8.94, 170.35, 13.72, 51.45],
  [26.19, 23.88, 52.5, 49.06, 48.21, 8.93, 171.1, 14.06, 52.78],
  [23.22, 16.92, 45.78, 40.18, 29.86, 9.84, 151.04, 10.62, 37.1],
  [24.83, 16.96, 45.66, 40.93, 27.4, 9.96, 149.33, 10.94, 37.91],
  [25.41, 18.19, 43.32, 42.38, 28.54, 9.81, 150.98, 11.47, 38.9],
  [25.7, 20.03, 47.1, 42.07, 26.3, 9.69, 157.83, 12.33, 41],
]

/**
 * standard deviation
 */
export const testDeviation2023Male: TestReportFromExcel = [
  [2.28, 4.97, 7.26, 5.62, 10.95, 1.49, 19.3, 3.34, 7.13],
  [2.69, 5.33, 7.55, 6.76, 14.87, 1.26, 19.96, 4.52, 7.9],
  [3.23, 6.12, 8.3, 8.48, 17.8, 1.32, 21.71, 5.71, 8.86],
  [3.78, 6.38, 9.25, 8.62, 20.24, 1.23, 23.51, 6.89, 9.67],
  [4.21, 6.19, 9.6, 8.59, 22.47, 1.26, 24.54, 7.83, 10.13],
  [5.44, 6.05, 9.68, 8.6, 23.48, 1.33, 27.27, 9.03, 10.45],
  [6.88, 6.34, 11.48, 9.27, 25.46, 1.26, 34.49, 5.95, 10.67],
  [7.7, 6.41, 11.63, 9.35, 25.88, 0.98, 33.22, 6.44, 11.65],
  [7.67, 6.35, 11.48, 9.15, 26.31, 0.85, 30.99, 6.85, 11.78],
  [7.03, 6.12, 11.02, 7.39, 24.41, 0.75, 26.66, 6.21, 10.68],
  [7.24, 6.53, 11.26, 8.14, 27.44, 0.77, 26.87, 6.55, 11.63],
  [7.43, 6.65, 11.73, 8.03, 27.53, 0.77, 27.82, 6.68, 11.94],
  [7.79, 6.01, 11.64, 8.57, 23.22, 1.41, 33.71, 6.15, 10.85],
  [8, 6.39, 12.5, 9.2, 23.97, 1.19, 36.24, 6.47, 11.07],
  [8.02, 6.58, 13.41, 10.85, 25.95, 1.24, 33.09, 6.36, 11.34],
  [8.94, 7.74, 11.83, 10.68, 20.07, 1.41, 33.35, 7.37, 10.61],
]

/**
 * standard deviation
 */
export const testDeviation2023Female: TestReportFromExcel = [
  [2.07, 4.67, 7.27, 5.15, 8.01, 1.3, 17.46, 2.02, 6.95],
  [2.46, 5.02, 7.69, 6.3, 11.04, 1.2, 18.41, 2.74, 7.8],
  [2.89, 5.55, 8.31, 7.74, 13.67, 1.17, 20.8, 3.37, 8.64],
  [3.46, 5.77, 9.15, 7.72, 15.55, 1.15, 22.51, 4.08, 9.13],
  [4.15, 5.54, 9.58, 7.62, 17.43, 1.19, 23.36, 4.82, 9.34],
  [4.49, 5.55, 9.88, 7.5, 18.64, 0.93, 24.64, 5.39, 9.35],
  [4.74, 5.88, 11.06, 7.81, 18.97, 1.27, 29.82, 4.13, 11.31],
  [4.78, 6.08, 10.92, 7.68, 20.38, 1.05, 28.41, 4.3, 11.91],
  [4.89, 6.26, 10.75, 7.86, 20.35, 0.94, 27.72, 4.52, 12.05],
  [4.48, 6.06, 10.28, 6.33, 16.39, 0.86, 22.8, 4.02, 10.92],
  [4.59, 6.34, 10.39, 6.96, 18.26, 0.98, 23.4, 4.21, 11.71],
  [4.63, 6.37, 10.41, 6.6, 18.81, 0.98, 23.48, 4.29, 11.77],
  [5.03, 6.02, 13.1, 7.94, 13.4, 1.43, 28.68, 3.45, 11.62],
  [5.13, 5.72, 11.16, 6.31, 13.62, 1.44, 28.36, 4.25, 11.07],
  [4.67, 5.56, 12.19, 7.73, 11.6, 1.24, 24.04, 3.5, 9.85],
  [5.55, 6.85, 10.35, 8.8, 19.68, 1.48, 25.78, 5.25, 12.65],
]
