import { TestReportFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel, replace comma character with `.` if needed.
 *   - Just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 */
export const testAverage2024Male: TestReportFromExcel = [
  [8.88, 12.84, 26.85, 27.22, 20.49, 11.68, 114.96, 8.18, 31.2],
  [10.52, 15.37, 28.9, 31.18, 29.46, 10.83, 125.42, 11.25, 38.07],
  [12.34, 17.92, 31.38, 35.05, 37.21, 10.19, 135.54, 14.53, 44.36],
  [14.31, 19.45, 33.76, 39.03, 44.08, 9.76, 144.92, 17.67, 49.92],
  [16.64, 20.62, 36.4, 42.29, 49.72, 9.38, 153.39, 20.77, 54.84],
  [19.86, 22.43, 39.17, 45.1, 56.66, 8.95, 164.49, 23.89, 60.52],
  [24.42, 24.12, 43.42, 48.37, 64.66, 8.45, 182.82, 17.78, 35.67],
  [30.05, 27.17, 47.5, 52.32, 80.77, 7.8, 199.51, 21.19, 44.53],
  [34.87, 29.19, 51.16, 54.91, 87.19, 7.42, 213.84, 23.9, 51.18],
  [36.61, 28.09, 48.16, 56.47, 79.95, 7.48, 216.32, 23.12, 49.47],
  [38.53, 29.16, 50.35, 57.4, 85.39, 7.37, 221.91, 24.37, 52.62],
  [39.85, 30.02, 51.63, 58.24, 86.15, 7.28, 225.19, 25.54, 54.67],
  [34.51, 21.52, 40.51, 45.43, 49.85, 8.15, 186.19, 19.35, 35.16],
  [36.89, 23.62, 44.53, 49.47, 59.95, 7.9, 197.15, 20.23, 41.06],
  [39.1, 23.65, 44.38, 49.85, 59.58, 7.89, 202.39, 22.05, 42.72],
  [36.01, 24.16, 40.36, 50.58, 48.97, 7.99, 199.88, 21.58, 40.86],
]

export const testAverage2024Female: TestReportFromExcel = [
  [8.37, 12.17, 29.1, 26.32, 17.05, 11.97, 107.86, 5.86, 31.44],
  [9.98, 14.69, 31.54, 30.2, 23.44, 11.09, 117.78, 7.65, 38.69],
  [11.68, 17.11, 34.83, 33.5, 29.09, 10.51, 127.4, 9.78, 45.26],
  [13.72, 18.75, 37.66, 37.56, 35.71, 10.02, 136.85, 11.92, 51.55],
  [16.3, 19.71, 40.78, 40.56, 41.32, 9.59, 146.48, 13.95, 57.03],
  [19.52, 20.97, 43.67, 42.76, 45.72, 9.23, 154.15, 15.86, 61.94],
  [21.7, 21.24, 46.8, 44.39, 47.58, 9.02, 162.67, 11.53, 45.27],
  [23.87, 22.67, 49.52, 46.06, 52.97, 8.74, 168.35, 13.02, 50.28],
  [25.22, 23.82, 51.59, 46.96, 53.11, 8.67, 170.62, 13.98, 52.87],
  [24.97, 22.22, 49.19, 48.11, 46.28, 8.94, 169.15, 13.06, 49.64],
  [25.78, 22.86, 50.6, 48.48, 48, 8.98, 170.58, 13.46, 51.17],
  [26.27, 23.51, 52.1, 48.89, 46.94, 8.98, 171.46, 14.07, 52.21],
  [23.83, 16.03, 43.17, 39.35, 27.26, 9.85, 140.93, 11.43, 36.28],
  [23.96, 18.33, 44.72, 41.64, 29.85, 9.98, 148.82, 11.53, 38.8],
  [25.1, 17.59, 45.37, 42.2, 29.87, 9.83, 146.57, 11.9, 39.46],
  [26.05, 17.25, 40.44, 41.44, 25.25, 9.6, 143.19, 12.63, 38.19],
]

/**
 * standard deviation
 */
export const testDeviation2024Male: TestReportFromExcel = [
  [2.47, 5.17, 7.31, 5.72, 10.81, 1.48, 19.49, 3.41, 7.32],
  [2.7, 5.63, 7.83, 7.03, 14.97, 1.46, 20.15, 4.71, 8.19],
  [3.41, 6.3, 8.44, 8.41, 18.1, 1.31, 22.17, 5.87, 9.1],
  [3.87, 6.39, 9.23, 8.71, 20.3, 1.54, 23.88, 7.04, 9.77],
  [4.37, 6.44, 9.78, 8.74, 22.13, 1.31, 25.32, 8.13, 10.44],
  [5.32, 6.19, 9.83, 8.39, 23.69, 1.3, 27.03, 9.17, 10.57],
  [6.85, 6.49, 11.66, 9.11, 25.38, 1.18, 34.72, 5.87, 10.6],
  [7.54, 6.38, 11.55, 9.08, 25.93, 0.96, 34.13, 6.46, 11.59],
  [7.86, 6.39, 11.42, 9.11, 26.22, 1.03, 31.71, 6.71, 11.93],
  [7.15, 6.27, 11.33, 7.81, 24.54, 0.81, 27.11, 6.35, 10.98],
  [7.19, 6.41, 11.61, 7.63, 26.72, 0.85, 27.21, 6.57, 11.44],
  [7.42, 6.59, 11.69, 8.09, 27.71, 0.85, 27.61, 6.76, 11.93],
  [8.17, 6.03, 12.06, 9.48, 23.18, 0.99, 48.08, 6.58, 10.96],
  [7.85, 6.7, 12.8, 9.59, 23.56, 1.06, 46.93, 6.06, 11.06],
  [7.94, 7.17, 12.7, 10.1, 23.74, 1.07, 45.14, 6.78, 11.71],
  [8.09, 6.18, 14, 10.19, 24.35, 0.88, 55.95, 5.56, 11.08],
]

/**
 * standard deviation
 */
export const testDeviation2024Female: TestReportFromExcel = [
  [2.11, 4.84, 7.24, 5.06, 7.89, 1.43, 17.53, 2.07, 7.11],
  [2.61, 5.2, 7.86, 6.22, 10.9, 1.25, 18.47, 2.76, 7.94],
  [3.16, 5.71, 8.64, 7.74, 13.65, 1.22, 20.96, 3.56, 8.76],
  [3.71, 5.73, 9.23, 7.81, 15.86, 1.33, 22.82, 4.18, 9.25],
  [4.14, 5.79, 9.95, 7.67, 17.42, 1.15, 23.47, 4.92, 9.57],
  [4.62, 5.65, 10.01, 7.21, 18.62, 1.26, 24.97, 5.47, 9.48],
  [4.83, 5.93, 11.32, 7.7, 19.8, 1.14, 29.71, 4.25, 11.49],
  [4.91, 6.18, 11.21, 7.69, 20.17, 1.02, 28.12, 4.36, 12],
  [4.95, 6.51, 10.99, 7.82, 20.4, 1.12, 27.5, 4.62, 12.34],
  [4.6, 6.32, 10.52, 6.62, 16.74, 0.87, 23.7, 4.1, 11.25],
  [4.69, 6.51, 10.53, 6.81, 18.45, 1.05, 23.7, 4.23, 11.99],
  [4.66, 6.46, 10.44, 7.06, 18.87, 1.03, 24.25, 4.39, 12.04],
  [5.01, 6.95, 11.53, 7.78, 12.41, 1.32, 41.63, 4.42, 10.83],
  [5.54, 6.99, 12.5, 8.76, 14.18, 1.48, 35.69, 3.75, 12.17],
  [4.69, 5.92, 10.01, 7.53, 14.09, 1.25, 35.58, 4.32, 11.3],
  [5.72, 6.44, 12.54, 10.52, 11.46, 1, 44.54, 3.56, 11.81],
]
