import { Card } from '@alpha/components'
import { Dashboard } from '../layout/Dashboard'
import {
  logout,
  ReportTestComparisonByGrade,
  ReportTestComparisonSummary,
  useReportTestComparisonSummaryLazyQuery,
  UserGender,
} from '@alpha/core'
import {
  //  Button,
  Radio,
  Select,
} from 'antd'
import { useEffect, useState } from 'react'
import { getJpYearNameTextOrNumber, makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'

import R3ComparisonNational from '../../assets/r3_comparison_national.png'
import R3ComparisonPrefecture from '../../assets/r3_comparison_prefecture.png'
import { handleEachYearComparison } from './data/comparison/handler'
import { LocationType } from '../../utils/type'
import { customYears, defaultSchoolYear, prefectureCode } from '../../utils/constant'

const { Option } = Select

const styleForReport = {
  fontSize: 10,
  width: 90,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr className="border border-white">
        <th className={className} style={styleForReport}>
          校種
        </th>
        <th className={className} style={styleForReport}>
          学年
        </th>
        <th className={className} style={styleForReport}>
          握力
        </th>
        <th className={className} style={styleForReport}>
          上体起こし
        </th>
        <th className={className} style={styleForReport}>
          長座体前屈
        </th>
        <th className={className} style={styleForReport}>
          反復横跳び
        </th>
        <th className={className} style={styleForReport}>
          シャトルラン
        </th>
        <th className={className} style={styleForReport}>
          50m走
        </th>
        <th className={className} style={styleForReport}>
          立ち幅跳び
        </th>
        <th className={className} style={styleForReport}>
          ボール投げ
        </th>
        <th className={className} style={styleForReport}>
          得点合計
        </th>
      </tr>
    </thead>
  )
}

const TableBody = ({
  className,
  data,
  showType,
}: {
  className: string
  data: ReportTestComparisonSummary
  showType: LocationType
}) => {
  if (!data) return null

  return (
    <tbody>
      <tr>
        <th rowSpan={7} className={className}>
          小学校
        </th>
      </tr>
      {data.elementarySchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={4} className={className}>
          中学校
        </th>
      </tr>
      {data.juniorHighSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
      <tr>
        <th rowSpan={4} className={className}>
          高校
        </th>
      </tr>
      {data.highSchoolReport?.reports?.map((data) => (
        <ReportTableData
          className={className}
          data={data}
          showType={showType}
          key={data.grade}
        />
      ))}
    </tbody>
  )
}

type ReportTableDataProps = {
  data: ReportTestComparisonByGrade
  showType: LocationType
  className: string
}

const boolToString = (b: boolean) => (b ? '○' : '●')
const ReportTableData = ({
  data,
  showType,
  className,
}: ReportTableDataProps) => {
  const report = data[showType]

  return (
    <tr className={className}>
      <td className={className}>{data.grade}</td>
      <td className={className}>{boolToString(report.grip)}</td>
      <td className={className}>{boolToString(report.sitUps)}</td>
      <td className={className}>{boolToString(report.bending)}</td>
      <td className={className}>{boolToString(report.sideJump)}</td>
      <td className={className}>{boolToString(report.shuttleRun)}</td>
      <td className={className}>{boolToString(report.sprintRun)}</td>
      <td className={className}>{boolToString(report.standingJump)}</td>
      <td className={className}>{boolToString(report.handballThrow)}</td>
      <td className={className}>{boolToString(report.totalScore)}</td>
    </tr>
  )
}

type TableProps = {
  prefectureCode: number
  year: number
  gender: UserGender
  showType: LocationType
}

const ReportTestComparisonTable = ({
  prefectureCode,
  year,
  gender,
  showType,
}: TableProps) => {
  const [getReport, { data, loading, called, error }] =
    useReportTestComparisonSummaryLazyQuery({
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (error) {
      const errorMessage = JSON.stringify(error?.networkError)
      if (errorMessage.includes('"code":"UNAUTHENTICATED"')) {
        logout()
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    }
  }, [error])
  useEffect(() => {
    if (prefectureCode === 0 || year >= 2023) return

    const variables = {
      input: {
        prefectureCode,
        year,
        gender: gender === UserGender.Male ? 1 : 2,
      },
    }
    getReport({ variables })
  }, [prefectureCode, year, gender, getReport])

  if (loading) {
    return <Loading />
  }

  if (
    (!data?.reportTestComparisonSummary ||
      !data.reportTestComparisonSummary.data) &&
    !customYears.includes(year)
  ) {
    if (called && year !== 2021) {
      return <NoData />
    } else {
      return null
    }
  }

  const tableClasses =
    gender === UserGender.Male ? 'table-border-male' : 'table-border-female'

  return (
    <div>
      <table className="table border-collapse">
        <TableHeader className={tableClasses} />
        <TableBody
          className={tableClasses}
          data={
            !customYears.includes(year)
              ? data.reportTestComparisonSummary.data
              : handleEachYearComparison(
                  year,
                  gender === UserGender.Male ? 1 : 2,
                  showType,
                )
          }
          showType={showType}
        />
      </table>
      <div>
        ○→
        {showType === LocationType.prefecture
          ? '前年度以上'
          : `${getJpYearNameTextOrNumber(
              year >= 2023 ? year - 1 : year,
            )}度全国平均値以上`}
      </div>
      <div>
        ●→
        {showType === LocationType.prefecture
          ? '前年度未満'
          : `${getJpYearNameTextOrNumber(
              year >= 2023 ? year - 1 : year,
            )}度全国平均値未満`}
      </div>
    </div>
  )
}

const ReportTestComparisonPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const [showType, setShowType] = useState(LocationType.prefecture)

  const [year, setYear] = useState(defaultSchoolYear)
  const [gender, setGender] = useState(UserGender.Male)

  const handleChangeShowType = (e) => {
    setShowType(e.target.value)
  }

  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">県平均値と全国平均値との比較</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div className="pb-8 space-y-4">
            <div className="space-x-4">
              <Select value={year} onChange={(v) => setYear(v)}>
                <Option value={2013}>2013 ({makeYearName(2013)})</Option>
                <Option value={2014}>2014 ({makeYearName(2014)})</Option>
                <Option value={2015}>2015 ({makeYearName(2015)})</Option>
                <Option value={2016}>2016 ({makeYearName(2016)})</Option>
                <Option value={2017}>2017 ({makeYearName(2017)})</Option>
                <Option value={2018}>2018 ({makeYearName(2018)})</Option>
                <Option value={2019}>2019 ({makeYearName(2019)})</Option>
                <Option value={2021}>2021 ({makeYearName(2021)})</Option>
                <Option value={2022}>2022 ({makeYearName(2022)})</Option>
                <Option value={2023}>2023 ({makeYearName(2023)})</Option>
                <Option value={2024}>2024 ({makeYearName(2024)})</Option>
              </Select>

              {year !== 2021 && (
                <Select value={gender} onChange={(v) => setGender(v)}>
                  <Option value={UserGender.Male}>男子</Option>
                  <Option value={UserGender.Female}>女子</Option>
                </Select>
              )}
            </div>

            <Radio.Group onChange={handleChangeShowType} value={showType}>
              <Radio value="prefecture" className="dark:text-white">
                県内比較
              </Radio>
              <Radio value="national" className="dark:text-white">
                全国比較
              </Radio>
            </Radio.Group>
          </div>

          {year === 2021 && (
            <img
              className="w-full"
              src={
                showType === LocationType.national
                  ? R3ComparisonNational
                  : R3ComparisonPrefecture
              }
              alt="comparison"
            />
          )}

          {year !== 2021 && (
            <ReportTestComparisonTable
              prefectureCode={prefectureCode}
              year={year}
              gender={gender}
              showType={showType}
            />
          )}
        </Card>
      </div>
      <div className="hidden print:block">
        <div>
          {`${makeYearName(year)}`}
          年度茨城県児童生徒の体力・運動能力調査結果
        </div>
        <div>
          {showType === 'prefecture'
            ? `${makeYearName(year)}年度体力テスト本県平均値と${makeYearName(
                year === 2021 ? year - 2 : year - 1,
              )}年度県平均値との比較`
            : `${makeYearName(year)}年度体力テスト本県平均値と${makeYearName(
                year === 2021 ? year - 2 : year - 1,
              )}年度全国平均値との比較`}
        </div>
        {year !== 2021 && (
          <>
            <div>{gender === UserGender.Male ? '男子' : '女子'}</div>
            <ReportTestComparisonTable
              prefectureCode={prefectureCode}
              year={year}
              gender={gender}
              showType={showType}
            />
          </>
        )}

        {year === 2021 && (
          <img
            className="w-full"
            src={
              showType === LocationType.national
                ? R3ComparisonNational
                : R3ComparisonPrefecture
            }
            alt="comparison"
          />
        )}
      </div>
    </Dashboard>
  )
}

export default ReportTestComparisonPage
