export const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}

export const getJpYearNameTextOrNumber = (year: number): string => {
  if (year === 2019) return '令和元年'

  if (year > 2019) {
    return `令和${year - 2018}年`
  }

  return `平成${year - 1988}年`
}
