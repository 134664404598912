import { Card } from 'antd'
import {
  RadarChart,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { ScoreData } from '../pages/ReportScoreRadarChartPage'

type Props = {
  title: string
  data: ScoreData[]
  radarColor: string
  forPrinting: boolean
}

const ScoreRadarChart = ({ title, data, radarColor, forPrinting }: Props) => {
  const radarChartProps = {
    width: forPrinting ? 480 : 0,
    height: forPrinting ? 380 : 0,
    data: data,
  }
  const radarChart = (
    <RadarChart {...radarChartProps}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={75} tickCount={10} domain={[30, 60]} />
      <Radar
        name="県平均"
        dataKey="prefecture"
        stroke={radarColor}
        fill={radarColor}
        fillOpacity={0.2}
        isAnimationActive={!forPrinting}
      />
      <Radar
        name="全国平均"
        dataKey="national"
        stroke="#FF0000"
        fillOpacity={0}
        isAnimationActive={!forPrinting}
      />
      <Legend />
      <Tooltip />
    </RadarChart>
  )
  const withinContainer = (
    <ResponsiveContainer height={420} width="100%">
      {radarChart}
    </ResponsiveContainer>
  )

  return (
    <Card title={title} style={{ textAlign: 'center' }}>
      {forPrinting ? (
        <div className="flex justify-center">{radarChart}</div>
      ) : (
        withinContainer
      )}
    </Card>
  )
}

export default ScoreRadarChart
