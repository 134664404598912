// prettier-ignore

import { CrossAnalysis } from "./type";

/**
 * 平均値
 * To migrate data from Excel:
 *   - On Excel, replace `,` character with `.` if needed.
 *   - Just select all data's cell (include rows and columns).
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with `,` character
 *   - Wrap each line by the `[]` character (an array) by many ways.
 *   - Use editor/IDE to format this file
 */
export const crossAnalysisAverage2024: CrossAnalysis = {
  /**
   * category: 運動部やスポーツクラブへの所属状況
   */
  q2: {
    male: [
      [4039, 116.99, 21.78, 9.1, 13.58, 27.07, 28.12, 22.69, 11.43, 118.09, 8.78, 32.72],
      [6380, 116.46, 21.54, 8.75, 12.36, 26.72, 26.66, 19.13, 11.83, 113.08, 7.79, 30.25],
      [5105, 123.04, 24.81, 10.74, 16.35, 29.03, 32.21, 32.89, 10.59, 128.38, 12.15, 39.82],
      [5635, 122.36, 24.52, 10.31, 14.46, 28.83, 30.24, 26.34, 11.04, 122.76, 10.41, 36.46],
      [5970, 128.93, 28.13, 12.65, 19.01, 31.72, 36.44, 41.57, 9.97, 138.73, 15.74, 46.43],
      [5099, 128.11, 28.04, 12, 16.71, 31.02, 33.43, 32.12, 10.45, 131.96, 13.12, 41.97],
      [6371, 134.36, 31.81, 14.71, 20.69, 34.42, 40.76, 49.32, 9.5, 149.05, 19.34, 52.47],
      [4687, 133.79, 32.1, 13.81, 17.87, 32.97, 36.81, 37.12, 10.09, 139.49, 15.46, 46.52],
      [6337, 140, 35.74, 17.11, 22.09, 37.19, 44.11, 56.22, 9.12, 157.88, 22.8, 57.69],
      [4711, 139.57, 36.69, 16.05, 18.76, 35.51, 40.05, 41.16, 9.71, 147.54, 18.05, 51.1],
      [6118, 146.42, 40.37, 20.48, 24.09, 40.35, 47.28, 64.45, 8.69, 170.19, 26.5, 63.84],
      [4973, 146.03, 41.27, 19.15, 20.47, 37.82, 42.58, 47.24, 9.26, 157.84, 20.76, 56.48],
      [8035, 154.48, 46.1, 24.84, 24.82, 44.08, 49.32, 67.97, 8.34, 185.63, 18.56, 37.06],
      [2273, 153.91, 46.33, 23.21, 21.96, 41.71, 45.28, 53.44, 8.81, 173.8, 15.25, 31.01],
      [8389, 161.34, 51.03, 30.44, 27.95, 48.18, 53.36, 84.93, 7.69, 202.52, 22, 46.15],
      [1998, 160.81, 51.58, 28.61, 24.43, 45.01, 48.15, 63.73, 8.23, 187.21, 17.9, 37.77],
      [8199, 166.23, 55.59, 35.55, 30.16, 52.11, 56.25, 92.28, 7.3, 217.79, 24.9, 53.33],
      [2320, 165.06, 55.32, 32.63, 26.26, 48.2, 50.36, 69.87, 7.84, 201.39, 20.45, 43.73],
      [4797, 168.67, 60.15, 37.52, 29.57, 49.81, 58.44, 88.18, 7.29, 221.43, 24.76, 53.12],
      [3621, 167.64, 59.55, 35.3, 25.65, 45.4, 53.08, 66.79, 7.78, 207.34, 20.64, 43.61],
      [4111, 170.14, 61.63, 39.88, 31.25, 52.51, 59.75, 98.03, 7.13, 229.27, 26.49, 57.7],
      [3872, 168.88, 60.85, 37.06, 26.52, 47.73, 54.42, 70.04, 7.66, 212.76, 21.86, 46.47],
      [3841, 170.88, 63.59, 41.45, 32.42, 54.43, 60.98, 100.25, 7.02, 233.5, 28.01, 60.45],
      [4309, 169.69, 62.1, 38.36, 27.43, 48.59, 55.27, 71.71, 7.55, 216.37, 23.1, 48.67],
    ],

    female: [
      [3167, 116, 21.3, 8.48, 12.77, 29.28, 26.87, 17.99, 11.8, 110.54, 6.08, 32.53],
      [6837, 115.54, 21.13, 8.32, 11.89, 29.04, 26.08, 16.65, 12.04, 106.72, 5.77, 30.97],
      [3798, 122.16, 24.15, 10.14, 15.62, 32.05, 31.02, 25.76, 10.9, 120.59, 8.07, 40.39],
      [6354, 121.49, 23.96, 9.89, 14.15, 31.3, 29.72, 22.04, 11.19, 116.21, 7.41, 37.71],
      [4549, 128.09, 27.31, 11.91, 18.01, 35.46, 34.6, 32.19, 10.33, 130.49, 10.41, 47.24],
      [6093, 127.63, 27.3, 11.52, 16.44, 34.42, 32.71, 26.85, 10.64, 125.25, 9.32, 43.82],
      [4651, 134.46, 31.14, 14.05, 19.96, 38.41, 38.97, 40.25, 9.8, 141.4, 12.77, 54.04],
      [5905, 134.01, 31.19, 13.49, 17.84, 37.16, 36.55, 32.25, 10.19, 133.56, 11.26, 49.66],
      [4344, 141.05, 35.08, 16.61, 21.19, 41.87, 42.27, 47.38, 9.37, 151.53, 15.13, 59.93],
      [6206, 141.41, 36.18, 16.11, 18.69, 40.11, 39.51, 37.19, 9.73, 143.15, 13.13, 55.06],
      [4071, 147.39, 39.99, 19.98, 22.83, 45, 45.07, 54.84, 8.94, 161.63, 17.74, 65.7],
      [6911, 147.75, 41.07, 19.26, 19.89, 42.86, 41.45, 40.45, 9.39, 149.93, 14.78, 59.73],
      [6021, 152.4, 44.7, 22.18, 22.51, 47.7, 45.79, 52.89, 8.8, 168.17, 12.4, 48.45],
      [3803, 152.05, 45.01, 21.06, 19.48, 45.74, 42.3, 39.58, 9.34, 155.12, 10.26, 40.56],
      [6045, 155.08, 47.41, 24.49, 24.16, 50.53, 47.83, 60.21, 8.48, 174.79, 14.24, 54.31],
      [3787, 154.17, 47.81, 22.97, 20.46, 48.03, 43.33, 41.62, 9.15, 158.29, 11.11, 43.84],
      [5622, 156.56, 49.64, 26.07, 25.75, 53.05, 49.15, 61.36, 8.36, 178.37, 15.53, 57.75],
      [4285, 155.54, 49.51, 24.16, 21.52, 49.81, 44.22, 42.45, 9.07, 160.76, 11.89, 46.4],
      [2385, 157.29, 51.7, 25.91, 24.45, 51.03, 50.46, 55.11, 8.59, 177.77, 14.82, 55.32],
      [4988, 156.24, 51.43, 24.54, 20.89, 48.07, 46.5, 41.21, 9.13, 164.21, 12.18, 46.38],
      [2109, 157.92, 52.84, 27.34, 26.15, 52.97, 51.71, 61.71, 8.49, 182.31, 15.75, 59.19],
      [5095, 156.72, 51.88, 25.12, 21.38, 49.33, 46.79, 41.53, 9.21, 165.24, 12.48, 47.39],
      [1916, 158.17, 53.23, 27.62, 27.09, 55.31, 52.93, 62.18, 8.43, 185.12, 16.56, 60.94],
      [5254, 157.26, 53.14, 25.75, 22.07, 50.68, 47.07, 40.7, 9.2, 165.8, 13.12, 48.62],
    ]
  },
  /**
   * 運動・スポーツの実施状況
   */
  q3: {
    male: [
      [2280, 117.14, 21.81, 9.41, 14.11, 27.28, 28.49, 24.42, 11.28, 120.55, 9.49, 34.01],
      [5581, 116.68, 21.62, 8.92, 12.86, 26.8, 27.3, 20.42, 11.62, 115.23, 8.12, 31.23],
      [1305, 116.18, 21.47, 8.35, 11.74, 26.42, 26.02, 17.74, 12.09, 110.31, 7.31, 28.92],
      [1248, 116.26, 21.52, 8.32, 11.5, 26.78, 25.81, 16.62, 12.2, 108.95, 6.94, 28.35],
      [3083, 123.06, 24.66, 11, 17.08, 29.65, 33.12, 36.3, 10.43, 131.18, 13.18, 41.58],
      [5466, 122.66, 24.69, 10.45, 15.09, 28.67, 30.86, 28.04, 10.86, 124.66, 10.85, 37.48],
      [1159, 122.22, 24.5, 10.01, 13.77, 28.59, 29.63, 24.47, 11.25, 120.02, 9.71, 34.98],
      [1037, 122.26, 24.68, 9.95, 13.4, 28.44, 28.76, 22.14, 11.35, 118.71, 9.25, 34.03],
      [4471, 128.96, 28.06, 12.9, 19.71, 32.26, 37.15, 44, 9.87, 140.94, 16.47, 47.76],
      [4386, 128.49, 28.2, 12.25, 17.41, 31.11, 34.33, 34.5, 10.24, 134.31, 13.84, 43.33],
      [1071, 128.13, 27.91, 11.66, 15.95, 30.65, 32.99, 31.07, 10.61, 129.49, 12.41, 40.74],
      [1135, 127.57, 27.91, 11.22, 14.91, 29.84, 31.48, 26.74, 10.87, 125.39, 11.6, 38.42],
      [5491, 134.3, 31.52, 14.89, 21.2, 34.87, 41.35, 51.54, 9.42, 150.9, 19.76, 53.54],
      [3788, 134.05, 32.17, 14, 18.44, 33.16, 37.86, 39.32, 9.89, 141.79, 16.47, 47.88],
      [932, 133.85, 32.52, 13.53, 17.3, 32.27, 35.86, 34.53, 10.27, 136.52, 14.5, 44.89],
      [835, 133.58, 32.92, 13.03, 15.51, 31.49, 33.33, 28.16, 10.76, 129.97, 13.29, 41.35],
      [5810, 140.02, 35.62, 17.34, 22.61, 37.79, 44.78, 58.29, 9.06, 160.04, 23.32, 58.82],
      [3642, 139.79, 36.72, 16.21, 19.24, 35.47, 40.75, 43.06, 9.57, 148.7, 18.9, 52.06],
      [837, 139.23, 36.34, 15.26, 17.46, 34.67, 38.26, 36.11, 9.9, 143.61, 16.25, 48.24],
      [737, 139.04, 37.25, 15.05, 16.07, 33.06, 36.26, 32.11, 10.31, 136.47, 15.14, 45.35],
      [5749, 146.34, 39.91, 20.68, 24.53, 40.65, 47.89, 67.07, 8.6, 172.32, 27.17, 64.89],
      [3736, 146.2, 41.51, 19.36, 21.02, 38.23, 43.17, 48.32, 9.19, 159.17, 21.45, 57.42],
      [895, 145.97, 42.21, 18.53, 18.88, 36.84, 40.85, 40.57, 9.57, 151.28, 18.09, 53.07],
      [699, 146.08, 41.9, 17.82, 17.64, 35.77, 38.91, 37.19, 9.73, 147.6, 17.77, 50.63],
      [7697, 154.52, 45.92, 24.96, 25.25, 44.42, 49.85, 69.82, 8.28, 187.31, 18.87, 37.78],
      [1547, 153.85, 46.64, 23.66, 21.92, 41.66, 45.25, 52.63, 8.77, 174.2, 15.64, 30.84],
      [565, 153.76, 47.46, 22.64, 20.15, 40.6, 42.93, 46.42, 9.19, 166.69, 13.64, 27.9],
      [445, 153.93, 46.77, 21.38, 18.89, 39.07, 41.59, 42.53, 9.18, 159.5, 12.83, 25.95],
      [8174, 161.38, 50.9, 30.59, 28.23, 48.49, 53.75, 86.17, 7.66, 203.56, 22.24, 46.72],
      [1184, 160.59, 51.97, 28.75, 24.65, 45.24, 48.19, 64.73, 8.16, 188.51, 18.42, 38.3],
      [489, 160.66, 51.65, 27.74, 23.38, 43.3, 47.26, 59.03, 8.36, 182, 16.26, 35.13],
      [486, 160.95, 52.2, 27.31, 21.4, 41.93, 44.32, 52.45, 8.59, 177.56, 15.51, 32.18],
      [7563, 166.31, 55.55, 35.85, 30.59, 52.61, 56.77, 93.96, 7.25, 219.29, 25.32, 54.23],
      [1596, 165.32, 55.43, 33.2, 27.14, 49.05, 51.8, 75.82, 7.67, 205.34, 21.53, 45.93],
      [639, 164.78, 55.91, 31.8, 25.07, 47.09, 48.91, 65.44, 7.95, 197.4, 19.27, 41.21],
      [662, 164.84, 55.17, 31.22, 23.89, 45.07, 47.89, 60.79, 8.16, 193.67, 18.22, 38.95],
      [4784, 168.64, 60.02, 37.65, 29.62, 49.9, 58.31, 87.98, 7.3, 221.52, 24.8, 53.14],
      [1520, 167.76, 60.14, 35.73, 26.49, 46.49, 54.07, 70.59, 7.67, 210.19, 21.52, 45.52],
      [899, 167.74, 59.96, 34.64, 24.78, 44.1, 52.97, 65.07, 7.84, 205.33, 19.94, 42.2],
      [1037, 167.25, 58.64, 34.15, 24.32, 43.43, 51.42, 60.8, 7.95, 201.98, 19.18, 40.35],
      [4263, 170.13, 61.43, 39.95, 31.3, 52.6, 59.76, 97.8, 7.12, 229.2, 26.48, 57.72],
      [1357, 168.96, 62, 37.68, 27.4, 48.95, 55.1, 73.51, 7.59, 214.15, 22.75, 48.16],
      [998, 168.98, 61.34, 36.89, 26.31, 47.53, 54.45, 69.28, 7.64, 214.23, 21.64, 46.21],
      [1332, 168.64, 59.9, 36, 25.07, 45.84, 53.03, 64.63, 7.8, 208.67, 20.62, 43.66],
      [3849, 170.77, 63.52, 41.45, 32.51, 54.45, 60.89, 100, 7.02, 233.49, 27.95, 60.41],
      [1513, 169.97, 63.2, 39.44, 28.3, 49.83, 56.19, 76.55, 7.44, 219.31, 24.48, 50.98],
      [1151, 169.73, 62.61, 38.32, 27.33, 48.83, 55.32, 70.59, 7.54, 215.2, 23.16, 48.58],
      [1607, 169.64, 60.78, 37.26, 26.4, 47.12, 54.44, 68.19, 7.68, 214.17, 21.79, 46.53],
    ],
    female: [
      [1546, 116, 21.28, 8.73, 13.25, 29.82, 27.09, 19.43, 11.64, 112.51, 6.42, 33.93],
      [5319, 115.82, 21.24, 8.43, 12.32, 29.16, 26.49, 17.27, 11.93, 108.4, 5.91, 31.7],
      [1424, 115.28, 20.92, 8.17, 11.66, 28.81, 25.91, 15.87, 12.11, 106, 5.66, 30.37],
      [1711, 115.34, 21.1, 8.03, 11.11, 28.6, 25.5, 15.38, 12.25, 103.91, 5.4, 29.4],
      [2016, 122.01, 24.04, 10.44, 16.18, 32.68, 31.45, 27.59, 10.78, 122.97, 8.47, 41.85],
      [5399, 121.77, 24.04, 9.99, 14.76, 31.53, 30.38, 23.46, 11.05, 117.91, 7.66, 38.81],
      [1385, 121.56, 24, 9.73, 13.62, 31.04, 29.19, 20.68, 11.32, 114.72, 7.17, 36.62],
      [1355, 121.43, 23.99, 9.56, 13.39, 30.66, 28.74, 19.95, 11.42, 113.23, 6.92, 35.82],
      [3162, 128.11, 27.19, 12.15, 18.64, 36.1, 35.22, 33.88, 10.26, 132.67, 10.71, 48.41],
      [5002, 127.81, 27.34, 11.62, 17, 34.8, 33.46, 28.5, 10.5, 127.1, 9.64, 45.09],
      [1182, 127.55, 27.35, 11.23, 15.55, 33.88, 32.1, 24.78, 10.75, 122.59, 9, 42.3],
      [1289, 127.45, 27.39, 11.21, 15.24, 32.95, 30.89, 23.92, 10.91, 120.84, 8.84, 41.07],
      [4043, 134.25, 30.81, 14.21, 20.34, 38.93, 39.48, 41.88, 9.72, 142.74, 13.01, 54.94],
      [4605, 134.17, 31.27, 13.57, 18.34, 37.35, 37.16, 33.64, 10.07, 135.32, 11.51, 50.59],
      [1056, 134.37, 31.51, 13.24, 16.76, 36.53, 35.24, 29.12, 10.44, 130.61, 10.82, 47.78],
      [849, 134.07, 31.95, 13.03, 16.13, 35.36, 34.08, 26.43, 10.62, 126.68, 10.4, 45.62],
      [4070, 141.03, 34.84, 16.78, 21.61, 42.29, 42.88, 49.51, 9.27, 153.16, 15.43, 60.94],
      [4474, 141.37, 36.15, 16.17, 19.05, 40.31, 39.97, 38.14, 9.69, 144.48, 13.44, 55.77],
      [1172, 141.29, 36.41, 15.77, 17.77, 39.65, 38.22, 33.31, 9.95, 139.86, 12.41, 52.94],
      [817, 141.76, 36.93, 15.6, 16.78, 38.15, 36.69, 30.37, 10.08, 135.19, 11.67, 50.59],
      [3742, 147.45, 39.8, 20.18, 23.37, 45.49, 45.51, 56.93, 8.87, 162.99, 18.09, 66.66],
      [4782, 147.51, 40.81, 19.39, 20.43, 43.37, 42.28, 42.43, 9.28, 152.47, 15.27, 60.97],
      [1479, 148.03, 41.62, 18.91, 18.92, 41.92, 40.12, 36.66, 9.6, 146.29, 13.98, 57.6],
      [955, 148.14, 41.92, 18.58, 17.54, 40.57, 38.8, 32.96, 9.76, 141.35, 13.14, 54.93],
      [5780, 152.49, 44.55, 22.2, 22.75, 48.18, 46.14, 53.76, 8.77, 169.06, 12.49, 49],
      [2211, 152.04, 45.37, 21.37, 20.14, 45.95, 42.74, 41.49, 9.2, 157.9, 10.73, 42.03],
      [1059, 151.79, 45.13, 20.86, 18.55, 44.88, 41.26, 36.8, 9.47, 151.43, 9.75, 38.39],
      [729, 152.06, 45.03, 20.55, 17.81, 43.16, 40.6, 34.64, 9.68, 148.45, 9.43, 36.87],
      [5973, 155.07, 47.33, 24.57, 24.34, 50.81, 48.08, 60.55, 8.46, 175.65, 14.32, 54.72],
      [1786, 154.25, 47.83, 23.21, 21.14, 48.65, 43.88, 44.73, 9.04, 160.36, 11.57, 45.59],
      [1053, 154.27, 48.46, 22.82, 20.09, 47.36, 42.6, 39.92, 9.19, 156.22, 10.84, 42.6],
      [966, 154.1, 47.52, 22.32, 18.66, 46.08, 41.86, 36.76, 9.42, 152.2, 10.21, 39.9],
      [5190, 156.52, 49.64, 26.2, 26.03, 53.34, 49.44, 62.03, 8.34, 179.07, 15.63, 58.25],
      [2082, 155.82, 49.83, 24.59, 22.8, 51.22, 45.51, 47.19, 8.86, 166.16, 12.87, 49.67],
      [1264, 155.61, 49.88, 24.12, 21.05, 49.22, 43.85, 41.29, 9.1, 159.3, 11.71, 45.59],
      [1305, 155.53, 48.69, 23.58, 20.18, 48.06, 42.98, 39.25, 9.21, 156.41, 11.24, 43.56],
      [2353, 157.37, 51.84, 25.94, 24.51, 51.17, 50.49, 55.44, 8.61, 177.55, 14.81, 55.37],
      [1693, 156.27, 51.52, 24.87, 22.01, 49.17, 47.48, 43.94, 8.96, 167.42, 12.83, 48.8],
      [1392, 156.06, 51.71, 24.54, 20.85, 48.13, 46.56, 40.74, 9.11, 164.25, 12.07, 46.28],
      [1910, 156.26, 51, 24.23, 19.88, 46.88, 45.59, 38.96, 9.26, 161.94, 11.73, 44.45],
      [2140, 157.83, 52.51, 27.18, 26.08, 53.11, 51.62, 61.62, 8.51, 182.12, 15.49, 58.81],
      [1426, 156.91, 52.86, 25.55, 22.5, 50.94, 48.13, 44.4, 9.04, 169.67, 13.28, 50.29],
      [1427, 156.72, 52.14, 25.47, 21.56, 49.62, 46.98, 42.25, 9.17, 165.45, 12.74, 48.06],
      [2194, 156.69, 51.39, 24.74, 20.53, 47.93, 45.81, 38.99, 9.34, 162.22, 12, 45.33],
      [1969, 158.15, 53.35, 27.57, 26.95, 55.27, 52.79, 61.6, 8.44, 184.41, 16.4, 60.63],
      [1260, 157.5, 53.71, 26.35, 23.66, 52.78, 48.42, 44.09, 9, 169.98, 13.99, 51.79],
      [1452, 157.25, 53.7, 25.92, 22.48, 50.88, 47.44, 41.53, 9.15, 166.36, 13.25, 49.34],
      [2471, 157.13, 52.42, 25.35, 21, 49.42, 46.12, 38.51, 9.33, 163.47, 12.67, 46.58],
    ]
  },
  /**
   * 1日の運動・スポーツの実施時間
   */
  q4: {
    male: [
      [2201, 116.29, 21.49, 8.53, 11.85, 26.78, 26.19, 17.63, 12.05, 110.36, 7.25, 29.14],
      [3986, 116.54, 21.56, 8.75, 12.65, 26.72, 26.94, 19.64, 11.76, 114.11, 7.86, 30.58],
      [3265, 116.92, 21.75, 9.13, 13.33, 26.88, 27.89, 22.05, 11.48, 117.48, 8.57, 32.36],
      [962, 117.21, 21.88, 9.47, 14.12, 27.53, 28.53, 25.44, 11.17, 121.17, 10.26, 34.61],
      [2048, 122.11, 24.48, 10.06, 13.72, 28.76, 29.33, 23.91, 11.22, 119.39, 9.65, 34.88],
      [3490, 122.6, 24.64, 10.31, 14.6, 28.47, 30.43, 26.72, 11.04, 123.16, 10.38, 36.48],
      [3525, 122.96, 24.77, 10.71, 16.16, 29.07, 31.8, 31.54, 10.62, 128.01, 11.57, 39.39],
      [1682, 123.01, 24.68, 11.1, 17.23, 29.73, 33.62, 37.5, 10.34, 132.23, 14.26, 42.35],
      [2434, 127.87, 27.77, 11.62, 15.71, 30.3, 32.43, 29.24, 10.68, 128.46, 12.08, 40.01],
      [2872, 128.45, 28.16, 12.09, 17.09, 31.04, 34.03, 33.17, 10.34, 132.9, 13.27, 42.57],
      [2899, 128.92, 28.29, 12.57, 18.52, 31.42, 35.53, 39.16, 10.04, 137.42, 14.88, 45.39],
      [2859, 128.87, 28.09, 13.01, 20.11, 32.67, 37.82, 46.07, 9.78, 142.58, 17.53, 48.84],
      [2076, 133.44, 32.33, 13.32, 16.76, 32.13, 35.05, 32.61, 10.35, 134.75, 14.18, 43.93],
      [2583, 134.02, 32.07, 13.87, 18.2, 33.01, 37.31, 38.38, 9.96, 140.55, 15.68, 47.15],
      [2820, 134.27, 31.84, 14.44, 19.87, 34.09, 39.76, 45.43, 9.62, 147.12, 17.65, 50.79],
      [3567, 134.47, 31.68, 15.16, 21.71, 35.12, 42.17, 54, 9.35, 152.46, 21.24, 54.77],
      [1841, 139.17, 36.82, 15.37, 17, 34.06, 37.47, 35.16, 10.06, 140.46, 16.13, 47.27],
      [2342, 139.7, 36.31, 15.98, 19.39, 35.64, 40.56, 42.49, 9.61, 149.01, 18.08, 51.78],
      [2989, 139.93, 36.36, 16.77, 20.7, 36.4, 42.62, 49.79, 9.31, 154.55, 20.36, 55.14],
      [3855, 140.11, 35.56, 17.6, 23.16, 38.18, 45.64, 61.26, 8.96, 161.54, 24.94, 60.17],
      [1778, 146.15, 41.92, 18.41, 18.76, 36.78, 40.19, 40.04, 9.63, 150.49, 18.33, 52.66],
      [2339, 145.95, 41.27, 19.21, 20.67, 38.08, 43.14, 47.53, 9.22, 158.49, 20.84, 56.95],
      [3026, 146.28, 40.69, 20, 22.68, 39.37, 45.68, 57.44, 8.87, 166.47, 23.49, 61.07],
      [3936, 146.43, 40, 20.84, 25.03, 40.88, 48.24, 69.21, 8.53, 173.26, 28.61, 65.79],
      [1081, 153.82, 46.93, 22.14, 20.03, 40.26, 42.74, 45.95, 9.09, 165.8, 13.74, 27.62],
      [1436, 153.71, 46.3, 23.42, 22.21, 41.69, 45.42, 55.23, 8.79, 174.84, 15.62, 31.46],
      [3530, 154.48, 46.23, 24.37, 23.88, 43.03, 48.07, 62.51, 8.46, 182.68, 17.44, 34.8],
      [4209, 154.6, 45.82, 25.55, 26.2, 45.51, 51.18, 74.81, 8.14, 190.53, 19.98, 39.95],
      [1093, 160.57, 51.51, 27.45, 22.88, 43.18, 46.34, 58.37, 8.42, 181.83, 16.55, 34.75],
      [1132, 160.64, 51.88, 29.12, 25.08, 45.39, 49.06, 67.61, 8.14, 189.53, 18.68, 39.09],
      [3761, 161.39, 51.15, 29.91, 26.89, 47.11, 52.1, 79.82, 7.79, 198.51, 20.73, 43.77],
      [4351, 161.42, 50.8, 31.18, 29.29, 49.65, 55.01, 90.98, 7.55, 207.68, 23.46, 49.11],
      [1368, 165.07, 55.22, 31.96, 25.3, 46.92, 49.43, 66.8, 7.9, 198.35, 19.2, 41.71],
      [1639, 165.53, 55.59, 33.74, 27.44, 49.69, 52.25, 76.96, 7.69, 206.77, 21.72, 46.67],
      [3523, 165.84, 55.11, 34.66, 29.29, 51, 55.16, 88.76, 7.37, 214.54, 23.69, 51.21],
      [3929, 166.6, 55.98, 36.65, 31.5, 53.66, 57.89, 97.72, 7.17, 222.61, 26.71, 56.47],
      [2001, 167.51, 59.05, 34.45, 24.99, 44.35, 52.49, 64.2, 7.87, 204.44, 19.58, 41.78],
      [1364, 168.06, 60.55, 36.2, 26.39, 46.71, 54.33, 70.43, 7.66, 210.41, 21.64, 45.76],
      [1702, 168.07, 59.8, 36.74, 28.17, 48.25, 56.54, 80.12, 7.47, 217.25, 23.35, 49.67],
      [3176, 168.78, 60.1, 37.88, 30.11, 50.26, 58.87, 91.11, 7.23, 223.09, 25.47, 54.44],
      [2293, 168.83, 60.44, 36.5, 25.78, 46.78, 53.64, 67.16, 7.73, 210.91, 21.07, 45],
      [1348, 168.84, 61.7, 37.63, 27.48, 48.48, 55.45, 74.51, 7.56, 215.27, 22.43, 48.23],
      [1509, 169.7, 61.09, 38.79, 29.62, 50.96, 58.08, 88.52, 7.29, 223.41, 24.9, 53.87],
      [2802, 170.37, 61.84, 40.45, 31.91, 53.41, 60.4, 101.51, 7.06, 231.61, 27.39, 59.4],
      [2712, 169.67, 61.23, 37.8, 27.12, 48.06, 55.12, 70.37, 7.6, 215.45, 22.62, 47.93],
      [1482, 169.98, 63.68, 39.26, 28.18, 50.22, 56.13, 75.84, 7.46, 218.72, 23.94, 50.69],
      [1409, 170.15, 62.78, 40.39, 30.62, 52.09, 58.65, 89.59, 7.19, 227.26, 26.09, 55.95],
      [2517, 171.09, 63.95, 41.97, 33.13, 55.12, 61.66, 104.17, 6.95, 235.89, 28.9, 62.13],
    ],
    female: [
      [2647, 115.43, 21.11, 8.18, 11.41, 28.88, 25.72, 15.73, 12.18, 105.1, 5.56, 30.06],
      [4103, 115.64, 21.14, 8.33, 12.21, 29.03, 26.33, 16.97, 11.97, 108, 5.86, 31.43],
      [2737, 115.91, 21.23, 8.5, 12.67, 29.34, 26.73, 17.96, 11.81, 110.03, 6.03, 32.41],
      [512, 116.24, 21.59, 9.01, 13.04, 29.77, 27.33, 20.19, 11.72, 110.76, 6.65, 33.9],
      [2538, 121.28, 23.88, 9.7, 13.58, 30.85, 29.25, 20.65, 11.35, 113.9, 7.08, 36.42],
      [3823, 121.8, 23.99, 9.9, 14.51, 31.35, 30.09, 22.81, 11.1, 117.53, 7.53, 38.33],
      [2966, 121.89, 24.12, 10.17, 15.38, 32.14, 30.72, 24.77, 10.95, 119.83, 7.95, 39.99],
      [827, 122.36, 24.3, 10.56, 16.67, 32.83, 31.92, 30.03, 10.67, 124.38, 8.98, 42.95],
      [3274, 127.47, 27.41, 11.3, 15.88, 33.8, 31.87, 25.2, 10.78, 122.84, 9, 42.47],
      [3443, 127.93, 27.36, 11.65, 16.93, 34.68, 33.34, 28.2, 10.49, 126.89, 9.64, 44.95],
      [2528, 127.92, 27.09, 11.95, 17.81, 35.66, 34.5, 31.06, 10.35, 130.54, 10.09, 46.93],
      [1389, 128.26, 27.29, 12.19, 19.2, 36.39, 36.05, 37.22, 10.18, 134.42, 11.45, 49.75],
      [2838, 133.91, 31.31, 13.28, 16.96, 36.34, 35.42, 29.56, 10.34, 130.88, 10.84, 47.81],
      [3361, 134.15, 31.15, 13.59, 18.54, 37.37, 37.07, 33.9, 10.05, 136.01, 11.4, 50.82],
      [2554, 134.44, 31.21, 13.96, 19.29, 38.5, 38.54, 37.46, 9.92, 139.37, 12.21, 52.98],
      [1802, 134.49, 30.92, 14.41, 21.33, 39.4, 40.74, 46.64, 9.59, 145.13, 14.2, 56.96],
      [2793, 141.29, 36.28, 15.7, 17.59, 39.15, 38.23, 33.82, 9.93, 139.29, 12.35, 52.8],
      [3042, 141.28, 35.99, 16.16, 19.04, 40.53, 39.82, 37.8, 9.67, 144.22, 13.25, 55.67],
      [2729, 141.18, 35.33, 16.47, 20.56, 41.58, 41.58, 43.35, 9.46, 149.63, 14.31, 58.61],
      [1969, 141.32, 35.09, 17.23, 22.64, 42.66, 44.04, 54.95, 9.13, 156.44, 16.83, 63.09],
      [2880, 147.71, 41.45, 18.83, 18.74, 41.83, 40.04, 36.51, 9.57, 145.47, 13.81, 57.3],
      [3101, 147.67, 40.84, 19.34, 20.23, 43.01, 42.01, 41.65, 9.34, 151.49, 14.95, 60.44],
      [2840, 147.35, 40.09, 19.61, 21.79, 44.49, 43.81, 47.7, 9.1, 157.47, 16.32, 63.56],
      [2136, 147.76, 40.14, 20.61, 24.01, 45.94, 46.28, 61.71, 8.76, 165.92, 19.42, 68.22],
      [2096, 152.04, 45.05, 20.92, 18.56, 44.67, 41.35, 37.25, 9.48, 152.35, 9.8, 38.79],
      [1884, 152.02, 45.15, 21.24, 20.16, 46.27, 42.83, 41.52, 9.22, 158.09, 10.69, 41.98],
      [3104, 152.25, 44.65, 21.79, 21.81, 47.34, 44.88, 48.5, 8.9, 164.89, 11.78, 46.34],
      [2696, 152.66, 44.66, 22.68, 23.77, 48.72, 47.44, 59.4, 8.62, 172.89, 13.32, 51.78],
      [2245, 154.26, 47.81, 22.72, 19.82, 46.97, 42.6, 39.58, 9.25, 155.55, 10.74, 42.17],
      [1603, 154.11, 47.81, 23.33, 21.18, 49.05, 44.18, 45.32, 9, 161.51, 11.72, 46.07],
      [3060, 154.83, 47.31, 24.01, 23.21, 49.73, 46.75, 56.06, 8.61, 170.97, 13.4, 51.86],
      [2869, 155.36, 47.5, 25.05, 25.39, 51.76, 49.24, 64.87, 8.33, 179.76, 15.19, 57.35],
      [2755, 155.56, 49.38, 23.95, 21.11, 49.1, 43.8, 42.04, 9.09, 159.46, 11.69, 45.59],
      [1927, 155.76, 49.67, 24.67, 22.51, 51.15, 45.35, 46.77, 8.9, 165.03, 12.74, 49.22],
      [2808, 156.33, 49.57, 25.73, 25.2, 52.62, 48.45, 57.57, 8.47, 175.62, 14.8, 55.97],
      [2351, 156.82, 49.78, 26.65, 26.91, 53.94, 50.52, 66.42, 8.19, 183.07, 16.65, 60.69],
      [3436, 156.22, 51.17, 24.31, 20.41, 47.55, 46.01, 40.2, 9.19, 162.79, 11.86, 45.28],
      [1563, 156.45, 52.03, 24.86, 21.94, 49.26, 47.52, 43.6, 8.97, 167.63, 12.75, 48.66],
      [1078, 156.65, 51.31, 25.62, 23.39, 50.37, 49.62, 49.93, 8.72, 174.27, 13.94, 52.89],
      [1271, 157.65, 52, 26.41, 25.4, 51.56, 51.31, 59.64, 8.48, 180.93, 15.82, 57.76],
      [3731, 156.62, 51.58, 25, 21.12, 48.92, 46.47, 40.62, 9.24, 164.41, 12.3, 46.75],
      [1361, 156.98, 52.99, 25.58, 22.33, 50.61, 47.95, 44.96, 9.06, 168.37, 13.18, 49.9],
      [931, 157.6, 52.15, 26.64, 24.9, 52.8, 50.47, 54.1, 8.74, 177.05, 14.44, 55.89],
      [1165, 158.21, 53.09, 27.77, 26.92, 53, 52.44, 66.93, 8.37, 185.79, 16.62, 61.25],
      [3943, 157.12, 52.66, 25.57, 21.7, 50.25, 46.79, 40.1, 9.25, 165.31, 12.92, 47.97],
      [1322, 157.59, 53.95, 26.34, 23.59, 52.11, 48.27, 43.88, 9.01, 168.8, 13.83, 51.4],
      [839, 157.86, 53.39, 27.13, 25.41, 54.66, 51.21, 54.09, 8.66, 179.27, 15.33, 57.25],
      [1050, 158.54, 53.85, 27.99, 27.99, 55.78, 53.93, 67.49, 8.27, 188.38, 17.51, 63.3],
    ],
  },
  /**
   * 朝食の有無
   */
  q5: {
    male: [
      [9525, 116.69, 21.6, 8.89, 12.85, 26.82, 27.26, 20.67, 11.66, 115.14, 8.16, 31.25],
      [772, 116.4, 21.93, 8.86, 12.71, 27.27, 26.57, 18.49, 11.86, 113.96, 8.27, 30.79],
      [117, 117.06, 22.12, 8.48, 12.39, 26.98, 28.56, 20.52, 11.92, 112.86, 9.1, 31.07],
      [9637, 122.77, 24.64, 10.53, 15.4, 28.94, 31.3, 29.73, 10.8, 125.53, 11.26, 38.18],
      [973, 122.02, 24.84, 10.45, 15, 28.74, 30.05, 27.04, 11.03, 124.64, 11.08, 37.1],
      [133, 121.64, 24.74, 9.65, 15.21, 28.8, 30.46, 28.35, 11.21, 126.58, 11.31, 36.96],
      [9423, 128.62, 28.03, 12.36, 17.99, 31.47, 35.24, 37.78, 10.17, 136.02, 14.57, 44.58],
      [1430, 128.3, 28.46, 12.36, 17.91, 31.06, 34.16, 34.72, 10.29, 133.87, 14.54, 43.6],
      [211, 127.42, 28.15, 11.77, 16.64, 30.69, 33.28, 30.06, 10.54, 129.8, 13.23, 41.41],
      [9379, 134.16, 31.78, 14.36, 19.63, 33.89, 39.4, 45.1, 9.71, 145.7, 17.82, 50.33],
      [1463, 133.93, 32.71, 14.21, 18.8, 33.42, 37.47, 39.46, 9.94, 141.5, 17.07, 48.05],
      [201, 133.67, 33.32, 13.82, 18.28, 32.89, 36.68, 36.28, 10.07, 138.96, 17.43, 47.39],
      [9180, 139.9, 36.04, 16.69, 20.85, 36.59, 42.65, 50.83, 9.34, 154.31, 20.96, 55.28],
      [1623, 139.45, 36.69, 16.51, 19.97, 36.04, 41.48, 45.39, 9.52, 149.48, 19.96, 53.28],
      [223, 139, 36.47, 16.46, 18.75, 35.02, 38.56, 42.44, 9.64, 149.34, 19.7, 51.6],
      [9167, 146.3, 40.59, 19.88, 22.68, 39.37, 45.52, 58.04, 8.9, 165.46, 24.07, 60.97],
      [1598, 145.85, 41.49, 19.97, 21.69, 38.69, 43.95, 51.57, 9.12, 161.77, 23.48, 59.07],
      [314, 146.65, 42.28, 19.61, 20.35, 37.61, 41.63, 46.75, 9.25, 156.82, 22.64, 56.37],
      [8472, 154.42, 45.94, 24.45, 24.4, 43.75, 48.84, 66.21, 8.41, 184.25, 17.91, 36.16],
      [1379, 153.93, 47.05, 24.67, 23.38, 43.12, 46.76, 58.59, 8.57, 178.21, 17.63, 34.05],
      [327, 154.75, 48.18, 25, 22.77, 42.38, 44.88, 56.15, 8.64, 175.31, 17.39, 33.19],
      [8503, 161.3, 50.86, 30.04, 27.51, 47.85, 52.85, 82.4, 7.77, 200.75, 21.29, 45.07],
      [1420, 160.76, 52.44, 30.26, 26.22, 46.51, 50.42, 74.76, 7.93, 194.96, 21.15, 42.54],
      [351, 161.29, 52.21, 30.52, 26.49, 45.11, 49.93, 71.72, 7.86, 194.71, 20.68, 42.12],
      [8312, 166.14, 55.41, 34.98, 29.61, 51.59, 55.52, 89.29, 7.38, 215.75, 24.06, 51.92],
      [1569, 165.49, 56.14, 34.74, 28.27, 50.03, 53.14, 81, 7.53, 209.24, 23.54, 49.03],
      [509, 164.92, 55.71, 34.33, 27.38, 48.95, 51.79, 76.45, 7.63, 205.55, 23.41, 47.39],
      [6295, 168.38, 59.78, 36.66, 28.24, 48.42, 56.72, 81.2, 7.46, 216.76, 23.13, 49.83],
      [1334, 167.89, 60.29, 36.22, 26.94, 46.19, 54.64, 72.58, 7.63, 210.79, 22.55, 46.55],
      [618, 167.15, 59.84, 35.95, 25.9, 45.32, 52.68, 68.68, 7.68, 209.39, 22.14, 44.86],
      [5861, 169.76, 61.38, 38.71, 29.41, 50.81, 57.87, 87.39, 7.33, 223.06, 24.44, 53.32],
      [1371, 168.9, 60.81, 38.05, 27.99, 49.06, 55.56, 78.57, 7.5, 217.02, 23.83, 49.89],
      [725, 168.99, 61.19, 37.89, 27.14, 47.42, 54.51, 72.29, 7.57, 215.06, 23.53, 48.03],
      [5739, 170.51, 62.83, 40.07, 30.4, 52.3, 58.92, 89.04, 7.24, 227.09, 25.62, 55.65],
      [1468, 169.75, 63.13, 39.16, 28.53, 49.65, 55.98, 77.54, 7.41, 219.43, 25.08, 51.4],
      [915, 169.45, 62.02, 39.16, 27.98, 48.08, 55.05, 72.94, 7.55, 215.91, 24.68, 49.73],
    ],
    female: [
      [9088, 115.74, 21.17, 8.39, 12.23, 29.11, 26.38, 17.2, 11.94, 108.14, 5.87, 31.56],
      [805, 115.21, 21.32, 8.28, 11.5, 29.1, 25.68, 15.63, 12.25, 105.6, 5.81, 30.35],
      [107, 115.43, 21.39, 7.48, 11.78, 29.56, 27.27, 17.58, 12.15, 107.79, 6.09, 31.27],
      [9168, 121.77, 23.98, 9.97, 14.78, 31.52, 30.32, 23.63, 11.06, 117.96, 7.66, 38.83],
      [881, 121.38, 24.34, 10.03, 14.02, 32.14, 29.2, 21.52, 11.3, 116.35, 7.66, 37.59],
      [108, 122.25, 25, 10.62, 14.38, 32.02, 29.19, 22.25, 11.17, 121.07, 7.87, 38.68],
      [9249, 127.86, 27.24, 11.69, 17.17, 34.86, 33.66, 29.48, 10.49, 127.7, 9.82, 45.43],
      [1265, 127.59, 27.69, 11.64, 16.82, 34.94, 32.64, 26.95, 10.6, 126.36, 9.59, 44.44],
      [122, 127.54, 28.1, 11.7, 16.25, 34.49, 31.92, 25.72, 10.87, 123.86, 9.6, 43.3],
      [9128, 134.21, 31.02, 13.75, 18.87, 37.77, 37.76, 36.34, 9.99, 137.57, 11.97, 51.83],
      [1281, 134.21, 32.05, 13.72, 18.33, 37.39, 36.9, 32.45, 10.15, 134.07, 11.72, 50.34],
      [139, 134.08, 33.14, 13.33, 16.81, 37.26, 34.96, 30.4, 10.79, 127.47, 10.7, 47.59],
      [8804, 141.2, 35.44, 16.28, 19.85, 40.95, 40.97, 42.26, 9.55, 147.28, 14.01, 57.43],
      [1548, 141.56, 37.14, 16.54, 19.19, 40.42, 39.11, 37.56, 9.71, 143.65, 13.77, 55.56],
      [179, 141.84, 37.73, 16.23, 18.51, 39, 38.21, 33.62, 9.99, 140.13, 13.08, 53.02],
      [8860, 147.55, 40.29, 19.46, 21.13, 43.76, 43.09, 46.8, 9.19, 155.24, 15.96, 62.31],
      [1809, 147.9, 42.14, 19.8, 20.44, 43.28, 41.76, 42.15, 9.35, 150.9, 15.66, 60.71],
      [289, 147.94, 43.3, 20.01, 19.89, 42.88, 40.27, 37.81, 9.51, 146.06, 15.03, 58.91],
      [7564, 152.32, 44.46, 21.72, 21.6, 47.07, 44.92, 49.44, 8.96, 164.5, 11.63, 46.09],
      [1832, 152.12, 46.02, 21.74, 20.57, 46.67, 42.84, 42.51, 9.17, 158.84, 11.36, 43.27],
      [314, 151.9, 47.3, 22.08, 19.86, 46.24, 42.27, 38.49, 9.3, 157.33, 11.61, 42.38],
      [7479, 154.87, 47.33, 23.99, 22.94, 49.71, 46.51, 54.63, 8.69, 169.87, 13.13, 51.02],
      [1932, 154.39, 48.31, 23.67, 22.14, 49.17, 44.91, 48.48, 8.88, 164.58, 12.85, 48.33],
      [310, 153.97, 48.91, 23.54, 22.14, 48.8, 44.64, 46.19, 9.01, 161.48, 12.68, 47.25],
      [7624, 156.27, 49.43, 25.38, 24.28, 51.95, 47.62, 55.12, 8.6, 172.54, 14.14, 53.86],
      [1808, 155.65, 50.1, 24.77, 22.88, 50.67, 45.22, 47.68, 8.86, 165.88, 13.53, 50.02],
      [362, 155.3, 50.43, 24.72, 21.94, 49.99, 44.12, 42.61, 9.05, 160.45, 13.01, 47.63],
      [5458, 156.72, 51.3, 25.06, 22.42, 49.47, 48.25, 47.04, 8.91, 169.84, 13.07, 50.01],
      [1490, 156.31, 52.37, 24.84, 20.98, 47.75, 46.5, 42.65, 9.07, 165.93, 12.95, 47.44],
      [405, 155.74, 51.42, 24.55, 20.73, 47.57, 46.05, 39.31, 9.11, 162.31, 12.94, 46.33],
      [5120, 157.26, 52.13, 25.96, 23.19, 51.02, 48.81, 49.19, 8.93, 171.88, 13.56, 51.9],
      [1551, 156.71, 52.14, 25.36, 21.99, 49.35, 47.08, 43.8, 9.15, 167.06, 13.16, 48.82],
      [518, 156.37, 52.59, 25.1, 21.05, 47.41, 45.97, 40.91, 9.26, 163.56, 13.15, 46.67],
      [5060, 157.59, 52.98, 26.37, 23.88, 52.63, 49.27, 48.56, 8.91, 172.72, 14.15, 53.07],
      [1571, 157.43, 53.7, 26.05, 22.57, 50.59, 47.42, 42.53, 9.15, 167.72, 13.92, 49.79],
      [525, 156.81, 53.29, 25.67, 21.35, 49.04, 45.98, 38.17, 9.28, 163.6, 13.42, 47.44],
    ]
  },
  /**
   * 1日の睡眠時間
   */
  q6: {
    male: [
      [83, 116.58, 21.67, 8.46, 12.04, 28.16, 28, 20.37, 11.86, 116.63, 8.58, 31.33],
      [2180, 116.49, 21.86, 8.76, 12.76, 27.14, 26.72, 18.93, 11.81, 114.2, 7.99, 30.59],
      [8140, 116.72, 21.57, 8.93, 12.86, 26.76, 27.35, 20.93, 11.64, 115.23, 8.22, 31.37],
      [339, 121.98, 24.2, 10.57, 16.24, 29.08, 30.56, 29.08, 10.78, 127.37, 11.63, 38.51],
      [2498, 122.56, 24.96, 10.39, 14.97, 29.09, 30.6, 27.38, 10.96, 123.94, 10.89, 37.23],
      [7890, 122.76, 24.59, 10.55, 15.44, 28.86, 31.38, 30.12, 10.78, 125.83, 11.33, 38.3],
      [1190, 128.18, 28.09, 12.03, 17.72, 30.86, 34.2, 34.58, 10.34, 132.73, 14.19, 43.05],
      [3269, 128.59, 28.25, 12.29, 17.84, 31.26, 34.79, 36.48, 10.22, 135.12, 14.32, 44.05],
      [6591, 128.61, 28.01, 12.44, 18.05, 31.58, 35.34, 38.13, 10.15, 136.41, 14.71, 44.81],
      [1165, 133.61, 32.15, 14.22, 19.07, 32.88, 37.42, 40.37, 9.87, 141.06, 17.46, 48.21],
      [3775, 134.25, 32.21, 14.22, 19.24, 33.62, 38.57, 43.01, 9.81, 144.45, 17.28, 49.35],
      [6098, 134.14, 31.72, 14.42, 19.75, 34.1, 39.75, 45.69, 9.68, 146.15, 18.03, 50.72],
      [843, 139.72, 36.81, 16.5, 19.66, 34.85, 39.64, 43.2, 9.62, 146.57, 19.66, 51.84],
      [4372, 139.82, 36.53, 16.56, 20.51, 36.15, 42.07, 48.91, 9.4, 152.76, 20.45, 54.39],
      [5795, 139.83, 35.76, 16.77, 20.96, 36.99, 43.05, 51.56, 9.32, 155.12, 21.22, 55.78],
      [801, 146.79, 41.93, 19.84, 21.07, 37.65, 42.85, 49.93, 9.14, 158.6, 22.72, 57.56],
      [5134, 146.53, 41.54, 20.02, 22.3, 39.11, 44.91, 55.25, 8.99, 164.15, 23.5, 60.18],
      [5131, 145.88, 39.81, 19.77, 22.88, 39.59, 45.85, 59.46, 8.86, 166.23, 24.58, 61.47],
      [1102, 155, 47.9, 25.04, 23.17, 42.06, 45.84, 57.06, 8.58, 177.31, 17.15, 33.55],
      [5821, 154.64, 46.47, 24.57, 24.1, 43.41, 48.36, 64.9, 8.44, 183.38, 17.76, 35.62],
      [3329, 153.65, 45.01, 24.18, 24.73, 44.38, 49.43, 67.39, 8.4, 184.53, 18.25, 36.77],
      [1248, 161.48, 52.58, 30.53, 26.39, 46.2, 50.12, 73.27, 7.91, 195.21, 20.5, 42.38],
      [6748, 161.42, 51.15, 30.13, 27.27, 47.45, 52.62, 81.77, 7.77, 200.36, 21.23, 44.74],
      [2338, 160.56, 50.24, 29.82, 27.86, 48.76, 53.03, 83.09, 7.79, 200.24, 21.68, 45.53],
      [1582, 165.84, 56.21, 35.15, 28.62, 50.59, 53.56, 81.47, 7.54, 211.09, 22.99, 49.38],
      [7312, 166, 55.55, 34.87, 29.45, 51.26, 55.26, 88.65, 7.39, 214.88, 24.01, 51.57],
      [1562, 166.01, 54.77, 34.89, 29.42, 52.03, 55.23, 87.94, 7.41, 214.74, 24.57, 51.89],
      [1454, 168.02, 60.17, 36.59, 27.42, 47.41, 55.22, 75.62, 7.57, 214.22, 22.65, 47.88],
      [6001, 168.25, 59.78, 36.56, 28.02, 47.95, 56.46, 80, 7.47, 215.98, 23.03, 49.33],
      [779, 168.25, 59.91, 36.19, 27.31, 47.82, 54.85, 76.43, 7.65, 211.51, 23, 47.79],
      [1844, 169.43, 61.28, 38.31, 28.51, 49.65, 56.35, 81.1, 7.43, 220.07, 23.71, 51.07],
      [5653, 169.55, 61.18, 38.54, 29.19, 50.41, 57.57, 86.03, 7.36, 221.96, 24.43, 52.74],
      [457, 169.88, 62.05, 39, 27.92, 49.87, 55.49, 79.36, 7.5, 218.12, 24.22, 50.95],
      [2146, 170.25, 62.8, 39.49, 29.37, 50.66, 57.48, 81.72, 7.37, 222.73, 24.98, 53.06],
      [5629, 170.23, 62.72, 39.93, 29.96, 51.64, 58.23, 86.63, 7.27, 225.43, 25.58, 54.75],
      [339, 170.46, 63.79, 39.76, 29.4, 50.67, 56.41, 82.2, 7.4, 219.13, 25.29, 52.88],
    ],
    female: [
      [81, 115.94, 21.67, 7.79, 11.69, 28.4, 27.14, 16.64, 12.13, 106.42, 5.85, 30.71],
      [2128, 115.72, 21.49, 8.3, 11.98, 29.12, 26.03, 16.48, 12.05, 106.44, 5.81, 30.95],
      [7781, 115.68, 21.09, 8.39, 12.22, 29.12, 26.4, 17.25, 11.94, 108.35, 5.88, 31.62],
      [279, 121.26, 23.92, 9.65, 14.82, 32.64, 29.5, 23.83, 11.07, 120.57, 7.68, 38.8],
      [2415, 121.9, 24.46, 10.04, 14.55, 31.66, 29.82, 22.46, 11.15, 116.65, 7.65, 38.24],
      [7452, 121.71, 23.89, 9.98, 14.75, 31.51, 30.36, 23.73, 11.06, 118.14, 7.66, 38.87],
      [950, 127.47, 27.41, 11.37, 16.35, 33.76, 31.84, 27.11, 10.65, 123.88, 9.47, 43.3],
      [3220, 127.95, 27.52, 11.63, 17.1, 34.52, 33.42, 28.43, 10.54, 127.27, 9.74, 44.96],
      [6460, 127.82, 27.18, 11.76, 17.23, 35.2, 33.82, 29.8, 10.47, 128.15, 9.86, 45.75],
      [828, 134.13, 31.99, 13.7, 17.84, 36.39, 35.38, 31.61, 10.25, 130.65, 11.55, 48.9],
      [3624, 134.37, 31.61, 13.8, 18.58, 37.53, 37.34, 35.36, 10.02, 137.1, 11.87, 51.32],
      [6097, 134.13, 30.79, 13.7, 19.01, 38, 38.08, 36.62, 9.98, 137.82, 12.01, 52.12],
      [638, 141.31, 37.08, 16.09, 18.58, 38.84, 37.56, 35.91, 9.87, 140.45, 13.23, 53.51],
      [4285, 141.65, 36.22, 16.5, 19.7, 40.83, 40.49, 40.88, 9.59, 146.41, 13.93, 57],
      [5599, 140.96, 35.19, 16.21, 19.87, 41.08, 41.12, 42.47, 9.55, 147.48, 14.05, 57.55],
      [768, 148.18, 42.81, 19.94, 20.26, 42.24, 40.95, 40.54, 9.39, 147.07, 15.26, 59.51],
      [5358, 147.79, 41.16, 19.59, 20.87, 43.62, 42.46, 45.02, 9.24, 153.46, 15.69, 61.63],
      [4815, 147.34, 39.79, 19.4, 21.23, 43.92, 43.46, 47.51, 9.19, 156.32, 16.17, 62.71],
      [1216, 152.45, 46.28, 22.25, 20.8, 46.9, 43.35, 43.95, 9.14, 158.98, 11.56, 44.08],
      [6127, 152.35, 44.94, 21.74, 21.35, 46.91, 44.54, 48.33, 8.99, 163.6, 11.56, 45.54],
      [2436, 152.01, 43.85, 21.52, 21.61, 47.09, 44.79, 48.39, 8.98, 164.14, 11.63, 45.9],
      [1483, 154.52, 48.65, 24.07, 22.49, 49.46, 45.17, 49.59, 8.81, 165.36, 13, 49.35],
      [6673, 154.77, 47.48, 23.86, 22.9, 49.54, 46.3, 54.06, 8.72, 169.09, 13.07, 50.63],
      [1624, 154.82, 46.91, 23.97, 22.4, 49.91, 46.33, 52.87, 8.74, 169.28, 13.04, 50.34],
      [1996, 155.97, 50.37, 25.16, 23.76, 51.45, 46.33, 51, 8.72, 168.82, 13.87, 52.06],
      [6814, 156.16, 49.44, 25.28, 24.09, 51.79, 47.34, 54.14, 8.63, 171.59, 14.02, 53.33],
      [1030, 156.2, 49.04, 25.27, 23.41, 51.37, 46.65, 52.76, 8.69, 170.3, 13.95, 52.45],
      [1933, 156.5, 51.47, 25, 22.15, 49.29, 47.92, 45.58, 8.96, 169.42, 13.11, 49.58],
      [4891, 156.6, 51.42, 24.97, 22.11, 49.07, 47.86, 46.3, 8.93, 168.76, 13.01, 49.44],
      [523, 156.73, 52.64, 25.02, 20.95, 47.43, 46.38, 41.12, 9.09, 164.52, 12.98, 46.77],
      [2400, 156.92, 52.09, 25.67, 22.8, 50.32, 48.18, 47.48, 9.03, 170.28, 13.43, 50.8],
      [4443, 157.14, 52.15, 25.82, 22.84, 50.53, 48.35, 47.78, 8.97, 170.46, 13.45, 51.08],
      [344, 157.25, 52.94, 25.83, 21.92, 49.39, 47.06, 43.13, 9.16, 167.47, 13.44, 48.73],
      [2606, 157.41, 53.34, 26.36, 23.6, 52.19, 48.53, 46.43, 9.01, 170.96, 14.1, 52.08],
      [4303, 157.53, 53.07, 26.21, 23.35, 51.89, 48.77, 46.73, 8.97, 171.07, 14.02, 52.01],
      [238, 157.75, 52.69, 25.72, 22.46, 49.51, 47.1, 42.17, 9.16, 168.64, 13.83, 49.09],
    ],
  },

  /**
   * 1日のテレビ（テレビゲーム含む）の視聴時間
   */
  q7: {
    male: [
      [1354, 116.73, 21.54, 9.02, 12.92, 26.52, 27.12, 21.2, 11.59, 114.9, 8.35, 31.36],
      [4267, 116.67, 21.51, 9.01, 13.06, 26.9, 27.42, 21.13, 11.61, 115.87, 8.3, 31.64],
      [3272, 116.61, 21.7, 8.76, 12.69, 26.84, 27.23, 19.92, 11.72, 114.44, 8.06, 30.9],
      [1495, 116.73, 21.87, 8.72, 12.42, 27.06, 26.69, 19.32, 11.85, 113.94, 7.89, 30.49],
      [1425, 122.48, 24.34, 10.69, 15.83, 29.23, 31.37, 30.9, 10.76, 126.67, 11.69, 38.78],
      [4038, 122.62, 24.42, 10.59, 15.5, 28.91, 31.59, 30.68, 10.76, 126.18, 11.37, 38.52],
      [3372, 122.72, 24.75, 10.46, 15.32, 28.75, 31.05, 28.76, 10.84, 125.1, 11.13, 37.82],
      [1887, 122.96, 25.28, 10.34, 14.79, 29.04, 30.31, 26.86, 11, 123.58, 10.79, 36.96],
      [2436, 128.43, 27.76, 12.48, 18.24, 31.44, 35.09, 38.28, 10.15, 136.3, 14.83, 44.78],
      [3322, 128.6, 27.93, 12.43, 18.29, 31.35, 35.48, 38.58, 10.1, 136.45, 14.69, 44.99],
      [2447, 128.61, 28.36, 12.4, 17.67, 31.58, 35.08, 36.71, 10.24, 135.27, 14.47, 44.23],
      [2814, 128.57, 28.33, 12.11, 17.52, 31.34, 34.43, 35.26, 10.29, 134.33, 14.17, 43.47],
      [2253, 133.78, 31.33, 14.44, 19.9, 33.56, 39.26, 45.62, 9.77, 145.69, 18.12, 50.43],
      [3278, 134.08, 31.52, 14.37, 19.83, 34.01, 39.37, 45.89, 9.67, 146.21, 17.86, 50.59],
      [2347, 134.14, 31.85, 14.39, 19.59, 33.91, 39.46, 44.62, 9.71, 145.67, 17.69, 50.29],
      [3144, 134.4, 32.86, 14.17, 18.79, 33.67, 38.42, 41.08, 9.85, 142.78, 17.24, 48.77],
      [2253, 133.78, 31.33, 14.44, 19.9, 33.56, 39.26, 45.62, 9.77, 145.69, 18.12, 50.43],
      [3193, 139.71, 35.41, 16.76, 21.38, 36.89, 43.6, 52.79, 9.25, 156.01, 21.29, 56.24],
      [2629, 140.08, 36.22, 16.7, 20.8, 36.31, 42.55, 50.81, 9.35, 154.1, 20.87, 55.17],
      [3570, 140.11, 37.26, 16.44, 19.61, 36.27, 41.17, 45.19, 9.55, 150.6, 19.97, 53.14],
      [1527, 146.33, 40.05, 20.2, 23.32, 40.04, 45.75, 59.87, 8.83, 166.21, 25.21, 61.81],
      [3265, 146.1, 39.87, 19.91, 22.99, 39.8, 45.97, 60.46, 8.85, 166.83, 24.43, 61.76],
      [2727, 146.13, 40.63, 19.86, 22.59, 39.02, 45.48, 57.59, 8.91, 165.66, 24.01, 60.99],
      [3535, 146.42, 42, 19.76, 21.55, 38.53, 43.99, 51.48, 9.11, 161.28, 22.9, 58.63],
      [1039, 154.08, 45.23, 24.8, 24.86, 44.16, 48.6, 68.4, 8.44, 183.77, 18.46, 37.14],
      [2548, 153.79, 44.86, 24.34, 24.97, 44.17, 49.45, 68.02, 8.38, 185.39, 18.1, 36.81],
      [2619, 154.16, 45.68, 24.39, 24.53, 43.52, 49.06, 67.54, 8.36, 184.54, 18.12, 36.41],
      [4047, 154.91, 47.49, 24.59, 23.35, 43.11, 47.37, 60.27, 8.53, 180.61, 17.38, 34.37],
      [876, 160.58, 50.26, 30.2, 27.95, 48.09, 52.79, 83.11, 7.81, 201.73, 21.94, 45.82],
      [2302, 160.87, 50.04, 30.03, 28.06, 48.45, 53.48, 85.54, 7.71, 202.33, 21.49, 45.98],
      [2754, 161.26, 50.97, 30.09, 27.64, 48.24, 53.19, 84.22, 7.73, 201.2, 21.53, 45.57],
      [4401, 161.54, 51.96, 30.14, 26.55, 46.65, 51.3, 76.26, 7.87, 197.02, 20.8, 43.1],
      [1015, 166.01, 55.25, 35.56, 30.01, 52.23, 55.39, 89.43, 7.36, 215.51, 24.57, 52.49],
      [2411, 166, 55.13, 35.11, 29.94, 51.93, 55.68, 90.89, 7.42, 215.61, 24.36, 52.37],
      [2674, 166.06, 55.34, 35.19, 29.85, 51.77, 55.78, 90.97, 7.33, 216.78, 24.4, 52.6],
      [4360, 165.9, 55.94, 34.49, 28.49, 50.38, 54.04, 82.98, 7.47, 211.76, 23.29, 49.61],
      [1742, 168.61, 59.58, 37.47, 28.96, 49.78, 57.24, 84.65, 7.4, 219.28, 24.04, 51.64],
      [2042, 168.12, 59.66, 36.79, 28.78, 48.87, 57.07, 82.73, 7.44, 217.83, 23.45, 50.55],
      [1821, 168.29, 60.06, 36.27, 27.55, 47.51, 55.97, 78.34, 7.52, 214.96, 22.66, 48.49],
      [2633, 167.96, 60.05, 35.89, 26.61, 45.93, 54.68, 72.63, 7.61, 210.86, 22.09, 46.26],
      [1729, 169.84, 60.94, 39.24, 30.58, 52.18, 58.71, 90.73, 7.28, 226.11, 25.53, 55.37],
      [1915, 169.71, 61.15, 39.02, 29.83, 51.03, 58.26, 89.93, 7.29, 224.7, 24.67, 54.08],
      [1659, 169.5, 61.2, 38.29, 28.84, 50.12, 57.12, 84.58, 7.38, 220.72, 24.02, 52],
      [2652, 169.25, 61.58, 37.82, 27.35, 48.39, 55.39, 76.59, 7.51, 216.07, 23.26, 49.14],
      [2139, 170.42, 62.15, 40.36, 31.11, 53.29, 59.72, 91.87, 7.2, 230.06, 26.58, 57.22],
      [2010, 170.45, 62.75, 40.25, 30.75, 52.62, 59.06, 90.13, 7.19, 227.61, 25.76, 56.31],
      [1441, 170.04, 62.95, 39.62, 29.7, 51.28, 57.8, 84.62, 7.32, 222.73, 25.24, 53.85],
      [2532, 170.06, 63.27, 39.09, 27.95, 48.71, 55.67, 75.94, 7.47, 218.21, 24.25, 50.32],
    ],
    female: [
      [1660, 115.57, 20.84, 8.48, 12.52, 29.09, 26.4, 17.81, 11.88, 109.22, 5.93, 32.01],
      [4339, 115.71, 21.12, 8.38, 12.23, 29.12, 26.44, 17.45, 11.92, 108.33, 5.92, 31.7],
      [2852, 115.71, 21.3, 8.33, 12.02, 29.12, 26.16, 16.47, 12.04, 107.33, 5.79, 31.07],
      [1123, 115.77, 21.58, 8.31, 11.77, 29.17, 26.06, 16.01, 12.08, 105.82, 5.76, 30.69],
      [1805, 121.5, 23.64, 10, 15.13, 31.67, 30.42, 24.72, 11, 118.86, 7.8, 39.39],
      [4176, 121.84, 23.99, 10, 14.79, 31.66, 30.4, 23.85, 11.06, 118.35, 7.66, 38.98],
      [2809, 121.74, 24.17, 9.96, 14.6, 31.44, 30.09, 22.79, 11.12, 117.23, 7.63, 38.39],
      [1352, 121.77, 24.33, 9.97, 14.11, 31.51, 29.6, 21.73, 11.2, 116.16, 7.5, 37.7],
      [3234, 127.71, 26.92, 11.75, 17.47, 35.07, 33.61, 30.28, 10.45, 128.22, 9.84, 45.8],
      [3583, 127.84, 27.12, 11.66, 17.32, 34.91, 33.83, 29.76, 10.44, 128.28, 9.85, 45.71],
      [2052, 127.89, 27.62, 11.72, 16.73, 34.74, 33.45, 28.42, 10.54, 126.63, 9.76, 44.89],
      [1730, 127.94, 28.02, 11.57, 16.48, 34.62, 32.71, 26.49, 10.72, 125.51, 9.59, 43.9],
      [2761, 133.69, 30.53, 13.64, 19.04, 37.76, 37.42, 37.16, 9.97, 136.62, 11.96, 51.8],
      [3680, 134.06, 30.85, 13.85, 19.1, 37.89, 38.01, 36.64, 9.97, 138, 12.06, 52.17],
      [2103, 134.51, 31.6, 13.71, 18.64, 37.59, 37.54, 34.81, 10.03, 137.27, 11.86, 51.43],
      [1983, 134.9, 32.18, 13.7, 17.94, 37.43, 37.21, 33.39, 10.14, 135.42, 11.7, 50.41],
      [1956, 140.35, 34.5, 15.9, 19.92, 40.85, 40.67, 43.25, 9.57, 146.57, 14.07, 57.25],
      [3523, 140.91, 35.09, 16.2, 20.15, 40.99, 41.11, 42.78, 9.52, 147.9, 13.99, 57.71],
      [2353, 141.54, 36.05, 16.31, 19.5, 40.63, 40.57, 40.55, 9.59, 146.08, 13.8, 56.73],
      [2668, 142.18, 37.22, 16.81, 19.2, 40.89, 40.08, 39.03, 9.67, 145.44, 13.99, 56.47],
      [1829, 147.03, 39.49, 19.26, 21.44, 43.83, 43.16, 47.91, 9.17, 155.46, 15.92, 62.51],
      [3569, 147.19, 39.66, 19.35, 21.34, 43.83, 43.15, 47.4, 9.17, 155.99, 16.01, 62.54],
      [2658, 147.87, 41.12, 19.64, 21, 43.9, 42.77, 45.84, 9.21, 153.98, 15.87, 62.04],
      [2875, 148.31, 42.28, 19.84, 20.24, 43.15, 42.14, 42.45, 9.33, 151.6, 15.69, 60.81],
      [951, 152.38, 44.12, 21.9, 21.86, 47.42, 44.82, 50.89, 8.98, 164.1, 11.91, 46.88],
      [2467, 151.99, 43.77, 21.67, 21.89, 47.06, 45.11, 51.11, 8.9, 165.54, 11.71, 46.77],
      [2495, 152.25, 44.7, 21.86, 21.49, 47.01, 44.61, 48.37, 8.98, 163.43, 11.55, 45.66],
      [3867, 152.46, 45.77, 21.7, 20.79, 46.73, 43.84, 44.56, 9.1, 161.26, 11.43, 44.13],
      [952, 155.23, 47.23, 24.23, 23.59, 50.98, 46.72, 55.28, 8.71, 170.87, 13.32, 51.86],
      [2231, 154.67, 46.71, 24.06, 23.21, 50, 46.81, 56.7, 8.64, 170.22, 13.09, 51.65],
      [2572, 154.67, 47.36, 23.91, 23, 49.85, 46.35, 54.39, 8.7, 169.38, 13.15, 50.88],
      [4022, 154.7, 48.25, 23.76, 22.14, 48.86, 45.48, 49.91, 8.82, 166.55, 12.91, 48.99],
      [1087, 156.27, 49.08, 25.3, 24.67, 52.37, 47.58, 55.09, 8.64, 172.44, 14.17, 54.03],
      [2451, 156.27, 49.05, 25.43, 24.51, 52.04, 47.71, 56.9, 8.55, 173.41, 14.1, 54.28],
      [2653, 156.25, 49.51, 25.34, 24.09, 52.01, 47.34, 53.94, 8.63, 171.55, 14.03, 53.43],
      [3648, 155.89, 50.16, 25.06, 23.27, 50.98, 46.28, 49.99, 8.76, 168.25, 13.81, 51.44],
      [2154, 156.76, 51.15, 25.3, 22.69, 50.31, 48.42, 48.01, 8.9, 171.06, 13.28, 50.89],
      [2046, 156.61, 51.27, 24.82, 22.39, 49.44, 48.16, 47.48, 8.92, 169.52, 13.05, 49.79],
      [1394, 156.51, 51.5, 24.88, 22.04, 48.85, 47.82, 45.77, 8.93, 168.67, 13.09, 49.44],
      [1755, 156.38, 52.28, 24.87, 20.83, 47.09, 46.54, 40.93, 9.07, 164.66, 12.67, 46.7],
      [2333, 157.09, 51.82, 25.83, 23.53, 51.38, 48.86, 50.03, 8.93, 172.92, 13.66, 52.35],
      [1846, 157.15, 51.92, 25.89, 23.45, 51.12, 48.86, 49.88, 8.91, 172.22, 13.47, 52.18],
      [1314, 157.09, 52.42, 25.88, 22.39, 49.8, 48.08, 46.2, 8.97, 169.89, 13.35, 50.32],
      [1690, 156.98, 52.75, 25.47, 21.3, 48.76, 46.79, 42.27, 9.22, 164.68, 13.18, 47.92],
      [2538, 157.86, 52.68, 26.44, 24.19, 52.85, 49.62, 49.87, 8.87, 174.5, 14.52, 53.9],
      [1894, 157.47, 53.27, 26.38, 23.78, 52.29, 49.12, 47.37, 8.97, 171.86, 13.98, 52.5],
      [1103, 157.23, 53.18, 26.08, 22.88, 51.56, 48.11, 44.62, 9.01, 169.54, 13.8, 50.95],
      [1618, 157.14, 53.79, 25.9, 22.1, 50.27, 46.85, 41.33, 9.2, 165.27, 13.54, 48.92],
    ],
  },
}
