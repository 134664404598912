/**
 * To migrate data from Excel:
 *   - On Excel, replace comma character with `.` if needed.
 *   - Just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 */
export const body2024Male = [
  [10545, 116.66, 21.63, 5.09, 3.73],
  [10932, 122.7, 24.67, 5.35, 5.14],
  [11293, 128.51, 28.06, 5.74, 6.33],
  [11457, 134.09, 31.92, 5.96, 7.55],
  [11445, 139.85, 36.2, 6.56, 8.91],
  [11557, 146.24, 40.81, 7.54, 10.12],
  [11158, 154.33, 46.17, 8.16, 11.22],
  [11188, 161.18, 51.12, 7.49, 11.12],
  [11450, 165.93, 55.54, 6.55, 11.11],
  [8340, 168.26, 59.8, 5.84, 11.78],
  [7939, 169.57, 61.23, 5.83, 11.73],
  [8053, 170.32, 62.76, 5.85, 11.9],
  [270, 167.18, 61.72, 6.42, 16.82],
  [271, 167.99, 60.42, 6.37, 14.77],
  [281, 168.64, 64.21, 6.08, 15.27],
  [39, 169.84, 61.33, 6.15, 14.92],
]

export const body2024Female = [
  [10130, 115.69, 21.18, 5.05, 3.59],
  [10339, 121.74, 24.02, 5.32, 4.46],
  [10824, 127.8, 27.29, 5.98, 5.68],
  [10875, 134.2, 31.17, 6.63, 7.01],
  [10885, 141.27, 35.78, 7.04, 8.09],
  [11337, 147.64, 40.7, 6.64, 8.73],
  [10509, 152.25, 44.82, 5.83, 8.67],
  [10498, 154.72, 47.64, 5.49, 8.23],
  [10584, 156.09, 49.67, 5.39, 8.25],
  [7602, 156.62, 51.4, 5.38, 8.7],
  [7440, 157.14, 52.14, 5.37, 8.28],
  [7363, 157.51, 53.1, 5.32, 8.99],
  [186, 156.02, 54.5, 5.65, 12.64],
  [139, 155.96, 53.63, 5.79, 12.54],
  [135, 156.91, 55.42, 6.2, 13.12],
  [16, 158.54, 59.59, 5.37, 23.99]
]
