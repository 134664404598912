// prettier-ignore

import { CrossAnalysis } from "./type";

/**
 * 標準偏差
 *
 * From 2024, data format had been changed.
 * No fix time high school in this data.
 *
 * So to migrate data from Excel:
 *   - On Excel, replace `,` character with `.` if needed.
 *   - Just select all data's cell (include rows and columns).
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with `,` character
 *   - Wrap each line by the `[]` character (an array) by many ways.
 *   - Use editor/IDE to format this file
 */
export const crossAnalysisDeviation2024: CrossAnalysis = {
  /**
   * category: 運動部やスポーツクラブへの所属状況
   */
  q2: {
    male: [
      [4039, 5.04, 3.69, 2.47, 5.08, 7.29, 5.61, 11.67, 1.24, 18.71, 3.64, 7.17],
      [6380, 5.11, 3.76, 2.47, 5.18, 7.31, 5.72, 10, 1.58, 19.68, 3.19, 7.25],
      [5105, 5.3, 5.04, 2.7, 5.37, 7.78, 6.92, 15.74, 1.19, 19.29, 4.82, 7.88],
      [5635, 5.36, 5.2, 2.65, 5.72, 7.91, 6.97, 13.49, 1.64, 20.53, 4.47, 8.16],
      [5970, 5.55, 5.76, 3.52, 6.16, 8.24, 8.28, 18.43, 1.06, 21.03, 6.09, 8.61],
      [5099, 5.86, 6.95, 3.27, 6.25, 8.64, 8.23, 16.25, 1.51, 22.9, 5.28, 9.09],
      [6371, 5.87, 7.01, 3.79, 6.12, 9.05, 8.3, 20.12, 1.39, 22.45, 7.09, 9.02],
      [4687, 6.04, 8.2, 3.88, 6.35, 9.37, 8.58, 18.02, 1.61, 24.16, 6.23, 9.66],
      [6337, 6.44, 8.15, 4.35, 6.19, 9.64, 8.2, 21.73, 0.99, 23.63, 8.12, 9.51],
      [4711, 6.68, 9.72, 4.28, 6.31, 9.91, 8.81, 19.42, 1.56, 26.21, 7.29, 10.37],
      [6118, 7.3, 9.37, 5.08, 5.79, 9.52, 7.47, 22.91, 0.97, 24.49, 9.16, 9.28],
      [4973, 7.75, 10.78, 5.51, 6.07, 9.93, 8.59, 21.05, 1.53, 28.28, 8.12, 10.62],
      [8035, 8.13, 10.93, 6.8, 6.29, 11.5, 8.71, 24.88, 1.11, 33.05, 5.64, 10.34],
      [2273, 8.31, 11.87, 6.93, 6.52, 12.05, 9.62, 23.81, 1.26, 38.56, 5.91, 10.25],
      [8389, 7.44, 10.66, 7.42, 6.1, 11.31, 8.54, 24.24, 0.83, 31.61, 6.19, 10.93],
      [1998, 7.71, 12.43, 7.77, 6.71, 12.03, 9.95, 25.65, 1.31, 39.56, 6.56, 11.95],
      [8199, 6.52, 10.41, 7.59, 5.92, 10.88, 8.06, 23.82, 0.71, 28, 6.32, 10.75],
      [2320, 6.54, 12.58, 8.15, 6.9, 12.42, 10.57, 26.51, 1.72, 37.52, 6.93, 12.77],
      [4797, 5.76, 10.7, 7, 5.85, 10.75, 6.98, 22.88, 0.62, 24.07, 6.01, 9.75],
      [3621, 5.92, 13.53, 7.18, 6.31, 11.76, 8.42, 22.32, 0.98, 31.43, 6.09, 10.72],
      [4111, 5.78, 10.17, 7.03, 5.65, 11.07, 6.74, 23.36, 0.64, 23.43, 6.24, 9.76],
      [3872, 5.88, 13.42, 7.13, 6.43, 11.79, 7.86, 22.69, 0.97, 30.36, 6.1, 10.52],
      [3841, 5.72, 10.36, 7.33, 5.85, 10.9, 6.9, 24.58, 0.8, 24.51, 6.19, 9.91],
      [4309, 5.92, 13.19, 7.21, 6.54, 11.86, 8.41, 23.58, 0.86, 29.56, 6.48, 11.14],
    ],
    female: [
      [3167, 5.11, 3.51, 2.13, 4.87, 7.21, 4.94, 8.25, 1.22, 17.01, 2.17, 7.06],
      [6837, 5.02, 3.63, 2.1, 4.81, 7.26, 5.1, 7.69, 1.52, 17.56, 2.02, 7.09],
      [3798, 5.2, 4.27, 2.5, 5.15, 7.75, 6.17, 11.63, 1.09, 18.06, 2.84, 7.88],
      [6354, 5.37, 4.57, 2.67, 5.15, 7.94, 6.19, 10.16, 1.32, 18.45, 2.69, 7.79],
      [4549, 5.76, 5.51, 2.84, 5.68, 8.28, 7.66, 14.71, 1.01, 20.52, 3.72, 8.6],
      [6093, 6.1, 5.79, 3.37, 5.63, 8.89, 7.7, 12.32, 1.34, 20.85, 3.36, 8.59],
      [4651, 6.54, 6.8, 3.75, 5.59, 9.1, 7.54, 16.86, 1.22, 21.55, 4.44, 8.73],
      [5905, 6.69, 7.16, 3.68, 5.66, 9.28, 7.8, 14.06, 1.38, 22.81, 3.83, 9.16],
      [4344, 6.96, 7.22, 4.23, 5.74, 9.82, 7.41, 18.4, 1.21, 21.95, 5.29, 8.99],
      [6206, 7.06, 8.47, 4.06, 5.62, 9.96, 7.58, 15.38, 1.1, 23.7, 4.46, 9.42],
      [4071, 6.67, 8.11, 4.55, 5.46, 9.65, 6.71, 20.04, 0.81, 22.68, 6.11, 8.45],
      [6911, 6.61, 9.02, 4.63, 5.44, 10.13, 7.12, 15.44, 1.32, 25.14, 4.74, 9.35],
      [6021, 5.79, 8.36, 4.73, 5.8, 11.06, 7.35, 20.5, 0.86, 27.4, 4.25, 11.02],
      [3803, 5.9, 9.08, 4.91, 5.66, 11.56, 7.7, 15.45, 1.3, 29.97, 3.97, 10.47],
      [6045, 5.46, 7.6, 4.73, 5.8, 10.85, 7.1, 19.6, 0.95, 25.56, 4.35, 10.68],
      [3787, 5.45, 8.78, 5, 6.09, 11.56, 7.7, 14.93, 0.99, 28.67, 3.61, 11.14],
      [5622, 5.35, 7.51, 4.73, 6.06, 10.36, 6.95, 19.69, 0.87, 24.74, 4.49, 10.5],
      [4285, 5.31, 8.75, 4.94, 6.25, 11.46, 7.91, 15.61, 1.22, 27.21, 3.76, 11.57],
      [2385, 5.29, 7.66, 4.49, 6.2, 10.16, 6.21, 18.03, 0.75, 21.53, 4.34, 10.52],
      [4988, 5.39, 9.36, 4.59, 6.21, 10.68, 6.64, 14.32, 0.91, 24.7, 3.75, 10.76],
      [2109, 5.3, 7.6, 4.72, 5.85, 9.79, 6.11, 19.58, 1.02, 21.27, 4.45, 10.37],
      [5095, 5.4, 8.67, 4.58, 6.35, 10.74, 6.69, 14.44, 1.03, 23.62, 3.75, 11.08],
      [1916, 5.33, 7.19, 4.54, 5.73, 9.41, 5.79, 19.82, 0.79, 20.65, 4.58, 9.99],
      [5254, 5.33, 9.63, 4.61, 6.26, 10.55, 6.9, 14.92, 1.04, 24.2, 3.97, 11.2],
    ]
  },
  /**
   * 運動・スポーツの実施状況
   */
  q3: {
    male: [
      [2280, 4.96, 3.66, 2.67, 5.04, 7.3, 5.81, 12.4, 1.26, 18.24, 3.77, 7.15],
      [5581, 5.07, 3.66, 2.46, 5.09, 7.3, 5.54, 10.47, 1.29, 18.92, 3.25, 7.09],
      [1305, 5.18, 3.92, 2.23, 5.22, 7.1, 5.6, 8.84, 1.76, 20.28, 3.02, 7.08],
      [1248, 5.22, 3.99, 2.2, 5.27, 7.54, 5.94, 8.51, 1.94, 20.17, 2.88, 6.98],
      [3083, 5.2, 4.33, 2.52, 5.51, 7.82, 7.05, 16.18, 1.15, 18.71, 4.94, 7.6],
      [5466, 5.32, 5.43, 2.71, 5.46, 7.66, 6.73, 13.76, 1.45, 19.73, 4.38, 7.8],
      [1159, 5.49, 5.19, 2.58, 5.52, 8.19, 6.8, 12.71, 1.8, 20.53, 4.56, 8.06],
      [1037, 5.62, 5.53, 2.84, 5.86, 8.37, 7.25, 11.44, 1.63, 21.65, 3.86, 8.14],
      [4471, 5.65, 6.09, 3.45, 6.16, 8.47, 8.28, 18.88, 1.04, 20.83, 6.03, 8.58],
      [4386, 5.61, 6.22, 3.43, 6.01, 8.15, 8.04, 15.91, 1.08, 21.34, 5.49, 8.26],
      [1071, 5.88, 6.37, 3.02, 6.08, 8.71, 8.3, 16.14, 1.8, 22.21, 5.18, 8.95],
      [1135, 6, 7.59, 3.23, 6.23, 8.71, 8.04, 14.3, 1.96, 24.46, 4.73, 9.09],
      [5491, 5.81, 6.98, 3.75, 6.07, 8.96, 8.29, 20.01, 1.29, 21.69, 7.02, 8.64],
      [3788, 5.95, 7.78, 3.78, 6.1, 9.2, 8.14, 17.35, 1.36, 23, 6.47, 9.04],
      [932, 6.19, 8.31, 3.77, 5.94, 9.42, 8.33, 17.39, 1.56, 24.18, 5.96, 9.48],
      [835, 6.41, 8.77, 4.32, 6.47, 9.7, 8.57, 15.32, 2.53, 26.35, 5.5, 9.57],
      [5810, 6.36, 8.16, 4.32, 6.01, 9.61, 8.13, 21.62, 1.17, 22.96, 8.01, 9.1],
      [3642, 6.69, 9.29, 4.21, 6.09, 9.52, 8.17, 18.26, 1.13, 23.81, 7.41, 9.43],
      [837, 6.76, 9.78, 4.28, 6.16, 10.29, 8.6, 17.43, 1.55, 28.07, 6.44, 9.95],
      [737, 6.98, 10.6, 4.39, 6.48, 10.2, 8.98, 17.35, 1.79, 29.42, 6.3, 10.78],
      [5749, 7.24, 9.19, 5.17, 5.64, 9.45, 7.36, 22.38, 1.09, 23.54, 8.92, 8.69],
      [3736, 7.75, 10.5, 5.3, 5.72, 9.73, 7.99, 19.41, 1.26, 26.92, 8.24, 9.89],
      [895, 8.12, 11.78, 5.24, 6.09, 10.17, 8.32, 18.42, 1.54, 28.07, 6.94, 9.89],
      [699, 7.54, 11.1, 5.41, 6.12, 10.21, 8.86, 19.17, 1.52, 30.67, 7.27, 11.13],
      [7697, 8.09, 10.65, 6.79, 6.17, 11.46, 8.46, 24.67, 0.96, 32.15, 5.61, 10.16],
      [1547, 8.42, 12.05, 6.82, 5.97, 11.48, 9.08, 20.79, 1.14, 35.93, 5.68, 9.16],
      [565, 8.64, 13.34, 6.68, 6.3, 12.2, 9.65, 20.88, 2.34, 39.07, 4.88, 9.31],
      [445, 8.23, 12.95, 7.09, 6.59, 12.68, 10.04, 19.63, 1.33, 43.96, 4.98, 9.53],
      [8174, 7.4, 10.5, 7.43, 6.03, 11.18, 8.3, 23.99, 0.79, 30.86, 6.14, 10.77],
      [1184, 7.76, 12.36, 7.48, 6.13, 11.82, 9.41, 22.73, 1.09, 40.15, 6.3, 10.74],
      [489, 7.58, 12.63, 7.33, 6.34, 12.21, 9.65, 21.96, 1.48, 36.59, 5.72, 10.13],
      [486, 8.12, 13.69, 8.02, 6.47, 12.5, 10.48, 21.14, 1.59, 40.95, 5.46, 10.76],
      [7563, 6.51, 10.24, 7.58, 5.8, 10.73, 7.69, 23.47, 0.66, 26.31, 6.25, 10.37],
      [1596, 6.54, 11.97, 7.66, 6.22, 11.68, 9.85, 24.08, 1.39, 36.95, 6.35, 11.6],
      [639, 6.53, 13.21, 7.74, 6.66, 12.26, 10.11, 23.7, 1.07, 36.54, 6.12, 11.37],
      [662, 6.74, 12.95, 8.2, 6.73, 12.81, 11.06, 23.73, 2.29, 40.26, 6.59, 12.32],
      [4784, 5.74, 10.73, 7.07, 5.8, 10.7, 7.06, 22.75, 0.62, 24.12, 6.01, 9.63],
      [1520, 5.96, 13.36, 7.09, 6.26, 11.43, 8.28, 22.52, 0.81, 30.79, 6.06, 10.53],
      [899, 6.03, 14.32, 6.81, 5.98, 11.36, 8.06, 21.16, 1.11, 27.5, 5.53, 9.73],
      [1037, 5.84, 13.42, 6.87, 6.43, 12.13, 8.63, 21.37, 1.07, 32.76, 6, 10.64],
      [4263, 5.77, 10.02, 6.97, 5.57, 10.98, 6.74, 23.32, 0.62, 23.47, 6.28, 9.63],
      [1357, 5.91, 13.77, 7.06, 6.18, 11.2, 7.57, 22.57, 0.79, 30.45, 5.97, 10],
      [998, 5.79, 13.71, 6.84, 6.03, 11.71, 7.11, 21.38, 0.73, 26.87, 5.85, 9.77],
      [1332, 5.94, 13.51, 7.36, 6.73, 12.31, 8.4, 21.7, 1.27, 31.73, 5.87, 10.73],
      [3849, 5.75, 10.12, 7.45, 5.85, 10.92, 6.98, 24.58, 0.6, 24.43, 6.27, 10],
      [1513, 5.88, 12.85, 7.07, 6.33, 11.37, 8.15, 24.41, 0.81, 29.13, 6.36, 10.66],
      [1151, 5.89, 14.13, 6.82, 6.21, 11.51, 7.74, 22.29, 0.79, 27.84, 6.12, 10.44],
      [1607, 5.96, 13.18, 7.11, 6.65, 12.29, 8.93, 23.27, 1.23, 31.01, 6.45, 11.46],
    ],
    female: [
      [1546, 5.05, 3.4, 2.16, 4.81, 6.98, 5.12, 8.96, 1.21, 17.23, 2.34, 7.14],
      [5319, 5.03, 3.59, 2.11, 4.79, 7.15, 4.97, 7.83, 1.43, 16.98, 2.06, 6.96],
      [1424, 5.04, 3.61, 2, 4.91, 7.42, 5.08, 6.8, 1.46, 17.52, 1.87, 6.91],
      [1711, 5.08, 3.75, 2.09, 4.8, 7.57, 5.13, 7.3, 1.56, 18.07, 1.88, 6.99],
      [2016, 5.36, 4.22, 2.45, 5.36, 7.78, 6.51, 12.57, 1.32, 18.12, 2.99, 8.08],
      [5399, 5.2, 4.41, 2.52, 5.1, 7.89, 6.02, 10.51, 1.1, 17.9, 2.68, 7.57],
      [1385, 5.54, 4.82, 3.02, 4.82, 7.95, 5.95, 9.08, 1.36, 18.09, 2.85, 7.6],
      [1355, 5.45, 4.62, 2.62, 5.19, 7.75, 6.34, 9.11, 1.43, 19.36, 2.29, 7.7],
      [3162, 5.99, 5.59, 3.23, 5.82, 8.78, 7.85, 15.27, 1.24, 19.82, 3.71, 8.75],
      [5002, 5.85, 5.58, 2.77, 5.5, 8.48, 7.5, 12.54, 1.13, 20.56, 3.47, 8.26],
      [1182, 5.92, 5.82, 3.42, 5.35, 8.38, 7.43, 11.37, 1.05, 20.56, 3.16, 8.1],
      [1289, 6.34, 6.11, 3.9, 5.46, 8.71, 7.63, 11.64, 1.45, 21.69, 3.37, 8.4],
      [4043, 6.62, 6.72, 3.57, 5.58, 9.12, 7.73, 17.3, 0.95, 21.21, 4.38, 8.68],
      [4605, 6.6, 6.95, 3.65, 5.46, 9.2, 7.4, 13.69, 1.17, 21.75, 3.87, 8.6],
      [1056, 6.51, 7.24, 3.59, 5.6, 9.02, 7.47, 12.64, 2.24, 22.51, 3.42, 8.71],
      [849, 6.93, 8.14, 4.6, 5.95, 9.3, 7.98, 12.03, 1.73, 26.4, 4.42, 9.71],
      [4070, 6.84, 7.18, 4.15, 5.71, 9.75, 7.17, 18.45, 0.81, 22.04, 5.19, 8.59],
      [4474, 7.07, 8.32, 4.09, 5.46, 9.69, 7.35, 14.71, 1.22, 22.85, 4.63, 9.04],
      [1172, 7.15, 8.37, 4.06, 5.46, 10.12, 7.43, 13.33, 1.52, 22.05, 4.02, 8.79],
      [817, 7.41, 9.05, 4.23, 5.79, 10.94, 8.32, 13.45, 1.26, 25.1, 4.06, 10.14],
      [3742, 6.65, 7.91, 4.51, 5.43, 9.65, 6.61, 20.14, 1.05, 22.89, 6.01, 8.07],
      [4782, 6.64, 8.98, 4.7, 5.22, 9.9, 6.79, 14.63, 0.85, 23.72, 4.95, 8.76],
      [1479, 6.52, 9.15, 4.42, 5.14, 9.85, 6.92, 14.03, 1.84, 24.82, 4.27, 9.09],
      [955, 6.71, 9.27, 4.59, 5.45, 10.82, 7.73, 13.73, 1.3, 26.79, 4.13, 9.76],
      [5780, 5.74, 8.27, 4.78, 5.77, 11.05, 7.22, 20.48, 0.86, 26.84, 4.36, 10.87],
      [2211, 5.88, 9.07, 4.64, 5.44, 11.12, 7.29, 15.41, 0.91, 28.46, 3.87, 9.94],
      [1059, 5.99, 9.17, 5.13, 5.53, 11.61, 7.38, 13.49, 1.18, 30.89, 3.13, 9.81],
      [729, 6.03, 9.33, 4.8, 5.52, 11.61, 8.7, 13.68, 2.02, 32.46, 4.03, 10.43],
      [5973, 5.43, 7.52, 4.8, 5.78, 10.89, 6.96, 19.52, 0.85, 24.8, 4.33, 10.53],
      [1786, 5.54, 8.66, 4.86, 5.92, 10.94, 7.46, 15.42, 1.13, 29.23, 3.68, 10.61],
      [1053, 5.38, 9.43, 4.75, 5.7, 11.56, 7.52, 13.21, 0.94, 27.77, 3.48, 10.27],
      [966, 5.52, 8.58, 4.94, 5.9, 11.81, 8.06, 13.29, 1.15, 28.97, 3.31, 10.73],
      [5190, 5.36, 7.44, 4.76, 5.95, 10.23, 6.79, 19.72, 1.04, 24.08, 4.53, 10.27],
      [2082, 5.23, 8.48, 4.96, 6.11, 11.06, 7.82, 17.01, 0.92, 26.95, 4.04, 11.38],
      [1264, 5.49, 9.14, 4.69, 6.19, 11.45, 7.6, 14.62, 0.97, 27.06, 3.48, 11.09],
      [1305, 5.26, 8.63, 4.82, 6.28, 11.74, 8.07, 14.11, 1.2, 28.2, 3.52, 11.48],
      [2353, 5.41, 7.67, 4.53, 6.23, 10.16, 6.32, 18.07, 0.77, 22.36, 4.46, 10.72],
      [1693, 5.23, 8.67, 4.49, 6.04, 10.32, 6.4, 14.34, 0.78, 23.43, 3.68, 10.08],
      [1392, 5.27, 10.08, 4.72, 6.03, 10.51, 6.46, 13.47, 0.86, 23.7, 3.66, 10.58],
      [1910, 5.46, 9.35, 4.49, 6.26, 10.94, 6.72, 14.17, 1.01, 24.98, 3.63, 10.83],
      [2140, 5.22, 7.11, 4.68, 5.84, 9.88, 6.1, 19.68, 0.91, 21.86, 4.6, 10.79],
      [1426, 5.46, 9.47, 4.65, 6.4, 10.46, 6.45, 15.73, 0.98, 23.4, 3.89, 10.85],
      [1427, 5.39, 8.87, 4.65, 6.07, 10.34, 6.53, 13.71, 0.94, 21.91, 3.67, 10.43],
      [2194, 5.43, 8.37, 4.54, 6.45, 10.86, 6.8, 13.24, 1.2, 23.85, 3.63, 11.08],
      [1969, 5.35, 7.35, 4.6, 5.78, 9.4, 5.98, 20.03, 0.81, 21.09, 4.62, 10.19],
      [1260, 5.32, 8.74, 4.57, 6.12, 10.28, 6.58, 14.93, 0.92, 23.42, 4.07, 11.01],
      [1452, 5.34, 10.44, 4.63, 6.04, 10.13, 6.51, 14.5, 1, 23.56, 3.87, 10.92],
      [2471, 5.3, 9.47, 4.54, 6.29, 10.76, 7.09, 14.82, 1.11, 24.75, 3.93, 11.07],
    ]
  },
  /**
   * 1日の運動・スポーツの実施時間
   */
  q4: {
    male: [
      [2201, 5.28, 3.88, 2.24, 5.23, 7.37, 5.75, 9.27, 1.77, 20.52, 3.01, 7.17],
      [3986, 5.02, 3.68, 2.56, 5.15, 7.23, 5.6, 9.91, 1.44, 19.09, 3.19, 7.15],
      [3265, 5.06, 3.73, 2.52, 5.06, 7.38, 5.59, 11.39, 1.28, 18.9, 3.32, 7.08],
      [962, 4.95, 3.64, 2.33, 5.11, 7.22, 6.1, 13.02, 1.17, 17.44, 4.2, 7.3],
      [2048, 5.53, 5.33, 2.92, 5.82, 8.43, 7.04, 12.54, 1.49, 21.17, 4.21, 8.26],
      [3490, 5.25, 5.15, 2.68, 5.55, 7.72, 6.91, 13.4, 1.73, 20.03, 4.33, 7.8],
      [3525, 5.27, 5.27, 2.55, 5.42, 7.71, 6.62, 14.78, 1.25, 19.03, 4.36, 7.72],
      [1682, 5.37, 4.44, 2.53, 5.31, 7.6, 7.19, 16.77, 0.97, 18.58, 5.27, 7.46],
      [2434, 5.99, 6.95, 3.69, 6.19, 8.55, 8.33, 15.09, 1.78, 22.71, 4.95, 8.84],
      [2872, 5.63, 6.44, 3.47, 6.05, 8.37, 8.07, 15.68, 1.25, 22.44, 5.15, 8.56],
      [2899, 5.66, 6.05, 3.21, 5.96, 8.17, 8.01, 17.4, 1, 20.73, 5.41, 8.15],
      [2859, 5.54, 5.95, 3.19, 6.19, 8.49, 8.24, 19.09, 0.97, 20.47, 6.37, 8.55],
      [2076, 6.2, 8.19, 3.79, 6.39, 9.49, 8.66, 16.74, 1.77, 25.13, 5.68, 9.7],
      [2583, 5.92, 7.92, 3.84, 5.95, 9.33, 8.06, 17.33, 1.42, 23.34, 6.08, 8.85],
      [2820, 5.9, 7.54, 3.97, 5.94, 8.97, 8.23, 18.87, 1.33, 22.56, 6.3, 8.81],
      [3567, 5.81, 6.82, 3.62, 6.19, 8.95, 8.09, 20, 1.43, 20.92, 7.24, 8.46],
      [1841, 6.77, 9.83, 4.5, 6.22, 10.06, 8.88, 17.84, 1.64, 28.3, 6.95, 10.27],
      [2342, 6.62, 9.24, 4.06, 6.18, 9.79, 8.21, 18.11, 1.17, 23.42, 6.9, 9.38],
      [2989, 6.59, 9.11, 4.25, 6.08, 9.57, 8.07, 20.43, 1.06, 24.43, 7.11, 9.45],
      [3855, 6.35, 7.88, 4.32, 5.96, 9.52, 7.93, 21.29, 1.19, 22.18, 8.08, 8.7],
      [1778, 7.95, 11.38, 5.7, 6.07, 10.24, 8.84, 19.24, 1.93, 29.92, 7.41, 10.83],
      [2339, 7.74, 10.43, 5.39, 5.83, 9.86, 7.95, 19.29, 1.33, 27.18, 7.69, 9.88],
      [3026, 7.42, 9.87, 5.09, 5.74, 9.59, 7.66, 22.01, 1.02, 25.18, 8.13, 9.45],
      [3936, 7.21, 9.17, 5.04, 5.55, 9.35, 7.34, 22.1, 0.83, 23, 9.17, 8.43],
      [1081, 8.32, 12.75, 6.92, 6.36, 11.92, 9.81, 21.06, 1.33, 41.06, 5.8, 9.72],
      [1436, 8.43, 12, 6.62, 6.13, 11.54, 9.31, 22.31, 1.73, 37.87, 5.39, 9.58],
      [3530, 8.12, 11.03, 6.7, 6.06, 11.5, 8.22, 22.92, 1.01, 32.32, 5.41, 9.72],
      [4209, 8.09, 10.47, 6.87, 6.14, 11.43, 8.41, 24.86, 0.84, 31.24, 5.5, 9.93],
      [1093, 7.91, 12.86, 7.57, 6.44, 12.29, 10.36, 23.81, 1.39, 39.66, 6.49, 11.37],
      [1132, 7.61, 12.53, 7.48, 6.35, 11.36, 9.49, 24.13, 1.22, 40.04, 5.99, 10.92],
      [3761, 7.4, 11, 7.38, 5.93, 11.37, 8.5, 23.59, 0.84, 31.68, 5.89, 10.49],
      [4351, 7.42, 10.04, 7.43, 5.9, 11.02, 7.92, 23.24, 0.72, 29.37, 6.07, 10.38],
      [1368, 6.59, 12.82, 7.92, 6.84, 12.73, 10.69, 25.5, 1.05, 37.19, 6.42, 12.36],
      [1639, 6.54, 11.69, 7.91, 6.27, 11.28, 9.59, 24.76, 1.88, 35.3, 6.39, 11.68],
      [3523, 6.51, 10.45, 7.36, 5.89, 11.01, 8.21, 23.74, 0.74, 28.28, 6.1, 10.68],
      [3929, 6.52, 10.19, 7.68, 5.68, 10.58, 7.33, 22.94, 0.61, 25.9, 6.15, 9.85],
      [2001, 5.94, 13.53, 6.9, 6.35, 12.01, 8.47, 22.09, 1.07, 31.14, 5.91, 10.62],
      [1364, 5.91, 13.31, 7.47, 6.15, 11.13, 8.14, 22.68, 0.83, 29.69, 6.02, 10.48],
      [1702, 5.76, 11.78, 6.87, 5.93, 11.6, 7.69, 23.06, 0.7, 26.05, 5.79, 10.06],
      [3176, 5.76, 10.45, 7.02, 5.79, 10.34, 6.85, 22.09, 0.57, 23.47, 5.99, 9.29],
      [2293, 5.83, 13.56, 7.22, 6.51, 12.07, 8.05, 22.26, 1.1, 30.83, 5.99, 10.59],
      [1348, 5.95, 13.48, 7.11, 6.28, 11.53, 7.28, 22.73, 0.81, 28.48, 5.96, 10.16],
      [1509, 5.9, 11.35, 6.78, 5.81, 10.87, 7.13, 24.1, 0.62, 24.72, 5.99, 9.53],
      [2802, 5.71, 9.55, 6.97, 5.42, 10.87, 6.57, 22.38, 0.59, 22.98, 6.13, 9.33],
      [2712, 6.01, 13, 7.22, 6.44, 12.07, 8.45, 23.23, 1.08, 30.05, 6.57, 11.18],
      [1482, 5.86, 13.52, 7.12, 6.52, 11.52, 8.19, 24.15, 0.79, 27.61, 6.21, 10.97],
      [1409, 5.72, 11.1, 7.13, 6.43, 11.33, 7.81, 25.27, 0.64, 27.33, 6.23, 10.41],
      [2517, 5.67, 9.92, 7.36, 5.61, 10.64, 6.61, 24.09, 0.59, 23.62, 6.03, 9.59],
    ],
    female: [
      [2647, 5.03, 3.69, 2.08, 4.91, 7.44, 5.11, 7.18, 1.74, 17.63, 1.96, 7.04],
      [4103, 5.09, 3.58, 2.13, 4.77, 7.12, 4.92, 7.68, 1.39, 17.21, 2.03, 7.03],
      [2737, 5.01, 3.51, 2.07, 4.77, 7.27, 5.11, 8.03, 1.16, 17.01, 2.09, 6.96],
      [512, 4.94, 3.65, 2.15, 5.06, 7.04, 5.35, 10.47, 1.19, 19.09, 2.56, 7.66],
      [2538, 5.49, 4.75, 2.74, 5.1, 7.8, 6.31, 9.36, 1.37, 18.65, 2.36, 7.64],
      [3823, 5.33, 4.4, 2.43, 5.04, 7.96, 6.09, 10.41, 1.27, 18.23, 2.77, 7.67],
      [2966, 5.16, 4.33, 2.7, 5.16, 7.87, 6.07, 10.86, 1.12, 17.8, 2.72, 7.73],
      [827, 5.17, 4.25, 2.49, 5.54, 7.5, 6.43, 13.65, 0.96, 18.02, 3.39, 8.14],
      [3274, 6.16, 6.01, 3.23, 5.38, 8.88, 7.59, 11.47, 1.37, 21.34, 3.3, 8.43],
      [3443, 5.81, 5.92, 3.03, 5.5, 8.45, 7.57, 12.49, 1.04, 20.02, 3.38, 8.25],
      [2528, 5.77, 5.13, 3.16, 5.78, 8.45, 7.43, 13.68, 0.98, 20.19, 3.25, 8.43],
      [1389, 6.13, 5.12, 3.18, 6.03, 8.59, 8.11, 16.72, 1.47, 20.23, 4.39, 8.83],
      [2838, 6.68, 7.56, 4.25, 5.69, 9.11, 7.79, 12.78, 1.33, 23.61, 3.79, 9.03],
      [3361, 6.59, 6.82, 3.31, 5.44, 9.22, 7.52, 13.82, 1.37, 21.8, 3.53, 8.57],
      [2554, 6.65, 6.84, 3.48, 5.39, 9.15, 7.34, 15.06, 1.42, 21.65, 4.2, 8.58],
      [1802, 6.54, 6.65, 3.77, 5.73, 9.14, 7.61, 18.68, 0.87, 20.81, 4.85, 8.64],
      [2793, 7.38, 8.7, 4.06, 5.66, 10.33, 7.72, 13.52, 1.33, 24.19, 4.38, 9.3],
      [3042, 6.94, 8.23, 4.12, 5.4, 9.66, 7.36, 14.85, 0.98, 22.51, 4.08, 8.94],
      [2729, 6.86, 7.57, 4.13, 5.49, 9.71, 7.14, 16.09, 1.24, 21.89, 4.78, 8.74],
      [1969, 6.85, 7.06, 4.11, 5.63, 9.72, 7.12, 19.37, 0.8, 21.22, 5.66, 8.25],
      [2880, 6.72, 9.44, 4.55, 5.33, 10.26, 7.47, 14.07, 1.44, 25.97, 4.38, 9.37],
      [3101, 6.61, 8.93, 4.74, 5.28, 9.85, 6.74, 14.85, 1.28, 23.99, 4.64, 8.92],
      [2840, 6.6, 8.11, 4.45, 5.23, 9.86, 6.57, 16.37, 0.81, 22.86, 5.18, 8.46],
      [2136, 6.59, 8.03, 4.54, 5.46, 9.54, 6.49, 20.99, 0.79, 21.85, 6.4, 7.58],
      [2096, 5.88, 9.17, 4.84, 5.25, 11.64, 7.95, 14.16, 1.48, 30.48, 3.47, 10.14],
      [1884, 5.96, 9.16, 4.96, 5.54, 11.45, 7.43, 15.44, 0.99, 29.02, 3.91, 10.26],
      [3104, 5.69, 8.44, 4.61, 5.81, 10.8, 7.17, 17.77, 0.85, 27.42, 4.14, 10.27],
      [2696, 5.83, 8.06, 4.8, 5.76, 11.11, 6.95, 22.01, 0.82, 26.36, 4.51, 10.88],
      [2245, 5.37, 8.79, 4.91, 6.01, 11.85, 7.83, 13.91, 1.1, 28.72, 3.46, 10.83],
      [1603, 5.62, 8.73, 4.8, 5.86, 10.86, 7.35, 16.03, 0.89, 27.58, 3.69, 10.85],
      [3060, 5.27, 7.71, 4.66, 5.68, 10.57, 7.09, 18.09, 0.86, 25.25, 4.12, 10.36],
      [2869, 5.6, 7.46, 4.9, 5.76, 11.03, 6.79, 20.2, 0.97, 25.04, 4.46, 10.2],
      [2755, 5.27, 8.92, 4.89, 6.37, 11.53, 7.85, 15.51, 1.04, 27.27, 3.81, 11.77],
      [1927, 5.36, 8.51, 4.9, 6.05, 10.96, 7.69, 17.16, 1.35, 28.26, 3.91, 11.21],
      [2808, 5.35, 7.8, 4.69, 5.89, 10.22, 7.03, 18.89, 0.98, 24.5, 4.25, 10.44],
      [2351, 5.36, 6.86, 4.76, 5.99, 10.49, 6.6, 20.04, 0.72, 22.66, 4.58, 9.74],
      [3436, 5.42, 9.4, 4.51, 6.26, 10.57, 6.57, 14.07, 0.93, 24.29, 3.59, 10.71],
      [1563, 5.2, 9.28, 4.52, 5.87, 10.73, 6.38, 14.58, 0.83, 23.34, 3.68, 10.22],
      [1078, 5.24, 7.75, 4.5, 6.27, 10.28, 6.33, 15.74, 0.74, 22.79, 4.01, 10.36],
      [1271, 5.49, 7.46, 4.63, 6.08, 10.17, 6.28, 18.69, 0.74, 21.28, 4.64, 10.23],
      [3731, 5.37, 8.49, 4.49, 6.37, 10.71, 6.67, 13.75, 1.03, 22.95, 3.69, 10.91],
      [1361, 5.5, 9.32, 4.79, 6.15, 10.67, 6.49, 15.96, 0.94, 23.85, 3.88, 10.97],
      [931, 5.31, 7.72, 4.76, 6.24, 10.14, 6.23, 17.74, 1.23, 23.13, 4.05, 10.81],
      [1165, 5.19, 7.12, 4.68, 5.67, 9.55, 6.21, 20, 0.96, 21.27, 4.64, 10.45],
      [3943, 5.31, 9.42, 4.56, 6.27, 10.54, 6.9, 14.74, 1.07, 24.04, 3.86, 11.14],
      [1322, 5.35, 9.64, 4.61, 5.99, 10.25, 6.66, 15.35, 0.91, 24.52, 4.2, 11.03],
      [839, 5.4, 8.24, 4.58, 6.3, 10.32, 6.36, 18.75, 0.92, 22.63, 4.4, 10.99],
      [1050, 5.23, 7.06, 4.62, 5.45, 8.9, 5.65, 20.11, 0.72, 19.55, 4.58, 9.24],
    ]
  },
  /**
   * 朝食の有無
   */
  q5: {
    male: [
      [9525, 5.09, 3.69, 2.5, 5.15, 7.31, 5.69, 10.86, 1.46, 19.38, 3.4, 7.31],
      [772, 5.13, 4.12, 2.24, 5.38, 7.38, 5.92, 9.89, 1.57, 20.12, 3.42, 7.39],
      [117, 5.09, 4.36, 2.02, 4.91, 6.72, 6.59, 11.97, 1.63, 21.3, 3.7, 7.32],
      [9637, 5.32, 5.11, 2.68, 5.62, 7.86, 7, 14.96, 1.39, 20.19, 4.73, 8.19],
      [973, 5.48, 5.25, 2.72, 5.76, 7.74, 6.99, 14.55, 2.07, 19.58, 4.54, 8.06],
      [133, 5.38, 4.98, 2.36, 5.68, 7.74, 7.75, 16.31, 1.46, 21.72, 4.57, 8.84],
      [9423, 5.71, 6.21, 3.45, 6.27, 8.46, 8.35, 18.17, 1.29, 22.12, 5.87, 9.11],
      [1430, 5.72, 7.1, 3.23, 6.41, 8.21, 8.43, 17.28, 1.35, 21.88, 5.94, 9.03],
      [211, 5.61, 6.45, 3.19, 5.96, 8.47, 9, 16.62, 1.59, 24.66, 5.53, 8.71],
      [9379, 5.92, 7.4, 3.86, 6.3, 9.2, 8.59, 20.2, 1.54, 23.31, 7.04, 9.7],
      [1463, 6.02, 8.24, 3.83, 6.69, 9.36, 8.65, 19.36, 1.34, 24.84, 6.72, 9.67],
      [201, 6.25, 8.22, 3.64, 6.57, 8.65, 9.11, 19.19, 1.4, 28.07, 7.26, 10.34],
      [9180, 6.51, 8.76, 4.32, 6.37, 9.72, 8.59, 22.03, 1.3, 25.06, 8.17, 10.35],
      [1623, 6.65, 9.46, 4.4, 6.54, 10.06, 8.85, 21.69, 1.26, 25.89, 7.81, 10.4],
      [223, 7.29, 8.64, 5.42, 7.72, 10.32, 10.21, 21.65, 1.24, 26.45, 7.99, 10.81],
      [9167, 7.48, 9.86, 5.22, 6.08, 9.7, 8.28, 23.71, 1.24, 26.62, 9.22, 10.45],
      [1598, 7.59, 10.78, 5.81, 6.44, 10.09, 8.25, 22.72, 1.46, 27.19, 8.89, 10.63],
      [314, 7.65, 10.76, 5.48, 6.78, 10.57, 8.5, 22.37, 1.42, 32.65, 9.02, 11.47],
      [8472, 8.17, 10.95, 6.8, 6.4, 11.49, 8.9, 25.38, 1.17, 33.87, 5.88, 10.61],
      [1379, 8.15, 12.04, 7.1, 6.51, 12.3, 9.38, 23.84, 1.07, 37.7, 5.72, 10.26],
      [327, 8.57, 12.13, 7.68, 7, 13.08, 10.82, 25.79, 1.14, 39.86, 6.22, 11.43],
      [8503, 7.46, 10.71, 7.4, 6.3, 11.39, 8.88, 25.63, 0.95, 32.86, 6.42, 11.51],
      [1420, 7.61, 12.49, 7.89, 6.4, 11.92, 9.24, 25.77, 1, 36.74, 6.62, 11.53],
      [351, 7.66, 11.2, 8.67, 6.86, 12.32, 10.65, 25.97, 0.92, 37.85, 6.63, 12.16],
      [8312, 6.55, 10.6, 7.71, 6.24, 11.15, 8.68, 25.56, 1.07, 29.83, 6.71, 11.65],
      [1569, 6.48, 11.87, 8.11, 6.53, 12.01, 9.84, 27.05, 0.89, 34.08, 6.65, 12.49],
      [509, 6.66, 12.5, 8.4, 7.01, 12.12, 9.66, 26.27, 0.98, 38.19, 6.94, 12.35],
      [6295, 5.78, 11.68, 7.12, 6.27, 11.19, 7.83, 24.66, 0.81, 27.67, 6.39, 11.09],
      [1334, 6.22, 13.42, 7.25, 6.53, 11.7, 8.39, 24.62, 0.88, 28.45, 6.24, 11.11],
      [618, 5.69, 12.5, 7.33, 6.42, 12.15, 8.74, 23.89, 0.85, 30.18, 6.48, 10.86],
      [5861, 5.83, 11.47, 7.13, 6.35, 11.56, 7.42, 26.67, 0.85, 26.86, 6.67, 11.49],
      [1371, 6.08, 12.98, 7.56, 6.58, 11.55, 8.21, 26.28, 0.91, 31.6, 6.37, 11.43],
      [725, 5.55, 12.72, 7.24, 6.86, 12.23, 8.62, 25.07, 0.79, 29.1, 6.27, 10.97],
      [5739, 5.81, 11.45, 7.44, 6.52, 11.48, 7.81, 27.48, 0.87, 26.89, 6.87, 11.88],
      [1468, 5.96, 13.08, 7.25, 6.86, 12.05, 8.72, 26.97, 0.82, 31.23, 6.67, 11.9],
      [915, 5.87, 13.09, 7.53, 6.98, 12.26, 8.76, 26.25, 0.9, 31.64, 6.55, 11.82],
    ],
    female: [
      [9088, 5.04, 3.56, 2.09, 4.85, 7.26, 5.03, 7.94, 1.35, 17.4, 2.07, 7.11],
      [805, 5.09, 3.94, 2.34, 4.82, 7.19, 5.2, 7.06, 2.18, 18.06, 2.11, 7.14],
      [107, 5.54, 4.12, 2.12, 4.32, 5.95, 5.99, 8.79, 1.51, 18.12, 1.88, 6.91],
      [9168, 5.31, 4.39, 2.44, 5.18, 7.89, 6.2, 10.89, 1.25, 18.35, 2.77, 7.93],
      [881, 5.34, 4.95, 3.47, 5.4, 7.7, 6.24, 10.63, 1.21, 19.21, 2.73, 7.98],
      [108, 5.45, 5.71, 5.85, 4.96, 8.21, 6.48, 10.76, 1.14, 17.95, 2.88, 7.23],
      [9249, 5.93, 5.58, 3.16, 5.69, 8.61, 7.68, 13.75, 1.19, 20.88, 3.6, 8.78],
      [1265, 6.1, 6.12, 3.15, 5.72, 8.9, 7.98, 12.72, 1.37, 20.87, 3.32, 8.48],
      [122, 6.73, 7.1, 3.03, 5.99, 8.36, 8.78, 13.31, 1.31, 19.11, 2.97, 9.27],
      [9128, 6.61, 6.84, 3.76, 5.66, 9.22, 7.71, 15.9, 1.3, 22.28, 4.2, 9.15],
      [1281, 6.76, 7.63, 3.42, 6.11, 9.32, 8.03, 15.16, 1.1, 23.94, 3.98, 9.51],
      [139, 6.54, 10.1, 3.75, 5.97, 8.47, 8.79, 15.48, 3.19, 27.51, 3.85, 10.19],
      [8804, 6.99, 7.8, 4.09, 5.74, 9.93, 7.55, 17.62, 1.18, 22.99, 4.91, 9.5],
      [1548, 7.17, 8.84, 4.38, 5.99, 9.8, 7.72, 15.64, 1.02, 24.77, 5.06, 9.48],
      [179, 7.36, 8.45, 4.21, 6.3, 11.35, 8.61, 16.43, 1.2, 26.1, 4, 10.53],
      [8860, 6.69, 8.55, 4.48, 5.57, 9.89, 7.1, 18.75, 1.19, 24.36, 5.47, 9.4],
      [1809, 6.4, 9.06, 4.86, 5.83, 10.37, 7.41, 17.71, 1.11, 25.98, 5.53, 9.6],
      [289, 6.43, 9.81, 6.63, 5.82, 11.19, 7.25, 16.41, 1.04, 30.82, 5.25, 9.9],
      [7564, 5.91, 8.49, 4.77, 5.85, 11.12, 7.42, 20.03, 1.1, 28.71, 4.12, 11.44],
      [1832, 5.53, 9.12, 4.95, 6.05, 11.86, 8.34, 17.99, 1.01, 30.09, 4.11, 11.33],
      [314, 5.54, 8.78, 5.32, 6.28, 12, 8.2, 16.37, 1.08, 29.32, 6.14, 11.01],
      [7479, 5.49, 7.88, 4.82, 6.08, 11.04, 7.52, 20.42, 1.01, 27.6, 4.33, 11.96],
      [1932, 5.45, 8.6, 5.07, 6.44, 11.58, 7.98, 18.25, 1.04, 28.62, 4.49, 11.85],
      [310, 5.14, 9.3, 5.51, 6.29, 12.33, 8.22, 18.17, 1.06, 28.96, 4.5, 11.8],
      [7624, 5.36, 7.86, 4.86, 6.41, 10.71, 7.44, 20.43, 1.08, 26.68, 4.56, 12.07],
      [1808, 5.33, 8.83, 5.04, 6.5, 11.69, 8.48, 18.58, 1.01, 28.08, 4.62, 12.65],
      [362, 5.15, 8.43, 5.25, 6.94, 12.06, 8.68, 18.3, 1.49, 29.32, 4.3, 12.3],
      [5458, 5.35, 8.57, 4.55, 6.33, 10.42, 6.65, 17.12, 0.88, 23.84, 4.13, 11.45],
      [1490, 5.47, 9.64, 4.65, 6.51, 10.95, 6.67, 15.77, 0.89, 24.44, 4.15, 11.19],
      [405, 5.34, 9.29, 5, 6.66, 11.32, 7.72, 15.29, 1.03, 29.92, 4.23, 11.53],
      [5120, 5.41, 8.24, 4.66, 6.46, 10.26, 6.65, 18.83, 1, 23.76, 4.24, 11.96],
      [1551, 5.3, 8.52, 4.8, 6.67, 10.96, 7.19, 17.12, 1.25, 24.15, 4.18, 12.21],
      [518, 5.38, 9.35, 5.02, 6.95, 11.93, 7.44, 16.71, 1.17, 26.91, 4.37, 11.83],
      [5060, 5.36, 8.77, 4.66, 6.4, 10.13, 7.02, 19.19, 1, 24.59, 4.47, 12.17],
      [1571, 5.32, 9.64, 4.63, 6.59, 10.73, 6.95, 17.76, 1.08, 24.49, 4.29, 11.84],
      [525, 5.23, 9.58, 4.7, 6.73, 11.77, 7.49, 15.56, 1.21, 25.92, 4.15, 11.5],
    ]
  },
  /**
   * 1日の睡眠時間
   */
  q6: {
    male: [
      [83, 5.68, 4.2, 2.17, 5.06, 8.6, 5.91, 12.41, 1.27, 19.55, 3.51, 7.2],
      [2180, 5.14, 4.06, 2.81, 5.22, 7.32, 5.76, 10.05, 1.54, 19.83, 3.32, 7.21],
      [8140, 5.07, 3.64, 2.38, 5.16, 7.29, 5.7, 10.96, 1.45, 19.36, 3.42, 7.34],
      [339, 4.85, 4.46, 2.42, 5.48, 8.44, 7.96, 14.56, 1.51, 20.74, 5.12, 8.01],
      [2498, 5.43, 5.18, 2.56, 5.71, 7.79, 7.19, 14.37, 1.52, 20.87, 4.55, 8.29],
      [7890, 5.33, 5.13, 2.73, 5.61, 7.83, 6.91, 15.07, 1.44, 19.88, 4.74, 8.16],
      [1190, 6.31, 7.59, 4.21, 6.65, 8.44, 8.38, 17.43, 1.39, 24.73, 6.26, 9.06],
      [3269, 5.66, 6.22, 3.08, 6.23, 8.49, 8.37, 17.85, 1.28, 22.23, 5.74, 9.03],
      [6591, 5.62, 6.14, 3.42, 6.25, 8.4, 8.38, 18.25, 1.31, 21.6, 5.87, 9.13],
      [1165, 6.02, 7.9, 3.94, 6.8, 9.37, 8.84, 19.75, 1.43, 25.51, 6.93, 9.87],
      [3775, 6.04, 7.82, 3.72, 6.36, 8.98, 8.62, 19.84, 1.81, 23.24, 6.83, 9.53],
      [6098, 5.86, 7.28, 3.92, 6.27, 9.32, 8.54, 20.33, 1.31, 23.47, 7.11, 9.78],
      [843, 6.78, 9.09, 4.94, 7.03, 10.35, 9.47, 20.68, 1.58, 31, 7.81, 10.69],
      [4372, 6.56, 9.06, 4.29, 6.4, 9.77, 8.7, 21.78, 1.13, 25.28, 7.93, 10.32],
      [5795, 6.51, 8.67, 4.31, 6.34, 9.68, 8.47, 22.28, 1.37, 24.1, 8.29, 10.31],
      [801, 7.55, 10.88, 6.68, 6.52, 10.32, 9.11, 22.57, 1.29, 31.41, 8.79, 11.07],
      [5134, 7.64, 10.37, 5.3, 6.2, 9.83, 8.3, 23.34, 1.45, 26.79, 8.94, 10.41],
      [5131, 7.35, 9.46, 5.08, 6.03, 9.63, 8.12, 23.89, 1.08, 26.21, 9.41, 10.49],
      [1102, 8.39, 12.46, 7.36, 6.48, 12.26, 9.84, 24.23, 1.63, 40.68, 5.84, 10.34],
      [5821, 8.09, 11.13, 6.75, 6.42, 11.48, 9.01, 25.26, 1.1, 33.51, 5.74, 10.54],
      [3329, 8.21, 10.58, 6.88, 6.42, 11.71, 8.73, 25.42, 1.07, 34.21, 6.06, 10.71],
      [1248, 7.38, 12.26, 8, 6.66, 12.21, 9.99, 26.31, 1.15, 37.67, 6.58, 11.93],
      [6748, 7.4, 10.79, 7.37, 6.25, 11.51, 8.78, 25.51, 0.92, 33.2, 6.39, 11.45],
      [2338, 7.77, 10.82, 7.69, 6.39, 11.03, 9.04, 25.73, 0.94, 32.9, 6.53, 11.58],
      [1582, 6.23, 11.14, 8.08, 6.78, 12.47, 9.74, 26.74, 1.57, 33.25, 6.74, 12.34],
      [7312, 6.52, 10.86, 7.69, 6.21, 11.05, 8.7, 25.81, 0.93, 30.16, 6.64, 11.67],
      [1562, 6.98, 10.79, 8.03, 6.5, 11.53, 9.22, 25.88, 0.83, 32.54, 6.91, 12.2],
      [1454, 5.8, 12.39, 7.01, 6.45, 11.66, 8.44, 25.27, 0.82, 27.8, 6.22, 11.19],
      [6001, 5.81, 11.93, 7.13, 6.3, 11.27, 7.77, 24.55, 0.74, 27.6, 6.4, 11.06],
      [779, 6.31, 12.27, 7.65, 6.67, 11.74, 9.47, 26.77, 1.31, 32.16, 6.55, 12.07],
      [1844, 5.87, 12.48, 7.37, 6.63, 11.95, 8.4, 26.53, 0.84, 30.31, 6.45, 11.66],
      [5653, 5.84, 11.53, 7.13, 6.39, 11.53, 7.38, 26.77, 0.85, 26.91, 6.61, 11.44],
      [457, 6.03, 13.06, 7.61, 6.81, 12.1, 9.31, 28.41, 0.88, 32.49, 6.78, 12.46],
      [2146, 5.81, 12.13, 7.4, 6.55, 12.2, 8.23, 27.06, 0.78, 29.1, 6.65, 11.83],
      [5629, 5.87, 11.76, 7.4, 6.71, 11.56, 8.09, 27.98, 0.89, 27.87, 6.84, 12.06],
      [339, 6, 13.97, 7.9, 7.49, 12.47, 10.14, 30.37, 1.03, 35.42, 7.06, 13.57],
    ],
    female:[
      [81, 5.07, 3.82, 1.99, 5.39, 6.95, 5.65, 7.52, 1.23, 17.87, 1.96, 7.11],
      [2128, 5.19, 4, 2.18, 4.86, 7.16, 5.21, 7.53, 1.35, 17.91, 1.98, 6.96],
      [7781, 5.01, 3.47, 2.09, 4.83, 7.27, 5.01, 7.99, 1.46, 17.32, 2.1, 7.15],
      [279, 5.3, 4.43, 2.25, 5.06, 7.84, 6.7, 15.86, 1.1, 20.07, 2.9, 7.88],
      [2415, 5.44, 4.91, 2.98, 5.18, 7.85, 6.08, 10.84, 1.18, 19.01, 3.15, 8.09],
      [7452, 5.28, 4.3, 2.49, 5.21, 7.89, 6.23, 10.65, 1.27, 18.15, 2.62, 7.88],
      [950, 6.7, 5.8, 3.3, 5.64, 8.89, 8.16, 13.91, 1.18, 23.71, 3.49, 8.75],
      [3220, 6.06, 6.09, 2.84, 5.78, 8.57, 7.75, 13.35, 1.36, 20.72, 3.51, 8.73],
      [6460, 5.79, 5.43, 3.28, 5.65, 8.63, 7.64, 13.72, 1.14, 20.45, 3.59, 8.73],
      [828, 6.91, 7.83, 5.25, 5.77, 9.1, 7.97, 15.34, 1.44, 25.59, 4.07, 9.12],
      [3624, 6.85, 7.46, 3.86, 5.8, 9.04, 7.83, 15.83, 1.24, 22.43, 4.25, 9.2],
      [6097, 6.45, 6.56, 3.37, 5.65, 9.33, 7.66, 15.86, 1.36, 22.17, 4.14, 9.2],
      [638, 7.4, 9.3, 4.47, 6.52, 10.17, 8.96, 16.22, 1.64, 26.09, 5.48, 10.54],
      [4285, 7.02, 8.13, 4.21, 5.8, 10.06, 7.73, 17.14, 1.21, 24.03, 4.85, 9.51],
      [5599, 6.97, 7.68, 4.04, 5.69, 9.8, 7.31, 17.66, 1.04, 22.39, 4.9, 9.38],
      [768, 6.39, 9.74, 5.16, 6.07, 10.79, 7.72, 17.71, 1.05, 29.82, 5.45, 9.85],
      [5358, 6.6, 8.83, 4.69, 5.6, 10.03, 7.21, 18.61, 0.96, 24.96, 5.38, 9.46],
      [4815, 6.71, 8.29, 4.44, 5.57, 9.85, 7, 18.66, 1.39, 23.72, 5.55, 9.36],
      [1216, 5.6, 9.09, 5.02, 6.23, 11.75, 8.19, 19.29, 1.05, 31.43, 5.28, 11.51],
      [6127, 5.81, 8.71, 4.68, 5.83, 11.22, 7.67, 19.95, 1.08, 28.84, 4.05, 11.46],
      [2436, 5.98, 8.13, 5.07, 5.95, 11.22, 7.34, 19.46, 1.09, 28.44, 4.28, 11.43],
      [1483, 5.67, 8.74, 5.3, 6.34, 11.71, 8.06, 18.91, 0.93, 30.59, 4.33, 12.11],
      [6673, 5.43, 7.94, 4.77, 6.08, 11.02, 7.58, 20.36, 1.01, 27.51, 4.38, 11.91],
      [1624, 5.49, 7.88, 5, 6.32, 11.39, 7.49, 19.8, 1.12, 27.11, 4.35, 12.13],
      [1996, 5.33, 8.83, 5.24, 6.67, 11.41, 8.04, 19.98, 0.92, 27.38, 4.68, 12.45],
      [6814, 5.36, 7.87, 4.79, 6.36, 10.73, 7.54, 20.29, 1.15, 26.84, 4.51, 12.15],
      [1030, 5.32, 7.7, 5.02, 6.83, 11.51, 8.48, 20.98, 0.98, 29.4, 4.74, 12.95],
      [1933, 5.29, 8.81, 4.67, 6.54, 11.14, 6.97, 16.28, 0.9, 23.72, 3.99, 11.38],
      [4891, 5.39, 8.7, 4.53, 6.31, 10.29, 6.58, 17.07, 0.88, 24.13, 4.14, 11.4],
      [523, 5.65, 10.12, 4.91, 6.82, 11.3, 7.52, 17.2, 0.95, 29.02, 4.54, 12.09],
      [2400, 5.41, 8.21, 4.79, 6.61, 10.96, 7.16, 18.79, 1.14, 24.21, 4.19, 12.05],
      [4443, 5.37, 8.43, 4.66, 6.51, 10.28, 6.69, 18.27, 1.04, 24, 4.25, 12.05],
      [344, 5.44, 8.86, 5.14, 7.15, 11.92, 7.37, 20.01, 1.13, 27.15, 4.46, 13.42],
      [2606, 5.21, 9.07, 4.67, 6.55, 10.56, 7.16, 18.63, 1.06, 24.93, 4.51, 12.15],
      [4303, 5.41, 9.04, 4.65, 6.48, 10.36, 7.1, 19.17, 1.02, 24.75, 4.34, 12.16],
      [238, 5.46, 8.19, 4.86, 6.42, 10.74, 6.81, 17.82, 1.24, 25.23, 4.74, 12.74],
    ],
  },

  /**
   * 1日のテレビ（テレビゲーム含む）の視聴時間
   */
  q7: {
    male: [
      [1354, 5.14, 3.72, 2.61, 5.07, 7.47, 5.71, 11.3, 1.38, 19.64, 3.53, 7.44],
      [4267, 5.07, 3.52, 2.56, 5.14, 7.31, 5.65, 11.05, 1.44, 19.54, 3.4, 7.28],
      [3272, 4.98, 3.79, 2.29, 5.18, 7.2, 5.55, 10.45, 1.47, 19.33, 3.35, 7.29],
      [1495, 5.33, 4.16, 2.48, 5.27, 7.39, 6.21, 10.28, 1.64, 19.23, 3.35, 7.3],
      [1425, 5.24, 5.22, 2.86, 5.67, 7.93, 7.08, 15.62, 1.76, 19.74, 4.81, 8.08],
      [4038, 5.31, 4.59, 2.55, 5.67, 7.72, 7.01, 15.09, 1.32, 19.84, 4.67, 8.13],
      [3372, 5.3, 5.05, 2.79, 5.55, 7.73, 6.87, 14.63, 1.4, 19.99, 4.82, 8.13],
      [1887, 5.54, 6.12, 2.62, 5.66, 8.25, 7.16, 14.24, 1.62, 21.28, 4.48, 8.41],
      [2436, 5.73, 6.13, 3.85, 6.36, 8.61, 8.53, 18.75, 1.36, 22.78, 5.97, 9.31],
      [3322, 5.68, 6.12, 3.34, 6.11, 8.17, 8.43, 18.28, 1.21, 21.68, 6.01, 9.05],
      [2447, 5.72, 6.66, 3.39, 6.28, 8.53, 8.15, 17.71, 1.4, 22.07, 5.76, 9.01],
      [2814, 5.73, 6.48, 3.13, 6.44, 8.52, 8.38, 17.42, 1.29, 22.3, 5.73, 9.05],
      [2253, 5.87, 7.17, 4.27, 6.35, 9.31, 8.98, 20.99, 2.08, 24.83, 7.43, 9.98],
      [3278, 5.87, 7.01, 3.94, 6.3, 9.18, 8.62, 20.12, 1.43, 22.7, 6.94, 9.63],
      [2347, 5.93, 7.25, 3.69, 6.18, 9.38, 8.23, 19.76, 1.18, 22.88, 7.01, 9.51],
      [3144, 6.08, 8.42, 3.56, 6.51, 9.07, 8.68, 19.63, 1.33, 24.26, 6.69, 9.76],
      [2253, 5.87, 7.17, 4.27, 6.35, 9.31, 8.98, 20.99, 2.08, 24.83, 7.43, 9.98],
      [3193, 6.45, 8.01, 4.34, 6.13, 9.66, 8.31, 22.03, 1.07, 24.9, 8.16, 10.09],
      [2629, 6.62, 8.77, 4.23, 6.29, 9.66, 8.52, 21.82, 1.26, 24.12, 8.08, 10.35],
      [3570, 6.56, 9.74, 4.37, 6.59, 10.04, 8.7, 21.04, 1.48, 24.93, 8.09, 10.37],
      [1527, 7.41, 9.29, 5.67, 6.49, 10.3, 8.68, 24.57, 1.03, 27.69, 9.72, 10.95],
      [3265, 7.42, 9.46, 5.11, 6.02, 9.64, 8.15, 23.45, 1.17, 25.64, 9.21, 10.29],
      [2727, 7.49, 9.79, 5.07, 5.82, 9.61, 7.98, 22.93, 1.24, 26.26, 8.92, 9.96],
      [3535, 7.65, 10.91, 5.52, 6.31, 9.77, 8.46, 23.18, 1.49, 28.03, 8.96, 10.76],
      [1039, 8.07, 10.73, 7.12, 6.68, 11.49, 10.12, 26.41, 1.37, 36.86, 5.9, 11.06],
      [2548, 7.96, 10.17, 6.71, 6.13, 11.43, 8.62, 24.79, 1.25, 32.51, 5.78, 10.37],
      [2619, 8.17, 10.82, 6.7, 6.37, 11.3, 8.79, 25.34, 0.95, 32.54, 5.95, 10.48],
      [4047, 8.31, 11.87, 7, 6.52, 12.05, 9.12, 24.76, 1.15, 36.43, 5.82, 10.57],
      [876, 7.71, 10.91, 7.87, 6.56, 11.94, 9.95, 27.52, 1.04, 35.59, 6.74, 12.38],
      [2302, 7.61, 10.12, 7.41, 6.21, 11.29, 8.51, 24.57, 0.77, 31.9, 6.43, 11.27],
      [2754, 7.46, 10.58, 7.34, 6.1, 11.12, 8.34, 25, 0.85, 31.29, 6.17, 11.18],
      [4401, 7.38, 11.65, 7.62, 6.45, 11.73, 9.4, 25.85, 1.08, 35.52, 6.55, 11.63],
      [1015, 6.96, 10.37, 8.12, 6.7, 11.43, 9.5, 26.35, 0.82, 34.44, 6.73, 12.27],
      [2411, 6.54, 10.32, 7.75, 6.16, 10.87, 8.56, 25.41, 1.57, 29.32, 6.62, 11.54],
      [2674, 6.77, 10.63, 7.62, 5.85, 11.02, 8.6, 25.08, 0.73, 29.72, 6.6, 11.35],
      [4360, 6.32, 11.47, 7.85, 6.56, 11.73, 9.19, 26.33, 0.86, 31.72, 6.75, 12.09],
      [1742, 5.75, 11.48, 7.17, 6.35, 11.36, 8.12, 25.17, 0.71, 29.52, 6.49, 11.03],
      [2042, 5.83, 11.66, 7.09, 6.21, 10.96, 7.48, 24.44, 0.9, 26.46, 6.47, 10.97],
      [1821, 5.85, 11.71, 7.08, 6.04, 11.13, 8.03, 23.41, 0.79, 27.12, 6.3, 10.85],
      [2633, 5.93, 12.77, 7.19, 6.5, 11.63, 8.29, 24.75, 0.84, 28.42, 6.16, 11.11],
      [1729, 5.84, 10.84, 7.17, 6.39, 11.33, 7.51, 25.97, 1.04, 26.74, 6.47, 11.11],
      [1915, 5.79, 10.93, 7.01, 5.87, 11.39, 7.4, 26.09, 0.67, 26.29, 6.68, 11.19],
      [1659, 5.85, 11.8, 7.26, 6.38, 11.36, 7.38, 26.25, 0.77, 26.18, 6.62, 11.35],
      [2652, 5.91, 13.08, 7.31, 6.68, 11.99, 8.08, 26.51, 0.88, 30.4, 6.41, 11.51],
      [2139, 5.65, 10.24, 7.42, 6.52, 11.64, 7.78, 26.71, 0.73, 26.87, 6.68, 11.38],
      [2010, 5.87, 11.17, 7.42, 6.37, 11.17, 7.88, 26.92, 0.69, 26.94, 6.85, 11.71],
      [1441, 5.85, 12.24, 7.29, 6.52, 11.22, 8.07, 27.73, 1.17, 28.28, 6.67, 11.92],
      [2532, 6.01, 13.62, 7.45, 6.8, 12.19, 8.42, 27.27, 0.88, 30.11, 6.75, 11.99],
    ],
    female: [
      [1660, 4.94, 3.18, 2.14, 4.84, 7.24, 5.01, 7.98, 1.48, 17.02, 2.15, 7.1],
      [4339, 5.12, 3.51, 2.11, 4.84, 7.3, 5.07, 8.11, 1.31, 17.46, 2.1, 7.17],
      [2852, 5.01, 3.77, 2.1, 4.8, 7.11, 4.99, 7.39, 1.61, 17.45, 1.98, 7],
      [1123, 5.07, 3.96, 2.1, 4.97, 7.41, 5.19, 7.93, 1.36, 18.11, 2.08, 7.14],
      [1805, 5.21, 4.11, 2.62, 5.41, 7.93, 6.47, 11.24, 1.13, 18.49, 2.98, 8.03],
      [4176, 5.27, 4.32, 2.34, 5.21, 7.77, 6.13, 10.81, 1.3, 18.26, 2.79, 7.78],
      [2809, 5.39, 4.64, 2.72, 5.02, 8.04, 6.16, 11.07, 1.26, 18.44, 2.62, 8.01],
      [1352, 5.44, 4.9, 3.09, 5.19, 7.84, 6.21, 9.91, 1.19, 18.66, 2.66, 8],
      [3234, 6.12, 5.21, 3.39, 5.74, 8.69, 7.89, 14.01, 1.3, 21.37, 3.67, 8.8],
      [3583, 5.8, 5.37, 2.79, 5.66, 8.78, 7.54, 13.71, 1.04, 20.52, 3.37, 8.68],
      [2052, 5.91, 6.23, 3.29, 5.58, 8.37, 7.57, 13.39, 1.17, 20.9, 3.32, 8.63],
      [1730, 6.04, 6.29, 3.25, 5.79, 8.62, 8, 12.69, 1.4, 20.48, 3.97, 8.84],
      [2761, 6.54, 6.83, 4.13, 5.79, 9.44, 7.97, 16.4, 1.35, 23.49, 4.09, 9.19],
      [3680, 6.56, 6.58, 3.72, 5.62, 9.17, 7.68, 15.77, 1.45, 21.91, 4.1, 9.08],
      [2103, 6.65, 7.21, 3.38, 5.65, 9.03, 7.47, 15.29, 1.17, 22.38, 4.24, 9.07],
      [1983, 6.79, 7.62, 3.45, 5.81, 9.24, 7.99, 15.6, 1.2, 22.89, 4.35, 9.62],
      [1956, 6.95, 7.35, 4.09, 5.94, 10.16, 8.02, 18.21, 1.35, 24.51, 5.4, 9.89],
      [3523, 6.94, 7.47, 4.07, 5.73, 9.67, 7.64, 17.42, 1.23, 22.2, 4.79, 9.38],
      [2353, 6.93, 7.91, 4.04, 5.59, 9.65, 7.3, 17.15, 0.96, 22.78, 4.87, 9.17],
      [2668, 7.15, 8.91, 4.32, 5.91, 10.39, 7.56, 16.79, 1.07, 24.39, 4.76, 9.8],
      [1829, 6.65, 8.14, 4.54, 5.82, 9.99, 7.45, 19.97, 0.97, 25.36, 5.5, 9.39],
      [3569, 6.68, 8.16, 4.71, 5.57, 9.82, 7.08, 18.56, 1.41, 23.72, 5.54, 9.31],
      [2658, 6.64, 8.79, 4.56, 5.51, 9.86, 7.11, 18.24, 0.97, 25.06, 5.56, 9.5],
      [2875, 6.49, 9.35, 4.58, 5.61, 10.38, 7.19, 17.79, 1.14, 25.64, 5.28, 9.62],
      [951, 6.3, 8.32, 5.05, 6.02, 11.53, 8.47, 20.76, 1.37, 31.77, 5.86, 11.87],
      [2467, 5.87, 8.1, 4.72, 5.92, 11.09, 7.48, 20.65, 0.97, 28.33, 3.9, 11.37],
      [2495, 5.84, 8.45, 4.8, 5.77, 10.99, 7.45, 19.66, 0.95, 28.9, 3.94, 11.41],
      [3867, 5.67, 9.08, 4.85, 5.93, 11.53, 7.68, 18.55, 1.14, 28.94, 4.24, 11.33],
      [952, 5.69, 7.99, 5.38, 6.47, 11.84, 8.28, 20.99, 0.96, 29.41, 4.6, 12.39],
      [2231, 5.54, 7.66, 4.87, 5.94, 10.71, 7.35, 20.68, 0.97, 28.39, 4.42, 11.98],
      [2572, 5.47, 7.68, 4.76, 6.01, 10.75, 7.63, 19.83, 1.16, 26.82, 4.33, 11.76],
      [4022, 5.38, 8.5, 4.86, 6.24, 11.51, 7.62, 19.24, 0.95, 27.97, 4.29, 11.88],
      [1087, 5.43, 7.35, 5.13, 6.58, 11.14, 7.74, 20.57, 1.19, 26.81, 4.58, 12.25],
      [2451, 5.46, 7.64, 4.91, 6.33, 10.66, 7.82, 20.91, 0.88, 26.92, 4.5, 12.04],
      [2653, 5.38, 7.93, 4.84, 6.31, 10.52, 7.25, 19.78, 1.34, 27.06, 4.59, 12.09],
      [3648, 5.23, 8.6, 4.89, 6.6, 11.37, 8.01, 19.75, 0.96, 27.52, 4.59, 12.5],
      [2154, 5.32, 8.3, 4.68, 6.46, 10.5, 7, 17.84, 0.86, 25, 4.24, 11.42],
      [2046, 5.42, 8.47, 4.47, 6.28, 10.29, 6.55, 16.91, 0.86, 23.26, 4.2, 11.39],
      [1394, 5.31, 9.23, 4.53, 6.26, 10.25, 6.23, 16.2, 0.87, 24.83, 4.04, 11.31],
      [1755, 5.46, 9.48, 4.69, 6.47, 11.06, 6.89, 15.28, 0.98, 24.17, 3.97, 11.28],
      [2333, 5.26, 7.87, 4.59, 6.63, 10.61, 6.9, 19.08, 0.99, 23.42, 4.35, 12.09],
      [1846, 5.46, 8.03, 4.69, 6.36, 10.22, 6.64, 18.83, 1.02, 23.44, 4.1, 11.91],
      [1314, 5.41, 8.56, 4.81, 6.22, 10.3, 6.64, 17.16, 0.9, 22.87, 4.26, 11.65],
      [1690, 5.47, 9.23, 4.88, 6.74, 10.97, 7.1, 17.34, 1.33, 26.22, 4.21, 12.19],
      [2538, 5.36, 8.48, 4.64, 6.56, 10.47, 7.04, 20.08, 0.99, 23.71, 4.52, 12.04],
      [1894, 5.35, 8.84, 4.65, 6.39, 10.25, 6.99, 18.63, 0.99, 24.58, 4.34, 12.24],
      [1103, 5.23, 9.04, 4.7, 6.32, 10.01, 6.95, 17.11, 0.98, 23.64, 4.27, 11.74],
      [1618, 5.34, 10.03, 4.68, 6.5, 10.77, 7.13, 17.35, 1.17, 26.51, 4.35, 11.98],
    ],
  },
}
