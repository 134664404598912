import { QuestionnaireRateFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel, replace comma character with `.` if needed.
 *   - Just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 *
 * Male: ID from 0 -> 11
 * Female: ID from 12 -> 23
 */
export const reportQuestionnaireRate2024: QuestionnaireRateFromExcel = {
  q2: [
    [38.80, 61.20],
    [47.50, 52.50],
    [53.90, 46.10],
    [57.60, 42.40],
    [57.40, 42.60],
    [55.20, 44.80],
    [77.90, 22.10],
    [80.80, 19.20],
    [77.90, 22.10],
    [57.00, 43.00],
    [51.50, 48.50],
    [47.10, 52.90],
    [31.70, 68.30],
    [37.40, 62.60],
    [42.70, 57.30],
    [44.10, 55.90],
    [41.20, 58.80],
    [37.10, 62.90],
    [61.30, 38.70],
    [61.50, 38.50],
    [56.70, 43.30],
    [32.30, 67.70],
    [29.30, 70.70],
    [26.70, 73.30],
  ],

  q3: [
    [21.90, 53.60, 12.50, 12.00],
    [28.70, 50.90, 10.80, 9.70],
    [40.40, 39.60, 9.70, 10.30],
    [49.70, 34.30, 8.40, 7.60],
    [52.70, 33.00, 7.60, 6.70],
    [51.90, 33.70, 8.10, 6.30],
    [75.10, 15.10, 5.50, 4.30],
    [79.10, 11.50, 4.70, 4.70],
    [72.30, 15.30, 6.10, 6.30],
    [58.10, 18.40, 10.90, 12.60],
    [53.60, 17.10, 12.60, 16.80],
    [47.40, 18.60, 14.20, 19.80],
    [15.50, 53.20, 14.20, 17.10],
    [19.90, 53.20, 13.60, 13.30],
    [29.70, 47.00, 11.10, 12.10],
    [38.30, 43.60, 10.00, 8.00],
    [38.60, 42.50, 11.10, 7.80],
    [34.10, 43.60, 13.50, 8.70],
    [59.10, 22.60, 10.80, 7.50],
    [61.10, 18.30, 10.80, 9.90],
    [52.70, 21.20, 12.80, 13.30],
    [32.00, 23.00, 18.90, 26.00],
    [29.80, 19.80, 19.90, 30.50],
    [27.50, 17.60, 20.30, 34.50],
  ],
  q4: [
    [21.10, 38.30, 31.40, 9.20],
    [19.10, 32.50, 32.80, 15.70],
    [22.00, 26.00, 26.20, 25.80],
    [18.80, 23.40, 25.50, 32.30],
    [16.70, 21.20, 27.10, 35.00],
    [16.00, 21.10, 27.30, 35.50],
    [10.50, 14.00, 34.40, 41.00],
    [10.60, 11.00, 36.40, 42.10],
    [13.10, 15.70, 33.70, 37.60],
    [24.30, 16.50, 20.60, 38.50],
    [28.80, 17.00, 19.00, 35.20],
    [33.40, 18.30, 17.40, 31.00],
    [26.50, 41.00, 27.40, 5.10],
    [25.00, 37.70, 29.20, 8.10],
    [30.80, 32.40, 23.80, 13.10],
    [26.90, 31.80, 24.20, 17.10],
    [26.50, 28.90, 25.90, 18.70],
    [26.30, 28.30, 25.90, 19.50],
    [21.40, 19.30, 31.70, 27.60],
    [23.00, 16.40, 31.30, 29.30],
    [28.00, 19.60, 28.50, 23.90],
    [46.80, 21.30, 14.70, 17.30],
    [51.90, 18.90, 13.00, 16.20],
    [55.10, 18.50, 11.70, 14.70],
  ],
  q5: [
    [91.50, 7.40, 1.10],
    [89.70, 9.10, 1.20],
    [85.20, 12.90, 1.90],
    [84.90, 13.20, 1.80],
    [83.30, 14.70, 2.00],
    [82.70, 14.40, 2.80],
    [83.20, 13.50, 3.20],
    [82.80, 13.80, 3.40],
    [80.00, 15.10, 4.90],
    [76.30, 16.20, 7.50],
    [73.70, 17.20, 9.10],
    [70.70, 18.10, 11.30],
    [90.90, 8.10, 1.10],
    [90.30, 8.70, 1.10],
    [87.00, 11.90, 1.10],
    [86.50, 12.10, 1.30],
    [83.60, 14.70, 1.70],
    [80.90, 16.50, 2.60],
    [77.90, 18.90, 3.20],
    [76.90, 19.90, 3.20],
    [77.80, 18.50, 3.70],
    [74.20, 20.30, 5.50],
    [71.20, 21.60, 7.20],
    [70.70, 22.00, 7.30],
  ],
  q6: [
    [0.80, 21.00, 78.20],
    [3.20, 23.30, 73.60],
    [10.80, 29.60, 59.60],
    [10.60, 34.20, 55.20],
    [7.70, 39.70, 52.60],
    [7.20, 46.40, 46.40],
    [10.70, 56.80, 32.50],
    [12.10, 65.30, 22.60],
    [15.10, 69.90, 14.90],
    [17.70, 72.90, 9.50],
    [23.20, 71.10, 5.70],
    [26.40, 69.40, 4.20],
    [0.80, 21.30, 77.90],
    [2.70, 23.80, 73.40],
    [8.90, 30.30, 60.80],
    [7.80, 34.40, 57.80],
    [6.10, 40.70, 53.20],
    [7.00, 49.00, 44.00],
    [12.40, 62.70, 24.90],
    [15.20, 68.20, 16.60],
    [20.30, 69.20, 10.50],
    [26.30, 66.60, 7.10],
    [33.40, 61.80, 4.80],
    [36.50, 60.20, 3.30],
  ],
  q7: [
    [13.00, 41.10, 31.50, 14.40],
    [13.30, 37.70, 31.40, 17.60],
    [22.10, 30.10, 22.20, 25.50],
    [20.40, 29.70, 21.30, 28.50],
    [14.60, 29.00, 23.90, 32.50],
    [13.80, 29.50, 24.70, 32.00],
    [10.10, 24.90, 25.50, 39.50],
    [8.50, 22.30, 26.70, 42.60],
    [9.70, 23.00, 25.60, 41.70],
    [21.10, 24.80, 22.10, 32.00],
    [21.70, 24.10, 20.90, 33.30],
    [26.30, 24.70, 17.70, 31.20],
    [16.60, 43.50, 28.60, 11.30],
    [17.80, 41.20, 27.70, 13.30],
    [30.50, 33.80, 19.40, 16.30],
    [26.20, 35.00, 20.00, 18.80],
    [18.60, 33.60, 22.40, 25.40],
    [16.70, 32.70, 24.30, 26.30],
    [9.70, 25.20, 25.50, 39.50],
    [9.70, 22.80, 26.30, 41.10],
    [11.00, 24.90, 27.00, 37.10],
    [29.30, 27.80, 19.00, 23.90],
    [32.50, 25.70, 18.30, 23.50],
    [35.50, 26.50, 15.40, 22.60],
  ],
}
