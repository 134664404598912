import {
  ReportTestRankByCategory,
  ReportTestRankByGrade,
  ReportTestRankSummary,
} from '@alpha/core'
import { TestRankReportFromExcel } from './type'
import {
  testRank2023Female,
  testRank2023Male,
  testRank2023Totals,
} from './year2023'
import { testRank2024Female, testRank2024Male, testRank2024Totals } from './year2024'

const ranksKeyNames = ['rankA', 'rankB', 'rankC', 'rankD', 'rankE'] as const

export const transformFromExcelDataEachYearTestRankReport = (
  year: number,
  isMale: boolean,
): ReportTestRankSummary => {
  let rawData: TestRankReportFromExcel

  // maybe add parameter for the year
  if (isMale) {
    rawData = year === 2023 ? testRank2023Male : testRank2024Male
  } else {
    rawData = year === 2023 ? testRank2023Female : testRank2024Female
  }

  const elementarySchoolReport = { reports: [] } as ReportTestRankByCategory
  const juniorHighSchoolReport = { reports: [] } as ReportTestRankByCategory
  const allDayHighSchoolReport = { reports: [] } as ReportTestRankByCategory
  const fixTimeHighSchoolReport = { reports: [] } as ReportTestRankByCategory

  for (let i = 0; i < rawData.length; i++) {
    const gradeScore = rawData[i]

    let graphDataForSchool: ReportTestRankByCategory

    /**
     * start form 1
     */
    let grade = 1
    if (0 <= i && i <= 5) {
      graphDataForSchool = elementarySchoolReport
      grade = i + 1
    } else if (6 <= i && i <= 8) {
      graphDataForSchool = juniorHighSchoolReport
      grade = i - 5
    } else if (9 <= i && i <= 11) {
      graphDataForSchool = allDayHighSchoolReport
      grade = i - 8
    } else {
      graphDataForSchool = fixTimeHighSchoolReport
      grade = i - 11
    }

    const report = {
      grade,
      totalCount: gradeScore[0],
      countByRank: {},
      rateByRank: {},
    } as ReportTestRankByGrade
    const countByRank = report.countByRank
    const rateByRank = report.rateByRank
    const reports = graphDataForSchool.reports

    for (let j = 1; j < 6; j++) {
      const subjectScore = gradeScore[j]
      const subjectName = ranksKeyNames[j - 1]
      countByRank[subjectName] = subjectScore
    }

    for (let j = 6; j < 11; j++) {
      const subjectScore = gradeScore[j]
      const subjectName = ranksKeyNames[j - 6]
      rateByRank[subjectName] = subjectScore / 100
    }

    reports.push(report)
  }

  return {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  }
}

export const transformFromExcelDataEachYearTestRankTotalsReport = (year: number): ReportTestRankSummary => {
    const elementarySchoolReport = { reports: [] } as ReportTestRankByCategory
    const juniorHighSchoolReport = { reports: [] } as ReportTestRankByCategory
    const allDayHighSchoolReport = { reports: [] } as ReportTestRankByCategory
    const fixTimeHighSchoolReport = { reports: [] } as ReportTestRankByCategory

    const grade = 0
    const testRankTotals = year === 2023 ? testRank2023Totals : testRank2024Totals

    for (let i = 0; i < testRankTotals.length; i++) {
      const gradeScore = testRankTotals[i]

      let graphDataForSchool: ReportTestRankByCategory

      if (i === 0) {
        graphDataForSchool = elementarySchoolReport
      } else if (i === 1) {
        graphDataForSchool = juniorHighSchoolReport
      } else if (i === 2) {
        graphDataForSchool = allDayHighSchoolReport
      } else {
        graphDataForSchool = fixTimeHighSchoolReport
      }

      const report = {
        grade,
        totalCount: gradeScore[0],
        countByRank: {},
        rateByRank: {},
      } as ReportTestRankByGrade
      const countByRank = report.countByRank
      const rateByRank = report.rateByRank
      const reports = graphDataForSchool.reports

      for (let j = 1; j < 6; j++) {
        const subjectScore = gradeScore[j]
        const subjectName = ranksKeyNames[j - 1]
        countByRank[subjectName] = subjectScore
      }

      for (let j = 6; j < 11; j++) {
        const subjectScore = gradeScore[j]
        const subjectName = ranksKeyNames[j - 6]
        rateByRank[subjectName] = subjectScore / 100
      }

      reports.push(report)
    }

    return {
      elementarySchoolReport,
      juniorHighSchoolReport,
      allDayHighSchoolReport,
      fixTimeHighSchoolReport,
    }
  }
