import { TScoreReportFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel. just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace comma character with `.` if needed.
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 *
 * Remove the `50.0` rows.
 */
export const theTScore2023Male: TScoreReportFromExcel = [
  [49.04, 51.05, 50.97, 47.91, 51.79, 46.83, 49.1, 48.82, 49.87, 49.94, 51.71],
  [49.19, 52.88, 50.46, 49.2, 50.92, 47.8, 49.24, 49.76, 50.36, 50.05, 52.46],
  [48.92, 53.31, 51.74, 49.89, 50.18, 48.91, 49.95, 48.85, 50.49, 50.15, 52.29],
  [49.89, 51.67, 52.94, 48.58, 49.14, 48.05, 48.24, 48.03, 49.45, 51.08, 54.02],
  [49.45, 50.89, 53.8, 48.75, 48.63, 48.36, 49.07, 48.1, 49.39, 50.75, 53.67],
  [50.51, 50.71, 54.07, 48.33, 47.92, 48.28, 49.16, 47.8, 49.39, 50.83, 53.17],
  [49.97, 50.16, 52.36, 47.87, 47.8, 48.62, 48.23, 49.06, 49.7, 50.28, 52.97],
  [49.74, 49.26, 52.43, 47.48, 47.28, 49.26, 48.3, 49.98, 49.66, 49.82, 52.44],
  [50.28, 49.71, 52.46, 47.44, 47.11, 49.66, 48.6, 50.43, 49.91, 49.34, 51.59],
  [49.44, 50.39, 51.23, 51.18, 49.41, 49.2, 49.23, 49.02, 49.83, 49.85, 52.11],
  [48.72, 48.89, 50.19, 50.28, 48.91, 48.82, 49.12, 48.96, 48.59, 49.57, 51.93],
  [48.27, 48.75, 50.1, 50.36, 49.09, 48.97, 48.83, 48.8, 48.29, 49.46, 51.51],
]

export const theTScore2023Female: TScoreReportFromExcel = [
  [49.09, 51.12, 50.79, 47.9, 52.46, 48.75, 49.81, 50.51, 50.69, 50.45, 52.21],
  [49.15, 52.41, 51.23, 49.09, 50.65, 48.33, 49.55, 51.22, 50.87, 50.16, 52.24],
  [49.42, 52.29, 51.01, 50.03, 50.05, 48.56, 48.95, 50.85, 50.31, 50.3, 52.35],
  [49.87, 51.32, 52.93, 49.29, 49.59, 49.11, 48.22, 50.66, 50.33, 50.82, 52.88],
  [50.44, 52.27, 54.47, 49.28, 49.75, 49.32, 49.38, 50.55, 51.17, 49.94, 51.85],
  [50.37, 52.36, 54.01, 48.7, 49.03, 49.35, 49.44, 49.52, 50.61, 50.77, 52.12],
  [49.72, 50.94, 52.93, 46.96, 48.34, 49.29, 46.97, 48.95, 49.44, 50.04, 51.37],
  [49.23, 48.98, 52.72, 46.4, 46.61, 48.72, 46.73, 49.27, 48.64, 50.1, 51.85],
  [48.96, 48.48, 52.77, 46.03, 46.6, 49.16, 46.55, 49.13, 48.4, 49.05, 51.08],
  [48.81, 49.73, 51.75, 50.78, 49.32, 49.06, 48.74, 47.92, 49.13, 49.3, 51.2],
  [48.29, 48.39, 51.17, 49.56, 47.67, 47.78, 48.76, 48.59, 47.93, 49.78, 52.3],
  [48.75, 49.57, 52.46, 50.97, 49.35, 48.64, 49.75, 48.32, 49.43, 49.6, 51.94],
]
