import { tScoreSubjectNames } from '../../../../utils/constant'
import { LocationType } from '../../../../utils/type'
import {
  ComparisonYesNo,
  ComparisonYesNoArr,
  ComparisonYesNoGrade,
  ComparisonYesNoSchool,
} from './type'
import {
  comparisonNationFemale2023,
  comparisonNationMale2023,
  comparisonPrefFemale2023,
  comparisonPrefMale2023,
} from './year2023'
import { comparisonNationFemale2024, comparisonNationMale2024, comparisonPrefFemale2024, comparisonPrefMale2024 } from './year2024'

export const handleEachYearComparison = (
  year: number,
  gender: 1 | 2,
  locationTypeName: LocationType,
): ComparisonYesNo => {
  let theTScoreMale: ComparisonYesNoArr
  let theTScoreFemale: ComparisonYesNoArr

  if (locationTypeName === LocationType.prefecture) {
    theTScoreMale = year === 2023 ? comparisonPrefMale2023 : comparisonPrefMale2024
    theTScoreFemale = year === 2023 ? comparisonPrefFemale2023 : comparisonPrefFemale2024
  } else {
    theTScoreMale = year === 2023 ? comparisonNationMale2023 : comparisonNationMale2024
    theTScoreFemale = year === 2023 ? comparisonNationFemale2023 : comparisonNationFemale2024
  }

  const tScore = gender === 1 ? theTScoreMale : theTScoreFemale

  const elementarySchoolReport = {
    reports: [],
  } as ComparisonYesNoSchool
  const juniorHighSchoolReport = {
    reports: [],
  } as ComparisonYesNoSchool

  const highSchoolReport = { reports: [] } as ComparisonYesNoSchool

  for (let i = 0; i < tScore.length; i++) {
    const schoolScore = tScore[i]

    let graphDataForSchool: ComparisonYesNoSchool

    /**
     * start form 1
     */
    let grade = 1
    if (0 <= i && i <= 5) {
      graphDataForSchool = elementarySchoolReport
      grade = i + 1
    } else if (6 <= i && i <= 8) {
      graphDataForSchool = juniorHighSchoolReport
      grade = i - 5
    } else {
      graphDataForSchool = highSchoolReport
      grade = i - 8
    }

    const locationTypeData = {} as ComparisonYesNoGrade
    const eachGradeData: ComparisonYesNoSchool['reports'][0] = {
      grade,
      [locationTypeName]: locationTypeData,
    }

    for (let j = 0; j < schoolScore.length; j++) {
      const subjectScore = schoolScore[j]
      const subjectName = tScoreSubjectNames[j]
      locationTypeData[subjectName] = subjectScore
    }

    graphDataForSchool.reports.push(eachGradeData)
  }

  return { elementarySchoolReport, juniorHighSchoolReport, highSchoolReport }
}
