// prettier-ignore

import { CrossAnalysis } from "./type";

/**
 * 標準偏差
 *
 * From 2023, data format had been changed.
 * No fix time high school in this data.
 *
 * So to migrate data from Excel:
 *   - On Excel, replace `,` character with `.` if needed.
 *   - Just select all data's cell (include rows and columns).
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace `tab` character between number with `,` character
 *   - Wrap each line by the `[]` character (an array) by many ways.
 *   - Use editor/IDE to format this file
 */
export const crossAnalysisDeviation2023: CrossAnalysis = {
  /**
   * category: 運動部やスポーツクラブへの所属状況
   */
  q2: {
    male: [[
   3406,5.03,3.71,2.32,4.89,7.44,5.65,11.83,1.31,18.85,3.56,7.11],
[7439,5.06,4.09,2.25,4.95,7.16,5.54,10.3,1.54,19.4,3.19,7.02],
[4380,5.23,4.66,2.65,5.22,7.56,6.61,15.63,1.07,19.2,4.81,7.74],
[6770,5.46,5.11,2.71,5.33,7.55,6.71,13.76,1.34,20.2,4.19,7.76],
[4962,5.56,6.03,3.19,6.05,8.07,8.47,18.25,1.04,21.01,5.97,8.4],
[6275,5.69,6.73,3.24,6.05,8.47,8.36,16.62,1.48,21.74,5.32,8.88],
[5540,5.94,7.3,3.77,6.3,9.11,8.33,20.32,1.01,22.35,7.03,9.14],
[5730,5.99,7.86,3.55,6.19,9.08,8.54,18.45,1.38,23.19,6.29,9.54],
[5697,6.27,8.07,4.02,5.94,9.33,7.94,22.1,1.07,23.24,8,9.35],
[5682,6.7,9.34,4.3,6.16,9.85,8.74,20.71,1.38,24.58,7.17,10.05],
[5578,7.39,9.55,5.25,5.61,9.43,7.82,22.7,0.92,24.97,9.14,9.21],
[6087,7.67,10.83,5.37,6.07,9.75,8.84,21.55,1.54,27.98,8.24,10.64],
[8461,8.23,10.97,6.73,6.17,11.33,8.9,25,1.13,32.86,5.85,10.42],
[2642,8.03,11.92,7.17,6.55,11.75,9.92,24.94,1.6,36.74,5.98,10.75],
[8752,7.53,10.58,7.45,6.07,11.39,8.7,24.14,0.86,31,6.1,10.93],
[2420,7.49,12.39,8.14,6.97,12.23,10.63,27.88,1.29,37.5,6.84,12.7],
[8572,6.57,10.39,7.52,5.96,11.01,8.38,24.36,0.71,28.45,6.49,10.88],
[2568,6.7,12.42,7.87,6.91,12.6,10.48,27.7,1.16,36.12,7.14,12.92],
[4591,5.78,10.87,6.95,5.71,10.79,6.6,22.61,0.63,24.02,5.87,9.58],
[3582,5.96,13.15,6.94,6.08,10.94,7.58,21.79,0.83,28.21,5.89,10.09],
[4290,5.7,9.88,7.1,5.86,10.61,7.14,23.88,0.58,23.57,6.19,9.92],
[3924,5.84,13.09,7.17,6.4,11.28,8.21,22.89,0.86,27.6,6.09,10.51],
[3915,5.72,9.91,7.17,5.75,10.84,6.8,23.57,0.6,23.97,6.17,9.85],
[4026,5.99,13.18,7.45,6.6,11.98,8.22,24.19,0.84,29.34,6.38,11.31]
    ],
    female: [
[2568,4.87,3.47,2.07,4.68,7.2,5.13,8.75,1.15,17.03,2.11,6.86],
[7704,5.01,3.75,2.07,4.64,7.29,5.11,7.72,1.33,17.52,1.99,6.93],
[3323,5.34,4.41,2.38,5.15,7.89,6.43,11.94,1.08,18.21,2.87,7.87],
[7389,5.42,4.71,2.44,4.93,7.59,6.17,10.43,1.24,18.34,2.65,7.62],
[3892,5.85,5.57,2.8,5.6,7.99,7.91,14.74,1.01,19.89,3.55,8.46],
[6785,5.86,5.66,2.94,5.44,8.46,7.58,12.65,1.24,20.67,3.21,8.56],
[3866,6.49,6.49,3.41,5.73,9.05,7.51,16.77,0.93,22.1,4.33,8.94],
[6836,6.64,7.14,3.47,5.67,8.98,7.71,14.22,1.16,21.77,3.71,8.97],
[4072,6.95,7.47,4.02,5.53,9.17,7.41,18.64,0.85,22.42,5.21,8.7],
[7050,7.16,8.28,4.19,5.36,9.79,7.47,15.49,1.31,22.97,4.33,9.18],
[3619,6.58,8.22,4.4,5.44,9.46,7.06,20.25,0.78,22.61,5.94,8.27],
[7407,6.47,8.77,4.51,5.39,9.98,7.41,16.08,0.97,24.52,4.8,9.26],
[6390,6.07,8.14,4.59,5.76,10.84,7.62,19.45,0.85,27.15,4.1,11.03],
[4010,5.83,8.83,4.91,5.8,11.37,7.8,15.71,1.74,30.86,3.91,10.62],
[6451,5.6,7.71,4.68,5.78,10.48,7.14,20.01,0.89,25.34,4.32,10.89],
[3893,5.72,8.86,4.8,5.98,11.52,7.73,16.47,1.18,30.14,3.58,11.31],
[5355,5.48,7.89,4.76,5.83,10.29,7.36,19.84,0.8,25.29,4.48,10.87],
[4021,5.43,8.75,4.89,6.25,11.32,7.96,15.84,1.04,28.33,3.84,11.41],
[2408,5.25,7.6,4.47,5.66,9.92,5.98,17.23,0.73,21.19,4.21,9.91],
[5259,5.31,8.59,4.42,5.97,10.31,6.12,13.85,0.87,22.37,3.62,10.23],
[2187,5.3,7.22,4.41,5.68,9.66,6.18,19.08,0.77,20.64,4.33,9.93],
[5335,5.23,8.93,4.57,6.06,10.44,6.67,14.21,0.98,22.58,3.75,10.67],
[2120,5.33,7.42,4.53,5.69,9.35,5.98,20.07,0.8,21.61,4.56,10.05],
[5287,5.39,8.83,4.56,6.15,10.58,6.25,14.22,0.96,22.21,3.76,10.65]
    ]
  },
  /**
   * 運動・スポーツの実施状況
   */
  q3: {
    male: [
[2533,5.08,3.72,2.38,5.01,7.06,5.57,12.21,1.23,18.6,3.63,6.97],
[5712,4.99,3.85,2.22,4.8,7.26,5.52,10.68,1.4,18.7,3.23,6.86],
[1521,5.12,4.07,2.21,5.04,7.13,5.44,9,1.69,19.57,2.83,6.7],
[1056,5.26,4.98,2.26,4.95,7.76,5.83,8.64,1.77,20.42,2.81,7.1],
[3498,5.3,4.5,2.73,5.17,7.41,6.83,16.22,0.97,18.39,4.97,7.54],
[5373,5.27,4.89,2.58,5.22,7.52,6.51,13.43,1.22,19.58,4.12,7.52],
[1314,5.45,5.35,2.86,5.36,7.71,6.75,12.23,1.47,19.83,3.62,7.41],
[928,6.01,6.08,2.67,5.26,7.93,6.38,12.16,1.58,21.59,3.9,7.63],
[4668,5.61,6.43,3.17,5.96,8.31,8.32,18.38,0.98,20.81,5.96,8.18],
[4263,5.65,6.06,3.3,5.91,8.07,8.16,16.04,1.14,20.81,5.24,8.3],
[1258,5.61,6.97,2.98,6.06,8.45,8.39,14.66,1.46,20.66,4.78,8.5],
[990,5.65,7.07,3.19,5.84,8.71,8.19,13.3,2.34,22.86,4.56,8.58],
[5667,5.82,6.99,3.57,6.2,9.01,8.18,20.09,0.91,21.63,6.83,8.69],
[3687,6.04,7.83,3.68,5.87,9.09,8.2,17.21,1.19,21.99,6.34,8.87],
[1063,5.82,8.18,3.33,6.01,8.62,8.36,15.6,1.55,22.12,5.35,8.79],
[775,6.73,9.41,4.15,6.01,9.59,8.28,14.31,1.92,25.85,5.16,9.14],
[5982,6.31,8,4.04,5.87,9.27,7.88,21.72,0.9,22.09,7.76,8.96],
[3629,6.53,9.21,3.97,5.74,9.62,8.18,18.62,1.11,23.57,7.09,9.23],
[962,6.8,9.55,4.05,6.27,10.09,8.29,16.98,1.69,26.12,6.27,9.38],
[670,7.26,10.44,5.52,6.19,10.44,9.56,20.03,2.44,26.89,6.58,9.8],
[6026,7.41,9.3,5.31,5.48,9.33,7.69,21.99,0.84,24.06,9,8.7],
[3711,7.6,10.79,5.15,5.69,9.52,8.06,19.36,1.4,26.51,7.78,9.62],
[1108,7.74,11.48,5.31,5.85,9.84,8.63,18.25,1.51,29.3,6.91,10.12],
[635,8.07,12.06,5.58,6.41,10.45,9.65,18.95,2.36,31.43,6.59,11.44],
[8083,8.19,10.7,6.82,6.07,11.22,8.61,24.82,1.12,31.74,5.81,10.21],
[1640,8.32,12.05,6.48,5.83,11.5,9.08,19.99,1.13,35.85,5.21,9.12],
[674,7.93,12.78,6.71,6.2,12.2,10.1,19.26,2.14,35.46,5.44,9.34],
[464,8.32,12.98,6.89,6.54,12.01,10.46,19.55,1.5,41.67,4.68,9.46],
[7766,7.5,10.33,7.44,5.98,11.29,8.49,23.74,0.83,29.63,6.06,10.72],
[1682,7.59,12.63,7.98,6.19,11.68,9.61,23.61,1.06,36.36,5.64,10.48],
[747,7.66,13.66,7.59,6.23,12.31,9.97,21.64,1.22,41.31,5.96,10.16],
[658,7.42,13.45,7.73,6.47,12.3,11.04,22.53,1.71,39.93,6.06,11.24],
[7766,6.58,10.3,7.49,5.83,10.83,8,23.72,0.71,27.56,6.41,10.48],
[1682,6.65,11.65,7.57,6.15,11.71,9.71,23.94,0.85,33,6.02,10.98],
[747,6.48,13.5,7.69,6.39,12.51,9.98,25.23,1.02,35.72,6.85,11.14],
[658,6.61,11.66,7.61,6.56,13.17,10.43,22.75,1.33,37.88,6.35,11.68],
[4655,5.75,10.89,6.93,5.75,10.7,6.51,22.47,0.62,24.07,5.86,9.45],
[1387,6.11,12.69,6.83,5.82,10.7,7.16,20.92,0.74,26.37,5.73,9.42],
[941,5.94,14.09,7.04,5.68,11.17,7.53,21.38,0.84,27.02,5.46,9.63],
[1155,5.89,12.71,6.74,6.11,11.15,7.89,21.24,0.9,29.59,5.79,10.1],
[4432,5.68,9.88,7.08,5.82,10.6,7.1,23.65,0.59,23.69,6.18,9.87],
[1314,5.75,13.46,6.97,6.05,10.87,7.99,22.73,0.76,26.32,6.03,9.8],
[980,5.91,13.5,7.15,6.13,11.06,8.15,22.26,0.9,26.96,5.83,9.79],
[1429,5.93,12.78,7.2,6.57,11.68,8.37,21.57,0.89,28.39,5.91,10.58],
[3822,5.74,9.87,7.19,5.74,10.95,6.82,23.46,0.59,24.65,6.13,9.73],
[1451,5.93,12.41,7.21,6.16,11.35,7.64,24.57,0.81,26.64,6.28,10.64],
[1048,5.76,14.29,7.39,6.41,11.81,7.87,23.46,0.79,27.09,6.17,10.94],
[1568,6.12,12.99,7.38,6.58,12.26,8.62,23.3,0.87,31.14,6.28,11.29
    
    ],],
    female: [
[1799,5.03,3.55,2.11,4.77,7.3,5.32,9.01,1.09,16.91,2.2,6.92],
[5404,4.93,3.6,2.04,4.66,7.29,5.04,8.08,1.3,17.17,1.99,6.8],
[1757,5.01,3.97,2.09,4.5,7.13,5.13,6.89,1.34,17.79,1.91,6.86],
[1299,5.05,3.75,2.07,4.46,7.31,4.95,6.74,1.36,17.3,1.91,6.57],
[2295,5.31,4.1,2.43,5.01,7.66,6.71,12.5,1.1,18.4,2.88,7.75],
[5582,5.36,4.55,2.41,4.92,7.64,6.08,10.61,1.1,17.92,2.63,7.45],
[1556,5.56,5.48,2.37,5.05,7.79,6.09,9.1,1.36,18.38,2.44,7.56],
[1224,5.52,4.67,2.44,4.8,7.69,6.11,9.59,1.4,18.27,2.93,7.56],
[3393,5.81,5.38,2.96,5.53,8.33,7.87,15.27,0.95,19.84,3.56,8.5],
[4890,5.85,5.52,2.87,5.35,8.14,7.52,12.25,1.16,20.32,3.29,8.12],
[1351,5.96,6,2.76,5.55,8.33,7.5,11.61,1.4,19.94,2.88,8.17],
[1004,5.93,6.35,2.85,5.38,8.39,7.64,10.82,1.27,20.9,3.05,8.56],
[4139,6.34,6.16,3.37,5.77,8.84,7.41,16.71,0.87,20.95,4.33,8.64],
[4461,6.59,7.01,3.34,5.41,9.07,7.39,13.33,1.11,21.46,3.58,8.4],
[1275,6.86,7.79,3.33,5.43,8.95,7.67,12.06,1.2,21.57,3.37,8.55],
[766,7.36,8.25,4.38,5.66,8.95,7.91,11.88,1.35,23.82,3.62,8.97],
[4335,6.96,7.29,4.11,5.4,9.24,7.15,18.35,0.78,21.47,5.05,8.21],
[4746,7.12,8.18,4.13,5.16,9.39,7.18,14.11,1.07,22.11,4.36,8.58],
[1262,7.19,8.67,4.02,5.17,10.11,7.24,13.52,2.04,23.52,4.13,9.03],
[677,7.29,9.08,4.21,5.2,10.94,8.48,13.43,1.24,24.78,4.11,9.77],
[3791,6.53,7.92,4.41,5.25,9.24,6.83,20.13,0.78,21.87,5.92,7.87],
[4509,6.46,8.74,4.37,5.2,9.89,7.07,14.77,0.84,23.85,4.75,8.58],
[1744,6.39,9.15,4.59,5.02,10.18,7.14,13.38,0.96,23.38,4.19,8.81],
[847,6.81,9.33,4.75,5.56,10.24,8.06,14.1,1.28,27.13,4.16,9.95],
[6079,6.06,8.15,4.68,5.73,10.77,7.49,19.38,0.86,27.4,4.11,10.77],
[2259,5.82,8.83,4.44,5.46,11.14,7.22,14.9,1.44,27.43,3.67,9.84],
[1176,5.89,8.62,4.48,5.24,11.08,7.77,13.16,1.67,29.83,3.46,9.81],
[675,5.87,8.91,5.83,5.61,11.87,8.36,12.66,2.29,34.33,4.68,9.47],
[6306,5.59,7.59,4.68,5.64,10.47,6.94,19.76,0.83,25.08,4.28,10.35],
[1808,5.68,8.5,4.73,5.73,10.92,7.62,15.18,1.07,28.37,3.71,10.74],
[1083,5.92,9.82,4.61,5.7,11.08,7.1,13.81,0.89,28.67,3.12,10.27],
[847,5.47,8.99,4.57,5.9,11.76,7.88,13.66,1.71,31.2,3.31,10.75],
[5647,5.45,7.68,4.74,5.69,10.21,6.86,19.45,0.77,25.1,4.5,10.29],
[2073,5.41,8.39,4.89,5.98,10.85,8.17,16.6,0.97,27.13,3.86,11.14],
[1328,5.44,9.27,4.7,6,10.92,7.99,15.04,1.02,27.32,3.77,11.07],
[1082,5.63,8.52,4.79,6.24,11.58,8.24,14.41,1.06,28.34,3.55,11.13],
[2512,5.18,7.49,4.43,5.62,9.91,5.83,17.27,0.71,21.33,4.26,9.94],
[1625,5.32,8.66,4.4,5.71,9.73,6.02,14.66,0.79,21.54,3.64,9.81],
[1521,5.34,8.9,4.35,5.8,10.38,5.97,13.17,0.81,21.41,3.52,9.9],
[1986,5.38,8.44,4.47,6.16,10.57,6.32,12.91,0.98,23.24,3.48,10.3],
[2253,5.24,7.21,4.48,5.85,9.53,6.44,19.56,0.8,21.62,4.38,10.37],
[1449,5.22,8.5,4.4,5.99,10.3,6.47,14.1,0.95,21.3,3.91,10.22],
[1457,5.37,9.54,4.55,5.78,9.95,5.95,13.46,0.91,22.02,3.69,10.18],
[2328,5.21,8.83,4.6,6.04,10.68,6.97,13.81,1.02,22.81,3.62,10.59],
[2051,5.37,7.54,4.54,5.79,9.49,5.89,20.38,0.76,21.76,4.5,10.1],
[1344,5.38,8.65,4.69,5.87,9.84,6.16,15.17,0.98,22.62,3.97,10.65],
[1460,5.17,8.98,4.43,5.97,10.06,6.1,14.5,0.9,21.91,3.84,10.34],
[2522,5.47,8.63,4.55,6.2,10.92,6.46,13.83,1.01,22.44,3.75,10.82]
    ]
  },
  /**
   * 1日の運動・スポーツの実施時間
   */
  q4: {
    male: [
[2439,5.17,4.38,2.17,4.91,7.36,5.63,9.07,1.76,19.57,2.94,6.96],
[4095,5.01,4,2.26,4.99,7.31,5.49,10.44,1.43,19.31,3.19,6.85],
[3379,5.02,3.72,2.33,4.83,7.03,5.49,11.43,1.32,18.33,3.19,6.89],
[906,5.02,3.59,2.28,4.8,7.48,5.79,12.94,1.12,18.71,4.19,7.22],
[2258,5.45,5.54,2.58,5.41,7.63,6.7,12.36,1.55,20.84,3.91,7.67],
[3546,5.44,4.87,2.73,5.15,7.67,6.5,13.17,1.23,19.77,4.05,7.49],
[3497,5.25,4.77,2.59,5.26,7.38,6.41,14.87,1.1,18.75,4.21,7.39],
[1811,5.34,4.6,2.78,5.09,7.48,7.12,16.42,0.91,18.56,5.33,7.61],
[2485,5.73,7.33,3.5,5.99,8.61,8.03,14.34,1.85,22.18,4.66,8.5],
[2929,5.62,6.38,3.09,5.92,8.28,8.14,15.88,1.14,20.53,4.93,8.29],
[2932,5.56,5.96,2.84,5.92,7.92,8.39,17.47,1.05,20.67,5.12,8.25],
[2835,5.59,6.04,3.35,5.99,8.33,8.44,18.46,0.98,20.78,6.44,8.14],
[2116,6.23,8.48,3.83,6.2,9.27,8.5,15.59,1.68,24.31,5.78,9.04],
[2696,5.83,7.68,3.47,5.92,9.05,8.19,17.39,1.16,22.42,5.67,8.89],
[2843,6.04,7.39,3.34,5.94,8.92,7.98,19.06,1.05,21.7,5.89,8.62],
[3540,5.8,7.1,3.79,6.2,9.01,8.24,20.17,0.88,21.24,7.22,8.67],
[1862,6.88,10.04,4.21,5.97,9.72,8.66,17.85,1.36,25.09,5.95,9.55],
[2538,6.61,8.96,3.99,5.8,9.7,8.14,18.21,1.16,23.68,6.75,9.23],
[2904,6.37,8.57,3.95,5.81,9.6,8.11,21.01,1.16,23.13,6.89,9.24],
[3935,6.27,7.93,4.24,5.89,9.3,7.71,21.65,1.17,22.09,8.04,8.66],
[2045,7.88,12.04,5.42,5.93,9.98,8.99,18.78,2.01,29.42,7.02,10.66],
[2472,7.68,10.53,5.21,5.76,9.53,8.34,19.67,1.27,27.28,7.73,9.8],
[3106,7.43,9.65,5.02,5.56,9.4,7.93,21.25,0.94,24.61,7.88,9.21],
[3858,7.35,9.33,5.39,5.5,9.33,7.55,22.13,0.85,23.99,9.28,8.43],
[1257,8.38,12.68,6.82,6.27,12.19,10.28,20.28,1.43,38.4,5.31,9.53],
[1526,8.12,12.22,6.53,6.05,11.56,9.38,21.98,2.05,36.09,5.31,9.76],
[3778,8.24,10.97,6.74,5.84,11.25,8.52,22.83,1.02,31.75,5.5,9.6],
[4304,8.14,10.45,6.81,5.98,11.01,8.44,25.06,0.88,31.32,5.85,10.04],
[1274,7.72,12.47,7.58,6.43,11.84,10.47,23.5,1.44,38.38,6.22,11.32],
[1323,7.76,12.15,7.41,6.17,12.15,9.5,25.04,1.09,36.24,5.99,11.12],
[3909,7.38,10.86,7.36,5.97,11.32,8.68,23.83,0.93,30.3,5.83,10.49],
[4387,7.5,10.23,7.6,5.89,11.17,8.21,23.23,0.7,29.38,5.97,10.41],
[1543,6.66,12.78,7.79,6.51,12.68,10.33,25.45,1.26,37.5,6.82,12.03],
[1725,6.4,11.6,7.48,6.33,11.54,9.64,24.9,0.85,32.99,6.45,11.26],
[3774,6.51,10.56,7.4,5.65,10.97,8.3,23.67,0.71,27.23,6.22,10.42],
[3813,6.72,9.91,7.47,5.79,10.67,7.54,23.03,0.62,26.86,6.28,10.01],
[2164,5.87,12.84,6.72,6.03,11.18,7.6,21.51,0.87,28.52,5.77,9.97],
[1306,5.97,13.11,7.11,6,10.72,7.28,21.91,0.77,27.47,5.68,9.92],
[1621,5.94,11.7,6.79,5.67,10.37,7.01,22.96,0.65,24.44,5.84,9.34],
[3038,5.73,10.62,6.98,5.63,10.81,6.36,21.9,0.58,23.49,5.74,9.25],
[2506,5.92,12.98,7.11,6.46,11.42,8.15,22.23,0.9,28.11,5.95,10.42],
[1236,5.75,13.38,7.1,6.1,10.94,7.96,22.81,0.75,26.35,5.99,9.95],
[1445,5.74,10.92,6.81,5.84,10.89,7.41,24.6,0.62,24.71,6.02,10.06],
[2968,5.65,9.48,7.16,5.73,10.54,7.08,22.98,0.56,22.64,6.14,9.52],
[2768,5.98,12.88,7.3,6.55,12.03,8.28,24.13,0.9,29.95,6.38,11.45],
[1354,6.02,12.87,7.3,6.22,11.55,7.92,25.35,0.69,26.54,6.26,10.96],
[1243,5.7,11.8,7.08,6.17,11.51,7.37,24.74,0.69,26.52,6.03,10.32],
[2525,5.69,9.27,7.27,5.6,10.55,6.57,22.21,0.53,23.16,5.99,9.04
    ],],
    female: [
[2881,5.07,3.89,2.09,4.69,7.08,5.07,7.08,1.36,17.59,1.88,6.82],
[4239,4.93,3.55,2.04,4.57,7.34,5.03,7.84,1.31,17.15,2.01,6.84],
[2663,4.98,3.66,2.06,4.63,7.3,5.23,8.56,1.19,17.32,2.07,6.87],
[474,4.88,3.53,2.27,4.71,7.57,5.13,9.94,1.21,17.07,2.33,6.82],
[2856,5.46,5.05,2.39,4.91,7.63,6.12,9.87,1.32,18.16,2.71,7.61],
[4027,5.41,4.45,2.38,4.96,7.66,6.28,10.45,1.09,18.49,2.58,7.52],
[2964,5.36,4.5,2.46,4.93,7.64,6.04,11.23,1.17,17.94,2.67,7.55],
[810,5.25,4.28,2.42,5.29,7.91,6.91,13.58,1.17,17.34,3.23,7.88],
[3141,5.93,5.84,2.85,5.51,8.34,7.5,11.44,1.37,20.92,2.92,8.32],
[3583,5.86,5.57,2.88,5.26,8.11,7.52,12.38,1.01,20.32,3.35,8.17],
[2522,5.8,5.56,2.78,5.42,8.33,7.77,13.91,1.13,19.61,3.21,8.32],
[1392,5.8,5.37,3.11,5.76,8.28,8.02,16.54,0.93,19.71,4.01,8.64],
[2990,6.91,7.63,3.59,5.43,8.99,7.6,12.49,1.18,22.48,3.47,8.76],
[3525,6.53,6.97,3.39,5.44,8.99,7.3,13.23,0.99,21.07,3.56,8.45],
[2428,6.41,6.37,3.32,5.53,9.12,7.39,15.39,1.15,21.03,3.75,8.55],
[1694,6.34,6.08,3.38,5.85,8.59,7.75,18,0.84,20.96,4.9,8.36],
[2820,7.28,8.45,4.1,5.12,10.17,7.57,13.67,1.56,22.82,3.96,9.04],
[3528,7.07,8.14,4.09,5.18,9.6,7.19,14.43,1.17,22.77,4.41,8.77],
[2836,6.96,7.66,4.03,5.41,9.16,7.21,16.1,0.82,22.08,4.49,8.52],
[2103,6.97,7.5,4.28,5.48,8.99,7.23,19.46,0.8,21.75,5.5,8.07],
[3097,6.59,8.93,4.43,5.29,10.01,7.51,14,1.03,24.92,4.33,9.22],
[3125,6.38,8.79,4.52,5.08,9.93,6.91,14.53,0.86,23.68,4.56,8.6],
[2570,6.43,8.32,4.37,5.41,9.57,7.14,16.71,0.83,22.69,5.08,8.45],
[2100,6.64,8.1,4.46,5.29,9.38,6.9,21.27,0.75,21.46,6.28,7.57],
[2200,5.92,8.91,4.95,5.38,11.26,7.65,13.62,1.92,30,3.8,9.84],
[2008,5.91,8.74,4.54,5.45,10.98,7.6,15.55,1.39,30.08,3.46,10.07],
[3332,6.06,8.31,4.6,5.54,10.82,7.42,16.84,0.8,25.66,3.91,10.26],
[2653,5.96,7.83,4.63,5.77,10.76,7.34,20.8,0.81,27.73,4.3,10.47],
[2383,5.61,9.22,4.71,5.83,11.35,7.73,15.05,1.15,30.05,3.53,11.04],
[1745,5.84,8.56,4.67,5.75,10.76,7.21,16.68,1.36,27.94,3.73,10.76],
[3078,5.63,7.74,4.61,5.59,10.34,7.06,18.24,0.77,24.49,4.01,10.31],
[2838,5.53,7.31,4.72,5.66,10.57,6.98,20.48,0.73,25.46,4.43,10.13],
[2654,5.48,8.62,4.9,6.11,10.96,7.96,15.37,1.05,27.64,3.65,11.2],
[2097,5.38,8.45,4.83,6.18,11.17,8.38,17.08,0.94,27.53,3.98,11.48],
[2983,5.47,8.44,4.52,5.66,9.93,6.75,18.33,0.74,25.08,4.22,10.26],
[2396,5.48,6.86,4.89,5.51,10.53,6.86,19.92,0.79,24.49,4.65,9.68],
[3735,5.33,8.38,4.4,6.02,10.49,6.21,13.47,0.88,22.56,3.55,10.24],
[1507,5.38,8.93,4.41,5.92,9.85,5.87,14.38,0.88,22.15,3.66,10.2],
[1072,5.1,7.97,4.5,5.59,10.08,5.93,15.92,0.7,21.02,3.97,9.86],
[1330,5.18,7.4,4.43,5.59,9.75,5.95,18.21,0.68,20.65,4.35,9.56],
[3946,5.25,8.96,4.53,6.13,10.51,6.7,13.42,0.98,22.63,3.66,10.66],
[1306,5.21,8.46,4.43,5.72,9.95,6.35,15.32,0.86,21.77,3.8,10.19],
[905,5.22,8.35,4.49,5.8,10.28,6.3,17.56,0.95,21.94,4.16,10.28],
[1326,5.3,6.85,4.6,5.8,9.45,6.26,19.56,0.77,20.72,4.44,10],
[4154,5.36,8.62,4.51,6.13,10.63,6.29,14.39,0.98,22.18,3.8,10.81],
[1300,5.46,9.02,4.63,6.06,10.22,6.3,15.5,0.92,22.54,4.02,10.66],
[788,5.37,7.46,4.47,5.88,9.83,6.02,17.92,0.85,22.85,4.17,10.25],
[1137,5.29,7.47,4.61,5.51,9,5.9,20.27,0.7,21.16,4.49,9.35]
    ]
  },
  /**
   * 朝食の有無
   */
  q5: {
    male: [
[9949,5.04,3.92,2.27,4.96,7.28,5.59,11.04,1.47,19.25,3.35,7.12],
[800,5.25,4.57,2.29,4.96,6.95,5.85,9.42,1.43,19.73,3.21,7.02],
[71,4.95,4.3,2.25,5.24,7.62,5.99,9.63,2.84,21.17,3.5,7.88],
[9978,5.38,4.89,2.71,5.32,7.55,6.72,14.92,1.25,19.88,4.54,7.91],
[1005,5.22,5.29,2.48,5.5,7.55,6.78,13.65,1.25,20.59,4.38,7.63],
[126,5.36,6.2,2.71,5.14,7.93,6.95,14.05,1.2,20.53,3.83,7.9],
[9548,5.64,6.37,3.25,6.1,8.26,8.44,17.83,1.2,21.5,5.75,8.84],
[1431,5.56,6.75,3.11,6.13,8.68,8.49,17.06,1.93,22.14,5.4,8.78],
[203,5.89,6.28,2.93,6.07,8.24,8.6,16.94,1.1,21.53,5.59,9.01],
[9494,5.94,7.45,3.67,6.31,9.08,8.6,20.19,1.22,22.75,6.93,9.63],
[1470,6.05,8.47,3.76,6.49,9.23,8.46,18.74,1.32,24.28,6.21,9.47],
[228,6.18,7.06,3.66,6.5,9.12,7.94,20.17,1.19,24.16,6.22,9.92],
[9331,6.47,8.57,4.2,6.12,9.52,8.5,22.41,1.2,24.16,7.93,10.05],
[1640,6.48,9.36,4.03,6.25,9.99,8.42,20.88,1.46,24.17,7.43,9.87],
[273,6.84,9.82,4.25,6.73,10.63,9.03,22.21,1.38,25.38,6.98,10.46],
[9441,7.54,10.01,5.33,5.93,9.57,8.49,23.43,1.2,26.5,9.16,10.39],
[1686,7.58,11.28,5.44,6.4,9.88,8.65,21.84,1.15,29.38,8.36,10.32],
[351,7.42,10.41,5.4,6.05,10.33,9.02,23.8,3.21,27.09,8.34,10.99],
[9022,8.23,10.95,6.7,6.22,11.37,8.96,25.36,1.28,33.49,5.86,10.61],
[1545,8.01,12.17,7.35,6.63,11.93,10.21,24.71,1.1,35.71,6.56,10.58],
[293,8.35,12.04,8.01,6.71,11.28,9.88,23.17,1.52,38.96,5.74,10.53],
[8777,7.55,10.61,7.52,6.28,11.45,8.92,25.58,0.96,31.85,6.37,11.51],
[1682,7.31,12.09,7.75,6.58,11.9,10,25.2,1.05,34.95,6.48,11.72],
[431,7.65,13,8.85,6.63,12.99,11.11,25.57,1.13,38.39,6.27,11.52],
[8675,6.59,10.67,7.61,6.21,11.24,8.83,25.77,0.83,30.23,6.84,11.65],
[1712,6.71,11.43,7.73,6.55,11.7,9.7,26.8,0.87,31.57,6.7,11.91],
[462,6.06,11.92,8.14,6.92,13.12,9.72,26.83,1.04,35.72,6.81,12.24],
[6335,5.87,11.63,6.96,6.01,10.94,7.21,24.2,0.74,26.46,6.22,10.6],
[1226,5.94,13.1,7.24,6.3,11.14,7.49,23.76,0.76,26.12,6.01,10.44],
[564,5.79,12.28,7.26,6.66,11.34,8.28,23.46,0.82,27.05,6.19,10.69],
[6028,5.7,11.09,7.14,6.39,11.14,7.77,27.11,0.7,26.12,6.61,11.55],
[1349,5.94,13.04,7.6,6.78,10.98,8.36,26.73,0.87,26.46,6.4,11.4],
[775,6.09,12.17,7.09,6.87,11.81,9.15,25.9,0.9,28.54,6.31,11.05],
[5717,5.86,11.15,7.4,6.53,11.57,7.69,27.06,0.7,27.82,6.74,11.82],
[1339,6,13.06,7.51,6.7,11.73,8.33,27.35,0.84,27.63,6.57,11.85],
[821,5.8,13.07,7.53,6.93,12.16,8.8,25.35,1.02,27.18,6.33,11.32
    ],],
    female: [
[9640,4.97,3.61,2.06,4.66,7.24,5.12,8.06,1.29,17.37,2.02,6.92],
[733,5.1,4.3,2.2,4.58,7.5,5.07,7.52,1.32,17.7,2.11,7.12],
[67,5.08,5.25,2.08,5.02,8.88,5.68,6,1.44,17.93,2.03,6.31],
[9683,5.39,4.58,2.41,5.02,7.66,6.28,11.07,1.15,18.42,2.68,7.77],
[883,5.52,5.07,2.55,5,7.98,6.28,10.44,1.59,17.97,3.32,7.86],
[90,4.88,4.45,2.28,4.77,7.34,6.82,10.13,1.36,20.34,3.03,8.71],
[9352,5.85,5.52,2.88,5.53,8.29,7.68,13.66,1.16,20.32,3.38,8.56],
[1144,5.87,6.23,2.94,5.64,8.38,7.96,13.6,1.13,21.57,3.3,8.99],
[141,6.09,6.97,3.12,6.07,8.33,8.63,11.7,1.33,24.94,3.23,9.31],
[9223,6.56,6.75,3.43,5.74,9.04,7.65,15.62,1.09,21.96,3.99,9.12],
[1267,6.68,7.72,3.64,5.87,8.99,7.91,14.5,1.07,22.34,4.1,9.04],
[150,6.93,7.97,3.45,5.84,9.14,8.35,14.72,1.06,23.67,3.84,9.37],
[9199,7.06,7.83,4.01,5.49,9.52,7.47,17.5,1.21,22.85,4.77,9.24],
[1608,7.05,8.56,4.75,5.69,9.95,7.97,16.68,0.98,23.99,4.92,9.58],
[212,8.08,8.77,4.34,5.39,10.35,8.57,14.08,0.95,24.55,4.87,9.05],
[8727,6.57,8.44,4.46,5.49,9.81,7.33,18.81,0.91,23.95,5.39,9.26],
[1874,6.27,8.99,4.56,5.67,10.22,7.59,16.99,0.96,25.65,5.38,9.3],
[287,5.86,9.3,4.76,5.77,9.96,8.06,16.53,1.01,25.61,4.89,9.61],
[8038,6.03,8.32,4.64,5.76,10.86,7.61,19.06,1.11,28.6,3.97,11.22],
[1894,5.76,8.74,5.07,6.23,11.77,8.25,17.7,1.87,30.36,4.81,11.35],
[260,5.68,8.44,4.93,5.7,11.33,8.56,17.21,0.95,33.34,3.91,10.99],
[7751,5.68,7.91,4.67,6.01,10.6,7.42,20.45,1.02,27.9,4.3,11.76],
[1953,5.53,8.73,4.98,6.06,11.56,8.01,18.99,1.1,28.84,4.26,12.07],
[339,5.15,9.27,5.34,6.51,12.52,8.26,19.12,1.2,28,4.43,11.97],
[7718,5.43,8.03,4.82,6.12,10.51,7.69,20.29,0.93,26.86,4.52,11.84],
[2042,5.61,8.63,5.07,6.54,11.27,8.05,19.53,0.97,28.47,4.52,12.42],
[369,5.16,7.68,5.08,6.33,11.78,8.96,18.6,0.89,29.3,4.38,11.81],
[5948,5.29,8.14,4.4,5.96,10.17,6.2,16.31,0.84,22.58,4.02,10.83],
[1369,5.32,8.56,4.68,6.24,10.54,6.65,15.77,0.89,23.03,3.94,10.84],
[322,5.6,9.98,5.02,6.7,10.52,6.72,15.89,0.98,24.41,3.95,11.26],
[5510,5.27,8.25,4.52,6.24,10.12,6.73,18.48,0.93,23.33,4.23,11.64],
[1490,5.24,8.88,4.74,6.32,10.83,7.29,17,1.07,22.55,4.11,11.53],
[483,5.42,9.63,4.97,6.93,11.19,7.35,15.44,1.08,24.69,3.92,11.04],
[5517,5.41,8.24,4.57,6.28,10.24,6.5,19.17,0.94,23.18,4.34,11.69],
[1432,5.45,9.06,4.74,6.6,10.56,6.42,16.44,1.01,23.14,4.15,11.53],
[421,4.79,8.97,4.94,6.17,11.05,7.16,17.44,1.1,25.28,4.05,11.48]
    ]
  },
  /**
   * 1日の睡眠時間
   */
  q6: {
    male: [
[88,5.04,3.97,2.12,4.96,7.18,6.85,10.13,2.32,18.92,3.09,6.74],
[2183,5.24,4.43,2.4,4.81,7.29,5.55,10.1,1.48,20.1,3.3,7.14],
[8534,5.01,3.85,2.24,5,7.24,5.6,11.11,1.47,19.1,3.35,7.12],
[280,5,4.57,2.69,5.41,7.41,7.68,13.41,1.14,19.55,4.2,7.53],
[2763,5.46,5.49,2.68,5.47,7.64,6.77,14.41,1.33,20.36,4.49,7.89],
[8055,5.36,4.74,2.69,5.29,7.53,6.68,14.99,1.23,19.83,4.55,7.9],
[911,5.74,6.81,2.94,6.35,8.95,8.7,17.41,1.21,21.49,5.41,8.81],
[3544,5.59,6.72,3.17,6.13,8.46,8.52,17.3,1.22,21.31,5.54,8.88],
[6690,5.66,6.2,3.3,6.06,8.14,8.39,17.99,1.38,21.73,5.82,8.82],
[887,6.36,8.34,4.77,6.76,10.14,8.76,19.07,1.43,25.67,6.98,9.83],
[4150,5.97,7.86,3.63,6.44,9.1,8.46,19.6,1.16,22.76,6.58,9.55],
[6133,5.9,7.26,3.53,6.19,8.95,8.59,20.37,1.25,22.75,6.96,9.59],
[772,7.04,9.8,5.22,6.41,10.54,9.39,22.68,2.29,26.44,7.76,10.4],
[4701,6.52,8.94,4.13,6.23,9.51,8.47,21.77,1.24,24.34,7.72,10],
[5736,6.38,8.34,4.07,6.08,9.55,8.42,22.56,1.04,23.77,7.91,10.02],
[834,7.99,11.87,5.8,6.64,10.63,9.53,21.41,1.25,29.53,8.87,11.12],
[5349,7.52,10.39,5.44,6,9.53,8.59,22.92,1.24,27.23,8.79,10.42],
[5249,7.48,9.66,5.16,5.9,9.57,8.29,23.68,1.37,26.06,9.28,10.25],
[1117,8.31,12.61,7.67,6.79,12.3,10.2,25.42,1.31,38.74,6.33,10.75],
[6616,8.24,11.2,6.78,6.14,11.36,9.09,25.1,1.2,33.13,5.91,10.54],
[3115,8.09,10.37,6.59,6.42,11.33,8.97,25.43,1.38,33.87,5.93,10.69],
[1404,7.41,11.78,8.05,6.87,12.8,10.48,25.72,1.03,36.32,6.31,11.8],
[7255,7.5,10.98,7.54,6.24,11.37,8.95,25.64,1,31.79,6.38,11.52],
[2226,7.62,10.27,7.5,6.35,11.56,9.22,25.74,0.9,33.25,6.42,11.66],
[1746,6.41,11.25,8.01,6.76,11.99,9.8,26.71,1.02,34.7,6.6,12.2],
[7767,6.64,10.7,7.49,6.14,11.24,8.77,25.71,0.77,29.51,6.76,11.53],
[1333,6.49,11.15,8.12,6.64,11.6,9.55,27.13,1,31.85,7.3,12.23],
[1588,5.89,12.26,7.12,6.44,11.29,7.67,23.94,0.75,27.61,6.15,10.72],
[5898,5.88,11.81,7.01,6.01,10.94,7.22,24.31,0.74,25.83,6.19,10.59],
[644,5.89,11.97,6.84,6.25,11.11,8.03,25.64,0.82,30.26,6.25,11.14],
[2007,5.83,11.69,7.3,6.65,11.64,8.31,26.75,0.77,27.61,6.51,11.52],
[5700,5.78,11.47,7.17,6.43,11.08,7.98,27.48,0.77,26.31,6.53,11.64],
[443,5.76,11.96,7.69,7.17,11.7,8.99,29,0.89,28.08,7.03,11.95],
[2090,5.93,12.23,7.51,6.81,12.15,8.47,27.41,0.85,29.51,6.54,11.93],
[5441,5.87,11.39,7.38,6.56,11.54,7.78,27.43,0.74,26.94,6.71,11.89],
[351,5.76,13.23,7.94,7.12,11.96,8.99,29.77,0.77,31.47,6.89,12.65
    ],],
    female:[
[62,4.51,5.13,2.33,4.61,8.74,5.4,8.16,1.52,15.82,1.9,6.83],
[2124,5.07,4.08,2.12,4.45,7.23,5.06,7.87,1.31,17.64,2,6.84],
[8062,4.96,3.54,2.06,4.71,7.27,5.12,8.06,1.29,17.35,2.03,6.95],
[261,5.59,4.31,2.47,4.96,7.77,7.86,11.02,1.35,19.52,2.97,8.6],
[2661,5.53,4.9,2.52,5.1,7.84,6.31,10.81,1.37,18.54,2.67,7.89],
[7723,5.35,4.53,2.39,5,7.63,6.21,11.08,1.12,18.31,2.76,7.72],
[704,5.92,5.72,3.02,5.84,8.07,7.77,13.33,1.15,24.35,3.6,8.83],
[3282,6,5.91,2.91,5.56,8.28,7.8,13.25,1.18,20.55,3.51,8.61],
[6633,5.78,5.45,2.87,5.51,8.32,7.66,13.85,1.16,20.01,3.27,8.58],
[607,6.85,7.15,3.83,5.74,8.74,8.42,15.21,1.09,24.05,4.23,9.31],
[3959,6.66,7.18,3.4,5.79,9.2,7.68,15.21,1.02,22.48,3.98,9.1],
[6060,6.51,6.69,3.45,5.72,8.94,7.59,15.63,1.13,21.49,3.99,9.05],
[621,7.58,9.38,4.71,5.69,10.65,8.27,16.53,1.82,25.21,4.66,10.02],
[4670,6.98,8.08,4.23,5.52,9.65,7.64,17.06,1.33,23.67,4.9,9.32],
[5719,7.09,7.65,3.99,5.49,9.43,7.43,17.63,0.92,22.29,4.72,9.16],
[771,6.57,9.52,4.9,5.95,10.49,8.17,17.75,1.08,27.24,5.89,9.95],
[5728,6.4,8.59,4.49,5.48,9.88,7.35,18.53,0.91,24.27,5.24,9.23],
[4367,6.61,8.34,4.39,5.55,9.78,7.38,18.76,0.92,23.98,5.46,9.3],
[1252,5.77,9.12,5.04,6.08,11.82,8.28,18.71,1.14,33.76,4.14,11.54],
[6646,6.02,8.3,4.67,5.74,10.88,7.71,18.81,1.34,28.51,4.12,11.15],
[2287,5.94,8.21,4.73,6.07,11.09,7.69,19.09,1.19,28.1,4.17,11.43],
[1735,5.7,9.19,5.14,6.2,11.5,7.99,20.17,1.36,30.94,4.28,12.03],
[6833,5.58,7.86,4.67,5.99,10.65,7.44,20.26,0.95,27.15,4.26,11.75],
[1471,5.81,8.07,4.7,6.09,11.07,7.8,20.56,0.99,29.41,4.49,12.22],
[2248,5.44,8.25,5.06,6.23,11.24,7.88,19.46,1.13,28.75,4.47,11.76],
[6996,5.46,7.99,4.78,6.19,10.48,7.76,20.43,0.87,26.44,4.52,11.98],
[886,5.48,8.92,5.25,6.54,11.42,8.5,20.8,0.97,30.28,4.65,12.95],
[2043,5.31,8.23,4.61,6.15,10.56,6.49,16.7,0.83,22.9,4.08,11.09],
[5121,5.3,8.23,4.43,5.98,10.08,6.21,16.18,0.87,22.75,3.97,10.77],
[480,5.54,9.23,4.48,6.49,10.86,6.74,15.91,0.9,23.22,4.15,11.59],
[2554,5.16,8.58,4.74,6.44,10.52,7.08,18.23,1.01,23.68,4.21,11.69],
[4630,5.33,8.36,4.48,6.29,10.24,6.9,18.23,0.94,23.1,4.16,11.63],
[300,5.29,9.28,5.16,6.36,11.05,6.9,18.58,1.14,25.56,4.59,12.96],
[2734,5.21,8.43,4.67,6.36,10.38,6.56,19.02,0.97,23.16,4.21,11.66],
[4390,5.45,8.39,4.59,6.36,10.37,6.55,18.72,0.98,23.68,4.33,11.84],
[247,6.05,9.52,5.05,6.44,10.52,7.26,16.5,1.01,23.15,4.38,11.5
    ],],
  },

  /**
   * 1日のテレビ（テレビゲーム含む）の視聴時間
   */
  q7: {
    male: [
[1346,4.96,3.7,2.26,5.12,7.44,5.41,11.48,1.48,18.45,3.3,6.84],
[4777,5.03,3.85,2.28,4.93,7.16,5.58,11.36,1.4,19.22,3.42,7.17],
[3317,5.08,4.04,2.26,4.96,7.33,5.73,10.36,1.53,19.62,3.33,7.15],
[1381,5.19,4.45,2.27,4.87,7.24,5.6,9.99,1.63,19.56,3.01,6.99],
[1505,5.33,4.61,2.68,5.55,7.59,6.92,15.62,1.18,19.69,4.61,8.04],
[4459,5.37,4.65,2.66,5.31,7.47,6.79,15.31,1.21,19.67,4.54,7.88],
[3310,5.34,5.15,2.73,5.24,7.68,6.63,14.13,1.3,19.98,4.47,7.81],
[1840,5.48,5.43,2.7,5.37,7.48,6.62,14.07,1.32,20.62,4.49,7.85],
[2290,5.58,6.3,2.96,6.05,8.49,8.51,18.32,1.2,21.66,5.79,8.71],
[3761,5.61,6.05,3.14,6.1,8.19,8.58,17.86,1.19,21.87,5.81,8.85],
[2589,5.62,6.36,3.67,5.91,8.13,8.26,17,1.21,20.58,5.54,8.61],
[2542,5.75,7.02,3.11,6.34,8.51,8.4,17.4,1.64,22.01,5.61,9.1],
[2130,5.89,6.99,3.98,6.61,9.26,9.1,20.94,1.14,24.19,7.22,10.08],
[3559,5.86,7.26,3.59,6.12,8.92,8.28,19.97,1.22,22.19,6.96,9.4],
[2430,5.89,7.53,3.39,6.21,9.07,8.57,19.88,1.27,22.31,6.66,9.58],
[3075,6.19,8.27,3.78,6.42,9.22,8.49,19.17,1.26,23.52,6.47,9.46],
[1578,5.89,6.99,3.98,6.61,9.26,9.1,20.94,1.14,24.19,7.22,10.08],
[3454,6.48,8.21,4.08,6.08,9.4,8.51,22.36,0.99,24.32,7.85,9.96],
[2620,6.39,8.75,4.38,6.09,9.61,8.29,22.28,1.44,22.96,7.98,9.78],
[3590,6.63,9.46,4.13,6.19,9.69,8.51,21.07,1.37,24.6,7.53,10.02],
[1330,7.5,9.93,5.57,6.31,9.74,9.35,24.48,1.46,27.34,9.49,10.67],
[3337,7.29,9.2,5.06,5.94,9.42,8.39,23.33,1.45,26.57,9.09,10.24],
[2838,7.45,10.18,5.21,5.7,9.27,8.08,22.79,1.08,25.68,8.99,10.14],
[3976,7.82,11,5.6,6.11,10.03,8.68,22.65,1.25,28,8.81,10.57],
[1214,8.14,10.74,7.27,6.62,12.03,9.85,26.62,1.14,37.28,6.7,10.88],
[2939,8.12,10.26,6.68,6.2,11.13,9,25.21,1.01,31.77,5.8,10.56],
[2777,8.27,10.89,6.66,6.2,11.47,8.85,25.04,1.04,32.2,5.77,10.7],
[3919,8.22,12.03,6.93,6.27,11.47,9.31,24.57,1.57,35.43,5.94,10.36],
[1042,7.49,10.17,7.97,6.7,11.58,9.55,25.93,1.18,35.67,6.71,12.09],
[2539,7.6,10.19,7.39,6.14,11.19,8.98,25.54,0.97,31.71,6.32,11.35],
[2923,7.55,10.88,7.43,6.14,11.29,8.75,25.03,0.93,31.01,6.22,11.44],
[4325,7.45,11.62,7.76,6.49,12.01,9.58,25.99,0.98,33.78,6.43,11.65],
[1232,6.73,10.26,7.79,6.53,11.71,9.68,26.07,0.92,29.85,6.8,11.55],
[2541,6.49,10.73,7.47,6.15,11.08,8.97,25.42,0.83,28.61,6.78,11.56],
[2889,6.46,10.21,7.56,5.97,11.15,8.45,25.36,0.81,29.76,6.84,11.63],
[4192,6.69,11.47,7.77,6.51,11.7,9.29,26.82,0.86,32.73,6.79,11.93],
[1950,5.79,10.51,7.12,6.01,10.66,7.22,23.76,0.72,24.71,6.29,10.26],
[2295,5.93,11.38,6.85,5.75,10.78,6.86,23.49,0.66,24.71,6.09,10.21],
[1727,5.81,11.73,6.94,6,10.77,7.29,23.73,0.8,26.91,5.92,10.43],
[2164,5.95,13.64,7.13,6.27,11.43,7.73,23.7,0.8,28.46,6.1,10.59],
[2047,5.65,10.45,7.32,6.43,11.1,8.18,27.51,0.8,25.95,6.6,11.29],
[2145,5.68,10.39,7.12,6.18,11.08,7.7,26.22,0.68,25.61,6.52,11.14],
[1615,5.86,11.76,7.1,6.38,10.91,7.86,26.76,0.79,27.03,6.56,11.57],
[2349,5.95,13.18,7.24,6.62,11.35,8.26,26.47,0.81,27.06,6.29,11.29],
[2376,5.83,10.34,7.47,6.4,11.39,7.42,26.53,0.67,24.62,6.57,11.03],
[2191,5.72,10.96,7.3,6.34,11.34,7.5,26.4,0.73,26.8,6.68,11.39],
[1400,5.87,12.06,7.47,6.31,11.98,8.08,26.99,0.83,28.51,6.78,12.02],
[1921,6.07,13.68,7.37,6.95,11.73,8.57,26.82,0.86,30.04,6.34,11.83
    ],],
    female: [
[1711,4.92,3.34,2.05,4.78,7.49,5.25,8.35,1.43,17.6,1.99,6.95],
[4716,4.96,3.63,2.04,4.64,7.19,5.13,8.11,1.26,17.36,2.03,7],
[2786,5.01,3.77,2.12,4.61,7.34,5.1,7.88,1.22,17.28,2.04,6.83],
[1046,5.08,4.12,2.14,4.63,7.11,4.91,7.2,1.41,17.61,2.01,6.83],
[1988,5.25,4.68,2.35,5.06,7.46,6.36,11.57,1.11,17.79,3.01,7.69],
[4618,5.4,4.4,2.38,5,7.74,6.38,11.24,1.21,18.37,2.64,7.83],
[2812,5.42,4.66,2.51,4.98,7.69,6.13,10.53,1.21,18.72,2.68,7.72],
[1237,5.6,5.14,2.49,5.11,7.85,6.16,9.98,1.23,18.49,2.8,7.85],
[3129,5.88,5.42,2.96,5.7,8.41,7.89,14.48,1.04,21.11,3.34,8.57],
[3910,5.73,5.37,2.91,5.46,8.17,7.62,13.56,1.17,19.79,3.56,8.56],
[2091,5.92,5.88,2.83,5.4,8.21,7.73,12.73,1.19,20.15,3.16,8.59],
[1508,6.04,6.2,2.78,5.59,8.48,7.67,13.01,1.34,21.65,3.19,8.85],
[2735,6.53,6.51,3.42,5.85,9.03,8,16.18,0.97,21.95,4.06,9.22],
[3748,6.38,6.43,3.48,5.72,9,7.66,15.39,1.01,21.51,4.03,9.02],
[2099,6.55,7.2,3.29,5.83,8.99,7.42,15.31,1.39,22.46,4.01,9.18],
[2057,6.92,7.68,3.61,5.58,9.17,7.63,14.66,1.04,22.59,3.86,9.07],
[2017,6.98,7.37,4.08,5.61,9.64,7.87,17.94,1.27,23.1,4.75,9.39],
[3714,7.08,7.66,4.01,5.49,9.37,7.4,17.59,1.1,22.92,4.78,9.3],
[2504,7.1,8.04,4.22,5.4,9.49,7.43,17.2,0.92,22.98,4.88,9.07],
[2783,7.01,8.5,4.25,5.57,9.98,7.74,16.54,1.39,23.28,4.77,9.38],
[1469,6.65,7.96,4.53,5.7,10.35,7.76,19.89,0.92,24.37,5.5,9.52],
[3319,6.61,8.22,4.43,5.36,9.94,7.47,18.75,0.93,24.36,5.54,9.31],
[2727,6.36,8.26,4.39,5.6,9.54,7.05,18.74,0.92,24.1,5.29,9.23],
[3377,6.4,9.25,4.59,5.52,9.87,7.5,17.13,0.93,24.54,5.22,9.21],
[1310,5.94,7.86,4.95,5.98,11.2,8.12,20.55,1.06,32.23,4.28,11.48],
[2799,5.95,8.37,4.54,5.65,10.8,7.77,19.08,1.36,27.92,3.94,11.26],
[2512,6.1,7.98,4.54,5.72,10.84,7.53,18.48,0.91,27.32,3.96,11.24],
[3566,5.91,8.84,4.91,6.03,11.31,7.83,17.94,1.5,29.98,4.35,11.11],
[1081,5.72,7.95,5.06,6.18,11.36,8.03,21.08,1.12,30.21,4.38,12.2],
[2636,5.66,7.68,4.67,5.9,10.62,7.35,20.68,0.85,26.77,4.21,11.49],
[2633,5.65,7.74,4.67,5.98,10.7,7.42,20.15,0.97,27.54,4.34,11.75],
[3682,5.58,8.74,4.77,6.12,11,7.71,19.58,1.18,28.92,4.3,12.06],
[1321,5.33,7.64,5.34,6.11,11.43,8.27,21.21,1.03,29.08,4.62,12.13],
[2780,5.48,7.57,4.83,6.15,10.27,7.56,20.38,0.85,26.68,4.5,11.75],
[2732,5.54,8.47,4.79,6.1,10.63,7.77,20.02,0.9,26.45,4.5,12],
[3299,5.43,8.49,4.83,6.4,10.87,7.97,19.75,1.02,27.78,4.51,12.14],
[2458,5.19,7.45,4.43,6.09,10.08,6.34,17.04,0.82,22.83,4.18,10.97],
[2402,5.44,8.3,4.41,5.89,10.19,6.09,16.26,0.84,22.51,3.94,10.84],
[1408,5.22,8.24,4.49,5.92,9.77,6.26,14.97,0.93,22.67,3.89,10.63],
[1374,5.39,9.62,4.67,6.27,10.93,6.55,15.28,0.88,22.98,3.91,10.76],
[2693,5.27,7.81,4.53,6.64,10.53,7.22,19.07,0.98,23.11,4.22,11.8],
[2148,5.24,8.5,4.55,6.11,10.14,6.73,18.07,0.93,22.94,4.19,11.56],
[1204,5.26,8.57,4.52,5.95,9.96,6.85,17.99,1.01,23.78,4.06,11.37],
[1443,5.29,9.47,4.83,6.2,10.62,6.77,15.77,0.99,23.46,4.19,11.47],
[2869,5.35,7.68,4.63,6.36,10.56,6.49,19.11,0.95,23.35,4.34,11.56],
[2123,5.31,8.09,4.59,6.12,10.09,6.42,19.31,0.93,22.34,4.29,11.69],
[1105,5.37,9,4.59,6.32,10.35,6.73,17.38,1,24.21,4.22,11.8],
[1280,5.59,9.91,4.77,6.53,10.17,6.56,16.62,1.05,23.36,4.15,11.42
    ],],
  },
}
