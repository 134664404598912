type ArrayLengthMutationKeys = 'splice' | 'push' | 'pop' | 'shift' | 'unshift'

/**
 * To make sure the array length is fixed number.
 */
export type FixedLengthArray<T, L extends number, TObj = [T, ...T[]]> = Pick<
  TObj,
  Exclude<keyof TObj, ArrayLengthMutationKeys>
> & {
  readonly length: L
  [I: number]: T
  [Symbol.iterator]: () => IterableIterator<T>
}

export enum LocationType {
  prefecture = 'prefecture',
  national = 'national',
}

export type SchoolLevelNameType4 =
  | 'ELEMENTARY_SCHOOL'
  | 'JUNIOR_HIGH_SCHOOL'
  | 'ALL_DAY_HIGH_SCHOOL'
  | 'FIX_TIME_HIGH_SCHOOL'
