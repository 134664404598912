import { Dashboard } from '../layout/Dashboard'
import { Card } from '@alpha/components'
import { Card as ACard } from 'antd'
import TestRankBarChart from '../graph/TestRankBarChart'
import { ReportTestRankTargetByYear, SchoolCategory } from '@alpha/core'
import { NoData } from '../shared/NoData'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { prefectureCode } from '../../utils/constant'
import { testRankTargetGraphData } from './data/test-rank-target-graph/data'

type Props = {
  prefectureCode: number
  forPrinting: boolean
}

const ReportTestRankTargetGraph = ({ prefectureCode, forPrinting }: Props) => {
  // From 2023, add data into the Frontend, not from the API anymore.
  // const [getReport, { data, loading }] =
  //   useReportTestRankTargetSummaryLazyQuery({
  //     fetchPolicy: 'network-only',
  //   })

  // useEffect(() => {
  //   if (prefectureCode === 0) return
  //   getReport({
  //     variables: {
  //       input: {
  //         prefectureCode,
  //       },
  //     },
  //   })
  // }, [prefectureCode, getReport])

  // if (loading) {
  //   return <Loading />
  // }

  // if (!data?.reportTestRankTargetSummary?.data) {
  //   return <NoData />
  // }

  const reportData = testRankTargetGraphData.reportTestRankTargetSummary.data

  const reports = reportData.reports
    .slice()
    .sort((a, b) => b.year! - a.year!) as ReportTestRankTargetByYear[]

  const national = reportData.national
    .slice()
    .sort((a, b) => b.year! - a.year!) as ReportTestRankTargetByYear[]

  if (!reports) {
    return <NoData />
  }

  return (
    <>
      <ACard title="全体の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={null}
          forPrinting={forPrinting}
        />
      </ACard>
      <ACard title="小学校の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={SchoolCategory.ElementarySchool}
          forPrinting={forPrinting}
        />
      </ACard>
      <ACard title="中学校の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={SchoolCategory.JuniorHighSchool}
          forPrinting={forPrinting}
        />
      </ACard>
      <ACard title="高校の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={reports}
          targetType={SchoolCategory.HighSchool}
          forPrinting={forPrinting}
        />
      </ACard>
      <ACard title="全国調査の年次推移" style={{ pageBreakAfter: 'always' }}>
        <TestRankBarChart
          data={national}
          targetType={'national'}
          forPrinting={forPrinting}
        />
      </ACard>
    </>
  )
}

const ReportTestRankTargetGraphPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  return (
    <Dashboard
      navbar={
        <div className="theme-nav-title">総合評価段階別人数の割合の推移</div>
      }
      selectedMenu={graphItem.tabIndex}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="min-h-full print:hidden">
        <Card>
          <div style={{ minWidth: 600 }}>
            <ReportTestRankTargetGraph
              prefectureCode={prefectureCode}
              forPrinting={false}
            />
          </div>
        </Card>
      </div>
      <div className="hidden print:block">
        <div>茨城県児童生徒の体力・運動能力調査結果</div>
        <div>測定項目平均値の年次推移</div>
        <ReportTestRankTargetGraph
          prefectureCode={prefectureCode}
          forPrinting
        />
      </div>
    </Dashboard>
  )
}

export default ReportTestRankTargetGraphPage
