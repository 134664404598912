/**
 * To migrate data from Excel: just copy and paste the data from the Excel file.
 */
export const count2024 = {
  elementarySchoolReport: {
    schoolCount: 447,
    reports: [
      [1, 10576, 10337, 10158, 9953],
      [2, 10981, 10732, 10381, 10159],
      [3, 11346, 11019, 10887, 10630],
      [4, 11581, 11087, 10950, 10588],
      [5, 11575, 11078, 11004, 10563],
      [6, 11726, 11106, 11485, 10946],
    ].map(r => ({
      grade: r[0],
      male: {
        totalCount: r[1],
        samplingCount: r[2],
      },
      female: {
        totalCount: r[3],
        samplingCount: r[4],
      }
    })),
  },
  juniorHighSchoolReport: {
    schoolCount: 230,
    reports: [
      [1, 11548, 10450, 10848, 9921],
      [2, 11794, 10367, 11064, 9711],
      [3, 12100, 10783, 11316, 9926],      
    ].map(r => ({
      grade: r[0],
      male: {
        totalCount: r[1],
        samplingCount: r[2],
      },
      female: {
        totalCount: r[3],
        samplingCount: r[4],
      }
    })),
  },
  allDayHighSchoolReport: {
    schoolCount: 91,
    reports: [
      [1, 8403, 7863, 7698, 7177],
      [2, 8022, 7469, 7540, 7046],
      [3, 8144, 7693, 7468, 7034],    
    ].map(r => ({
      grade: r[0],
      male: {
        totalCount: r[1],
        samplingCount: r[2],
      },
      female: {
        totalCount: r[3],
        samplingCount: r[4],
      }
    })),
  },
  fixTimeHighSchoolReport: {
    schoolCount: 13,
    reports: [
      [1, 271, 247, 186, 162],
      [2, 274, 257, 140, 131],
      [3, 281, 268, 136, 118],
      [4, 41, 37, 16, 16],
    ].map(r => ({
      grade: r[0],
      male: {
        totalCount: r[1],
        samplingCount: r[2],
      },
      female: {
        totalCount: r[3],
        samplingCount: r[4],
      }
    })),
  },
}
