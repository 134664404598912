import { TestRankReportFromExcel, TestRankReportTotalsFromExcel } from './type'

/**
 * To migrate data from Excel:
 *   - On Excel, just select all data's cell.
 *   - Ctrl + C
 *   - Ctrl + V on your Editor/IDE
 *   - Replace comma character with `.` if needed.
 *   - Replace `tab` character between number with comma character
 *   - Wrap each line by the `[]` character (an array)
 */
export const testRank2024Male: TestRankReportFromExcel = [
  [10337, 1674, 2899, 3034, 1726, 1004, 16.19, 28.04, 29.35, 16.70, 9.71],
  [10732, 1656, 2621, 3442, 2096, 917, 15.43, 24.42, 32.07, 19.53, 8.54],
  [11019, 2121, 3025, 3084, 1866, 923, 19.25, 27.45, 27.99, 16.93, 8.38],
  [11087, 2317, 2817, 2860, 1872, 1221, 20.90, 25.41, 25.80, 16.88, 11.01],
  [11078, 2254, 2521, 3054, 2081, 1168, 20.35, 22.76, 27.57, 18.78, 10.54],
  [11106, 2341, 2975, 2757, 2020, 1013, 21.08, 26.79, 24.82, 18.19, 9.12],
  [10450, 1022, 2546, 3138, 2759, 985, 9.78, 24.36, 30.03, 26.40, 9.43],
  [10367, 1791, 2987, 3048, 1829, 712, 17.28, 28.81, 29.40, 17.64, 6.87],
  [10783, 3229, 2962, 2593, 1383, 616, 29.95, 27.47, 24.05, 12.83, 5.71],
  [7863, 1311, 2273, 2632, 1253, 394, 16.67, 28.91, 33.47, 15.94, 5.01],
  [7469, 1676, 2207, 2280, 1062, 244, 22.44, 29.55, 30.53, 14.22, 3.27],
  [7693, 1907, 2412, 2109, 1030, 235, 24.79, 31.35, 27.41, 13.39, 3.05],
  [247, 3, 14, 59, 78, 93, 1.21, 5.67, 23.89, 31.58, 37.65],
  [257, 10, 29, 80, 94, 44, 3.89, 11.28, 31.13, 36.58, 17.12],
  [268, 10, 41, 88, 87, 42, 3.73, 15.30, 32.84, 32.46, 15.67],
  [37, 0, 8, 9, 13, 7, 0.00, 21.62, 24.32, 35.14, 18.92],
]

export const testRank2024Female: TestRankReportFromExcel = [
  [9953, 1684, 2715, 3117, 1611, 826, 16.92, 27.28, 31.32, 16.19, 8.30],
  [10159, 1729, 2565, 3240, 1974, 651, 17.02, 25.25, 31.89, 19.43, 6.41],
  [10630, 2274, 3015, 3023, 1710, 608, 21.39, 28.36, 28.44, 16.09, 5.72],
  [10588, 2614, 2904, 2777, 1548, 745, 24.69, 27.43, 26.23, 14.62, 7.04],
  [10563, 2584, 2818, 2954, 1565, 642, 24.46, 26.68, 27.97, 14.82, 6.08],
  [10946, 2463, 3221, 2955, 1719, 588, 22.50, 29.43, 27.00, 15.70, 5.37],
  [9921, 3447, 2992, 2306, 1014, 162, 34.74, 30.16, 23.24, 10.22, 1.63],
  [9711, 3312, 2905, 2140, 1094, 260, 34.11, 29.91, 22.04, 11.27, 2.68],
  [9926, 3564, 2460, 2225, 1240, 437, 35.91, 24.78, 22.42, 12.49, 4.40],
  [7177, 1329, 1865, 2433, 1202, 348, 18.52, 25.99, 33.90, 16.75, 4.85],
  [7046, 1407, 1911, 2178, 1212, 338, 19.97, 27.12, 30.91, 17.20, 4.80],
  [7034, 1312, 1976, 2201, 1275, 270, 18.65, 28.09, 31.29, 18.13, 3.84],
  [162, 3, 9, 45, 55, 50, 1.85, 5.56, 27.78, 33.95, 30.86],
  [131, 7, 11, 32, 43, 38, 5.34, 8.40, 24.43, 32.82, 29.01],
  [118, 1, 11, 34, 44, 28, 0.85, 9.32, 28.81, 37.29, 23.73],
  [16, 1, 0, 3, 8, 4, 6.25, 0.00, 18.75, 50.00, 25.00],
]

export const testRank2024Totals: TestRankReportTotalsFromExcel = [
  [128198, 25711, 34096, 36297, 21788, 10306, 20.06, 26.60, 28.31, 17.00, 8.04],
  [61158, 16365, 16852, 15450, 9319, 3172, 26.76, 27.55, 25.26, 15.24, 5.19],
  [44282, 8942, 12644, 13833, 7034, 1829, 20.19, 28.55, 31.24, 15.88, 4.13],
  [1236, 35, 123, 350, 422, 306, 2.83, 9.95, 28.32, 34.14, 24.76]
]
