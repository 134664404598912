import { Button, SelectMenu } from '@sergeimeza/uikit-react'
import { Select, Table } from 'antd'
import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { utils, writeFile } from 'xlsx'

import { makeYearName } from '../../utils'
import cities from '../../utils/cities.json'
import { defaultSchoolYear } from '../../utils/constant'
import { schoolCategories } from '../../utils/schoolCategories'
import { titles } from '../../utils/titles'
const { Option } = Select

const sample = {
  year: 2021,
  city: '宇都宮市',
  schoolName: '那珂市立横堀小学校',
  grade: 1,
  gender: 1,
  age: 6,
  family_name: 'メサメサ',
  given_name: 'セルゲイセルゲイ',
  family_name_hiragana: 'めさめさ',
  given_name_hiragana: 'せるげいせるげい',
  q1: 1,
  q2: 1,
  q3: 1,
  q4: 1,
  q5: 1,
  q6: 1,
  q7: 1,
  height: 180,
  weight: 80,
  grip: 20,
  sit_ups: 20,
  bending: 20,
  side_jump: 20,
  shuttle_run: 20,
  sprint_run: 20,
  standing_jump: 20,
  ball_throw: 20,
  grip_points: 8,
  sit_ups_points: 9,
  bending_points: 2,
  side_jump_points: 2,
  shuttle_run_points: 5,
  sprint_run_points: 1,
  standing_jump_points: 3,
  ball_throw_points: 8,
  fileId: '07a1210b-3eaa-488c-a09c-2dafb57d6d45',
}

const UploadResultsPage = () => {
  const [category, setCategory] = useState<any>()
  const [city, setCity] = useState<any>()
  const [schools, setSchools] = useState<any[]>()
  const [school, setSchool] = useState<any>()
  const [results, setResults] = useState<any[]>()
  const [year, setYear] = useState(defaultSchoolYear)

  const ibarakiCities = useMemo(
    () =>
      cities.map((city) => ({
        name: city.name,
        value: city.name,
      })),
    [],
  )

  const ibarakiSchools = useMemo(
    () =>
      schools?.map((school) => ({
        name: `${school.schoolName} - ${school.studentCount ?? 0}人`,
        value: school.schoolCode,
      })) ?? [],
    [schools],
  )

  function transformData(data: any[]) {
    return data.map((item) => {
      return Object.keys(sample).reduce(
        (acc, curr) => ({ ...acc, [titles[curr]]: item[curr] }),
        {},
      )
    })
  }

  const columnsForData = (data: any[]) => {
    if (!data?.length) return []
    let sample = data[0]
    return Object.keys(sample).map((key) => ({
      title: key,
      dataIndex: key,
      key,
    }))
  }

  useEffect(() => {
    if (!city) return
    if (!category) return

    axios
      .create({ baseURL: 'https://api-v2.alpha-pestalozzi.com' })
      .get(
        `/ibaraki/upload-schools/?city=${city}&category=${category}&year=${year}`,
      )
      .then((res) => {
        // results
        setSchools(res.data)
      })
      .catch(() => {})
  }, [city, category, year])

  useEffect(() => {
    if (!city) return
    if (!category) return
    if (!school) return

    axios
      .create({ baseURL: 'https://api-v2.alpha-pestalozzi.com' })
      .get(
        `/ibaraki/upload-results/?city=${city}&category=${category}&school=${school.value}&year=${year}`,
      )
      .then((res) => {
        // results
        const tResults = transformData(res.data)
        setResults(tResults)
      })
      .catch(() => {})
  }, [city, category, school, year])

  function handleDownload() {
    if (!results) return
    let workbook = utils.book_new()
    let worksheet = utils.json_to_sheet(results, {})
    utils.book_append_sheet(workbook, worksheet, 'results')
    writeFile(workbook, 'results.xlsx')
  }

  return (
    <div className="px-10 py-10 max-w-7xl mx-auto flex flex-col items-center">
      <p className="font-medium text-gray-900">新体力テストAlpha</p>
      <h1 className="font-bold text-2xl mb-8 text-gray-900">茨城県</h1>
      <div className="space-y-4 pb-8 w-full">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <div className="w-full grid grid-cols-3 gap-x-4">
        <div>
          <SelectMenu
            name="schoolCategory"
            label="学校種別選択"
            options={schoolCategories}
            type={SelectMenu.type.custom}
            onItemSelected={(item) => {
              setCategory(item.code)
            }}
          />
        </div>
        <div>
          <SelectMenu
            name="city"
            label="市町選択「所在地」"
            options={ibarakiCities}
            type={SelectMenu.type.custom}
            onItemSelected={(item) => {
              setCity(item.value)
            }}
          />
        </div>
        {!!ibarakiSchools.length && (
          <div>
            <SelectMenu
              name="school"
              label="学校"
              options={ibarakiSchools}
              type={SelectMenu.type.custom}
              onItemSelected={(item) => {
                setSchool(item)
              }}
            />
          </div>
        )}
      </div>

      <div className="w-full">
        <div className="flex justify-end my-4">
          {!!results?.length && (
            <Button title="ダウンロード" onClick={handleDownload} />
          )}
        </div>
        {!results?.length && <div className="my-8">記録がありません。</div>}

        {!!results?.length && (
          <div className="overflow-x-auto bg-white px-4">
            <Table
              className="min-w-[4400px]"
              style={{
                minWidth: 4400,
              }}
              dataSource={results}
              columns={columnsForData(results)}
              pagination={{
                pageSize: 100,
                position: ['topLeft', 'bottomLeft'],
              }}
              scroll={{ x: true }}
              size="small"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default UploadResultsPage
