import { useState } from 'react'

// import { Select, Table } from 'antd'
import { Select } from 'antd'
// import { ColumnType } from 'antd/es/table'

import { Card } from '@alpha/components'

import { Dashboard } from '../layout/Dashboard'
import { makeYearName } from '../../utils'
// import { Loading } from '../shared/Loading'
// import { NoData } from '../shared/NoData'
// import {
// ReportSchoolPerformance,
// useReportSchoolPerformanceSummaryLazyQuery,
// } from '@alpha/core'
import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'

import R3SchoolPrize from '../../assets/r3_schools_prize.png'
import R3SchoolPrize2 from '../../assets/r3_schools_prize_2.png'
import R4SchoolPerformance from '../../assets/r4_school_performance.png'
import { defaultSchoolYear } from '../../utils/constant'

const { Option } = Select

// const tableColumns: ColumnType<ReportSchoolPerformance>[] = [
//   {
//     title: '学校コード',
//     dataIndex: 'prefectureSchoolCode',
//     key: 'prefectureSchoolCode',
//   },
//   {
//     title: '学校名',
//     dataIndex: 'schoolName',
//     key: 'schoolName',
//   },
//   {
//     title: '生徒数',
//     dataIndex: 'studentCount',
//     key: 'studentCount',
//     sorter: (a, b) => a.studentCount! - b.studentCount!,
//     sortDirections: ['ascend', 'descend'],
//   },
//   {
//     title: '(A+B)-(D+E)の割合',
//     dataIndex: 'score',
//     key: 'score',
//     sorter: (a, b) => a.score! - b.score!,
//     sortDirections: ['descend'],
//     render: (score) => score.toFixed(2),
//   },
// ]

// const ReportSchoolPerformanceTable = ({
//   prefectureCode,
//   year,
//   schoolType,
//   schoolScale,
// }: {
//   prefectureCode: number
//   year: number
//   schoolType: string
//   schoolScale: number
// }) => {
//   const [schools, setSchools] = useState<ReportSchoolPerformance[]>([])

//   const [
//     getReportSchoolPerformanceSummary,
//     { data: reportSchoolPerformanceData, called, loading },
//   ] = useReportSchoolPerformanceSummaryLazyQuery({
//     fetchPolicy: 'network-only',
//   })

//   useEffect(() => {
//     if (prefectureCode === 0) return

//     const variables = {
//       input: {
//         prefectureCode,
//         year,
//       },
//     }
//     getReportSchoolPerformanceSummary({ variables })
//   }, [getReportSchoolPerformanceSummary, prefectureCode, year])

//   useEffect(() => {
//     if (!reportSchoolPerformanceData?.reportSchoolPerformanceSummary?.data) {
//       return
//     }

//     let _schools: ReportSchoolPerformance[] = []
//     switch (schoolType) {
//       case 'elementarySchool':
//         _schools =
//           reportSchoolPerformanceData.reportSchoolPerformanceSummary.data
//             .elementarySchool ?? []
//         if (schoolScale !== 0) {
//           _schools = _schools.filter((s) => s.scale === schoolScale)
//         }
//         setSchools(_schools)
//         break
//       case 'juniorHighSchool':
//         _schools =
//           reportSchoolPerformanceData.reportSchoolPerformanceSummary.data
//             .juniorHighSchool ?? []
//         if (schoolScale !== 0) {
//           _schools = _schools.filter((s) => s.scale === schoolScale)
//         }
//         setSchools(_schools)
//         break
//       case 'highSchool':
//         _schools =
//           reportSchoolPerformanceData.reportSchoolPerformanceSummary.data
//             .highSchool ?? []
//         if (schoolScale !== 0) {
//           _schools = _schools.filter((s) => s.scale === schoolScale)
//         }
//         setSchools(_schools)
//         break
//     }
//   }, [
//     reportSchoolPerformanceData,
//     prefectureCode,
//     year,
//     schoolType,
//     schoolScale,
//   ])

//   if (loading) {
//     return <Loading />
//   }

//   if (
//     !reportSchoolPerformanceData?.reportSchoolPerformanceSummary ||
//     !reportSchoolPerformanceData.reportSchoolPerformanceSummary.data
//   ) {
//     if (called) {
//       return <NoData />
//     } else {
//       return null
//     }
//   }

//   return (
//     <Table
//       className="w-full overflow-x-scroll"
//       columns={tableColumns}
//       dataSource={schools}
//       rowKey="prefectureSchoolCode"
//     />
//   )
// }

// const SchoolTypes = {
//   elementarySchool: '小学校・義務教育学校（前期課程）',
//   juniorHighSchool:
//     '中学校・義務教育学校（後期課程）・中等教育学校（前期課程）',
//   highSchool: '中等教育学校（後期課程）・高等学校',
// }

const ReportSchoolPerformanceListPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const [year, setYear] = useState(defaultSchoolYear)
  // const [schoolType, setSchoolType] = useState('elementarySchool')
  // const [schoolScale, setSchoolScale] = useState(1)

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={
        <div className="theme-nav-title">
          体力つくり関係表彰校一覧（体力つくり優秀賞用）
        </div>
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Card>
        <div className="space-x-4 pb-8">
          <Select value={year} onChange={(v) => setYear(v)}>
            <Option value={2024}>2024 ({makeYearName(2024)})</Option>
            <Option value={2023}>2023 ({makeYearName(2023)})</Option>
            <Option value={2022}>2022 ({makeYearName(2022)})</Option>
            <Option value={2021}>2021 ({makeYearName(2021)})</Option>
          </Select>
          {year === 2021 && (
            <>
              <img
                className="mt-8 w-full"
                src={R3SchoolPrize}
                alt="school_prize"
              />
              <img
                className="w-full"
                src={R3SchoolPrize2}
                alt="school_prize_2"
              />
            </>
          )}
          {year === 2022 && (
            <>
              <img
                className="min-w-[800px]"
                src={R4SchoolPerformance}
                alt="school_performance"
              />
            </>
          )}
          {/* <Select value={schoolType} onChange={(v) => setSchoolType(v)}>
            <Option value="elementarySchool">
              {SchoolTypes['elementarySchool']}
            </Option>
            <Option value="juniorHighSchool">
              {SchoolTypes['juniorHighSchool']}
            </Option>
            <Option value="highSchool">{SchoolTypes['highSchool']}</Option>
          </Select>
          <Select value={schoolScale} onChange={(v) => setSchoolScale(v)}>
            <Option value={0}>全て</Option>
            <Option value={1}>小規模校（生徒数 120 人以下）</Option>
            <Option value={2}>小規模校（生徒数 121 人～240 人）</Option>
            <Option value={3}>中規模校（生徒数 241 人～480 人）</Option>
            <Option value={4}>大規模校（生徒数 481 人以上） </Option>
          </Select> */}
        </div>

        {/* <ReportSchoolPerformanceTable
          prefectureCode={prefectureCode}
          year={year}
          schoolType={schoolType}
          schoolScale={schoolScale}
        /> */}
      </Card>
    </Dashboard>
  )
}

export default ReportSchoolPerformanceListPage
